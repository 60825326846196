import React, { ReactElement, forwardRef, Ref, useImperativeHandle, useState, useRef } from 'react';

import ZIndex from 'constants/zIndex';

import ModalCore from './ModalCore';
import { ModalContainer } from './Modal.styles';
import { TModalProps, TModalRef } from './Modal.types';
import { MODAL_TRANSITION } from './Modal.constants';
import ModalContent from './views/ModalContent';

function Modal(
  {
    children,
    rootId,
    zIndex = ZIndex.COMMON_MODAL,
    maskClosable = true,
    afterOpen,
    afterClose,
    header,
    isFullPage,
    loadingProps,
    ...restProps
  }: TModalProps,
  ref: Ref<TModalRef>
): ReactElement {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    show: showModal,
  }));

  function hideModal() {
    setModalVisible(false);
    afterClose?.();
  }

  function showModal(shouldShow: boolean) {
    if (shouldShow) {
      setModalVisible(true);
      afterOpen?.();
    } else {
      hideModal();
    }
  }

  return modalVisible ? (
    <ModalCore rootId={rootId}>
      <ModalContainer
        zIndex={zIndex}
        {...(maskClosable && {
          onClick: hideModal,
        })}
        isFullPage={isFullPage}
        animate={{ opacity: 1 }}
        transition={MODAL_TRANSITION}>
        <ModalContent onClose={hideModal} header={header} isFullPage={isFullPage} {...restProps}>
          {loadingProps?.loading ? loadingProps.shimmerContent : children}
        </ModalContent>
      </ModalContainer>
    </ModalCore>
  ) : (
    <></>
  );
}

export const useModal = () => useRef<TModalRef>(null);

export default forwardRef(Modal);
