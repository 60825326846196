import { isNil } from 'lodash/fp';

import { TI18nId } from 'types/common';

enum CardType {
  VIRTUAL = 0,
  PHYSICAL = 1,
}

const MAP: Record<
  CardType,
  {
    labelId: TI18nId;
    badgeLabelId: TI18nId;
    lowercaseLabelId: TI18nId;
    createCardTotalStep: number;
    cardColorSelectDescriptionId: TI18nId;
    type: 'VIRTUAL' | 'PHYSICAL';
  }
> = {
  [CardType.VIRTUAL]: {
    labelId: 'label.virtualCard',
    badgeLabelId: 'label.virtual',
    lowercaseLabelId: 'label.virtual.lowercase',
    createCardTotalStep: 4,
    type: 'VIRTUAL',
    cardColorSelectDescriptionId: 'modals.createCardColorSelect.virtual.description',
  },
  [CardType.PHYSICAL]: {
    labelId: 'label.physicalCard',
    badgeLabelId: 'label.physical',
    lowercaseLabelId: 'label.physical.lowercase',
    createCardTotalStep: 5,
    type: 'PHYSICAL',
    cardColorSelectDescriptionId: 'modals.createCardColorSelect.physical.description',
  },
};

export function isVirtualCard(cardType: CardType) {
  return cardType === CardType.VIRTUAL;
}

export function isPhysicalCard(cardType: CardType) {
  return cardType === CardType.PHYSICAL;
}

export function getLabelIdByCardType(cardType: CardType): TI18nId {
  return MAP[cardType]?.labelId;
}

export function getLowercaseLabelIdByCardType(cardType: CardType): TI18nId {
  return MAP[cardType]?.lowercaseLabelId;
}

export function getCreateCardTotalStepByCardType(cardType?: CardType): number {
  return isNil(cardType) ? 0 : MAP[cardType].createCardTotalStep;
}

export function getCardBadgeLabelIdByCardType(cardType: CardType): TI18nId {
  return MAP[cardType].badgeLabelId;
}

export function getCardColorSelectByCardType(cardType: CardType): TI18nId {
  return MAP[cardType]?.cardColorSelectDescriptionId;
}

export function getTypeByCardType(cardType: CardType): string {
  return MAP[cardType]?.type;
}

export default CardType;
