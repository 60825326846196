import React, { useEffect, useState } from 'react';

import SideLayoutPage from 'components/SideLayoutPage';
import InfoItem from 'components/core/InfoItem';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import Button from 'components/core/Button';
import MemberPermission from 'components/MemberPermission';

import { TI18nId } from 'types/common';
import MemberType from 'enums/MemberType';

import { TRecipientDetailsProps } from './RecipientDetails.types';
import { useRecipientOperations } from './RecipientDetails.hooks';
import RecipientDetailsLoading from './views/RecipientDetailsLoading';
import RecipientAuthenticationSwitch from './views/RecipientAuthenticationSwitch';

function RecipientDetails({
  id,
  fetchRecipients,
  isDeleteVisible = true,
  isAuthenticationPreferenceVisible = true,
}: TRecipientDetailsProps) {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const {
    recipient,
    getDetail,
    updateAuthentication,
    updateAuthenticationLoading,
    showDeleteModal,
    renderedModals,
  } = useRecipientOperations({
    id,
    afterDelete: fetchRecipients,
  });

  useEffect(() => {
    handleInitialFetchRecipient();
  }, [id]);

  async function handleInitialFetchRecipient() {
    setInitialLoading(true);
    await getDetail();
    setInitialLoading(false);
  }

  if (initialLoading || !recipient) {
    return <RecipientDetailsLoading />;
  }

  return (
    <>
      <SideLayoutPage headerId={recipient.name as TI18nId}>
        <Box paddingX="m" alignment="col.verticalCenter" flex="auto">
          <Card paddingX="m" marginBottom="m">
            {recipient.iban && <InfoItem titleId="label.iban" value={recipient.iban} />}
            {recipient.accountNumber && (
              <InfoItem titleId="label.accountNumber" value={recipient.accountNumber} />
            )}
            {recipient.routingNumber && (
              <InfoItem titleId="label.routingNumber" value={recipient.routingNumber} />
            )}
            {recipient.bicSwift && (
              <InfoItem titleId="label.bicSwiftCode" value={recipient.bicSwift} />
            )}
            {recipient.sortCode && <InfoItem titleId="label.sortCode" value={recipient.sortCode} />}
            <InfoItem titleId="label.currency" value={recipient.currency.name} />
            {recipient.email && <InfoItem titleId="label.email" value={recipient.email} />}
          </Card>
          {isAuthenticationPreferenceVisible && (
            <MemberPermission allowTo={[MemberType.DIRECTOR]}>
              <RecipientAuthenticationSwitch
                recipient={recipient}
                onChange={updateAuthentication}
                loading={updateAuthenticationLoading}
              />
            </MemberPermission>
          )}
          <MemberPermission allowTo={[MemberType.DIRECTOR]}>
            <Box
              marginTop="l"
              flex="auto"
              alignment="col.bottom.left"
              justifyContent="flex-end"
              marginBottom="m">
              {isDeleteVisible && (
                <Button id="button.delete" variant="danger" onClick={showDeleteModal} />
              )}
            </Box>
          </MemberPermission>
        </Box>
      </SideLayoutPage>
      {renderedModals}
    </>
  );
}

export default RecipientDetails;
