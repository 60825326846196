import React from 'react';

import InfoItem from 'components/core/InfoItem';

import { TExchangeDetailProps } from '../TransactionDetail.types';

function ExchangeDetail({ detail, formattedExchangeAmount }: TExchangeDetailProps) {
  function getRate() {
    return `${detail.sourceCurrency.symbol}1 = ${detail.targetCurrency.symbol}${detail.rate}`;
  }

  return (
    <>
      <InfoItem titleId="label.exchangeAmount" value={formattedExchangeAmount} />
      <InfoItem titleId="label.rate" value={getRate()} />
    </>
  );
}

export default ExchangeDetail;
