import { Options } from 'react-lottie';

import NOOP from 'constants/noop';

import warningLottieData from 'assets/lotties/warning.json';
import successLottieData from 'assets/lotties/success.json';
import errorLottieData from 'assets/lotties/error.json';

import { TAlertModalShowOptions, TAlertModalType } from './AlertModal.types';

export const ALERT_MODAL_ID = 'alert-modal-root';

export const INITIAL_STATE: TAlertModalShowOptions = {
  title: undefined,
  titleValues: undefined,
  titleId: undefined,
  text: undefined,
  textValues: undefined,
  textId: undefined,
  bottomContent: undefined,
  disableOnClose: false,
  showCancelButton: true,
  type: TAlertModalType.success,
  onCancel: NOOP,
};

export const WARNING_LOTTIE_OPTIONS: Options = {
  loop: false,
  autoplay: true,
  animationData: warningLottieData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const SUCCESS_LOTTIE_OPTIONS: Options = {
  loop: false,
  autoplay: true,
  animationData: successLottieData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const ERROR_LOTTIE_OPTIONS: Options = {
  loop: false,
  autoplay: true,
  animationData: errorLottieData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const ALERT_MODAL_LOTTIE: { [key in TAlertModalType]: Options } = {
  success: SUCCESS_LOTTIE_OPTIONS,
  warning: WARNING_LOTTIE_OPTIONS,
  error: ERROR_LOTTIE_OPTIONS,
};
