import React from 'react';

import SideLayoutPage from 'components/SideLayoutPage';
import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';
import TransactionItem from 'components/TransactionItem';

function ScheduledBulkTransferDetailShimmer() {
  return (
    <SideLayoutPage.Shimmer>
      <Box paddingX="m">
        <Card mb="m">
          <TransactionItem.Shimmer count={1} />
        </Card>
        <Card mb="m">
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
        </Card>
        <Shimmer shape="line" width="20%" mb="s" />
        <Box
          marginTop="l"
          flex="auto"
          alignment="col.bottom.left"
          justifyContent="flex-end"
          marginBottom="m">
          <Shimmer shape="square" color="secondary" width="100%" marginTop="m" />
        </Box>
      </Box>
    </SideLayoutPage.Shimmer>
  );
}

export default ScheduledBulkTransferDetailShimmer;
