import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const TrashIcon = ({ iconColor = 'danger', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M6 9V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V9M14 10V17M10 10V17M4 6H20M16 6V5C16 3.89543 15.1046 3 14 3H11.5H10C8.89543 3 8 3.89543 8 5V6"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
