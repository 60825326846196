import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TBillingPackagesState, TBillingPackagesDispatch } from './BillingPackagesProvider.types';

export const INITIAL_STATE: TBillingPackagesState = {
  billingPackages: undefined,
  loading: true,
  hasError: false,
};

export const INITIAL_DISPATCH: TBillingPackagesDispatch = {
  getBillingPackages: ASYNC_NOOP,
  clear: NOOP,
};
