import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CalendarIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M6 6V3M10 6V3M14 6V3M21 10H3M18 6V3M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
