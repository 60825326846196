import 'react-day-picker/dist/style.css';
import styled, { css } from 'styled-components';

import { getColor } from 'helpers/theme';
import { FONT_FAMILY, FONT_SIZE } from 'theme/v2/fonts';

export const CalendarCss = css`
  .rdp {
    --rdp-background-color: ${getColor('buttonGhostPrimary')};
    --rdp-accent-color: ${getColor('buttonGhostPrimary')};

    .rdp-head_cell {
      text-transform: lowercase;
      font-weight: 400;
      font-family: ${FONT_FAMILY};
      font-size: ${FONT_SIZE.textSmall.fontSize};
      line-height: ${FONT_SIZE.textSmall.lineHeight};
      color: ${getColor('textPassive')};
    }

    .rdp-day {
      color: ${getColor('textPrimary')};
    }

    .rdp-day_today:not(.rdp-day_outside) {
      font-weight: bold;
      color: ${getColor('textActive')};
    }

    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
      color: ${getColor('textActive')};
      opacity: 1;
      background-color: var(--rdp-accent-color);
    }

    .rdp-caption_label {
      font-weight: 600;
      font-family: ${FONT_FAMILY};
      font-size: ${FONT_SIZE.textBody.fontSize};
      line-height: ${FONT_SIZE.textBody.lineHeight};
      color: ${getColor('textPrimary')};
    }

    .rdp-nav_button {
      svg {
        color: ${getColor('textActive')};
      }
    }

    .rdp-button[disabled]:not(.rdp-day_selected) {
      opacity: 1;
      color: ${getColor('textPassive')};
    }
  }
`;

export const StyledDay = styled.time`
  font-size: ${FONT_SIZE.titleSubsection.fontSize};
  line-height: ${FONT_SIZE.titleSubsection.lineHeight};
  font-family: ${FONT_FAMILY};
  font-weight: 600;
`;
