import React, { memo } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import Button from 'components/core/Button';

import { showErrorAlert } from 'helpers/showAlertModal';
import file from 'helpers/file';
import { TDownloadStatementButtonProps } from '../LedgerEntrySideLayoutPage.types';

function DownloadStatementButton({ statementApiEndpoint }: TDownloadStatementButtonProps) {
  const { loading, get: requestGetStatement, response } = useFetch(statementApiEndpoint);

  async function handleDownload() {
    try {
      const pdfContent = await requestGetStatement();
      if (pdfContent?.errorMessage) {
        showErrorAlert({ title: pdfContent?.errorMessage });
        return;
      }

      file.downloadPdf(response);
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'label.unexpectedError' });
    }
  }

  return (
    <Button
      id="button.downloadStatement"
      marginTop="m"
      loading={loading}
      disabled={loading}
      onClick={handleDownload}
    />
  );
}

export default memo(DownloadStatementButton);
