import React from 'react';

import Box from 'components/core/Box';

import isLastIndexOfArray from 'helpers/isLastIndexOfArray';

import OnboardingProgressStepsItem from './views/OnboardingProgressStepsItem';
import { StyledContainer } from './OnboardingProgressSteps.styles';

import { TOnboardingProgressStepsProps } from './OnboardingProgressSteps.types';

function OnboardingProgressSteps({
  steps,
  activeParentStepIndex,
  activeChildStepIndex,
}: TOnboardingProgressStepsProps) {
  return (
    <StyledContainer>
      {steps.map((item, i) => {
        const parentStepIndex = i + 1;
        const isParentStepActive = activeParentStepIndex === parentStepIndex;

        return (
          <Box key={item.index} mb={isLastIndexOfArray(i, steps) ? 'none' : 'l'}>
            <OnboardingProgressStepsItem
              type={item.type}
              labelId={item.labelId}
              active={isParentStepActive}
              completed={parentStepIndex < activeParentStepIndex}
              order={parentStepIndex}
            />
            {isParentStepActive &&
              item.subSteps?.map((subItem, j: number) => (
                <Box key={subItem.index} mt="m">
                  <OnboardingProgressStepsItem
                    type={subItem.type}
                    labelId={subItem.labelId}
                    active={activeChildStepIndex === j + 1}
                  />
                </Box>
              ))}
          </Box>
        );
      })}
    </StyledContainer>
  );
}

export default OnboardingProgressSteps;
