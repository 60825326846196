import { TOnlinePaymentLedger } from 'types/onlinePayments';
import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TOnlinePaymentsState, TOnlinePaymentsDispatch } from './OnlinePaymentsProvider.types';

export const INITIAL_STATE: TOnlinePaymentsState = {
  loading: true,
  ledgers: [],
  selectedLedger: undefined,
};

export const INITIAL_DISPATCH: TOnlinePaymentsDispatch = {
  setLedgers: NOOP,
  setSelectedLedger: NOOP,
  requestGetOnlinePaymentsLedgers: ASYNC_NOOP,
  findMainLedger: () => ({} as TOnlinePaymentLedger),
  clearState: NOOP,
};
