import React from 'react';
import { motion } from 'framer-motion';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import ListCardButton from 'components/ListCardButton';
import { BankIcon, FreezeIcon, MoreIcon, EyeOpenIcon } from 'components/Icons';

import useCardFreeze from 'hooks/useCardFreeze';
import { isCardActivating, isCardFreezing, isCardFrozen } from 'enums/CardStatus';
import { formatAmount } from 'helpers/formatAmount';
import { useMemberContext } from 'context/MemberProvider';
import MemberType from 'enums/MemberType';

import ActionButton from './ActionButton';
import { TCardDetailSummaryProps } from '../CardDetail.types';
import { CARD_SUMMARY_ANIMATION_VARIANTS } from '../CardDetail.constants';
import CardDetailTransactions from './CardDetailTransactions';

function CardDetailSummary({
  card,
  isFrozen,
  onShowSettingsClick,
  onCardInfoClick,
  onRequestCardDetail,
}: TCardDetailSummaryProps) {
  const { memberState } = useMemberContext();
  const {
    handleCardFreeze,
    handleCardUnfreeze,
    loading: cardFreezeLoading,
  } = useCardFreeze({
    cardId: card.id,
    requestCardDetail: onRequestCardDetail,
    cardStatus: card.status,
  });
  const isBalanceShow = memberState?.memberType !== MemberType.EMPLOYEE || memberState?.showBalance;

  return (
    <motion.div
      variants={CARD_SUMMARY_ANIMATION_VARIANTS}
      initial="initial"
      animate="enter"
      transition={{ bounce: 0 }}>
      <Box display="flex" flexDirection="row" mt="l" mb="xl">
        {!isCardFrozen(card.status) && (
          <ActionButton
            titleId="screens.cardDetail.showDetails.title"
            icon={EyeOpenIcon}
            onClick={onCardInfoClick}
          />
        )}
        <ActionButton
          titleId={
            isCardFrozen(card.status) || isCardFreezing(card.status)
              ? 'screens.cardDetail.unfreeze.title'
              : 'screens.cardDetail.freeze.title'
          }
          icon={FreezeIcon}
          onClick={isFrozen ? handleCardUnfreeze : handleCardFreeze}
          loading={
            cardFreezeLoading || isCardFreezing(card.status) || isCardActivating(card.status)
          }
        />
        <ActionButton
          titleId="screens.cardDetail.settings"
          icon={MoreIcon}
          onClick={onShowSettingsClick}
        />
      </Box>
      <Box marginX="m" mb="l">
        <Text
          id="screens.cardDetail.accountInfo"
          variant="smallTextRegular"
          mb="xs"
          color="textPrimary"
          ellipsis
        />
        <ListCardButton
          titleId={card.ledger.name}
          descriptionId={card.ledger.iban ?? card.ledger.accountNumber}
          leftComponent={<BankIcon width={24} height={24} />}
          {...(isBalanceShow &&
            card.ledger.balance && {
              rightComponent: (
                <Text variant="smallTextRegular" color="textPrimary">
                  {formatAmount({
                    amount: card.ledger.balance,
                    currency: card.ledger.currency,
                  })}
                </Text>
              ),
            })}
          titleProps={{ variant: 'mediumTextBold' }}
          mb="none"
          disabledHover
        />
      </Box>
      <Box marginX="m">
        <CardDetailTransactions card={card} />
      </Box>
    </motion.div>
  );
}

export default CardDetailSummary;
