import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { addDays } from 'date-fns/fp';
import { isNil } from 'lodash/fp';

import { TCommonResponse } from 'types/common';
import { TInvoiceDetailResponse, TInvoicePreviewPdfResponse } from 'types/invoice';
import API_ENDPOINT from 'constants/apiEndpoint';
import normalizeUrl from 'helpers/normalizeUrl';
import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import { useLedgersContext } from 'context/LedgersProvider';

import {
  TInvoiceCreateState,
  TUpdateIssueDateParams,
  TUpdateDueDateParams,
  TAddCustomerParams,
  TAddItemParams,
  TEditItemParams,
  TDeleteItemParams,
  TUpdateCurrencyParams,
  TUpdatePreferredReceivingLedgerParams,
  TUpdateFooterParams,
  TFinalizeInvoiceParams,
  TGetDraftInvoiceDetailParams,
  TDeleteDraftInvoiceParams,
  TPreviewInvoicePdf,
  TRemoveCustomerParams,
} from './InvoiceCreateProvider.types';
import {
  convertToAddCustomerRequest,
  convertToDraftInvoiceUpdateRequest,
  convertToItemRequest,
  getDueDate,
} from './InvoiceCreateProvider.helpers';

export function useUpdateDraftInvoice({
  invoice,
  selectedAfterIssueDateDays,
}: TInvoiceCreateState) {
  const { ledgersState } = useLedgersContext();
  const { get: requestGetInvoiceDetail, response: invoiceDetailResponse } = useFetch<
    TCommonResponse<TInvoiceDetailResponse>
  >(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES);
  const {
    put: requestUpdateDraftInvoice,
    delete: requestDeleteDraftInvoice,
    response: updateDraftInvoiceResponse,
  } = useFetch<TCommonResponse<TInvoiceDetailResponse>>(
    normalizeUrl(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_DETAIL, {
      pathVariables: {
        id: invoice?.id as string,
      },
    })
  );
  const {
    put: requestAddCustomer,
    delete: requestDeleteCustomer,
    response: customerResponse,
  } = useFetch<TCommonResponse<TInvoiceDetailResponse>>(
    normalizeUrl(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_CUSTOMERS, {
      pathVariables: {
        id: invoice?.id as string,
      },
    })
  );
  const {
    post: requestAddItem,
    put: requestUpdateItem,
    delete: requestDeleteItem,
    response: itemResponse,
  } = useFetch<TCommonResponse<TInvoiceDetailResponse>>(
    normalizeUrl(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_ITEMS, {
      pathVariables: {
        id: invoice?.id as string,
      },
    })
  );
  const { post: requestCreateInvoiceFinalize, response: createInvoiceFinalizeResponse } = useFetch<
    TCommonResponse<TInvoiceDetailResponse>
  >(
    normalizeUrl(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_CREATE_FINALIZE, {
      pathVariables: {
        id: invoice?.id as string,
      },
    })
  );
  const { get: requestPreviewInvoicePdf, response: previewInvoicePdfResponse } = useFetch<
    TCommonResponse<TInvoicePreviewPdfResponse>
  >(
    normalizeUrl(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_PREVIEW_PDF, {
      pathVariables: {
        id: invoice?.id as string,
      },
    })
  );

  async function getDraftInvoiceDetail({
    invoiceId,
    onSuccess,
    onError,
  }: TGetDraftInvoiceDetailParams) {
    try {
      const data = await requestGetInvoiceDetail(`/${invoiceId}`);

      if (!invoiceDetailResponse.ok) {
        onError?.();
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }
      return onSuccess(data.result);
    } catch (e) {
      onError?.();
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function updateIssueDate({ issueDate, onSuccess }: TUpdateIssueDateParams) {
    try {
      const invoiceParams = invoice as TInvoiceDetailResponse;

      const dueDate = isNil(selectedAfterIssueDateDays)
        ? getDueDate(invoiceParams.dueDate, issueDate)
        : addDays(selectedAfterIssueDateDays, new Date(issueDate));

      const data = await requestUpdateDraftInvoice({
        ...convertToDraftInvoiceUpdateRequest(invoice),
        issueDate,
        dueDate,
      });

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function updateDueDate({ dueDate, onSuccess }: TUpdateDueDateParams) {
    try {
      const data = await requestUpdateDraftInvoice({
        ...convertToDraftInvoiceUpdateRequest(invoice),
        dueDate,
      });

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function addCustomer({ customer, onSuccess }: TAddCustomerParams) {
    try {
      const data = await requestAddCustomer(convertToAddCustomerRequest(customer));

      if (!customerResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function removeCustomer({ onSuccess }: TRemoveCustomerParams) {
    try {
      const data = await requestDeleteCustomer();

      if (!customerResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function addItem({ item, onSuccess }: TAddItemParams) {
    try {
      const data = await requestAddItem(convertToItemRequest(item));

      if (!itemResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function editItem({ itemId, item, onSuccess }: TEditItemParams) {
    try {
      const data = await requestUpdateItem(`/${itemId}`, convertToItemRequest(item));

      if (!itemResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function deleteItem({ item, onSuccess }: TDeleteItemParams) {
    try {
      const data = await requestDeleteItem(`/${item.id}`);

      if (!itemResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return showSuccessAlert({
        titleId: 'messages.invoiceItemDelete.success.title',
      });
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function updateCurrency({ currencyCode, onSuccess }: TUpdateCurrencyParams) {
    try {
      const isDifferentCurrency = invoice?.preferredReceivingLedger?.currency.code !== currencyCode;
      const preferredReceivingLedger = ledgersState.ledgers.find(
        (ledger) => ledger.currency.code === currencyCode
      );

      const data = await requestUpdateDraftInvoice({
        ...convertToDraftInvoiceUpdateRequest(invoice),
        currencyCode,
        ...(isDifferentCurrency && {
          preferredReceivingLedgerId: preferredReceivingLedger?.id as string,
        }),
      });

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function updatePreferredReceivingLedger({
    ledgerId,
    onSuccess,
  }: TUpdatePreferredReceivingLedgerParams) {
    try {
      const data = await requestUpdateDraftInvoice({
        ...convertToDraftInvoiceUpdateRequest(invoice),
        preferredReceivingLedgerId: ledgerId,
      });

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function updateFooter({ footer, onSuccess }: TUpdateFooterParams) {
    try {
      const data = await requestUpdateDraftInvoice({
        ...convertToDraftInvoiceUpdateRequest(invoice),
        footer,
      });

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function finalizeInvoice({ onSuccess }: TFinalizeInvoiceParams) {
    try {
      const data = await requestCreateInvoiceFinalize();

      if (!createInvoiceFinalizeResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }

      await onSuccess(data.result);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function deleteDraftInvoice({ onSuccess }: TDeleteDraftInvoiceParams) {
    try {
      const data = await requestDeleteDraftInvoice();

      if (!updateDraftInvoiceResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }
      showSuccessAlert({
        titleId: 'messages.invoiceDraftDelete.success.title',
      });
      return onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  async function previewInvoicePdf({ onSuccess }: TPreviewInvoicePdf) {
    try {
      const data = await requestPreviewInvoicePdf();

      if (!previewInvoicePdfResponse.ok) {
        return showErrorAlert({
          title: data?.errorMessage,
        });
      }
      return onSuccess(data.result.pdfData);
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    }
  }

  return {
    getDraftInvoiceDetail,
    updateIssueDate,
    updateDueDate,
    addCustomer,
    removeCustomer,
    addItem,
    editItem,
    deleteItem,
    updateCurrency,
    updatePreferredReceivingLedger,
    updateFooter,
    finalizeInvoice,
    deleteDraftInvoice,
    previewInvoicePdf,
  };
}
