import React from 'react';

import Box from 'components/core/Box';
import { WamoIcon, WamoWordmarkIcon } from 'components/v2/BrandIcons';
import Badge from 'components/v2/core/Badge';

import CardType, { getCardBadgeLabelIdByCardType } from 'enums/CardType';

import { getWamoCardColorsByVariant, getWamoCardSize } from '../WamoCard.helpers';
import { TCardTopContentProps } from '../WamoCard.types';

function CardTopContent({
  cardType = CardType.VIRTUAL,
  opacity,
  variant,
  size,
  isCardTypeBadgeVisible,
}: TCardTopContentProps) {
  const colors = getWamoCardColorsByVariant(variant);
  const { logoWidth, logoHeight, padding } = getWamoCardSize(size);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" p={padding} opacity={opacity}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="xs"
        alignSelf="flex-start"
        height={logoHeight}
        flex={1}>
        <WamoIcon width={logoWidth} height={logoHeight} iconColor={colors.logoColor} />
        {size === 'default' && <WamoWordmarkIcon height={12} iconColor={colors.logoWordmark} />}
      </Box>
      {isCardTypeBadgeVisible && (
        <Badge variant="natural" size="small" labelId={getCardBadgeLabelIdByCardType(cardType)} />
      )}
    </Box>
  );
}

export default CardTopContent;
