/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import Button from 'components/core/Button';
import Card from 'components/core/Card';
import BottomButtonBox from 'components/BottomButtonBox';

import { TMultipleSelectModalContentProps } from '../MultipleSelect.types';

function MultipleSelectModalContent<T>({
  options,
  renderOptionItem,
  onClick,
  selectedValues,
  valueKey,
  renderSelectAllOption,
}: TMultipleSelectModalContentProps<T>) {
  const [temporarySelectedValues, setTemporarySelectedValues] = useState<string[]>(selectedValues);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleClick(value: any) {
    if (temporarySelectedValues.includes(value)) {
      setTemporarySelectedValues((state) => state.filter((stateValue) => stateValue !== value));
      return;
    }
    setTemporarySelectedValues((stateValue) => [...stateValue, value]);
  }

  return (
    <>
      <Card>
        {renderSelectAllOption?.({
          selected: temporarySelectedValues.length === options.length,
          onClick: () =>
            setTemporarySelectedValues(
              temporarySelectedValues.length === options.length
                ? []
                : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  options.map((option: any) => option[valueKey])
            ),
        })}
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options.map((option: any) => (
            <div key={option[valueKey]} onClick={() => handleClick(option[valueKey])}>
              {renderOptionItem({
                ...option,
                selected: temporarySelectedValues.includes(option[valueKey]),
              })}
            </div>
          ))
        }
      </Card>
      <BottomButtonBox>
        <Button mt="m" id="button.apply" onClick={() => onClick(temporarySelectedValues)} />
      </BottomButtonBox>
    </>
  );
}

export default MultipleSelectModalContent;
