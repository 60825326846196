import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from 'types/icon';

function Snowflake01Icon({
  width = 24,
  height = 24,
  strokeWidth = 2,
  iconColor = 'textPrimary',
}: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M18.0623 8.5L5.93792 15.5M18.0623 8.5L19.1604 4.40192M18.0623 8.5L22.1604 9.59808M5.93792 15.5L1.83984 14.4019M5.93792 15.5L4.83984 19.5981M18.0622 15.4999L5.93784 8.49986M18.0622 15.4999L22.1604 14.4018M18.0622 15.4999L19.1604 19.598M5.93784 8.49986L4.83999 4.40203M5.93784 8.49986L1.83999 9.59819M12.0001 5L12.0001 19M12.0001 5L9.0001 2M12.0001 5L15.0001 2M12.0001 19L9.0001 22M12.0001 19L15.0001 22"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Snowflake01Icon;
