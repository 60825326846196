import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const OnlinePaymentsIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M20 8V3.33333C20 2.59695 19.403 2 18.6667 2H5.33333C4.59695 2 4 2.59695 4 3.33333V20.6667C4 21.403 4.59695 22 5.33333 22H8"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M14 19L18 15" stroke="#051726" strokeWidth="2" />
      <path
        d="M17 19.6948L15.46 21.2348C15.2174 21.4774 14.9294 21.6698 14.6124 21.8011C14.2955 21.9324 13.9557 22 13.6126 22C12.9197 22 12.2552 21.7247 11.7652 21.2348C11.2753 20.7448 11 20.0803 11 19.3874C11 18.6945 11.2753 18.0299 11.7652 17.54L13.3052 16"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
      <path
        d="M18.6948 18L20.2348 16.46C20.7247 15.9701 21 15.3055 21 14.6126C21 13.9197 20.7247 13.2552 20.2348 12.7652C19.7448 12.2753 19.0803 12 18.3874 12C17.6945 12 17.0299 12.2753 16.54 12.7652L15 14.3052"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default OnlinePaymentsIcon;
