import React, { cloneElement, ReactElement } from 'react';
import { isObject } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import ListItemButton from 'components/core/ListItemButton';
import Spinner from 'components/core/Spinner';

import { ICON_SIZE } from './ListItem.constants';
import { TListItemProps } from './ListItem.types';
import ListItemShimmer from './views/ListItemShimmer';

function ListItem({
  titleId,
  titleVariant = 'mediumTextBold',
  description,
  leftIcon,
  rightIcon,
  loading,
  boxProps,
  rightComponent,
  ...rest
}: TListItemProps): ReactElement {
  function renderRightIcon(): ReactElement | undefined {
    if (rest['aria-selected'] && loading) {
      return <Spinner size={ICON_SIZE.RIGHT} />;
    }
    if (rightIcon) {
      return cloneElement(rightIcon, {
        width: ICON_SIZE.RIGHT,
        height: ICON_SIZE.RIGHT,
      });
    }
    return undefined;
  }

  const renderedRightIcon = renderRightIcon();

  return (
    <ListItemButton {...rest}>
      <Box alignment="row.verticalCenter" padding="m" {...boxProps}>
        {leftIcon && (
          <Box className="left-icon" minWidth={ICON_SIZE.LEFT} marginRight="m" display="flex">
            {cloneElement(leftIcon, {
              width: ICON_SIZE.LEFT,
              height: ICON_SIZE.LEFT,
            })}
          </Box>
        )}
        <Box alignment="col.top.left" flex={1}>
          <Text id={titleId} variant={titleVariant} />
          {!!description && (
            <Text
              variant="smallTextRegular"
              color="textSecondary"
              ellipsis
              {...(isObject(description) ? description : { id: description })}
            />
          )}
        </Box>
        {rightComponent}
        {renderedRightIcon && (
          <Box minWidth={ICON_SIZE.RIGHT} marginLeft="m">
            {renderedRightIcon}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}

ListItem.Shimmer = ListItemShimmer;

export default ListItem;
