import 'react-day-picker/dist/style.css';
import styled, { css } from 'styled-components';

import { getColor } from 'helpers/theme';

export const DayPickerCss = css`
  .rdp {
    --rdp-background-color: ${getColor('buttonGhostBackground')};
    --rdp-accent-color: ${getColor('tertiary')};

    .rdp-head_cell {
      font-size: 12px;
      text-transform: lowercase;
      font-weight: 400;
      color: ${getColor('textPassive')};
    }

    .rdp-day_today:not(.rdp-day_outside) {
      font-weight: bold;
      color: ${getColor('tertiary')};
    }
    .rdp-day_today:is(.rdp-day_selected) {
      color: ${getColor('textWhite')};
    }
    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
      color: white;
      opacity: 1;
      background-color: var(--rdp-accent-color);
    }
  }
`;

export const StyledDay = styled.time`
  font-size: 18px;
  font-weight: 600;
`;
