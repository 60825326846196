import React, { ReactElement, memo } from 'react';

import Text from 'components/core/Text/Text';
import Box from 'components/core/Box';

import { TEmptyContentProps } from './EmptyContent.types';

function EmptyContent({ icon: Icon, textId, textProps }: TEmptyContentProps): ReactElement {
  return (
    <Box paddingX="m" paddingY="xl" alignment="center">
      <Icon width={72} height={72} iconColor="emptyContentIcon" />
      <Text id={textId} variant="smallTitleSemiBold" color="primary" {...textProps} />
    </Box>
  );
}

export default memo(EmptyContent);
