import lazyWithRetry from 'helpers/lazyWithRetry';

import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';

import { TOnboardingRoutes } from './Onboarding.types';

const OnboardingComplianceRequest = lazyWithRetry(() => import('./OnboardingComplianceRequest'));

const OnboardingBusinessType = lazyWithRetry(() => import('./OnboardingBusinessType'));
const OnboardingApplicationSummary = lazyWithRetry(() => import('./OnboardingApplicationSummary'));
const OnboardingBusinessOwnerDirectors = lazyWithRetry(
  () => import('./BusinessOwner/OnboardingBusinessOwnerDirectors')
);
const OnboardingBusinessOwnerShareholders = lazyWithRetry(
  () => import('./BusinessOwner/OnboardingBusinessOwnerShareholders')
);
const OnboardingSelectBillingPackage = lazyWithRetry(
  () => import('./OnboardingSelectBillingPackage')
);
const OnboardingProofIdentity = lazyWithRetry(() => import('./OnboardingProofIdentity'));
const OnboardingBusinessInformation = lazyWithRetry(
  () => import('./OnboardingBusinessInformation')
);
const OnboardingBusinessOwnersInformation = lazyWithRetry(
  () => import('./BusinessOwner/OnboardingBusinessOwnersInformation')
);
const OnboardingApplicationReject = lazyWithRetry(() => import('./OnboardingApplicationReject'));
const OnboardingAccount = lazyWithRetry(() => import('./OnboardingAccount'));

const OnboardingDocumentsInformation = lazyWithRetry(
  () => import('./OnboardingDocumentsInformation')
);
const OnboardingDocumentDetail = lazyWithRetry(() => import('./OnboardingDocumentDetail'));
const OnboardingDocumentsReview = lazyWithRetry(() => import('./OnboardingDocumentsReview'));

export const onboardingRoutes: TOnboardingRoutes = {
  onboardingBusinessType: {
    path: '/onboarding-business-type',
    element: OnboardingBusinessType,
    titleId: 'screens.headerTitle.onboardingBusinessType',
  },
  onboardingAccount: {
    path: '/application/account',
    element: OnboardingAccount,
    titleId: 'screens.headerTitle.account',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingApplicationSummary: {
    path: '/application',
    element: OnboardingApplicationSummary,
    titleId: 'screens.onboardingApplicationSummary.title',
    forbiddenApplicationStatuses: [MerchantOnboardingStatus.LIVE],
  },
  onboardingProofIdentity: {
    path: '/verify-identity',
    element: OnboardingProofIdentity,
    titleId: 'screens.headerTitle.onboardingProofIdentity',
  },
  onboardingBusinessInformation: {
    path: '/business-information',
    element: OnboardingBusinessInformation,
    titleId: 'screens.headerTitle.onboardingBusinessInformation',
    forbiddenApplicationStatuses: [
      MerchantOnboardingStatus.APPROVING,
      MerchantOnboardingStatus.REJECTED,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.LIVE,
    ],
  },
  onboardingBusinessOwnersInformation: {
    path: '/business-owners',
    element: OnboardingBusinessOwnersInformation,
    titleId: 'screens.headerTitle.onboardingBusinessInformation',
    forbiddenApplicationStatuses: [
      MerchantOnboardingStatus.APPROVING,
      MerchantOnboardingStatus.REJECTED,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.LIVE,
    ],
  },
  onboardingBusinessOwnerDirectors: {
    path: '/directors',
    element: OnboardingBusinessOwnerDirectors,
    titleId: 'screens.headerTitle.directors',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingBusinessOwnerShareholders: {
    path: '/shareholders',
    element: OnboardingBusinessOwnerShareholders,
    titleId: 'screens.headerTitle.shareholders',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingSelectBillingPackage: {
    path: '/plan',
    element: OnboardingSelectBillingPackage,
    titleId: 'screens.headerTitle.onboardingSelectBillingPackage',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingDocuments: {
    path: '/documents',
    element: OnboardingDocumentsInformation,
    titleId: 'screens.headerTitle.onboardingDocuments',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingDocumentDetail: {
    path: '/documents/:type',
    element: OnboardingDocumentDetail,
    titleId: 'screens.headerTitle.onboardingDocuments',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingDocumentsReview: {
    path: '/documents/review',
    element: OnboardingDocumentsReview,
    titleId: 'screens.headerTitle.onboardingDocuments',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
  onboardingApplicationReject: {
    path: '/application/reject',
    element: OnboardingApplicationReject,
    titleId: 'screens.onboardingApplicationReject.title',
    requiredApplicationStatuses: [MerchantOnboardingStatus.REJECTED],
  },
  onboardingComplianceRequest: {
    path: '/application/compliance-request',
    element: OnboardingComplianceRequest,
    titleId: 'screens.headerTitle.complianceRequest',
    requiredApplicationStatuses: [
      MerchantOnboardingStatus.IN_PROGRESS,
      MerchantOnboardingStatus.MANUAL_REVIEW,
      MerchantOnboardingStatus.APPROVING,
    ],
  },
};

export default onboardingRoutes;
