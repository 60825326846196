import React from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import CardItem from 'components/v2/CardItem';
import Card from 'components/core/Card';

import API_ENDPOINT from 'constants/apiEndpoint';
import replaceUrlParams from 'helpers/replaceUrlParams';
import { useSideLayoutContext } from 'context/SideLayoutProvider';
import { useCardsContext } from 'context/CardsProvider';
import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import { isPhysicalCard } from 'enums/CardType';
import { TShowPopconfirmModalProps } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { CARD_STATUS_UPDATE_MAP, CardStatusUpdate } from './CardDetail.constants';
import {
  TUseCardStatusFetch,
  TUseCardStatusFetchProps,
  TUseCardStatusUpdate,
  TUseCardStatusUpdateProps,
} from './CardDetail.types';

function useCardStatusUpdate({
  card,
  onRequestCardDetail,
}: TUseCardStatusUpdateProps): TUseCardStatusUpdate {
  const { sideLayoutDispatch } = useSideLayoutContext();
  const [unblockPinLoading, requestUnblockPin] = useCardStatusFetch(API_ENDPOINT.CARD_PIN_RESET, {
    cardId: card.id,
    onSuccess: () => {
      onRequestCardDetail();
      showSuccessAlert({
        titleId: isPhysicalCard(card.type)
          ? 'screens.cardUnblockPin.success.physical.title'
          : 'screens.cardUnblockPin.success.virtual.title',
      });
    },
  });
  const [terminateLoading, requestTerminate] = useCardStatusFetch(API_ENDPOINT.CARD_TERMINATE, {
    cardId: card.id,
    onSuccess: () => {
      onRequestCardDetail();
      sideLayoutDispatch.hideSideLayout();
    },
  });

  function handleGetPopconfirmState(
    statusUpdateType: CardStatusUpdate,
    loading: boolean,
    updateCardStatus: TShowPopconfirmModalProps['onConfirm']
  ): TShowPopconfirmModalProps {
    const { titleId, descriptionId, buttonId, buttonVariant } =
      CARD_STATUS_UPDATE_MAP[statusUpdateType];

    return {
      contentIcon: (
        <Card p="s" width="100%">
          <CardItem card={card} />
        </Card>
      ),
      titleId,
      descriptionId,
      confirmButtonProps: { id: buttonId, variant: buttonVariant },
      onConfirm: updateCardStatus,
    };
  }

  return {
    terminateShowPopconfirmState: handleGetPopconfirmState(
      CardStatusUpdate.TERMINATE,
      terminateLoading,
      requestTerminate
    ),
    unblockPinShowPopconfirmState: handleGetPopconfirmState(
      CardStatusUpdate.UNBLOCK_PIN,
      unblockPinLoading,
      requestUnblockPin
    ),
  };
}

function useCardStatusFetch(
  apiEndpoint: string,
  { cardId, onSuccess }: TUseCardStatusFetchProps
): TUseCardStatusFetch {
  const { cardsDispatch } = useCardsContext();
  const { post: requestUpdateStatus, loading } = useFetch(
    replaceUrlParams(apiEndpoint, { cardId })
  );

  async function handleUpdateCardStatus() {
    try {
      const { errorMessage } = await requestUpdateStatus();

      if (errorMessage) {
        showErrorAlert({ title: errorMessage });
        return;
      }

      onSuccess();
      cardsDispatch.getCards();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'label.unexpectedError' });
    }
  }

  return [loading, handleUpdateCardStatus];
}

export { useCardStatusUpdate };
