enum CardStatus {
  CREATED = 10,
  AWAITING_ACTIVATION = 20,
  ACTIVATING = 25,
  ACTIVE = 30,
  FREEZING = 35,
  FROZEN = 40,
  UNFREEZING = 45,
  CLOSED = 50,
  FAILED = 60,
  UNKNOWN = -100,
}

export function isCardFreezing(status: CardStatus) {
  return status === CardStatus.FREEZING;
}

export function isCardFrozenOrFreezing(status: CardStatus) {
  return [CardStatus.FROZEN, CardStatus.FREEZING].includes(status);
}

export function isCardFrozen(status: CardStatus) {
  return status === CardStatus.FROZEN;
}

export function isCardAwaitingActivation(status: CardStatus) {
  return status === CardStatus.AWAITING_ACTIVATION;
}

export function isCardActivating(status: CardStatus) {
  return status === CardStatus.ACTIVATING;
}

export default CardStatus;
