import React, { memo } from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import SearchBar from 'components/SearchBar';

import { StyledModalHeader } from '../Modal.styles';
import { TModalHeaderProps } from '../Modal.types';

function ModalHeader({ header }: TModalHeaderProps) {
  return (
    <StyledModalHeader>
      <Box alignment="row.verticalCenter" pb="m">
        <Box flex={1}>
          <Text id={header.id} variant="smallTitleBold" />
        </Box>
        {header?.rightButton}
      </Box>
      {header?.onSearch && (
        <Box pb="m">
          <SearchBar placeholderId="placeholder.search" onSearch={header.onSearch} mb="none" />
        </Box>
      )}
    </StyledModalHeader>
  );
}

export default memo(ModalHeader);
