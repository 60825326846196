import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const GrapesIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M9 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm0 0a3 3 0 1 0 6 0M9 7a3 3 0 0 1 3-3m3 3a3 3 0 0 0-3-3m3 3a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm-3-3c.333-.833 1.2-2.6 2-3m-2 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        stroke={colors[iconColor]}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GrapesIcon;
