import React, { memo } from 'react';

import Text from 'components/core/Text';
import Box from 'components/core/Box';

import Radio from '../Radio';
import { TRadioItemProps } from '../Radio.types';
import { useRadioGroupContext } from '../RadioContext';

function RadioItem({
  id,
  titleId,
  descriptionId,
  value,
  valueKey,
  titleProps,
  descriptionProps,
  leftComponent,
  rightComponent,
  ...rest
}: TRadioItemProps) {
  const context = useRadioGroupContext();
  const selectedContextValue = context?.value?.[valueKey as never] ?? context?.value;
  const valueByValueKey = valueKey ? (value as never)[valueKey] : value;

  return (
    <Radio
      id={id}
      selected={selectedContextValue === valueByValueKey}
      value={value}
      valueKey={valueKey}
      {...rest}>
      <Box alignment="row.verticalCenter" ml="s">
        {leftComponent}
        <Box flex={1} mr="s">
          <Text id={titleId} variant="largeTextBold" {...titleProps} hasTransComponents={false} />
          {descriptionId && (
            <Text id={descriptionId} variant="mediumTextRegular" mt="xs" {...descriptionProps} />
          )}
        </Box>
        {rightComponent}
      </Box>
    </Radio>
  );
}

export default memo(RadioItem);
