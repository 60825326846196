import React, { createElement, ReactElement, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import InvoiceCreateLayout from 'layouts/InvoiceCreateLayout';
import InvoiceDetailLayout from 'layouts/InvoiceDetailLayout';

import useInitialRequests from 'hooks/useInitialRequests/useInitialRequests';
import { useMemberContext } from 'context/MemberProvider';
import { useMerchantContext } from 'context/MerchantProvider';
import useIdleTimer from 'hooks/useIdleTimer';
import lazyWithRetry from 'helpers/lazyWithRetry';
import { useFeatureFlagsContext } from 'context/FeatureFlagsProvider';

import authRoutes from 'pages/Auth/Auth.routes';
import authOpenBankingRoutes from 'pages/AuthOpenBanking/AuthOpenBanking.routes';
import {
  handleRenderOnboardingLayout,
  mapOnboardingRoutes,
} from 'pages/Onboarding/Onboarding.helpers';
import { handleRenderDashboardLayout, mapMainRoutes } from 'pages/Main/Main.helpers';
import {
  invoiceCreateRoutes,
  invoiceDetailRoutes,
  mainRoutesByNavMenu,
  mainRoutesWithoutNavMenu,
} from 'pages/Main/Main.routes';

import { useActiveCampaignContext } from 'context/ActiveCampaignProvider';
import { useOnboardingApplicationContext } from 'context/OnboardingApplicationProvider';
import onboardingRoutes from 'pages/Onboarding/Onboarding.routes';
import HandleRenderAuthLayout from 'pages/Auth/Auth.helpers';

import LoadingPage from './views/LoadingPage';
import { TRoutes } from './routes.types';

const TerminateSession = lazyWithRetry(() => import('pages/Common/TerminateSession'));
const NotFound = lazyWithRetry(() => import('pages/NotFound'));

function AppRouter(): ReactElement {
  useIdleTimer();
  const { activeCampaignState } = useActiveCampaignContext();
  const { loading } = useInitialRequests();
  const { memberState } = useMemberContext();
  const { merchantState } = useMerchantContext();
  const { onboardingApplicationState } = useOnboardingApplicationContext();
  const { featureFlagsState } = useFeatureFlagsContext();

  if (loading || activeCampaignState.loading) {
    return <LoadingPage />;
  }

  function mapRoutes(routes: TRoutes) {
    return Object.entries(routes).map(([key, { path, element: Element }]) => (
      <Route key={key} path={path} element={createElement(Element)} />
    ));
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route element={<HandleRenderAuthLayout />}>{mapRoutes(authRoutes as TRoutes)}</Route>
        <Route element={<HandleRenderAuthLayout />}>
          {mapRoutes(authOpenBankingRoutes as TRoutes)}
        </Route>
        <Route
          element={handleRenderOnboardingLayout({
            memberState,
            merchantState,
            onboardingApplicationState,
            featureFlagsState,
          })}>
          {mapOnboardingRoutes({
            routes: onboardingRoutes,
            memberState,
            merchantState,
            onboardingApplicationState,
          })}
        </Route>
        <Route
          element={handleRenderDashboardLayout({
            member: memberState,
            merchant: merchantState.merchant,
            onboardingSections: onboardingApplicationState?.summary,
            visibleMenu: true,
          })}>
          {mapMainRoutes({
            routes: mainRoutesByNavMenu as TRoutes,
            memberType: memberState?.memberType,
          })}
        </Route>
        <Route
          element={handleRenderDashboardLayout({
            member: memberState,
            merchant: merchantState.merchant,
            onboardingSections: onboardingApplicationState?.summary,
            visibleMenu: false,
          })}>
          {mapMainRoutes({
            routes: mainRoutesWithoutNavMenu as TRoutes,
            memberType: memberState?.memberType,
          })}
        </Route>
        <Route element={<InvoiceCreateLayout />}>
          {mapMainRoutes({
            routes: invoiceCreateRoutes as TRoutes,
            memberType: memberState?.memberType,
          })}
        </Route>
        <Route element={<InvoiceDetailLayout />}>
          {mapMainRoutes({
            routes: invoiceDetailRoutes as TRoutes,
            memberType: memberState?.memberType,
          })}
        </Route>
        <Route path="/logged-out" element={<TerminateSession />} />
        <Route path="/" element={<Navigate to={mainRoutesByNavMenu.home.path} replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
