import NOOP, { ASYNC_NOOP } from 'constants/noop';

import {
  TBusinessOwnerCreateDispatch,
  TBusinessOwnerCreateState,
} from './BusinessOwnerCreateProvider.types';

export const INITIAL_STATE: TBusinessOwnerCreateState = {
  personalInformation: undefined,
  shouldCreateDirectorFromMember: false,
  addressInformation: undefined,
  addressDocument: undefined,
  identityDocumentFront: undefined,
  identityDocumentBack: undefined,
};

export const INITIAL_DISPATCH: TBusinessOwnerCreateDispatch = {
  setDirectorPersonalInformation: NOOP,
  setCreateDirectorFromMember: NOOP,
  setShareholderPersonalInformation: NOOP,
  setAddressInformation: NOOP,
  setAddressDocument: NOOP,
  removeAddressDocument: NOOP,
  setIdentityDocumentFront: NOOP,
  removeIdentityDocumentFront: NOOP,
  setIdentityDocumentBack: NOOP,
  removeIdentityDocumentBack: NOOP,
  clear: NOOP,
  createDirector: ASYNC_NOOP,
  createShareholder: ASYNC_NOOP,
};
