import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Dot from 'components/v2/core/Dot';
import { Lock03Icon } from 'components/v2/Icons';

import { isCardAwaitingActivation, isCardFrozenOrFreezing } from 'enums/CardStatus';
import getCardHolderTitle from 'helpers/getCardHolderTitle';

import { TCardItemDescriptionProps } from '../CardItem.types';

function CardItemDescription({ truncatedPan, status, cardHolder }: TCardItemDescriptionProps) {
  const isCardStatusVisible = isCardFrozenOrFreezing(status) || isCardAwaitingActivation(status);

  function renderCardStatus() {
    if (isCardFrozenOrFreezing(status)) {
      return (
        <Text textId="label.cardFrozen" variant="textBodySmall" color="statusNaturalPrimary" />
      );
    }
    if (isCardAwaitingActivation(status)) {
      return (
        <Box display="flex" flexDirection="row" alignItems="center" gap="xxs">
          <Lock03Icon width={16} height={16} iconColor="statusWarningPrimary" />
          <Text
            textId="label.awaitingActivation"
            variant="textBodySmall"
            color="statusWarningPrimary"
          />
        </Box>
      );
    }
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="xs">
      <Text variant="textBodySmall" color="textSecondary">
        ••{truncatedPan}
      </Text>
      {(isCardStatusVisible || cardHolder) && <Dot size={4} color="textPassive" />}
      {isCardStatusVisible && renderCardStatus()}
      {!isCardStatusVisible && cardHolder && (
        <Text variant="textBodySmall" color="textSecondary">
          {getCardHolderTitle(cardHolder)}
        </Text>
      )}
    </Box>
  );
}

export default CardItemDescription;
