import React from 'react';

import { TIconProps } from '../Icon.types';

const SekIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="gold"
        d="M24.94 48.001c13.254 0 24-10.745 24-24 0-13.254-10.746-24-24-24-13.255 0-24 10.746-24 24 0 13.255 10.745 24 24 24Z"
      />
      <path
        fill="#1A2A6C"
        d="M19.722 20.872h29.014C47.202 9.097 37.133.002 24.939.002c-1.792 0-3.537.199-5.217.571v20.3ZM13.461 20.872V2.921C6.871 6.517 2.153 13.112 1.143 20.872H13.46ZM13.461 27.133H1.143c1.01 7.76 5.728 14.356 12.318 17.952V27.133ZM19.722 27.133v20.3c1.68.372 3.425.57 5.217.57 12.194 0 22.263-9.094 23.797-20.87H19.722Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.94 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default SekIcon;
