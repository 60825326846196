import { TI18nId } from 'types/common';

enum Card3DSType {
  SECURITY_QUESTION = 'SECURITY_QUESTION',
  STATIC_PASSWORD = 'STATIC_PASSWORD',
}

const MAP: Record<
  Card3DSType,
  {
    labelId: TI18nId;
    resetLabelId: TI18nId;
    listItemDescriptionId: TI18nId;
    resetSuccessMessageId: TI18nId;
  }
> = {
  [Card3DSType.STATIC_PASSWORD]: {
    labelId: 'label.personalStaticPassword',
    resetLabelId: 'label.resetPersonalStaticPassword',
    listItemDescriptionId: 'label.updateOnlinePaymentPassword',
    resetSuccessMessageId: 'messages.cardReset3DSSecurity.staticPassword.success.title',
  },
  [Card3DSType.SECURITY_QUESTION]: {
    labelId: 'label.securityQuestion',
    resetLabelId: 'label.resetSecurityQuestion',
    listItemDescriptionId: 'label.updateOnlinePaymentQuestion',
    resetSuccessMessageId: 'messages.cardReset3DSSecurity.securityQuestion.success.title',
  },
};

export function getLabelIdByCard3DSType(type: Card3DSType) {
  return MAP[type].labelId;
}

export function getResetLabelIdByCard3DSType(type: Card3DSType) {
  return MAP[type].resetLabelId;
}

export function getListItemDescriptionIdByCard3DSType(type: Card3DSType) {
  return MAP[type].listItemDescriptionId;
}

export function getResetSuccessMessageIdByCard3DSType(type: Card3DSType) {
  return MAP[type].resetSuccessMessageId;
}

export default Card3DSType;
