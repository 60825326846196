import React from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

function TeamMemberItemShimmer() {
  return (
    <Box flexDirection="row">
      <Shimmer shape="circle" width={40} height={40} />
      <Box width="100%" ml="s" paddingY="xs">
        <Shimmer shape="line" width="50%" mb="s" />
        <Shimmer shape="line" />
      </Box>
    </Box>
  );
}

export default TeamMemberItemShimmer;
