import React from 'react';

import Box from 'components/core/Box';
import { StarIcon } from 'components/Icons';

function LedgerItemMainAccountBadge() {
  return (
    <Box
      alignment="center"
      backgroundColor="tertiary"
      position="absolute"
      right={0}
      bottom={-2}
      width={20}
      height={20}
      borderWidth={1}
      borderStyle="solid"
      borderColor="whiteBackground"
      borderRadius={20}>
      <StarIcon width={10} height={10} iconColor="whiteIcon" />
    </Box>
  );
}

export default LedgerItemMainAccountBadge;
