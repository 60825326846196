import React, { ReactElement, useState, cloneElement } from 'react';

import Text from 'components/core/Text';
import { LeftArrowIcon } from 'components/Icons';
import StepScreen from 'components/StepScreen';

import { TStickyScrollHeaderProps } from './StickyScrollHeader.types';
import {
  NavButton,
  NavBar,
  TitleWrapper,
  StyledStickyHeader,
  LargeTextContainer,
} from './StickyScrollHeader.styles';
import { SMALL_TITLE_OFFSET } from './StickyScrollHeader.constants';
import StickyScrollHeaderShimmer from './views/StickyScrollHeaderShimmer';

function StickyScrollHeader({
  header,
  navButton,
  scrollY,
  marginBottom = 'm',
  rightButton,
  steps,
  activeStep,
  hideNavBackButton,
  isStaticSmallHeader = false,
  ...rest
}: TStickyScrollHeaderProps): ReactElement {
  const [showSmallHeader, setShowSmallHeader] = useState<boolean>(isStaticSmallHeader);

  scrollY?.onChange((scrollOffset) => {
    setShowSmallHeader(scrollOffset > SMALL_TITLE_OFFSET);
  });

  return (
    <>
      <StyledStickyHeader {...rest}>
        <StepScreen steps={steps} activeStep={activeStep}>
          <NavBar>
            {navButton && !hideNavBackButton && (
              <NavButton
                size="small"
                shape="square"
                variant="icon"
                block={false}
                icon={navButton.icon ?? LeftArrowIcon}
                onClick={navButton.onClick}
              />
            )}
            {header && !header.hideOnSmall && showSmallHeader && (
              <TitleWrapper
                className="headerTitle"
                animate={{ y: [12, 0], opacity: [0, 1] }}
                transition={{ duration: 0.15 }}>
                <Text id={header.id} variant="mediumTextSemiBold">
                  {header.text}
                </Text>
              </TitleWrapper>
            )}
            {rightButton &&
              cloneElement(rightButton, {
                className: 'right-button',
              })}
          </NavBar>
        </StepScreen>
      </StyledStickyHeader>
      {header && !isStaticSmallHeader && (
        <LargeTextContainer
          className="headerTitle"
          marginBottom={marginBottom}
          paddingX="m"
          {...rest}>
          <Text id={header.id} variant="largeTitleBold">
            {header.text}
          </Text>
        </LargeTextContainer>
      )}
    </>
  );
}

StickyScrollHeader.Shimmer = StickyScrollHeaderShimmer;

export default StickyScrollHeader;
