export const UNBLOCK_PIN_CVV_LABEL_MAP = {
  virtual: {
    titleId: 'screens.cardDetail.unblockPin.virtual.title',
    descriptionId: 'screens.cardDetail.unblockPin.virtual.description',
  },
  physical: {
    titleId: 'screens.cardDetail.unblockPin.physical.title',
    descriptionId: 'screens.cardDetail.unblockPin.physical.description',
  },
};
