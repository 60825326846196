import styled, { keyframes } from 'styled-components';

const spinnerFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div<{ size: number; color: string }>`
  display: inline-block;
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    border: 4px solid ${(props) => props.color};
    border-radius: 50%;
    animation: ${spinnerFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  },
  div:nth-child(1) {
    animation-delay: -0.45s;
  },
  div:nth-child(2) {
    animation-delay: -0.3s;
  },
  div:nth-child(3) {
    animation-delay: -0.15s;
  },
`;
