import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import Box from 'components/core/Box';
import { RadioGroup } from 'components/core/Radio';
import FormErrorLabel from 'components/FormErrorLabel';

import { TControllerRenderProps } from 'types/form';
import { TI18nId } from 'types/common';
import { TOption } from 'components/core/Radio/Radio.types';

import { TFieldRadioGroupProps } from './FieldRadioGroup.types';

function FieldRadioGroup({
  name,
  defaultValue,
  errors,
  errorKey,
  showErrorMessage = true,
  disabled = false,
  options,
  children,
  boxProps,
  ...rest
}: TFieldRadioGroupProps) {
  const errorMessageId = errors[errorKey || name]?.message;
  const hasError = showErrorMessage && !!errorMessageId;

  function handleRender({ field: { onChange, value } }: TControllerRenderProps): ReactElement {
    return (
      <RadioGroup
        options={options as TOption[]}
        value={value}
        onChange={(val) => !disabled && onChange(val)}>
        {children}
      </RadioGroup>
    );
  }

  return (
    <Box mb="m" {...boxProps}>
      <Controller name={name} defaultValue={defaultValue} {...rest} render={handleRender} />
      {hasError && <FormErrorLabel labelId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldRadioGroup;
