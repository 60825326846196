import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const NationalIdentityIcon = ({
  iconColor = 'primary',
  strokeWidth = 2,
  ...rest
}: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M13 16c0-1.518 1.382-1.374 3.088-1.374 1.705 0 3.087-.144 3.087 1.374m-.931-5.844a2.156 2.156 0 1 1-4.312 0 2.156 2.156 0 0 1 4.312 0Z"
      />
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7ZM8.5 9.5H5m3.5 3H5"
      />
    </svg>
  );
};
export default NationalIdentityIcon;
