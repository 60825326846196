import NOOP from 'constants/noop';

import { TSideLayoutState, TSideLayoutDispatch } from './SideLayoutProvider.types';

export const INITIAL_STATE: TSideLayoutState = {
  path: null,
  params: {},
  onClose: NOOP,
};

export const INITIAL_DISPATCH: TSideLayoutDispatch = {
  showSideLayout: NOOP,
  hideSideLayout: NOOP,
};
