import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { display, flex, flexbox, margin, padding, width, zIndex, ZIndexProps } from 'styled-system';

import media from 'helpers/media';
import { getColor } from 'helpers/theme';
import { TStyledModalContentProps } from './Modal.types';

export const ModalContainer = styled(motion.div)<ZIndexProps & TStyledModalContentProps>`
  position: fixed;
  display: flex;
  overflow: hidden auto;
  align-items: center;
  justify-content: center;
  background: rgba(5, 23, 38, 0.8);
  backdrop-filter: blur(4px);
  inset: 0;
  opacity: 0;
  padding: 3rem 0 0;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ isFullPage }) =>
    isFullPage &&
    css`
      overflow: auto !important;
      backdrop-filter: none !important;
      opacity: 1;
      padding: 0 !important;
    `}
  ${zIndex}
  ${media.s} {
    padding: 3rem 0;
  }
`;

export const StyledModalContent = styled(motion.div)<TStyledModalContentProps>`
  width: 100%;
  max-width: 100%;
  margin: auto auto 0;
  max-height: 100%;
  min-height: unset;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  background-color: ${getColor('primaryBackground')};
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  border-radius: 1rem;
  position: relative;

  ${media.s} {
    padding-top: 3rem;
    height: auto;
    max-width: 26rem;
    max-height: 42rem;
    margin: auto;
  }

  ${({ alertModal }) =>
    alertModal &&
    css`
      max-width: 24rem;
      height: auto;
      margin: auto 16px 16px 16px;
    `}

  ${({ isFullPage }) =>
    isFullPage &&
    css`
      padding: 0 !important;
      border-radius: 0 !important;
      max-width: 100% !important;
      min-height: 100%;
    `}

  ${display}
  ${flex}
  ${flexbox}
  ${width}
  ${margin}
  ${padding}
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0;
  background-color: transparent;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;

  ${media.s} {
    display: block;
  }

  svg > path {
    stroke: #808080;
  }
`;

export const StyledModalHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${getColor('primaryBackground')};
  z-index: 9;
  padding: 0 1.5rem;
`;
