import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { TChildrenOnly } from 'types/common';

import ThemeProvider from './ThemeProvider';
import FetchProvider from './FetchProvider';
import SideLayoutProvider from './SideLayoutProvider';
import OnboardingApplicationProvider from './OnboardingApplicationProvider';
import MerchantProvider from './MerchantProvider';
import LedgersProvider from './LedgersProvider';
import CardsProvider from './CardsProvider';
import CardCreateProvider from './CardCreateProvider';
import CountriesProvider from './CountriesProvider';
import ActiveCampaignProvider from './ActiveCampaignProvider';
import MemberProvider from './MemberProvider';
import ActionRequestProvider from './ActionRequestProvider';
import MerchantFeatureFlagProvider from './MerchantFeatureFlagProvider';
import FeatureFlagsProvider from './FeatureFlagsProvider';
import OnlinePaymentsProvider from './OnlinePaymentsProvider';
import HomeProvider from './HomeProvider';
import SendMoneyProvider from './SendMoneyProvider';
import BusinessOwnersProvider from './BusinessOwnersProvider';
import BusinessOwnerCreateProvider from './BusinessOwnerCreateProvider';
import BillingPackagesProvider from './BillingPackagesProvider';
import BillingPackageCurrentProvider from './BillingPackageCurrentProvider';
import ExchangeProvider from './ExchangeProvider';
import TeamMembersProvider from './TeamMembersProvider';
import BulkTransfersProvider from './BulkTransfersProvider';
import ReferralCampaignProvider from './ReferralCampaignProvider';
import InvoiceSettingsProvider from './InvoiceSettingsProvider';
import InvoiceCreateProvider from './InvoiceCreateProvider';
import InvoiceCreatePreviewPdfProvider from './InvoiceCreatePreviewPdfProvider';
import HeaderProvider from './HeaderProvider';
import InvoiceDetailProvider from './InvoiceDetailProvider';
import InvoicePreviewPdfProvider from './InvoicePreviewPdfProvider';
import FingerprintProvider from './FingerprintProvider';

function AllContextProvider({ children }: TChildrenOnly): ReactElement {
  return (
    <BrowserRouter>
      <SideLayoutProvider>
        <HeaderProvider>
          <FetchProvider>
            <FingerprintProvider>
              <MemberProvider>
                <FeatureFlagsProvider>
                  <OnboardingApplicationProvider>
                    <BusinessOwnersProvider>
                      <BusinessOwnerCreateProvider>
                        <MerchantProvider>
                          <MerchantFeatureFlagProvider>
                            <ActionRequestProvider>
                              <LedgersProvider>
                                <SendMoneyProvider>
                                  <BillingPackagesProvider>
                                    <BillingPackageCurrentProvider>
                                      <CardsProvider>
                                        <CardCreateProvider>
                                          <CountriesProvider>
                                            <ActiveCampaignProvider>
                                              <HomeProvider>
                                                <OnlinePaymentsProvider>
                                                  <ExchangeProvider>
                                                    <TeamMembersProvider>
                                                      <BulkTransfersProvider>
                                                        <ReferralCampaignProvider>
                                                          <InvoiceSettingsProvider>
                                                            <InvoiceCreateProvider>
                                                              <InvoiceCreatePreviewPdfProvider>
                                                                <InvoiceDetailProvider>
                                                                  <InvoicePreviewPdfProvider>
                                                                    <ThemeProvider>
                                                                      {children}
                                                                    </ThemeProvider>
                                                                  </InvoicePreviewPdfProvider>
                                                                </InvoiceDetailProvider>
                                                              </InvoiceCreatePreviewPdfProvider>
                                                            </InvoiceCreateProvider>
                                                          </InvoiceSettingsProvider>
                                                        </ReferralCampaignProvider>
                                                      </BulkTransfersProvider>
                                                    </TeamMembersProvider>
                                                  </ExchangeProvider>
                                                </OnlinePaymentsProvider>
                                              </HomeProvider>
                                            </ActiveCampaignProvider>
                                          </CountriesProvider>
                                        </CardCreateProvider>
                                      </CardsProvider>
                                    </BillingPackageCurrentProvider>
                                  </BillingPackagesProvider>
                                </SendMoneyProvider>
                              </LedgersProvider>
                            </ActionRequestProvider>
                          </MerchantFeatureFlagProvider>
                        </MerchantProvider>
                      </BusinessOwnerCreateProvider>
                    </BusinessOwnersProvider>
                  </OnboardingApplicationProvider>
                </FeatureFlagsProvider>
              </MemberProvider>
            </FingerprintProvider>
          </FetchProvider>
        </HeaderProvider>
      </SideLayoutProvider>
    </BrowserRouter>
  );
}

export default AllContextProvider;
