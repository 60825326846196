import React from 'react';
import { isArray } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Dot from 'components/v2/core/Dot';

import { useHeaderContext } from 'context/HeaderProvider';

import { StyledHeaderContainer } from './Header.styles';

function Header() {
  const { headerState } = useHeaderContext();
  const headers = Object.values(headerState.headers);
  const currentHeaderItem = headers[headers.length - 1];

  function renderTitles() {
    return headers.map((header, index) => {
      const isLast = headers.length === index + 1;

      if (isArray(header.title)) {
        return (
          <Box key={header.title.join(',')} alignment="row.verticalCenter">
            {header.title.map((title, headerTitleIndex) => {
              const isLastTitle = header.title?.length === headerTitleIndex + 1;

              return (
                <Box key={title} alignment="row.verticalCenter">
                  <Text
                    variant={isLast && isLastTitle ? 'textBodyBold' : 'textBody'}
                    color={isLast && isLastTitle ? 'textPrimary' : 'textPassive'}>
                    {title}
                  </Text>
                  {!isLastTitle && <Dot size={4} color="textPassive" mx="s" />}
                </Box>
              );
            })}
          </Box>
        );
      }

      return (
        <Box key={header.title} alignment="row.verticalCenter">
          <Text
            variant={isLast ? 'textBodyBold' : 'textBody'}
            color={isLast ? 'textPrimary' : 'textPassive'}>
            {header.title}
          </Text>
          {!isLast && <Dot size={4} color="textPassive" mx="s" />}
        </Box>
      );
    });
  }

  return (
    <StyledHeaderContainer>
      <Box>{currentHeaderItem?.left}</Box>
      <Box alignment="row.verticalCenter">{renderTitles()}</Box>
      <Box>{currentHeaderItem?.right}</Box>
    </StyledHeaderContainer>
  );
}

export default Header;
