import styled from 'styled-components';

import Text from 'components/v2/core/Text';
import IMAGES from 'constants/images';

export const StyledCardLabelText = styled(Text)<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;

export const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background: url(${IMAGES.cardFreezeMask});
  background-size: contain;
`;

export const StyledText = styled(Text)<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;

export const StyledWifiIconWrapper = styled.div`
  transform: rotate(90deg);
`;
