import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

function MasterCardIcon(props: TIconProps): ReactElement {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="m15.844 10.995-2.138 9.995h-2.585l2.138-9.995h2.585Zm10.879 6.454 1.36-3.753.784 3.753h-2.144Zm2.886 3.541H32l-2.089-9.995h-2.205c-.497 0-.916.288-1.102.733l-3.88 9.262h2.716l.539-1.493h3.317l.313 1.493Zm-6.75-3.263c.011-2.638-3.647-2.784-3.622-3.963.008-.358.35-.74 1.096-.837.37-.047 1.392-.086 2.55.448l.453-2.12a6.968 6.968 0 0 0-2.418-.443c-2.557 0-4.355 1.358-4.37 3.304-.016 1.44 1.285 2.242 2.264 2.72 1.008.49 1.346.805 1.34 1.242-.006.67-.803.968-1.546.98-1.3.02-2.054-.352-2.655-.633l-.47 2.192c.606.277 1.72.518 2.875.53 2.718 0 4.495-1.342 4.503-3.42Zm-10.71-6.732L7.96 20.99H5.228l-2.062-7.977c-.125-.49-.234-.67-.614-.878-.622-.337-1.648-.653-2.551-.85l.061-.29h4.4c.56 0 1.065.373 1.193 1.019l1.09 5.784 2.689-6.803h2.716Z"
        fill="#1434CB"
      />
    </svg>
  );
}

export default MasterCardIcon;
