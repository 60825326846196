import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const UtilityServicesIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_3766:51321)">
        <path
          d="M2.47487 5.30345C1.59197 7.31782 1.975 9.75332 3.62396 11.4023C5.27293 13.0512 7.70843 13.4343 9.7228 12.5514L18.3848 21.2133C18.7753 21.6039 19.4085 21.6039 19.799 21.2133L21.2132 19.7991C21.6037 19.4086 21.6037 18.7754 21.2132 18.3849L12.5512 9.72295C13.4341 7.70858 13.0511 5.27307 11.4021 3.62411C9.75318 1.97515 7.31767 1.59212 5.3033 2.47502L8.83883 6.01055L6.01041 8.83898L2.47487 5.30345Z"
          stroke={colors[iconColor]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3766:51321">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UtilityServicesIcon;
