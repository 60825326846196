import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const SafeIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M8 28H5.33333C4.59695 28 4 27.4031 4 26.6667V5.33333C4 4.59695 4.59695 4 5.33333 4H26.6667C27.4031 4 28 4.59695 28 5.33333V8M8 28V30.6667M8 28H24M24 28H26.6667C27.4031 28 28 27.4031 28 26.6667V24M24 28V30.6667M28 8H26.8C26.7264 8 26.6667 8.0597 26.6667 8.13333V11.8667C26.6667 11.9403 26.7264 12 26.8 12H28M28 8V12M28 12V20M28 20H26.8C26.7264 20 26.6667 20.0597 26.6667 20.1333V23.8667C26.6667 23.9403 26.7264 24 26.8 24H28M28 20V24M11.239 11.3333C10.0602 12.5358 9.33333 14.183 9.33333 16C9.33333 17.8649 10.0991 19.551 11.3333 20.761M11.239 11.3333C12.449 10.0991 14.1351 9.33333 16 9.33333C17.8649 9.33333 19.551 10.0991 20.761 11.3333M11.239 11.3333L15.0112 15.1055M20.6667 20.761C21.9009 19.551 22.6667 17.8649 22.6667 16C22.6667 14.183 21.9398 12.5358 20.761 11.3333M20.6667 20.761C19.4642 21.9398 17.817 22.6667 16 22.6667C14.183 22.6667 12.5358 21.9398 11.3333 20.761M20.6667 20.761L16.8945 16.9888M20.761 11.3333L16.9888 15.1055M11.3333 20.761L15.1055 16.9888M16.9888 15.1055C16.7448 14.836 16.3922 14.6667 16 14.6667C15.6078 14.6667 15.2552 14.836 15.0112 15.1055M16.9888 15.1055C17.2029 15.3421 17.3333 15.6558 17.3333 16C17.3333 16.3922 17.164 16.7448 16.8945 16.9888M15.0112 15.1055C14.7971 15.3421 14.6667 15.6558 14.6667 16C14.6667 16.3922 14.836 16.7448 15.1055 16.9888M15.1055 16.9888C15.3421 17.2029 15.6558 17.3333 16 17.3333C16.3442 17.3333 16.6579 17.2029 16.8945 16.9888"
        stroke={colors[iconColor]}
        strokeWidth="2.66667"
      />
    </svg>
  );
};

export default SafeIcon;
