import React, { useMemo } from 'react';

import Box from 'components/core/Box';

import { TDotProps } from './Dot.types';

function Dot({ size, color, ...rest }: TDotProps) {
  const dotStyle = useMemo(
    () => ({
      width: size,
      height: size,
      borderRadius: size / 2,
    }),
    [size]
  );

  return (
    <Box
      width={dotStyle.width}
      height={dotStyle.height}
      borderRadius={dotStyle.borderRadius}
      backgroundColor={color}
      {...rest}
    />
  );
}

export default Dot;
