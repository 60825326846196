import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const EmptyTransactionIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...rest}>
      <path
        d="M50.4881 50.4878C51.5381 51.5378 53.3333 50.7942 53.3333 49.3093L53.3333 38.333C53.3333 37.4125 52.5871 36.6663 51.6666 36.6663L40.6903 36.6663C39.2055 36.6663 38.4619 38.4616 39.5118 39.5115L50.4881 50.4878Z"
        fill={colors[iconColor]}
      />
      <path
        d="M50 40L26.6667 60L6.66667 43.3333"
        stroke={colors[iconColor]}
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="65"
        cy="28.3333"
        r="8.33333"
        stroke={colors[iconColor]}
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyTransactionIcon;
