import React, { FC } from 'react';

import TransactionItem from 'components/TransactionItem';
import BeneficiaryAvatar from 'components/v2/BeneficiaryItem/views/BeneficiaryAvatar';
import Avatar from 'components/v2/core/Avatar';
import { ArrowsRightIcon } from 'components/Icons';

import { TRANSACTION_DATE_FORMAT } from 'constants/dateFormat';
import { TIconProps } from 'components/v2/Icons/Icon.types';

import { TScheduledTransactionHistoryItemProps } from './ScheduledTransactionHistoryItem.types';
import { convertToTransaction } from './ScheduledTransactionHistoryItem.helpers';

function ScheduledTransactionHistoryItem({ item }: TScheduledTransactionHistoryItemProps) {
  const avatar = renderAvatar();

  function renderAvatar() {
    if (item.isBulkTransfer) {
      return (
        <Avatar
          icon={ArrowsRightIcon as FC<TIconProps>}
          iconColor="textSecondary"
          backgroundColor="backgroundSecondary"
          size="small"
        />
      );
    }
    return <BeneficiaryAvatar name={item.titleDetail.title} currency={item.currency} />;
  }

  return (
    <TransactionItem
      item={convertToTransaction(item)}
      dateFormat={TRANSACTION_DATE_FORMAT}
      avatar={avatar}
    />
  );
}

export default ScheduledTransactionHistoryItem;
