import React from 'react';

import SideLayoutPage from 'components/SideLayoutPage';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';

import { useMemberContext } from 'context/MemberProvider';
import getPhoneNumber from 'helpers/getPhoneNumber';

function ProfileDetail() {
  const { memberState } = useMemberContext();

  return (
    <SideLayoutPage headerId="screens.headerTitle.profileDetails">
      <Card marginX="m" paddingX="m">
        <InfoItem titleId="screens.profileDetails.email" value={memberState?.email} />
        {memberState?.firstName && (
          <InfoItem titleId="screens.profileDetails.firstName" value={memberState.firstName} />
        )}
        {memberState?.lastName && (
          <InfoItem titleId="screens.profileDetails.lastName" value={memberState.lastName} />
        )}
        {memberState?.phoneNumber && (
          <InfoItem
            titleId="screens.profileDetails.phoneNumber"
            value={getPhoneNumber(memberState.phoneNumber)}
          />
        )}
      </Card>
    </SideLayoutPage>
  );
}

export default ProfileDetail;
