export const LINE_BREAK = /\r?\n/;

export const SPACE = / /g;

export const NOT_NUMBER = /^[^0-9]+$/;

export const BIRTHDATE = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

export const NUMBER = /[^0-9]/g;

export const NORMALIZE_PRICE_FORMAT = /[,]|[.]^$/gi;

export const MT_REGISTRATION_NUMBER = /[^0-9](?=[0-9])/;

export const IBAN_COUNTRY_CODE = /^[A-Za-z]{2}/;

export const SPECIAL_CHARS = /[^\w\s]|[_]/gi;

export const CITY_NAME = /[0-9]|[?!@$<>€¥%]/g;

export const ONLY_DIGITS = /[^A-Za-z]+/g;

export const ONLY_DIGITS_REPLACE = /[^\d.]/g;

export const ONLY_WORDS = /[^0-9.]/g;

export const ONLY_LATIN_CHARS = /^[a-zA-Z\s]*$/;

export const UK_SORT_CODE = /^[0-9]{2}[0-9]{2}[0-9]{2}$/;

export const UK_ACCOUNT_NUMBER = /^[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/;

export const CARD_DELIVERY_CHARS = /^[a-zA-Z\d-./\s]*$/;

export const UK_POST_CODE = /^[a-zA-Z]{1,2}[0-9R][0-9a-zA-Z]? ?[0-9][a-zA-Z]{2}$/;

export const GENERIC_POSTCODE_PATTERN = /^[a-zA-Z0-9 -]*$/;

export const ADDRESS_LINE = /^[a-zA-Z0-9., '()/-]*$/;

export const TOWN_OR_CITY = /^[a-zA-Z0-9 &'-.]*$/;

export const NAME = /^[a-zA-Z' -]*$/;

export const NOT_ALPHANUMERIC = /[^a-zA-Z0-9]/g;

export const SEND_MONEY_DESCRIPTION_RAILSR_REPLACE = /[^a-zA-Z0-9 ?:().+]/gi;

export const SEND_MONEY_DESCRIPTION_MODULR = /^[a-zA-Z0-9 -.&/]+$/g;

export const SEND_MONEY_DESCRIPTION_COMMON = /^[A-Za-z0-9 .&/-]+$/;

export const BENEFICIARY_NAME = /^[A-Za-z0-9 .&/-]+$/;

export const DASH = /-/g;
