import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const MerchantIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M7.5 10C7.5 11.1046 6.49264 12 5.25 12C4.78746 12 4.35752 11.8759 4 11.6632M7.5 10C7.5 11.1046 8.50736 12 9.75 12C10.9926 12 12 11.1046 12 10M7.5 10L8.25 4M12 10C12 11.1046 13.0074 12 14.25 12C15.4926 12 16.5 11.1046 16.5 10M12 10V4M16.5 10C16.5 11.1046 17.5074 12 18.75 12C19.2125 12 19.6425 11.8759 20 11.6632M16.5 10L16 4M16 4H18.7192C19.1781 4 19.5781 4.3123 19.6894 4.75746L21 10C21 10.6934 20.603 11.3044 20 11.6632M16 4H12M12 4H8.25M8.25 4H5.28078C4.82191 4 4.42193 4.3123 4.31063 4.75746L3 10C3 10.6934 3.39701 11.3044 4 11.6632M4 11.6632V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V11.6632"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default MerchantIcon;
