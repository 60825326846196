import React from 'react';

import { TIconProps } from '../Icon.types';

const JpyIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FEFEFE"
        d="M24.99 47.999c13.254 0 24-10.745 24-24s-10.746-24-24-24c-13.256 0-24 10.745-24 24s10.744 24 24 24Z"
      />
      <path
        fill="#C8102E"
        d="M24.99 34.433c5.762 0 10.434-4.671 10.434-10.434 0-5.763-4.672-10.435-10.435-10.435-5.763 0-10.434 4.672-10.434 10.435 0 5.763 4.671 10.434 10.434 10.434Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.99 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default JpyIcon;
