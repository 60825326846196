import React, {
  createContext,
  useCallback,
  useMemo,
  ReactElement,
  useState,
  useContext,
} from 'react';

import { TChildrenOnly } from 'types/common';

import { INITIAL_DISPATCH, INITIAL_STATE } from './SideLayoutProvider.constants';
import {
  TShowSideLayoutProps,
  TSideLayoutContext,
  TSideLayoutState,
} from './SideLayoutProvider.types';

const sideLayoutContext = createContext<TSideLayoutContext>({
  sideLayoutState: INITIAL_STATE,
  sideLayoutDispatch: INITIAL_DISPATCH,
});

function SideLayoutProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TSideLayoutState>(INITIAL_STATE);

  function showSideLayout({ path, params, onClose, onCallback }: TShowSideLayoutProps) {
    setState({
      path,
      params,
      onClose,
      onCallback,
    });
  }

  const hideSideLayout = useCallback(() => {
    state.onClose?.();
    setState((stateParams) => ({ ...stateParams, path: null }));
  }, [state]);

  const value = useMemo(
    (): TSideLayoutContext => ({
      sideLayoutState: state,
      sideLayoutDispatch: {
        showSideLayout,
        hideSideLayout,
      },
    }),
    [state, showSideLayout, hideSideLayout]
  );

  return <sideLayoutContext.Provider value={value}>{children}</sideLayoutContext.Provider>;
}

export default SideLayoutProvider;

export const useSideLayoutContext = (): TSideLayoutContext => useContext(sideLayoutContext);
