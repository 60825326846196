import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ErrorMarkIcon = ({ iconColor = 'error', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...rest}>
      <path
        d="M3.33318 70L76.6665 70L39.9998 8.8889L3.33318 70Z"
        stroke={colors[iconColor]}
        strokeWidth="6.66667"
        strokeLinejoin="round"
      />
      <path
        d="M40 33.333L40 46.6663M40 56.6663L40 54.9997"
        stroke={colors[iconColor]}
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorMarkIcon;
