import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ReportIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M25.3333 13.3333V26C25.3333 27.1046 24.4379 28 23.3333 28H8.66667C7.5621 28 6.66667 27.1046 6.66667 26V6C6.66667 4.89543 7.5621 4 8.66667 4H16M25.3333 13.3333L16 4M25.3333 13.3333H16.5C16.2239 13.3333 16 13.1095 16 12.8333V4"
        stroke={colors[iconColor]}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReportIcon;
