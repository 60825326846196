import React from 'react';

import NavItem from 'components/v2/NavItem';
import Box from 'components/core/Box';
import { Home01Icon, User01Icon } from 'components/v2/Icons';

import routes from 'router/routes';

function OnboardingNavMobileItems() {
  return (
    <Box alignment="row.verticalCenter" width="100%" px="xl">
      <Box alignment="center" flex={1}>
        <NavItem
          to={routes.onboardingApplicationSummary.path}
          titleId="label.summary"
          icon={Home01Icon}
        />
      </Box>
      <Box alignment="center" flex={1}>
        <NavItem
          to={routes.onboardingAccount.path}
          titleId={routes.account.titleId}
          icon={User01Icon}
        />
      </Box>
    </Box>
  );
}

export default OnboardingNavMobileItems;
