import styled from 'styled-components';

import Box from 'components/core/Box';

export const StyledBox = styled(Box)<{ block?: boolean }>`
  position: sticky;
  padding: ${(props) => (props.block ? 0 : '1.5rem')} 0 0;
  bottom: 1.5rem;
  width: 100%;
  margin: auto auto 0;
  max-width: ${(props) => (props.block ? 'auto' : '24rem')};
`;
