import React from 'react';
import { useFetch } from 'use-http';

import Card from 'components/core/Card';
import SectionItem from 'components/core/SectionItem';
import ScheduledBulkTransferItem from 'components/ScheduledBulkTransferItem';

import API_ENDPOINT from 'constants/apiEndpoint';
import { TScheduledBulkTransferItem } from 'types/scheduledTransaction';
import normalizeUrl from 'helpers/normalizeUrl';

import { TScheduledBulkTransferDetailItemsProps } from '../ScheduledBulkTransferDetail.types';

function ScheduledBulkTransferDetailItems({
  scheduledId,
  transferCount,
  scheduledAt,
  onNavigateToScheduledBulkTransferItems,
}: TScheduledBulkTransferDetailItemsProps) {
  const { data: scheduledBulkTransferItemsData } = useFetch(
    normalizeUrl(API_ENDPOINT.SCHEDULED_TRANSACTION_ORDER_TRANSFERS, {
      pathVariables: {
        id: scheduledId,
      },
      queryParams: {
        page: 0,
        size: 2,
      },
    }),
    []
  );

  const scheduledBulkTransferItems = scheduledBulkTransferItemsData?.result?.content ?? [];

  if (!scheduledBulkTransferItems.length) {
    return <></>;
  }

  return (
    <Card mt="m" marginX="m">
      <SectionItem
        titleId="label.transfers"
        count={transferCount}
        rightButton={{
          id: 'button.seeAll',
          onClick: onNavigateToScheduledBulkTransferItems,
        }}
      />
      {scheduledBulkTransferItems.map((scheduledBulkTransferItem: TScheduledBulkTransferItem) => (
        <ScheduledBulkTransferItem
          key={scheduledBulkTransferItem.id}
          item={scheduledBulkTransferItem}
          scheduledAt={scheduledAt}
        />
      ))}
    </Card>
  );
}

export default ScheduledBulkTransferDetailItems;
