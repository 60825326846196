import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function UserIcon({ iconColor = 'primary', ...rest }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}>
      <path
        d="M3 22L2.04119 21.716C1.95157 22.0185 2.00997 22.3455 2.19874 22.5983C2.38751 22.8511 2.6845 23 3 23V22ZM21 22V23C21.3155 23 21.6125 22.8511 21.8013 22.5983C21.99 22.3455 22.0484 22.0185 21.9588 21.716L21 22ZM16 7C16 9.20914 14.2091 11 12 11V13C15.3137 13 18 10.3137 18 7H16ZM12 11C9.79086 11 8 9.20914 8 7H6C6 10.3137 8.68629 13 12 13V11ZM8 7C8 4.79086 9.79086 3 12 3V1C8.68629 1 6 3.68629 6 7H8ZM12 3C14.2091 3 16 4.79086 16 7H18C18 3.68629 15.3137 1 12 1V3ZM3.95881 22.284C5.04818 18.6067 8.26481 16 12 16V14C7.28876 14 3.35554 17.2791 2.04119 21.716L3.95881 22.284ZM12 16C15.7352 16 18.9518 18.6067 20.0412 22.284L21.9588 21.716C20.6445 17.2791 16.7112 14 12 14V16ZM3 23H21V21H3V23Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
}

export default UserIcon;
