import React from 'react';

import Box from 'components/core/Box';
import { WifiIcon } from 'components/v2/Icons';
import { VisaBusinessIcon, WamoIcon } from 'components/v2/BrandIcons';

import spacing from 'theme/spacing';

import { getWamoCardColorsByCardColorType, getWamoCardSize } from '../WamoCard.helpers';
import { TWamoCardBackProps } from '../WamoCard.types';
import { StyledText, StyledWifiIconWrapper } from '../WamoCard.styles';

function WamoCardBack({ size, companyName, cardColorType }: TWamoCardBackProps) {
  const {
    width: cardWidth,
    height: cardHeight,
    borderRadius,
    borderOutlineWidth,
    visaWidth,
    visaHeight,
  } = getWamoCardSize(size);

  const {
    backgroundColor,
    backLineColor,
    backSignatureLineColor,
    backWamoIcon,
    textColor,
    visaColor,
  } = getWamoCardColorsByCardColorType(cardColorType);

  return (
    <Box
      style={{ backgroundColor }}
      position="relative"
      overflow="hidden"
      width={cardWidth as number}
      borderRadius={borderRadius}
      borderWidth={borderOutlineWidth}
      borderColor="borderOutline"
      display="flex"
      flexDirection="column"
      height={cardHeight}>
      <Box mt="m" mb="l" width="100%" height={32} style={{ backgroundColor: backLineColor }} />
      <Box display="flex" flexDirection="row" alignItems="center" gap="s" px="m" mb="m">
        <Box
          style={{ backgroundColor: backSignatureLineColor }}
          width={165}
          height={24}
          borderRadius={spacing.xxs}
        />
        <StyledWifiIconWrapper>
          <WifiIcon staticIconColor={backWamoIcon} />
        </StyledWifiIconWrapper>
        <WamoIcon width={32} iconColor={backWamoIcon} />
      </Box>
      {!!companyName && (
        <StyledText variant="textBodySub" numberOfLines={1} ml="m" textColor={textColor}>
          {companyName}
        </StyledText>
      )}
      <Box position="absolute" bottom={16} right={16}>
        <VisaBusinessIcon iconColor={visaColor} width={visaWidth} height={visaHeight} />
      </Box>
    </Box>
  );
}

export default WamoCardBack;
