import React, { createContext, ReactElement, useContext, useMemo, useState } from 'react';
import i18next from 'i18next';

import { TChildrenOnly } from 'types/common';
import { TBeneficiary } from 'types/beneficiary';
import { TLedger } from 'types/ledger';
import { TProcessedRow } from 'types/bulkTransfers';

import {
  TBulkTransferScheduleOptions,
  TBulkTransfersContext,
  TBulkTransfersState,
  TSendInfo,
} from './BulkTransfersProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './BulkTransfersProvider.constants';

const bulkTransfersContext = createContext<TBulkTransfersContext>({
  bulkTransfersState: INITIAL_STATE,
  bulkTransfersDispatch: INITIAL_DISPATCH,
});

function BulkTransfersProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TBulkTransfersState>(INITIAL_STATE);
  const selectedBeneficiariesIds = useMemo(
    () => state.transfers.map(({ beneficiary }) => beneficiary.id),
    [state.transfers.length]
  );

  function setLedgerFrom(ledgerFrom: TLedger) {
    setState((prevState) => ({
      ...prevState,
      ledgerFrom,
    }));
  }

  function setTransfers(transfers: TProcessedRow[]) {
    setState((prevState) => ({
      ...prevState,
      transfers,
    }));
  }

  function setNumberOfCreatedBeneficiaries(count: number) {
    setState((prevState) => ({
      ...prevState,
      numberOfCreatedBeneficiaries: count,
    }));
  }

  function setSendInfo(sendInfo: TSendInfo) {
    setState((prevState) => ({
      ...prevState,
      isEnoughMoney: sendInfo.isEnoughMoney,
      totalTransfer: sendInfo.totalTransfer,
      totalEstimatedFee: sendInfo.totalEstimatedFee,
    }));
  }

  function setScheduleOptions(scheduleOptions?: TBulkTransferScheduleOptions) {
    setState((state) => ({
      ...state,
      scheduleOptions,
    }));
  }

  function addBeneficiary(beneficiary: TBeneficiary) {
    setState((prevState) => ({
      ...prevState,
      transfers: [
        {
          beneficiary,
          amount: 0,
          description: i18next.t('label.sentFromWamo'),
        },
        ...prevState.transfers,
      ],
    }));
  }

  function removeBeneficiary(beneficiary: TBeneficiary) {
    setState((prevState) => ({
      ...prevState,
      transfers: prevState.transfers.filter(
        (transfer) => transfer.beneficiary.id !== beneficiary.id
      ),
    }));
  }

  function clearSendInfo() {
    setState((prevState) => ({
      ...prevState,
      transfers: [],
      isEnoughMoney: false,
      totalTransfer: undefined,
      totalEstimatedFee: undefined,
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TBulkTransfersContext = {
    bulkTransfersState: {
      ...state,
      selectedBeneficiariesIds,
    },
    bulkTransfersDispatch: {
      setSendInfo,
      setTransfers,
      setScheduleOptions,
      setNumberOfCreatedBeneficiaries,
      addBeneficiary,
      removeBeneficiary,
      setLedgerFrom,
      clearSendInfo,
      clear,
    },
  };

  return <bulkTransfersContext.Provider value={value}>{children}</bulkTransfersContext.Provider>;
}

export default BulkTransfersProvider;

export const useBulkTransfersContext = (): TBulkTransfersContext =>
  useContext(bulkTransfersContext);
