import React from 'react';

import Box from 'components/core/Box';

import { TCardLabelProps } from '../WamoCard.types';
import { StyledCardLabelText } from '../WamoCard.styles';

function CardLabel({ nameOnCard, truncatedPan, textColor }: TCardLabelProps) {
  return (
    <Box>
      {nameOnCard && (
        <StyledCardLabelText textColor={textColor} variant="textBodySub" numberOfLines={1} pr="m">
          {nameOnCard}
        </StyledCardLabelText>
      )}
      {truncatedPan && (
        <StyledCardLabelText textColor={textColor} variant="textBodySubBold">
          ••{truncatedPan}
        </StyledCardLabelText>
      )}
    </Box>
  );
}

export default CardLabel;
