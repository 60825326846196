import React, { ReactElement, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal, { useModal } from 'components/core/Modal';
import Card from 'components/core/Box';
import InfoItem from 'components/core/InfoItem';

import {
  getColorByMerchantFeeStatus,
  getLabelIdByMerchantFeeStatus,
} from 'enums/MerchantFeeStatus';
import { formatAmount } from 'helpers/formatAmount';
import { TFeeDetailModalProps, TModalState } from './FeeDetailModal.types';

function FeeDetailModal({ modalRef }: TFeeDetailModalProps): ReactElement {
  const { t } = useTranslation();
  const ref = useModal();
  const [state, setState] = useState<TModalState>();

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  return (
    <Modal ref={ref} header={{ id: 'label.feeDetail' }} secondary>
      {state?.feeDetail && (
        <Card py="none" px="m" borderRadius="16px" backgroundColor="whiteBackground">
          <InfoItem titleId="modals.feeDetailModal.feeType" value={state.feeDetail.title} />
          <InfoItem
            titleId="modals.feeDetailModal.feeAmount"
            value={formatAmount({
              amount: state.feeDetail.amount,
              currency: state.feeDetail.currency,
            })}
          />
          <InfoItem
            titleId="modals.feeDetailModal.status"
            value={t(getLabelIdByMerchantFeeStatus(state.feeDetail.status))}
            valueColor={getColorByMerchantFeeStatus(state.feeDetail.status)}
          />
        </Card>
      )}
    </Modal>
  );
}

export default FeeDetailModal;
