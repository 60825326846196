import styled from 'styled-components';
import { compose, space, variant } from 'styled-system';

import Spinner from 'components/core/Spinner';
import Text from 'components/core/Text';

import spacing from 'theme/spacing';
import { TBaseTextButtonProps } from './TextButton.types';
import { TEXT_BUTTON_VARIANTS } from './TextButton.variants';

export const BaseTextButton = styled.button<TBaseTextButtonProps>`
  transition: all 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
  padding: 0 ${spacing.s};
  cursor: pointer;
  display: flex;
  align-items: center;

  ${compose(
    space,
    variant({
      variants: TEXT_BUTTON_VARIANTS,
    })
  )}
`;

export const StyledSpinner = styled(Spinner)`
  div {
    border-width: 2px;
  }
`;

export const StyledText = styled(Text)<{ loading: boolean }>`
  transform: translateX(${({ loading }) => (loading ? spacing.s : 0)});

  transition: transform 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
`;
