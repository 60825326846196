import { TWamoCardVariant } from 'components/WamoCard/WamoCard.types';
import palette from 'theme/v2/palette';

enum CardColorType {
  BLACK = 'BLACK',
  SILVER = 'SILVER',
}

const MAP: Record<
  CardColorType,
  {
    variant: TWamoCardVariant;
    circleColor: string;
  }
> = {
  [CardColorType.BLACK]: {
    variant: 'black',
    circleColor: palette.moon800,
  },
  [CardColorType.SILVER]: {
    variant: 'silver',
    circleColor: palette.silver,
  },
};

export function getWamoCardVariantByColorType(colorType: CardColorType): TWamoCardVariant {
  return MAP[colorType]?.variant ?? 'black';
}

export function getCircleColorByColorType(colorType: CardColorType): string {
  return MAP[colorType]?.circleColor ?? palette.moon800;
}

export default CardColorType;
