import CardColorType from 'enums/CardColorType';

import { TWamoCardProps, TWamoCardSize } from './WamoCard.types';
import { CARD_COLORS, WAMO_CARD_SIZE_MAP } from './WamoCard.constants';

export function getWamoCardSize(size: TWamoCardSize) {
  return WAMO_CARD_SIZE_MAP[size];
}

export function getWamoCardColorsByVariant(variant: TWamoCardProps['variant']) {
  return CARD_COLORS[variant];
}

export function getWamoCardColorsByCardColorType(cardColorType: CardColorType) {
  switch (cardColorType) {
    case CardColorType.BLACK:
      return CARD_COLORS.black;
    case CardColorType.SILVER:
      return CARD_COLORS.silver;
    default:
      return CARD_COLORS.black;
  }
}
