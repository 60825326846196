import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const TransactionStatementIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}>
      <path
        d="M19 10V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12M19 10L12 3M19 10H12.5C12.2239 10 12 9.77614 12 9.5V3M15 13H8M13 17H8M9 9H8"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TransactionStatementIcon;
