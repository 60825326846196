import React, { ReactElement } from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import ListItemButton from 'components/core/ListItemButton';
import { Hourglass01Icon } from 'components/v2/Icons';

import TransactionStatus from 'enums/TransactionStatus';
import FxOrderStatus from 'enums/FxOrderStatus';

import Description from './views/Description';
import TransactionItemShimmer from './views/TransactionItemShimmer';
import TransactionIcon from './views/TransactionIcon';
import TransactionItemAmountContent from './views/TransactionItemAmountContent';

import { TTransactionItemButtonProps, TTransactionItemProps } from './TransactionItem.types';
import ExchangeIcon from './views/ExchangeIcon';

function TransactionItem({
  item,
  avatar,
  dateFormat,
  ...rest
}: TTransactionItemProps): ReactElement {
  const isPending =
    item.status === TransactionStatus.PENDING || item.fxOrder?.status === FxOrderStatus.PENDING;

  function renderAvatar() {
    if (avatar) {
      return avatar;
    }
    if (item.fxOrder) {
      return (
        <ExchangeIcon
          sourceCurrencyCode={item.fxOrder.sourceCurrency.code}
          targetCurrencyCode={item.fxOrder.targetCurrency.code}
        />
      );
    }
    return (
      <TransactionIcon
        title={item.titleDetail?.title ?? item.title}
        transactionStatus={item.status}
        transactionType={item.type}
        cardMerchantCategoryCode={item.card?.cardMerchantCategoryCode}
        merchantLogoLink={item.card?.merchantLogoLink}
        isBulkTransfer={item.isBulkTransfer}
      />
    );
  }

  return (
    <Box p="m" display="flex" alignItems="center" {...rest}>
      <Box display="flex" alignSelf="flex-start">
        {renderAvatar()}
      </Box>
      <Box display="flex" flex={1} flexDirection="column" px="m">
        <Text variant="mediumTextSemiBold" color="textPrimary" mb="xs">
          {item.title}
        </Text>
        <Box display="flex" flexDirection="row" alignItems="center" gap="s">
          <Description
            amount={item.amount}
            transactionDate={item.createdAt}
            dateFormat={dateFormat}
          />
          {isPending && <Hourglass01Icon width={16} height={16} iconColor="statusWarningPrimary" />}
        </Box>
      </Box>
      <TransactionItemAmountContent item={item} />
    </Box>
  );
}

function TransactionItemButton({
  onClickTransaction,
  item,
  isSelected,
  ...rest
}: TTransactionItemButtonProps): ReactElement {
  return (
    <>
      <ListItemButton onClick={() => onClickTransaction(item.id)} aria-pressed={isSelected}>
        <TransactionItem item={item} {...rest} />
      </ListItemButton>
    </>
  );
}

TransactionItem.Shimmer = TransactionItemShimmer;
TransactionItem.Button = TransactionItemButton;

export default TransactionItem;
