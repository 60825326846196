import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ExchangeIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg viewBox="0 0 24 24" width="24" height="24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 18v-4h4m11-8v4h-4m-10 4.603A7.002 7.002 0 0 0 18.71 14M5.29 10a7.003 7.003 0 0 1 13.336-.263"
      />
    </svg>
  );
};

export default ExchangeIcon;
