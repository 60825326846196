import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CopyIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M16 13L16 20C16 21.1046 15.1046 22 14 22H5C3.89543 22 3 21.1046 3 20L3 8C3 6.89543 3.89543 6 5 6L9 6M16 13L9 6M16 13H9.5C9.22386 13 9 12.7761 9 12.5L9 6M21 8L21 15C21 16.1046 20.1046 17 19 17H17M21 8L14 1M21 8L14.5 8C14.2239 8 14 7.77614 14 7.5L14 1M14 1L10 1C8.89543 1 8 1.89493 8 2.9995V6"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
