import { FC } from 'react';

import {
  CheckCircleIcon,
  HelpCircleIcon,
  Hourglass01Icon,
  Hourglass03Icon,
  ReverseLeftIcon,
  SlashOctagonIcon,
  XCircleIcon,
} from 'components/v2/Icons';

import { TThemePrimitives } from 'theme/theme.types';
import { TThemePrimitives as TThemePrimitivesV2 } from 'theme/v2/theme.types';
import { TI18nId } from 'types/common';
import { TIconProps } from 'components/v2/Icons/Icon.types';

enum TransactionStatus {
  DECLINED = -1,
  PENDING = 0,
  ACCEPTED = 1,
  APPROVED = 2,
  REVERSED = 3,
}

const MAP: Record<
  TransactionStatus,
  {
    labelId: TI18nId;
    labelColor: keyof TThemePrimitives;
    icon?: FC<TIconProps>;
    avatarIcon?: FC<TIconProps>;
    iconColor?: keyof TThemePrimitivesV2;
    transactionDetailIcon?: FC<TIconProps>;
    transactionDetailIconColor?: keyof TThemePrimitivesV2;
  }
> = {
  [TransactionStatus.PENDING]: {
    labelId: 'label.transactionStatus.pending',
    labelColor: 'warning',
    icon: Hourglass03Icon,
    iconColor: 'statusWarningPrimary',
    avatarIcon: undefined,
    transactionDetailIcon: Hourglass01Icon,
    transactionDetailIconColor: 'statusWarningPrimary',
  },
  [TransactionStatus.DECLINED]: {
    labelId: 'label.transactionStatus.declined',
    labelColor: 'error',
    icon: undefined,
    iconColor: 'statusErrorPrimary',
    avatarIcon: SlashOctagonIcon,
    transactionDetailIcon: XCircleIcon,
    transactionDetailIconColor: 'statusErrorPrimary',
  },
  [TransactionStatus.ACCEPTED]: {
    labelId: 'label.transactionStatus.accepted',
    labelColor: 'success',
    icon: undefined,
    iconColor: undefined,
    avatarIcon: undefined,
    transactionDetailIcon: CheckCircleIcon,
    transactionDetailIconColor: 'statusPositivePrimary',
  },
  [TransactionStatus.APPROVED]: {
    labelId: 'label.transactionStatus.accepted',
    labelColor: 'success',
    icon: undefined,
    iconColor: undefined,
    avatarIcon: undefined,
    transactionDetailIcon: CheckCircleIcon,
    transactionDetailIconColor: 'statusPositivePrimary',
  },
  [TransactionStatus.REVERSED]: {
    labelId: 'label.transactionStatus.accepted',
    labelColor: 'success',
    icon: undefined,
    iconColor: 'textPassive',
    avatarIcon: ReverseLeftIcon,
    transactionDetailIcon: ReverseLeftIcon,
    transactionDetailIconColor: 'statusErrorPrimary',
  },
};

export function getLabelColorByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.labelColor;
}

export function getIconByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.icon;
}

export function getIconColorByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.iconColor;
}

export function getLabelIdByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.labelId;
}

export function getAvatarIconByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.avatarIcon;
}

export function isTransactionReverted(transactionStatus: TransactionStatus) {
  return [TransactionStatus.REVERSED, TransactionStatus.DECLINED].includes(transactionStatus);
}

export function getTransactionDetailIconByTransactionStatus(transactionStatus: TransactionStatus) {
  return MAP?.[transactionStatus]?.transactionDetailIcon ?? HelpCircleIcon;
}

export function getTransactionDetailIconColorByTransactionStatus(
  transactionStatus: TransactionStatus
) {
  return MAP?.[transactionStatus]?.transactionDetailIconColor ?? 'textSecondary';
}

export default TransactionStatus;
