import i18next from 'i18next';
import { TScheduledBulkTransferDetail, TScheduledTransaction } from 'types/scheduledTransaction';

export function convertToScheduledTransactionItem(
  item: TScheduledBulkTransferDetail
): TScheduledTransaction {
  return {
    id: item.id,
    titleDetail: {
      title: i18next.t('label.transfers.title.withCount', {
        count: item.transferCount,
      }),
      prefix: i18next.t('label.bulk.lowercase'),
    },
    scheduledAt: item.scheduledAt,
    amount: item.amount,
    recurringPeriod: item.recurringPeriod,
    currency: item.currency,
    isBulkTransfer: true,
  };
}
