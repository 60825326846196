import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const FxTransactionIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 4.252a8.003 8.003 0 0 1 4 13.04m-8 2.456a8.003 8.003 0 0 1-4-13.04M14 14v7h7l-7-7ZM10 10V3H3l7 7Z"
      />
    </svg>
  );
};

export default FxTransactionIcon;
