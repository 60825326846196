import styled from 'styled-components';

import Box from 'components/core/Box';

import { getColor } from 'helpers/theme';

export const StyledContainer = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: ${getColor('onboardingProgressStepsLineBackground')};
    width: 24px;
    z-index: -1;
    border-radius: 32px;
  }
`;

export const StyledStepStatusBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
