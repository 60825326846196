import { isMatch } from 'date-fns/fp';

import { DATE_MASK_SEPARATOR } from 'constants/masks';
import date from 'helpers/date';

function convertToDateRequest(dateStr: string) {
  if (isMatch('yyyy-MM-dd', dateStr)) {
    return dateStr;
  }

  const [day, month, year] = dateStr.replaceAll(' ', '').split(DATE_MASK_SEPARATOR);

  return date.format({
    date: new Date(Number(year), Number(month) - 1, Number(day)),
    formatType: 'yyyy-MM-dd',
  });
}

export default convertToDateRequest;
