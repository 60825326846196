import { TI18nId } from 'types/common';

import { TGetMaxLengthInfo } from './sendMoney.types';

export const SEND_MONEY_DESCRIPTION_MAX_LENGTH: Record<string, TGetMaxLengthInfo> = {
  EUR_TO_EUR: {
    maxLength: 140,
    validationMessage: 'validation.maxLength.140',
  },
  EUR_OR_GBP_TO_ANY: {
    maxLength: 18,
    validationMessage: 'validation.maxLength.18',
  },
};

export const DEFAULT_REFERENCE: TI18nId = 'label.sentFromWamo';

export const BETWEEN_ACCOUNTS_DEFAULT_REFERENCE: TI18nId = 'label.betweenAccounts';
