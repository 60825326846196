import React, { PointerEvent, useState } from 'react';

import { TPressableProps } from './Pressable.types';
import PressableContainer from './Pressable.styles';

function Pressable({ children, onClick, ...rest }: TPressableProps) {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);

  function handlePressEnd() {
    setPressed(false);
  }

  function handleHoverStart() {
    setHovered(true);
  }

  function handleHoverEnd() {
    setHovered(false);
  }

  function handlePointerDown(event: PointerEvent) {
    (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    setPressed(true);
  }

  function handlePointerUp(event: PointerEvent) {
    (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
  }

  return (
    <PressableContainer
      {...(onClick && {
        onPointerDown: handlePointerDown,
        onPointerUp: handlePointerUp,
        onLostPointerCapture: handlePressEnd,
        onPointerLeave: handleHoverEnd,
        onPointerEnter: handleHoverStart,
      })}
      onClick={onClick}
      type="button"
      {...rest}>
      {typeof children === 'function' ? children?.({ pressed, hovered }) : children}
    </PressableContainer>
  );
}

export default Pressable;
