import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TTeamMembersDispatch, TTeamMemberState } from './TeamMembersProvider.types';

export const INITIAL_STATE: TTeamMemberState = {
  loading: true,
  teamMembers: [],
  hasError: false,
};

export const INITIAL_DISPATCH: TTeamMembersDispatch = {
  getTeamMembers: ASYNC_NOOP,
  clear: NOOP,
};
