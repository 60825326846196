import NOOP, { ASYNC_NOOP } from 'constants/noop';
import FeatureFlagType from 'enums/FeatureFlagType';

import { TFeatureFlagsDispatch, TFeatureFlagsState } from './FeatureFlagsProvider.types';

export const INITIAL_STATE: TFeatureFlagsState = {
  [FeatureFlagType.ONBOARDING_COMPANY]: false,
  [FeatureFlagType.ONBOARDING_SOLE_TRADER]: false,
  [FeatureFlagType.BULK_TRANSFER]: false,
  [FeatureFlagType.REFERRAL_CAMPAIGN]: false,
};

export const INITIAL_DISPATCH = {
  getFeatureFlagsByType: ASYNC_NOOP,
  clear: NOOP,
} as TFeatureFlagsDispatch;
