import { TCurrencyPairsSize, TGetCurrencyPairsSizeReturn } from './CurrencyPairs.types';

export const CURRENCY_PAIRS_SIZE_MAP: Record<TCurrencyPairsSize, TGetCurrencyPairsSizeReturn> = {
  medium: {
    width: 40,
    height: 40,
    smallIconSize: 24,
    largeIconSize: 32,
  },
  large: {
    width: 48,
    height: 48,
    smallIconSize: 28,
    largeIconSize: 40,
  },
};
