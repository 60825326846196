import React, { useState } from 'react';

import FieldRadioGroup from 'components/FieldRadioGroup';
import { RadioItem } from 'components/core/Radio';
import Box from 'components/core/Box';
import FieldTextInput from 'components/FieldTextInput';

import { TI18nId } from 'types/common';

import { TSecurityQuestionRadioGroupProps } from '../CardSecurityQuestionForm.types';
import { StyledHoverBox } from '../CardSecurityQuestionForm.styles';

function SecurityQuestionRadioGroup({
  securityQuestions,
  selectedSecurityQuestion,
  control,
  errors,
}: TSecurityQuestionRadioGroupProps) {
  const [focused, setFocused] = useState(false);

  return (
    <FieldRadioGroup
      control={control}
      errors={errors}
      name="securityQuestion"
      boxProps={{ mb: 'none' }}>
      {securityQuestions.map((item) => (
        <StyledHoverBox
          key={item.value}
          active={selectedSecurityQuestion?.value === item.value && focused}>
          <Box py="s">
            <RadioItem titleId={item.label as TI18nId} value={item} valueKey="value" />
          </Box>
          {selectedSecurityQuestion?.value === item.value && (
            <Box mt="s">
              <FieldTextInput
                name="answer"
                control={control}
                errors={errors}
                labelId={selectedSecurityQuestion.label as TI18nId}
                placeholderId="placeholder.empty"
                autoCapitalize="words"
                maxLength={50}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                marginBottom="none"
                autoFocus
              />
            </Box>
          )}
        </StyledHoverBox>
      ))}
    </FieldRadioGroup>
  );
}

export default SecurityQuestionRadioGroup;
