import React, { ReactElement } from 'react';

import Text from 'components/core/Text';

import { SwitchButtonContainer, SwitchButtonInput, SwitchButtonShape } from './SwitchButton.styles';
import { TSwitchButtonProps } from './SwitchButton.types';

function SwitchButton(
  { name, labelId, disabled, ...rest }: TSwitchButtonProps,
  ref: React.Ref<HTMLInputElement>
): ReactElement {
  return (
    <SwitchButtonContainer disabled={disabled} htmlFor={name}>
      {labelId && (
        <Text
          color={disabled ? 'switchButtonPassiveLabel' : 'switchButtonActiveLabel'}
          id={labelId}
        />
      )}
      <SwitchButtonInput ref={ref} type="checkbox" id={name} {...rest} />
      <SwitchButtonShape />
    </SwitchButtonContainer>
  );
}

export default React.forwardRef(SwitchButton);
