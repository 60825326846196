import { FC } from 'react';

import { ProfileCircleIcon, SuitcaseIcon } from 'components/Icons';

import { TIconProps } from 'components/Icons/Icon.types';
import { TI18nId } from 'types/common';

enum BeneficiaryType {
  PERSON = 0,
  COMPANY = 1,
}

const MAP: Record<BeneficiaryType, { labelId: TI18nId; icon: FC<TIconProps> }> = {
  [BeneficiaryType.PERSON]: { labelId: 'label.individual', icon: ProfileCircleIcon },
  [BeneficiaryType.COMPANY]: { labelId: 'label.business', icon: SuitcaseIcon },
};

export function getLabelIdByBeneficiaryType(beneficiaryType: BeneficiaryType) {
  return MAP[beneficiaryType]?.labelId ?? 'label.business';
}

export function getIconByBeneficiaryType(beneficiaryType: BeneficiaryType) {
  return MAP[beneficiaryType]?.icon ?? 'ProfileCircleIcon';
}

export default BeneficiaryType;
