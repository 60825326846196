import React, { useRef } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import PopConfirmModal from 'modals/PopconfirmModal';
import { TrashIcon } from 'components/Icons';
import { useModal } from 'components/core/Modal';

import PasswordAuthenticationPageModal from 'modals/PasswordAuthenticationPageModal';

import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import replaceUrlParams from 'helpers/replaceUrlParams';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';
import { useSideLayoutContext } from 'context/SideLayoutProvider';

import {
  TUseRecipientAuthenticationUpdate,
  TUseRecipientAuthenticationUpdateParams,
  TUseRecipientOperations,
  TUseRecipientOperationsParams,
} from './RecipientDetails.types';

function useRecipientOperations({
  id,
  afterDelete,
}: TUseRecipientOperationsParams): TUseRecipientOperations {
  const { sideLayoutDispatch } = useSideLayoutContext();
  const deleteRecipientConfirmModalRef = useRef<TPopconfirmModalRef>(null);
  const authPreferenceAuthenticationModalRef = useModal();
  const { data, get: requestRecipientDetail } = useFetch(
    replaceUrlParams(API_ENDPOINT.BENEFICIARY_BY_ID, { id })
  );
  const { delete: requestDeleteRecipient } = useFetch(
    replaceUrlParams(API_ENDPOINT.BENEFICIARY_BY_ID, { id })
  );
  const { enableAuthentication, disableAuthentication, updateAuthenticationLoading } =
    useRecipientAuthenticationUpdate({
      id,
      onSuccess: requestRecipientDetail,
    });

  function handleShowDeleteRecipientConfirmModal() {
    deleteRecipientConfirmModalRef.current?.show({
      titleId: 'modals.recipientDeleteConfirmationModal.title',
      contentIcon: <TrashIcon width={64} height={64} />,
      confirmButtonProps: {
        id: 'button.delete',
        variant: 'danger',
      },
      cancelButtonTitleId: 'button.cancel',
      onConfirm: handleDeleteRecipient,
    });
  }

  async function handleDeleteRecipient() {
    try {
      await requestDeleteRecipient();
      await afterDelete?.();
      sideLayoutDispatch.hideSideLayout();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({
        titleId: 'label.unexpectedError',
      });
    }
  }

  async function handleRecipientAuthenticationChange(alwaysAuthenticate: boolean) {
    if (!alwaysAuthenticate) {
      authPreferenceAuthenticationModalRef.current?.show(true);
      return;
    }
    await enableAuthentication();
  }

  return {
    recipient: data?.result,
    getDetail: requestRecipientDetail,
    updateAuthentication: handleRecipientAuthenticationChange,
    updateAuthenticationLoading,
    showDeleteModal: handleShowDeleteRecipientConfirmModal,
    renderedModals: (
      <>
        <PasswordAuthenticationPageModal
          modalRef={authPreferenceAuthenticationModalRef}
          onSubmit={disableAuthentication}
          onSuccess={requestRecipientDetail}
        />
        <PopConfirmModal ref={deleteRecipientConfirmModalRef} />
      </>
    ),
  };
}

function useRecipientAuthenticationUpdate({
  id,
  onSuccess,
}: TUseRecipientAuthenticationUpdateParams): TUseRecipientAuthenticationUpdate {
  const { put: requestUpdateBeneficiary, loading } = useFetch(
    replaceUrlParams(API_ENDPOINT.BENEFICIARIES_AUTHENTICATION, { id })
  );

  async function handleEnableAuthentication() {
    try {
      const { errorMessage } = await requestUpdateBeneficiary({
        alwaysAuthenticate: true,
      });

      if (errorMessage) {
        showErrorAlert({ title: errorMessage });
        return;
      }

      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({
        titleId: 'label.unexpectedError',
      });
    }
  }

  return {
    updateAuthenticationLoading: loading,
    enableAuthentication: handleEnableAuthentication,
    disableAuthentication: ({ password }) =>
      requestUpdateBeneficiary({
        alwaysAuthenticate: false,
        password,
      }),
  };
}

export { useRecipientOperations };
