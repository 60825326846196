import React from 'react';
import { Controller } from 'react-hook-form';

import Form from 'components/Form';
import FieldTextInput from 'components/FieldTextInput';
import FieldPhoneNumberInput from 'components/FieldPhoneNumberInput';
import Box from 'components/core/Box';
import Card from 'components/core/Card';
import Button from 'components/core/Button';
import LedgerSelectItem from 'components/LedgerSelectItem';
import Text from 'components/core/Text';
import FieldSwitchButton from 'components/FieldSwitchButton';
import ListItem from 'components/v2/core/ListItem';
import { EyeIcon } from 'components/v2/Icons';

import useForm from 'hooks/useForm';
import date from 'helpers/date';
import masks from 'helpers/masks';
import MemberType, { getDeleteButtonIdByMemberType } from 'enums/MemberType';
import { useMemberContext } from 'context/MemberProvider';
import { useLedgersContext } from 'context/LedgersProvider';
import { TControllerRenderProps } from 'types/form';

import { FORM_VALIDATION_SCHEMA } from '../TeamMemberDetail.constants';
import { TTeamMemberDetailFormData, TTeamMemberDetailFormProps } from '../TeamMemberDetail.types';

function TeamMemberDetailForm({
  teamMember,
  onDelete,
  onSubmit,
  loading,
}: TTeamMemberDetailFormProps) {
  const { memberState } = useMemberContext();
  const { ledgersState } = useLedgersContext();
  const {
    control,
    trigger,
    formState: { errors, isSubmitted },
    handleSubmit,
  } = useForm<TTeamMemberDetailFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues: {
      firstName: teamMember.firstName,
      lastName: teamMember.lastName,
      email: teamMember.email,
      phoneNumberCountry: teamMember.phoneNumber?.country,
      phoneNumber: teamMember.phoneNumber?.nationalNumber,
      ...(teamMember.dateOfBirth && {
        dateOfBirth: date.format({
          date: teamMember.dateOfBirth,
          formatType: 'dd / MM / yyyy',
        }),
      }),
      ...(teamMember.memberType === MemberType.EMPLOYEE && {
        allowedLedgers: getInitialAllowedLedgers(),
        showBalance: teamMember.showBalance,
      }),
    },
  });

  function getInitialAllowedLedgers() {
    const allowedLedgersIds = (teamMember.allowedLedgers ?? []).map(({ ledgerId }) => ledgerId);
    return ledgersState.ledgers
      .filter(({ id }) => allowedLedgersIds.includes(id))
      .map(({ id, name }) => ({
        ledgerId: id,
        ledgerName: name,
      }));
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Box px="m" pb="m">
        <FieldTextInput
          control={control}
          errors={errors}
          name="firstName"
          labelId="label.firstName"
          autoCapitalize="words"
          disabled={!!teamMember.firstName}
          autoFocus
        />
        <FieldTextInput
          control={control}
          errors={errors}
          name="lastName"
          labelId="label.lastName"
          disabled={!!teamMember.lastName}
          autoCapitalize="words"
        />
        <FieldTextInput
          name="email"
          type="email"
          labelId="label.email"
          placeholderId="placeholder.email"
          control={control}
          errors={errors}
          inputMode="email"
          disabled={!!teamMember.email}
        />
        <FieldPhoneNumberInput
          control={control}
          errors={errors}
          trigger={trigger}
          isSubmitted={isSubmitted}
          disabled={!!teamMember.phoneNumber}
        />
        <FieldTextInput
          name="dateOfBirth"
          labelId="label.dateOfBirth"
          placeholderId="placeholder.dateOfBirth"
          control={control}
          errors={errors}
          mask={masks.birthdate.mask}
          maxLength={14}
          replaceChangeText={date.replaceBirthOfDate}
        />
        {teamMember.memberType === MemberType.EMPLOYEE && (
          <>
            <Text id="label.accessibleAccount" variant="smallTitleSemiBold" mt="xl" mb="m" />
            <Controller
              name="allowedLedgers"
              control={control}
              render={({ field }: TControllerRenderProps) => (
                <LedgerSelectItem
                  modalTitleId="label.accessibleAccount"
                  selectedLedgers={field.value ?? []}
                  onSelect={field.onChange}
                />
              )}
            />
          </>
        )}
        {teamMember.memberType === MemberType.EMPLOYEE && (
          <Card p="m" gap="m" mt="m">
            <ListItem
              labelId="screens.addEmployee.showBalances.ledger.label"
              descriptionId="screens.addEmployee.showBalances.ledger.description"
              icon={EyeIcon}
            />
            <Box px="s">
              <FieldSwitchButton
                labelId="screens.addEmployee.showBalances.switchButton.label"
                name="showBalance"
                control={control}
                errors={errors}
              />
            </Box>
          </Card>
        )}
        {teamMember.id !== memberState?.id && (
          <Box mt="m">
            <Button
              id={getDeleteButtonIdByMemberType(teamMember.memberType)}
              variant="danger"
              onClick={onDelete}
              disabled={loading}
            />
            <Button id="button.update" mt="m" onClick={handleSubmit(onSubmit)} loading={loading} />
          </Box>
        )}
      </Box>
    </Form>
  );
}

export default TeamMemberDetailForm;
