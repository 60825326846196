import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const PayoutIcon = ({
  iconColor = 'transactionItemPayoutIcon',
  ...rest
}: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg
      style={{ transform: 'rotate(-180deg)' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}>
      <path
        d="M15.1464 15.1474C15.4614 15.4623 16 15.2393 16 14.7938L16 11.5009C16 11.2248 15.7761 11.0009 15.5 11.0009L12.2071 11.0009C11.7617 11.0009 11.5386 11.5395 11.8536 11.8545L15.1464 15.1474Z"
        fill={colors[iconColor]}
      />
      <path
        d="M15 12.0009L8 18.0009L2 13.0009"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19.5"
        cy="8.50092"
        r="2.5"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PayoutIcon;
