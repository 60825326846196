import { ReactNode } from 'react';

import { TOnboardingPageProps } from 'components/OnboardingPage/OnboardingPage.types';

export type TDashboardLayoutProps = {
  navMenu: NavMenu;
  children?: ReactNode;
  visibleMenu?: boolean;
  isPageModal?: boolean;
  sideLayoutVisible?: boolean;
} & TOnboardingApplicationSummaryLayoutProps;

export type TDashboardLayoutNavProps = {
  navMenu: NavMenu;
} & TOnboardingApplicationSummaryLayoutProps;

export type TOnboardingApplicationSummaryLayoutProps = {
  children?: ReactNode;
  rightContent?: TOnboardingPageProps['rightContent'];
  activeParentStepIndex?: number;
  activeChildStepIndex?: number;
  hideProgressSteps?: boolean;
  showMobileNav?: boolean;
};

export type TOnboardingApplicationSummaryLayoutNavProps = Pick<
  TOnboardingApplicationSummaryLayoutProps,
  'activeParentStepIndex' | 'activeChildStepIndex' | 'hideProgressSteps' | 'showMobileNav'
>;

export type TOnboardingApplicationSummaryLayoutDesktopNavProps = Omit<
  TOnboardingApplicationSummaryLayoutNavProps,
  'showMobileNav'
>;

export enum NavMenu {
  ONBOARDING = 'onboarding',
  HOME = 'home',
}
