import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const HeartIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="m12.662 19.765.494.87.03-.018-.524-.852Zm-1.315 0-.523.852.012.007.511-.86Zm.743-13.537-.852-.524.852.524Zm-.179 0 .855-.52-.855.52ZM12 21c.277 0 .528-.081.685-.141.175-.067.34-.15.47-.225l-.987-1.739a1.528 1.528 0 0 1-.244.111c-.006.002.022-.006.076-.006v2Zm1.185-.383c2.61-1.605 4.793-3.371 6.333-5.254 1.54-1.884 2.482-3.941 2.482-6.1h-2c0 1.555-.677 3.18-2.03 4.834-1.353 1.655-3.34 3.283-5.832 4.816l1.047 1.704ZM22 9.264C22 5.65 19.374 3 15.952 3v2C18.22 5 20 6.706 20 9.264h2ZM15.952 3c-2.096 0-3.724 1.095-4.714 2.704l1.704 1.048C13.615 5.656 14.637 5 15.951 5V3Zm-3.186 2.708C11.789 4.1 10.14 3 8.048 3v2c1.316 0 2.352.666 3.009 1.747l1.709-1.039ZM8.048 3C4.626 3 2 5.651 2 9.264h2C4 6.706 5.78 5 8.048 5V3ZM2 9.264c0 2.158.941 4.215 2.483 6.1 1.541 1.882 3.726 3.648 6.341 5.253l1.046-1.705c-2.496-1.532-4.485-3.16-5.84-4.815C4.678 12.442 4 10.817 4 9.264H2Zm8.836 11.36c.135.08.3.165.477.234.159.06.41.142.687.142v-2c.054 0 .082.008.077.007a1.243 1.243 0 0 1-.218-.101l-1.023 1.718Zm.402-14.92a.895.895 0 0 1 1.528.004l-1.71 1.039a1.105 1.105 0 0 0 1.886.005l-1.704-1.048Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default HeartIcon;
