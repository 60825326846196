import React, { createContext, ReactElement, useContext, useState, useEffect } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TInvoiceSettingsResponse } from 'types/invoice';

import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoiceSettingsProvider.constants';
import { TInvoiceSettingsState, TInvoiceSettingsContext } from './InvoiceSettingsProvider.types';
import { useInvoiceSettingsUpdate } from './InvoiceSettingsProvider.hooks';

const invoiceSettingsContext = createContext<TInvoiceSettingsContext>({
  invoiceSettingsState: INITIAL_STATE,
  invoiceSettingsDispatch: INITIAL_DISPATCH,
});

function InvoiceSettingsProvider({ children }: TChildrenOnly): ReactElement {
  const {
    get: requestGetInvoiceSettings,
    data,
    response,
  } = useFetch<TCommonResponse<TInvoiceSettingsResponse>>(
    API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_SETTINGS
  );
  const [state, setState] = useState<TInvoiceSettingsState>(INITIAL_STATE);
  const {
    updateMerchantLogo,
    updateBusinessDetails,
    updateDefaultSettings,
    updateEmailMessages,
    updateDefaultFooter,
    setupInvoiceSettings,
  } = useInvoiceSettingsUpdate();

  useEffect(() => {
    if (response.ok && data?.result) {
      setState((oldState) => ({
        ...oldState,
        settings: data.result,
        loading: false,
      }));
    }
  }, [data]);

  function clear() {
    setState(INITIAL_STATE);
  }

  async function getInvoiceSettings() {
    await requestGetInvoiceSettings();
  }

  const value: TInvoiceSettingsContext = {
    invoiceSettingsState: state,
    invoiceSettingsDispatch: {
      updateMerchantLogo,
      updateBusinessDetails,
      updateDefaultSettings,
      updateEmailMessages,
      updateDefaultFooter,
      setupInvoiceSettings,
      getInvoiceSettings,
      clear,
    },
  };

  return (
    <invoiceSettingsContext.Provider value={value}>{children}</invoiceSettingsContext.Provider>
  );
}

export default InvoiceSettingsProvider;

export const useInvoiceSettingsContext = (): TInvoiceSettingsContext =>
  useContext(invoiceSettingsContext);
