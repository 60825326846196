import React from 'react';
import { isNil } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import { TAvatarProps } from './Avatar.types';

function Avatar({ label, children, className }: TAvatarProps) {
  return (
    <Box
      alignment="center"
      width={40}
      height={40}
      borderRadius={8}
      backgroundColor="tertiary"
      className={className}>
      {!isNil(label) && (
        <Text variant="mediumTextBold" color="textWhite">
          {label}
        </Text>
      )}
      {children}
    </Box>
  );
}

export default Avatar;
