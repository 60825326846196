import { lazy } from 'react';
import * as Sentry from '@sentry/react';

import storage, { STORAGE_KEYS } from 'helpers/storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function lazyWithRetry(componentImport: any) {
  // eslint-disable-next-line consistent-return
  return lazy(async () => {
    const isPageHasBeenForceRefreshed: boolean = JSON.parse(
      storage.readStorage(STORAGE_KEYS.PAGE_HAS_BEEN_FORCE_REFRESHED) ?? String(false)
    );

    try {
      const component = await componentImport();

      if (!component?.default) {
        Sentry.captureMessage('Could not found default', {
          level: 'info',
          extra: {
            component,
          },
        });
        throw new Error('Could not found default');
      }

      storage.removeStorage(STORAGE_KEYS.PAGE_HAS_BEEN_FORCE_REFRESHED);

      return component;
    } catch (e) {
      if (!isPageHasBeenForceRefreshed) {
        storage.writeStorage(STORAGE_KEYS.PAGE_HAS_BEEN_FORCE_REFRESHED, String(true));
        return window.location.reload();
      }
      Sentry.captureException(e);
      throw e;
    }
  });
}

export default lazyWithRetry;
