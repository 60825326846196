import React from 'react';
import { useTheme } from 'styled-components';

import { StyledSpinner } from './Spinner.styles';
import { TSpinnerProps } from './Spinner.types';

function Spinner({ size = 32, color = 'primary', className, currentColor = false }: TSpinnerProps) {
  const { colors } = useTheme();

  return (
    <StyledSpinner
      size={size}
      color={currentColor ? 'currentColor' : colors[color]}
      className={className}>
      <div />
      <div />
      <div />
      <div />
    </StyledSpinner>
  );
}

export default Spinner;
