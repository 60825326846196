import palette from 'theme/palette';
import { TThemePrimitives } from './theme.types';

const lightPrimitives: TThemePrimitives = {
  primary: palette.black,
  secondary: palette.green500,
  tertiary: palette.blue,

  transparent: palette.transparent,

  primaryBackground: palette.gray40,
  secondaryBackground: palette.black,
  tertiaryBackground: palette.gray90,
  whiteBackground: palette.white,

  textPrimary: palette.black,
  textSecondary: palette.gray900,
  textTertiary: palette.gray800,
  textLink: palette.blue,
  textWhite: palette.white,

  success: palette.green900,
  warning: palette.orange,
  error: palette.red500,
  danger: palette.red500,

  currentColor: 'currentColor',

  // Button primary default
  buttonPrimaryBackground: palette.blue,
  buttonPrimaryBorder: palette.blue,
  buttonPrimaryText: palette.white,
  // Button primary tapped
  buttonPrimaryTappedBackground: palette.blue900,
  buttonPrimaryTappedText: palette.white,
  buttonPrimaryTappedBorder: palette.blue900,
  // Button primary hover
  buttonPrimaryHoverBackground: palette.blue800,
  buttonPrimaryHoverBorder: palette.blue800,
  // Button primary disabled
  buttonPrimaryDisabledBackground: palette.gray80,
  buttonPrimaryDisabledBorder: palette.gray80,
  buttonPrimaryDisabledText: palette.white,

  // Button secondary default
  buttonSecondaryBackground: palette.transparent,
  buttonSecondaryBorder: palette.black,
  buttonSecondaryText: palette.black,
  // Button secondary tapped
  buttonSecondaryTappedBackground: palette.blue300,
  buttonSecondaryTappedBorder: palette.blue400,
  buttonSecondaryTappedText: palette.black,
  // Button secondary hover
  buttonSecondaryHoverBackground: palette.blue300,
  buttonSecondaryHoverBorder: palette.blue300,
  // Button secondary disabled
  buttonSecondaryDisabledBackground: palette.gray80,
  buttonSecondaryDisabledBorder: palette.gray80,
  buttonSecondaryDisabledText: palette.white,

  // Button ghost default
  buttonGhostBackground: palette.blue300,
  buttonGhostBorder: palette.blue300,
  buttonGhostText: palette.blue,
  // Button ghost tapped
  buttonGhostTappedBackground: palette.blue400,
  buttonGhostTappedBorder: palette.blue400,
  buttonGhostTappedText: palette.blue900,
  // Button ghost hover
  buttonGhostHoverBackground: palette.blue300,
  buttonGhostHoverBorder: palette.blue300,
  // Button ghost disabled
  buttonGhostDisabledBackground: palette.gray80,
  buttonGhostDisabledBorder: palette.gray80,
  buttonGhostDisabledText: palette.white,

  // Button link default
  buttonLinkBackground: palette.transparent,
  buttonLinkBorder: palette.transparent,
  buttonLinkText: palette.blue,
  // Button link tapped
  buttonLinkTappedBackground: palette.transparent,
  buttonLinkTappedBorder: palette.transparent,
  buttonLinkTappedText: palette.blue900,
  // Button link disabled
  buttonLinkDisabledBackground: palette.transparent,
  buttonLinkDisabledBorder: palette.transparent,
  buttonLinkDisabledText: palette.gray80,

  // Button danger default
  buttonDangerBackground: palette.red300,
  buttonDangerBorder: palette.red300,
  buttonDangerText: palette.red500,
  // Button danger tapped
  buttonDangerTappedBackground: palette.red400,
  buttonDangerTappedBorder: palette.red400,
  buttonDangerTappedText: palette.red900,
  // Button danger hover
  buttonDangerHoverBackground: palette.red400,
  buttonDangerHoverBorder: palette.red400,
  buttonDangerHoverText: palette.red800,
  // Button danger disabled
  buttonDangerDisabledBackground: palette.gray80,
  buttonDangerDisabledBorder: palette.gray80,
  buttonDangerDisabledText: palette.white,

  buttonGhostGreenBackground: palette.green100,
  buttonGhostGreenText: palette.green600,
  buttonGhostGreenTappedBackground: palette.green300,
  buttonGhostGreenTappedText: palette.green900,

  // TextButton component
  textButtonPrimaryText: palette.black,
  textButtonPrimaryHoverText: palette.blue,
  textButtonSecondaryText: palette.blue,
  textButtonSecondaryHoverText: palette.black,

  // Tab component
  tabBackground: palette.gray90,
  tabActiveBackground: palette.white,

  // ListItem component
  listItemBackground: palette.white,
  listItemHoverBackground: palette.gray200,
  listItemActiveBackground: palette.gray200,

  // TransactionItem
  transactionItemIcon: palette.newGray30,
  transactionItemDate: palette.black,
  transactionItemIconBackground: palette.gray40,
  transactionItemPayoutIcon: palette.green800,
  transactionItemConvertedAmount: palette.gray700,
  transactionDeclinedReversedAmount: palette.gray600,
  transactionItemStatusLabel: palette.newGray50,

  // Scheduled Transaction Item
  scheduledTransactionItemInactive: palette.gray60,

  // PaymentItem
  paymentItemIcon: palette.newGray30,
  paymentItemBackground: palette.purple,
  paymentItemStatusFailure: palette.red700,
  paymentItemStatusSuccess: palette.green800,
  paymentItemStatusPayoutCompleted: palette.green800,
  paymentItemStatusPayoutCompletedIcon: palette.green500,
  paymentItemStatusPending: palette.orange,
  paymentItemStatusLabel: palette.newGray50,

  // Shimmer
  shimmerPrimaryBackground: palette.gray100,
  shimmerPrimaryIndicatorBackground: palette.gray300,
  shimmerSecondaryBackground: palette.gray300,
  shimmerSecondaryIndicatorBackground: palette.gray400,

  // StickyHeader
  stickyHeaderButtonIcon: palette.gray900,

  // SearchHeader
  searchHeaderCloseButtonIcon: palette.newGray50,

  // Header back title
  headerBackTitle: palette.newGray50,

  // Input
  textInput: palette.black,
  textInputFocusedBackground: palette.white,
  textInputBlurBackground: palette.gray90,
  textInputHoverBackground: palette.gray80,
  textInputPlaceholder: palette.gray500,
  textInputLabel: palette.black,
  textInputFocusedTopLabel: palette.blue,
  textInputTopLabelDisabled: palette.gray60,
  textInputBorder: palette.gray90,
  textInputFocusedBorder: palette.blue,
  textInputErrorBackground: palette.red50,
  textInputErrorLabel: palette.red500,
  textInputErrorDescriptionLabel: palette.gray600,
  textInputErrorBorder: palette.red500,
  textInputCharactersLimit: palette.newGray30,

  // NavItem
  navItemBackground: palette.gray200,
  navItemHover: palette.gray200,
  navItemActive: palette.gray200,

  // Empty Content Icon
  emptyContentIcon: palette.gray400,

  // Icons
  freezeIcon: palette.lightBlue,
  chevronRightIcon: palette.gray600,
  reviewLoadingIcon: palette.orange,
  whiteIcon: palette.white,

  // Amount
  amountLabelPrimaryText: palette.black,
  amountLabelSecondaryPassiveText: palette.gray70,

  // FieldAmountInput
  amountInputDisabledText: palette.gray600,

  // DatePicker
  datePickerDateOutside: palette.gray400,
  datePickerDaySelected: palette.gray200,
  datePickerDayStartAndEndLabel: palette.black,

  // Checkbox
  checkboxBorder: palette.blue,
  checkboxActive: palette.blue,
  checkboxDisabled: palette.blue100,
  checkboxErrorBorder: palette.red500,
  checkboxErrorBackground: palette.red500,
  checkboxIcon: palette.white,

  // Radio
  radioBorder: palette.blue,
  radioHover: palette.blue800,
  radioActive: palette.blue,
  radioDisabled: palette.blue100,
  radioErrorBorder: palette.red500,
  radioErrorBackground: palette.red50,

  // Loading
  loadingInfoText: palette.white,

  // Card
  cardBackground: palette.white,
  cardEmptyInfoBackground: palette.gray90,
  cardActiveHoverBackground: palette.blue300,
  cardShadow: 'rgba(5, 23, 38, 0.16)',
  cardVirtualBadgeBackground: palette.gray80,
  cardPhysicalBadgeBackground: palette.newGray30,
  cardActionLabelDisabled: palette.gray60,

  // InfoItem
  infoItemTitle: palette.gray700,
  infoItemValue: palette.black,
  infoItemGrayTitle: palette.gray60,

  // NavItem
  navItemActiveText: palette.white100,

  // MultiFileUpload
  multiFileUploadBorder: palette.blue,
  multiFileUploadBorderHover: palette.blue300,
  multiFileUploadBackground: palette.blue300,
  multiFileUploadIconBackground: palette.orange,
  multiFileUploadTrashIcon: palette.newGray30,

  // Home
  homeBannerText: palette.white,

  // PricingPlan
  billingDetailsFeeTypeItemDescription: palette.gray60,
  billingPlanPopularText: palette.white,
  billingCycleLabel: palette.white,
  billingPlanHeaderBackground: palette.white,
  billingPlanHeaderBorderActive: palette.green500,
  billingPlanHeaderDotBackgroundActive: palette.black,
  billingPlanHeaderDotBackground: palette.gray400,

  // Amount Input Fee
  amountInputFee: palette.blue,

  // Step Screen
  stepScreenLineBackground: palette.gray80,
  stepScreenActiveLineBackground: palette.blue,

  // CardInfoItem
  cardInfoItemDisabledBackground: palette.gray90,
  cardInfoItemDisabledIconBackground: palette.gray70,
  cardInfoItemDisabledIconColor: palette.gray40,
  cardInfoItemIconColor: palette.white,
  cardInfoItemOrangeIconColor: palette.orange,

  // FieldCountrySelect
  fieldCountrySelectIconBackground: palette.orange,
  fieldCountrySelectIconColor: palette.white,

  // DocumentsCardButton
  documentsCardButtonBackground: palette.gray40,

  // Card Button
  cardButtonHeaderColor: palette.newGray40,

  // ProgressBar
  progressBarBackground: palette.gray90,

  // Spending Limit Card Button
  spendingLimitCardButtonProgressBarIndicator: palette.blue,
  spendingLimitCardButtonLimitTypeColor: palette.newGray50,

  // SwitchButton
  switchButtonActiveThumb: palette.white,
  switchButtonPassiveThumb: palette.gray90,
  switchButtonActiveTrack: palette.blue,
  switchButtonPassiveTrack: palette.gray90,
  switchButtonActiveLabel: palette.black,
  switchButtonPassiveLabel: palette.gray70,

  // Stories
  storyImageBoxBackground: palette.gray40,

  // CircularProgressBar
  circularProgressBarBackground: palette.gray70,
  circularProgressBarStrokeInitial: palette.green500,
  circularProgressBarStrokeThreshold50: palette.red500,

  // PayLaterButton
  payLaterBadgeTitle: palette.purple,

  // Dot
  dotColor: palette.gray500,

  // CashbackItem
  cashbackItemTitleColor: palette.gray600,
  cashbackItemBorderColor: palette.gray40,

  cashbackBannerBackground: palette.purple,

  // Info banner
  infoBannerInfoBackground: palette.blue300,
  infoBannerInfoText: palette.blue,
  infoBannerWarningBackground: palette.orange100,
  infoBannerWarningText: palette.orange,
  infoBannerDangerBackground: palette.red100,
  infoBannerDangerText: palette.red500,
  infoBannerSuccessBackground: palette.green300,
  infoBannerSuccessText: palette.green600,

  // BusinessOwnerCard
  businessOwnerCardEditText: palette.newGray50,

  // Badge
  badgeInfoText: palette.blue,
  badgeInfoBackground: palette.blue300,
  badgeSuccessText: palette.green600,
  badgeSuccessBackground: palette.green300,
  badgeWarningText: palette.orange,
  badgeWarningBackground: palette.orange100,
  badgeDangerText: palette.red500,
  badgeDangerBackground: palette.red100,

  // Onboarding Progress Steps
  onboardingProgressStepsCheckBackground: palette.green600,
  onboardingProgressStepsItemActiveStatus: palette.blue,
  onboardingProgressStepsItemDisabledStatus: palette.newGray50,
  onboardingProgressStepsLineBackground: palette.gray90,

  // OnboardingTodoItem
  onboardingTodoItemPassiveText: palette.newGray50,
  onboardingTodoItemInfoIcon: palette.newGray50,
  onboardingTodoItemInfoText: palette.gray60,

  // OnboardingSummaryInfoItem
  onboardingSummaryInfoItemProgressBar: palette.green500,
  onboardingSummaryEditButton: palette.newGray50,

  // MoneyAmount
  moneyAmountText: palette.newGray50,

  // Select
  selectDefaultIcon: palette.newGray50,

  // Dot
  dotGrayBackground: palette.newGray30,

  // SendMoneyRecipientConfirmation
  sendMoneyRecipientConfirmationTrustedRecipientIcon: palette.orange,
  sendMoneyRecipientConfirmationTrustedRecipientIconBackground: 'rgba(255, 111, 0, 0.08)',

  // SendMoneyTrustedRecipientInformation
  sendMoneyTrustedRecipientInformationCurrencyText: palette.newGray30,
  sendMoneyTrustedRecipientInformationIcon: palette.newGray50,
};

export default lightPrimitives;
