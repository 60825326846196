import React, { memo, ReactElement } from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { TInfoItemShimmerProps } from '../InfoItem.types';

function InfoItemShimmer({ count }: TInfoItemShimmerProps): ReactElement {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Box key={i.toString()} alignment="col.verticalCenter" padding="m">
          <Shimmer shape="line" marginRight="m" height="16px" width="20%" mb="m" />
          <Shimmer shape="line" marginRight="m" height="16px" width="40%" />
        </Box>
      ))}
    </>
  );
}

export default memo(InfoItemShimmer);
