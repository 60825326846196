import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const MoreIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M18.25 11.5C18.25 10.8096 18.8096 10.25 19.5 10.25C20.1904 10.25 20.75 10.8096 20.75 11.5C20.75 12.1904 20.1904 12.75 19.5 12.75C18.8096 12.75 18.25 12.1904 18.25 11.5ZM10.75 11.5C10.75 10.8096 11.3096 10.25 12 10.25C12.6904 10.25 13.25 10.8096 13.25 11.5C13.25 12.1904 12.6904 12.75 12 12.75C11.3096 12.75 10.75 12.1904 10.75 11.5ZM3.25 11.5C3.25 10.8096 3.80964 10.25 4.5 10.25C5.19036 10.25 5.75 10.8096 5.75 11.5C5.75 12.1904 5.19036 12.75 4.5 12.75C3.80964 12.75 3.25 12.1904 3.25 11.5Z"
        stroke={colors[iconColor]}
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default MoreIcon;
