import NOOP from 'constants/noop';

import { TBulkTransfersDispatch, TBulkTransfersState } from './BulkTransfersProvider.types';

export const INITIAL_STATE: TBulkTransfersState = {
  selectedBeneficiariesIds: [],
  transfers: [],
  ledgerFrom: undefined,
  isEnoughMoney: false,
  totalTransfer: undefined,
  totalEstimatedFee: undefined,
  scheduleOptions: undefined,
  numberOfCreatedBeneficiaries: 0,
};

export const INITIAL_DISPATCH: TBulkTransfersDispatch = {
  setSendInfo: NOOP,
  setTransfers: NOOP,
  addBeneficiary: NOOP,
  removeBeneficiary: NOOP,
  setScheduleOptions: NOOP,
  setNumberOfCreatedBeneficiaries: NOOP,
  setLedgerFrom: NOOP,
  clearSendInfo: NOOP,
  clear: NOOP,
};
