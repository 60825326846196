import { useState } from 'react';
import { debounce, isEmpty } from 'lodash/fp';

import { TTeamMember } from 'types/teamMember';
import { TUseListWithSearch } from 'types/list';
import { useMemberContext } from 'context/MemberProvider';

import { useTeamMembersContext } from './TeamMembersProvider';
import { TUseTeamMembersList } from './TeamMembersProvider.types';

export function useTeamMembersList({
  filterCurrentMember,
}: TUseTeamMembersList): TUseListWithSearch<TTeamMember> {
  const { memberState } = useMemberContext();
  const { teamMembersState, teamMembersDispatch } = useTeamMembersContext();
  const [filteredData, setFilteredData] = useState<TTeamMember[]>();
  const [isSearchTextEmpty, setIsSearchTextEmpty] = useState<boolean>(true);

  const teamMembers = filterCurrentMember
    ? teamMembersState.teamMembers.filter(({ id }) => id !== memberState?.id)
    : teamMembersState.teamMembers;

  async function getInitialData() {
    await teamMembersDispatch.getTeamMembers();
  }

  function handleClearSearch() {
    setIsSearchTextEmpty(true);
    setFilteredData(undefined);
  }

  function filterTeamMember(teamMember: TTeamMember, searchText: string): boolean {
    const textToSearch = searchText.toLowerCase();

    const { firstName, lastName, email } = teamMember;
    const fullName =
      firstName && lastName && `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`;
    const isNameMatched = fullName?.includes(textToSearch);
    const isEmailMatched = email.toLowerCase().includes(textToSearch);

    return isNameMatched || isEmailMatched;
  }

  function handleSearch(searchText: string) {
    if (searchText.length === 0) {
      handleClearSearch();
      return;
    }

    setIsSearchTextEmpty(false);
    setFilteredData(teamMembers.filter((item) => filterTeamMember(item, searchText)));
  }

  return {
    initialLoading: teamMembersState.loading,
    data: filteredData ?? teamMembers,
    isListEmpty: isEmpty(teamMembers),
    hasError: teamMembersState.hasError,
    getInitialData,
    clearSearch: handleClearSearch,
    isSearchResultEmpty: !teamMembersState.loading && isEmpty(filteredData) && !isSearchTextEmpty,
    search: debounce(300, handleSearch),
    searchLoading: false,
  };
}

export default useTeamMembersList;
