import styled from 'styled-components';
import { variant, compose, space, color } from 'styled-system';

import borderRadius from 'theme/borderRadius';
import spacing from 'theme/spacing';

import { BUTTON_VARIANTS, BUTTON_SIZES, BUTTON_SHAPES } from './Button.variants';
import { TButtonProps } from './Button.types';

export const StyledButton = styled.button<{ block: boolean; variant: TButtonProps['variant'] }>`
  width: ${(props) => (props.block ? '100%' : 'auto')};
  height: 3.25rem;
  padding: 0 ${spacing.s};
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius.s};
  border: 1px solid transparent;

  .button-label {
    padding: 0 ${spacing.s};
    font-weight: 700;
  }

  ${compose(
    space,
    variant({
      variants: BUTTON_VARIANTS,
    }),
    variant({
      prop: 'size',
      variants: BUTTON_SIZES,
    }),
    variant({
      prop: 'shape',
      variants: BUTTON_SHAPES,
    })
  )}
  ${color}
`;

export const StyledBaseButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
