import React, { useRef, useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import masks from '@wamoio/common/lib/masks';

import SideLayoutPage from 'components/SideLayoutPage';
import { TrashIcon } from 'components/Icons';

import normalizeUrl from 'helpers/normalizeUrl';
import { useSideLayoutContext } from 'context/SideLayoutProvider';
import { getDetailHeaderTitleIdByMemberType } from 'enums/MemberType';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import PopconfirmModal from 'modals/PopconfirmModal';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import convertToDateRequest from 'helpers/convertToDateRequest';
import { useTeamMembersContext } from 'context/TeamMembersProvider';

import { TTeamMemberDetailFormData, TTeamMemberDetailProps } from './TeamMemberDetail.types';
import TeamMemberDetailForm from './views/TeamMemberDetailForm';

function TeamMemberDetail({ teamMember: teamMemberValue, refetch }: TTeamMemberDetailProps) {
  const teamMemberDeleteModalRef = useRef<TPopconfirmModalRef>(null);
  const { sideLayoutDispatch } = useSideLayoutContext();
  const { teamMembersDispatch } = useTeamMembersContext();
  const [teamMember, setTeamMember] = useState(teamMemberValue);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { put: requestUpdateTeamMember, response } = useFetch(
    normalizeUrl(API_ENDPOINT.TEAM_MEMBER_DETAIL, {
      pathVariables: {
        id: teamMember.id,
      },
    })
  );
  const { delete: requestDeleteTeamMember } = useFetch(API_ENDPOINT.TEAM_MEMBERS_DELETE);

  useEffect(() => {
    if (teamMember && teamMember.id !== teamMemberValue.id) {
      changeTeamMember();
    }
  }, [teamMember?.id, teamMemberValue?.id]);

  async function changeTeamMember() {
    setLoading(true);
    await handleSetTeamMember();
    setLoading(false);
  }

  async function handleSetTeamMember() {
    return new Promise((resolve) => {
      setTeamMember(teamMemberValue);
      return resolve(teamMemberValue);
    });
  }

  function handleOpenDeleteModal() {
    teamMemberDeleteModalRef.current?.show({
      contentIcon: <TrashIcon width={64} height={64} />,
      titleId: 'modals.deleteTeamMemberModal.title',
      descriptionId: 'modals.deleteTeamMemberModal.description',
      confirmButtonProps: {
        id: 'button.delete',
        variant: 'danger',
      },
      onConfirm: handleDeleteTeamMember,
    });
  }

  async function handleDeleteTeamMember() {
    try {
      const { errorMessage } = await requestDeleteTeamMember(`/${teamMember.id}`);

      if (errorMessage) {
        showErrorAlert({ title: errorMessage });
        return;
      }
      refetch();
      sideLayoutDispatch.hideSideLayout();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'label.unexpectedError' });
    }
  }

  async function handleUpdateTeamMember({
    firstName,
    lastName,
    email,
    phoneNumber: unmaskedPhoneNumber,
    phoneNumberCountry,
    dateOfBirth,
    allowedLedgers,
    showBalance,
  }: TTeamMemberDetailFormData) {
    try {
      setUpdateLoading(true);

      const allowedLedgerIds = allowedLedgers?.map(({ ledgerId }) => ledgerId);

      const data = await requestUpdateTeamMember({
        memberType: teamMember.memberType,
        firstName,
        lastName,
        email,
        dateOfBirth: convertToDateRequest(dateOfBirth),
        phoneNumber: {
          callingCode: phoneNumberCountry.callingCode,
          countryCode: phoneNumberCountry.callingCode,
          nationalNumber: masks.phoneNumber.unmask(unmaskedPhoneNumber),
        },
        allowedLedgerIds,
        showBalance,
      });

      if (!response.ok) {
        return showErrorAlert({
          titleId: data?.errorMessage,
        });
      }
      showSuccessAlert({
        titleId: 'messages.updateTeamMember.success.title',
      });
      return teamMembersDispatch.getTeamMembers();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'label.unexpectedError' });
    } finally {
      setUpdateLoading(false);
    }
  }

  return (
    <>
      <SideLayoutPage headerId={getDetailHeaderTitleIdByMemberType(teamMember.memberType)}>
        {teamMember && !loading && (
          <TeamMemberDetailForm
            teamMember={teamMember}
            onSubmit={handleUpdateTeamMember}
            onDelete={handleOpenDeleteModal}
            loading={updateLoading}
          />
        )}
      </SideLayoutPage>
      <PopconfirmModal ref={teamMemberDeleteModalRef} />
    </>
  );
}

export default TeamMemberDetail;
