import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';
import { TAuthOutletContextState } from './Auth.types';

function HandleRenderAuthLayout() {
  const [layoutBanner, setLayoutBanner] = useState<TAuthOutletContextState>();

  return (
    <AuthLayout layoutBanner={layoutBanner}>
      <Outlet context={{ layoutBanner, setLayoutBanner }} />
    </AuthLayout>
  );
}

export default HandleRenderAuthLayout;
