import { TCountry } from 'types/common';

import {
  TIdentityDocumentParams,
  TBusinessOwnerCreateState,
} from './BusinessOwnerCreateProvider.types';

export function convertToAddDirectorFormDataRequest(state: TBusinessOwnerCreateState) {
  const { personalInformation, addressInformation } = state;
  const formData = new FormData();
  formData.append('firstName', personalInformation?.firstName as string);
  formData.append('lastName', personalInformation?.lastName as string);
  formData.append('dateOfBirth', personalInformation?.dateOfBirth as string);
  formData.append('email', personalInformation?.email as string);
  formData.append(
    'phoneNumber.nationalNumber',
    personalInformation?.phoneNumber?.nationalNumber as string
  );
  formData.append(
    'phoneNumber.countryCode',
    personalInformation?.phoneNumber?.country?.callingCode as TCountry['callingCode']
  );
  formData.append(
    'phoneNumber.callingCode',
    personalInformation?.phoneNumber?.country?.callingCode as TCountry['callingCode']
  );

  formData.append('address.countryCode', addressInformation?.country.code as TCountry['code']);
  formData.append('address.city', addressInformation?.city as string);
  formData.append('address.street', addressInformation?.addressLine as string);
  formData.append('address.postCode', addressInformation?.postalCode as string);

  formData.append('proofOfAddressDocument', state.addressDocument as File);

  const identityDocuments = [state.identityDocumentFront, state.identityDocumentBack].filter(
    (item) => item
  ) as TIdentityDocumentParams[];

  identityDocuments.forEach(({ file, type }, index) => {
    formData.append(`proofOfIdentityDocuments[${index}].file`, file);
    formData.append(`proofOfIdentityDocuments[${index}].type`, String(type));
  });

  return formData;
}

export function convertToAddDirectorFromMemberRequest(state: TBusinessOwnerCreateState) {
  const { addressInformation } = state;
  const formData = new FormData();
  formData.append('address.countryCode', addressInformation?.country.code as TCountry['code']);
  formData.append('address.city', addressInformation?.city as string);
  formData.append('address.street', addressInformation?.addressLine as string);
  formData.append('address.postCode', addressInformation?.postalCode as string);

  formData.append('proofOfAddressDocument', state.addressDocument as File);

  return formData;
}

export function convertToAddShareholderFormDataRequest(state: TBusinessOwnerCreateState) {
  const { personalInformation, addressInformation } = state;
  const formData = new FormData();
  formData.append('firstName', personalInformation?.firstName as string);
  formData.append('lastName', personalInformation?.lastName as string);
  formData.append('dateOfBirth', personalInformation?.dateOfBirth as string);
  formData.append('sharePercentage', personalInformation?.sharePercentage as string);

  formData.append('address.countryCode', addressInformation?.country.code as TCountry['code']);
  formData.append('address.city', addressInformation?.city as string);
  formData.append('address.street', addressInformation?.addressLine as string);
  formData.append('address.postCode', addressInformation?.postalCode as string);

  formData.append('proofOfAddressDocument', state.addressDocument as File);

  const identityDocuments = [state.identityDocumentFront, state.identityDocumentBack].filter(
    (item) => item
  ) as TIdentityDocumentParams[];

  identityDocuments.forEach(({ file, type }, index) => {
    formData.append(`proofOfIdentityDocuments[${index}].file`, file);
    formData.append(`proofOfIdentityDocuments[${index}].type`, String(type));
  });

  return formData;
}
