import { TI18nId } from 'types/common';
import { TThemePrimitives } from 'theme/theme.types';

enum MerchantFeeStatus {
  PENDING = 0,
  FAILED = -1,
  PAID = 1,
  COLLECTING = 2,
}

const MAP: Record<MerchantFeeStatus, { labelId: TI18nId; color: keyof TThemePrimitives }> = {
  [MerchantFeeStatus.PENDING]: {
    labelId: 'label.outstanding',
    color: 'warning',
  },
  [MerchantFeeStatus.FAILED]: {
    labelId: 'label.outstanding',
    color: 'warning',
  },
  [MerchantFeeStatus.PAID]: {
    labelId: 'label.collected',
    color: 'primary',
  },
  [MerchantFeeStatus.COLLECTING]: {
    labelId: 'label.collecting',
    color: 'primary',
  },
};

export function getLabelIdByMerchantFeeStatus(status: MerchantFeeStatus): TI18nId {
  return MAP[status]?.labelId ?? 'label.outstanding';
}

export function getColorByMerchantFeeStatus(status: MerchantFeeStatus): keyof TThemePrimitives {
  return MAP[status]?.color ?? 'primary';
}

export default MerchantFeeStatus;
