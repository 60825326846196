import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import ScheduledTransactionType, {
  getLabelIdByScheduledTransactionType,
} from 'enums/ScheduledTransactionType';
import { formatAmount } from 'helpers/formatAmount';
import date from 'helpers/date';
import TransactionDirectionType from 'enums/TransactionDirectionType';

import { TScheduledTransactionItemProps } from './ScheduledTransactionItem.types';
import ScheduledTransactionItemAvatar from './views/ScheduledTransactionItemAvatar';

function ScheduledTransactionItem({ item }: TScheduledTransactionItemProps) {
  return (
    <Box alignment="row.verticalCenter" p="m">
      <Box alignment="row.verticalCenter" flex={1}>
        <ScheduledTransactionItemAvatar
          title={item.titleDetail.title}
          isBulkTransfer={item.isBulkTransfer}
        />
        <Box flex={1} px="m">
          <Text variant="mediumTextSemiBold" mb="xs">
            {item.titleDetail.prefix} {item.titleDetail.title}
          </Text>
          <Box alignment="row.verticalCenter">
            <Text
              id="label.scheduledAt"
              variant="smallTextRegular"
              color="textPrimary"
              values={{
                date: date.format({ date: item.scheduledAt, formatType: 'd MMM' }),
              }}
            />
            {item.recurringPeriod !== ScheduledTransactionType.ONLY_ONCE && (
              <>
                <Box
                  width={3}
                  height={3}
                  borderRadius={3}
                  backgroundColor="textPrimary"
                  marginX="xs"
                />
                <Text
                  id={getLabelIdByScheduledTransactionType(item.recurringPeriod)}
                  variant="smallTextSemiBold"
                  color="infoItemGrayTitle"
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box alignment="row.verticalCenter" alignSelf="flex-start">
        <Text variant="mediumTextBold" color="amountLabelSecondaryPassiveText" mb="xs">
          {formatAmount({
            amount: item.amount,
            currency: item.currency,
            direction: TransactionDirectionType.OUTBOUND,
          })}
        </Text>
      </Box>
    </Box>
  );
}

export default ScheduledTransactionItem;
