import { TThemePrimitives } from 'theme/v2/theme.types';
import stringToHash from 'helpers/stringToHash';

import {
  TBeneficiaryItemStateParams,
  TBeneficiaryItemStateStyleProperties,
} from './BeneficiaryItem.types';
import { AVATAR_COLORS, BENEFICIARY_ITEM_STATE_MAP } from './BeneficiaryItem.constants';

export function getBeneficiaryItemStateStyle({
  pressed,
  hovered,
}: TBeneficiaryItemStateParams): TBeneficiaryItemStateStyleProperties {
  if (pressed) {
    return BENEFICIARY_ITEM_STATE_MAP.onTap;
  }
  if (hovered) {
    return BENEFICIARY_ITEM_STATE_MAP.hover;
  }
  return BENEFICIARY_ITEM_STATE_MAP.default;
}

export function getAvatarColorKeyByHash(str: string): keyof TThemePrimitives {
  const hash = stringToHash(str);
  return AVATAR_COLORS[Math.abs(hash) % AVATAR_COLORS.length];
}
