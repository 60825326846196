import React from 'react';
import { SpaceProps } from 'styled-system';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { TStickyScrollHeaderProps } from '../StickyScrollHeader.types';
import { NavButton } from '../StickyScrollHeader.styles';

function StickyScrollHeaderShimmer({
  navButton,
  marginBottom = 'm',
  ...rest
}: Pick<TStickyScrollHeaderProps, 'navButton'> & SpaceProps) {
  return (
    <>
      <Box paddingX="m" paddingY="xs" {...rest}>
        <Box height="2.75rem" alignment="row.verticalCenter">
          {navButton && (
            <NavButton
              size="small"
              variant="icon"
              shape="square"
              icon={navButton.icon}
              onClick={navButton.onClick}
            />
          )}
        </Box>
      </Box>
      <Box paddingX="m" marginBottom={marginBottom} {...rest}>
        <Shimmer shape="line" color="secondary" height="2.5rem" width="50%" />
      </Box>
    </>
  );
}

export default StickyScrollHeaderShimmer;
