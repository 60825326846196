import React from 'react';

import { TIconProps } from '../Icon.types';

const GbpIcon = ({ iconColor, ...rest }: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <g clipPath="url(#a)">
      <path
        fill="#FEFEFE"
        d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
      />
      <path
        fill="#1A2A6C"
        d="M4.961 9.386a23.912 23.912 0 0 0-4.134 8.351h12.485l-8.35-8.35ZM47.173 17.737a23.914 23.914 0 0 0-4.134-8.35l-8.351 8.35h12.485ZM.827 30.26a23.915 23.915 0 0 0 4.134 8.35l8.351-8.35H.827ZM38.612 4.96A23.913 23.913 0 0 0 30.26.825v12.486l8.35-8.351ZM9.388 43.037a23.914 23.914 0 0 0 8.351 4.135V34.687l-8.35 8.35ZM17.74.825A23.914 23.914 0 0 0 9.387 4.96l8.351 8.35V.825ZM30.261 47.172a23.916 23.916 0 0 0 8.35-4.135l-8.35-8.35v12.485ZM34.688 30.26l8.35 8.35a23.914 23.914 0 0 0 4.135-8.35H34.688Z"
      />
      <path
        fill="#C8102E"
        d="M47.797 20.87H27.13V.203a24.238 24.238 0 0 0-6.261 0V20.87H.203a24.238 24.238 0 0 0 0 6.261H20.87v20.667a24.237 24.237 0 0 0 6.261 0V27.13h20.667a24.237 24.237 0 0 0 0-6.261Z"
      />
      <path
        fill="#C8102E"
        d="m30.261 30.26 10.71 10.71c.492-.492.962-1.007 1.41-1.54l-9.169-9.17h-2.951ZM17.739 30.26 7.029 40.97c.493.492 1.007.962 1.541 1.41l9.169-9.169v-2.95ZM17.739 17.739 7.029 7.028a24.057 24.057 0 0 0-1.41 1.54l9.169 9.17h2.951ZM30.261 17.74l10.71-10.71a24.045 24.045 0 0 0-1.541-1.41l-9.169 9.168v2.952Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GbpIcon;
