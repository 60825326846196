import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const LockUnlockedIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M13 11V5a4 4 0 0 1 8 0v3.273m-15 8.65L8 19l4-4m-6 8h6a4 4 0 0 0 4-4v-6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v6a4 4 0 0 0 4 4Z"
        stroke={colors[iconColor]}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockUnlockedIcon;
