import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const AirlinesIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.045 20.5701C17.1454 20.7375 17.3764 20.7659 17.5144 20.6279L19.1636 18.9787C19.2432 18.8991 19.2716 18.7816 19.237 18.6745L16.5356 10.293L19.9651 7.07574C20.86 6.18085 21.6648 4.10853 20.7782 3.22195C19.8916 2.33537 17.9255 3.24629 17.0306 4.14119L13.7072 7.46459L5.32568 4.76312C5.21855 4.72859 5.1011 4.75693 5.02151 4.83652L3.37225 6.48579C3.23424 6.62379 3.26267 6.85475 3.43003 6.95517L9.62249 10.6706C9.90142 10.838 9.94881 11.2229 9.7188 11.4529L6.63609 14.5357L4.43189 13.555C4.31844 13.5045 4.18562 13.5292 4.09781 13.617L2.53095 15.1838C2.30127 15.4135 2.34814 15.7978 2.62629 15.9655L6 18.0001L8.03462 21.3739C8.20237 21.652 8.58666 21.6989 8.81634 21.4692L10.3832 19.9024C10.471 19.8146 10.4956 19.6817 10.4452 19.5683L9.46451 17.3641L12.5472 14.2814C12.7772 14.0514 13.1622 14.0987 13.3295 14.3777L17.045 20.5701Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AirlinesIcon;
