import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const SendMoneyIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M1 12H13M13 12L8 7M13 12L8 17M23 12C23 13.6569 21.6569 15 20 15C18.3431 15 17 13.6569 17 12C17 10.3431 18.3431 9 20 9C21.6569 9 23 10.3431 23 12Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendMoneyIcon;
