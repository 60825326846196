import NOOP from 'constants/noop';
import { TMerchantDispatch, TMerchantState } from './MerchantProvider.types';

export const INITIAL_STATE: TMerchantState = {
  merchant: undefined,
};

export const INITIAL_DISPATCH: TMerchantDispatch = {
  getMerchant: NOOP as TMerchantDispatch['getMerchant'],
  setMerchant: NOOP as TMerchantDispatch['setMerchant'],
  clearState: NOOP,
};
