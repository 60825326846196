import { STORAGE_KEYS, STORAGE_PREFIX } from './storage.constants';
import { TStorage, TStorageKeys } from './storage.types';

function readStorage(key: TStorageKeys): string | null {
  return localStorage.getItem(`${STORAGE_PREFIX}${key}`);
}

function writeStorage(key: TStorageKeys, data: string): void {
  localStorage.setItem(`${STORAGE_PREFIX}${key}`, data);
}

function writeStorageFromKeys(keyValuePairs: Partial<Record<TStorageKeys, string>>): void {
  Object.entries(keyValuePairs).forEach(([key, value]) => [
    writeStorage(key as TStorageKeys, value as string),
  ]);
}

function removeStorage(key: TStorageKeys): void {
  localStorage.removeItem(`${STORAGE_PREFIX}${key}`);
}

function removeStorageFromKeys(keys: TStorageKeys[]): void {
  keys.forEach(removeStorage);
}

function removePersonalData(): void {
  removeStorageFromKeys([STORAGE_KEYS.ACCESS_TOKEN, STORAGE_KEYS.REFRESH_TOKEN]);
}

const storage: TStorage = {
  readStorage,
  writeStorage,
  writeStorageFromKeys,
  removeStorage,
  removeStorageFromKeys,
  removePersonalData,
};

export default storage;
