export const MASK_SEPERATOR = {
  SLASH: '/',
};

export const MASKS = {
  birthdate: '99 / 99 / 9999',
  cardNumber: '9999 9999 9999 9999',
  expiryDate: '99/99',
  sortCode: '99-99-99',
};
