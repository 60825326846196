import { Res } from 'use-http';

import { TFile } from './file.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function downloadPdf(response: Res<any>) {
  const resBlob = await response.blob();
  const fileName = getFileNameFromResponse(response) ?? 'file.pdf';
  downloadFromBlob(resBlob, fileName);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function downloadCsv(response: Res<any>) {
  const resBlob = await response.blob();
  const fileName = getFileNameFromResponse(response) ?? 'file.csv';
  downloadFromBlob(resBlob, fileName);
}

function downloadFromBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFileNameFromResponse(response: Res<any>) {
  return response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '');
}

const file: TFile = {
  downloadPdf,
  downloadCsv,
};

export default file;
