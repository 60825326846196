import React from 'react';

import { TWordmarkIconProps } from 'types/icon';

function WamoWordmarkIcon({ width = 64, height = 12, iconColor }: TWordmarkIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 12" fill="none">
      <path
        d="M47.2826 5.21697C47.2826 2.14242 45.7915 0.502723 43.2742 0.500003L43.2794 0.5H43.2679L43.2742 0.500003C42.4472 0.501075 40.9721 0.75694 39.99 2.48434C39.4101 1.20197 38.3179 0.618684 36.6674 0.530395C35.6875 0.530395 34.2586 1.00368 33.6787 2.28316L33.6816 2.10368C33.6816 1.34671 33.0715 0.730132 32.3189 0.730132C31.5663 0.730132 30.9835 1.31921 30.9562 2.05158V10.0512C30.9562 10.8082 31.5663 11.4247 32.3189 11.4247C33.0715 11.4247 33.6816 10.8111 33.6816 10.0512L33.6787 5.62368C33.6787 3.82605 34.7493 3.17474 35.863 3.17474C36.9768 3.17474 37.7596 3.98382 37.7812 5.44276L37.7754 10.0468C37.7754 10.8038 38.3855 11.4204 39.1381 11.4204C39.8907 11.4204 40.5008 10.8067 40.5008 10.0468V5.62368C40.5008 4.03013 41.393 3.17474 42.619 3.17474C43.7356 3.17474 44.5817 4.00553 44.5817 5.51079L44.5759 10.057C44.5759 10.8111 45.1832 11.4189 45.9286 11.4189C46.6739 11.4189 47.2812 10.8082 47.2812 10.057V5.21842L47.2826 5.21697Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7759 1.92711C25.8407 1.23237 26.4206 0.689608 27.1286 0.689608C27.8797 0.689608 28.4884 1.30184 28.4884 2.05737V10.0497C28.4884 10.8096 27.8783 11.4233 27.1257 11.4233C26.3731 11.4233 25.763 10.8067 25.763 10.0497L25.7659 9.87027C25.0953 10.9486 23.5571 11.4218 22.5311 11.4218C19.7856 11.4218 17.2213 9.33329 17.2213 5.96237C17.2213 2.59145 19.7884 0.502898 22.5311 0.502898C23.4664 0.502898 25.0104 0.863292 25.7702 2.02842L25.7759 1.92711ZM20.0316 5.96816C20.0316 7.6529 21.4116 8.79777 22.9081 8.79777C24.3787 8.79777 25.763 7.7875 25.763 5.96816C25.763 4.14882 24.4003 3.13856 22.9081 3.13856C21.4159 3.13856 20.0316 4.28342 20.0316 5.96816Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.1619 5.94355C49.1619 2.63921 51.6139 0.505786 54.581 0.505786C57.5237 0.505786 60.0001 2.64065 60.0001 5.94355C60.0001 9.24644 57.5453 11.4262 54.581 11.4262C51.6168 11.4262 49.1619 9.24789 49.1619 5.94355ZM51.8585 5.9421C51.8585 7.65 53.0888 8.75144 54.581 8.75144C56.0775 8.75144 57.3035 7.60513 57.3035 5.9421C57.3035 4.27908 56.0775 3.20079 54.581 3.20079C53.0845 3.20079 51.8585 4.23421 51.8585 5.9421Z"
        fill={iconColor}
      />
      <path
        d="M6.36152 10.5505L8.47679 4.10684L10.6525 10.5505C10.8252 11.1005 11.3389 11.5 11.9418 11.5C12.5447 11.5 13.0613 11.0976 13.234 10.5447L16.1752 2.40908C16.404 1.70132 16.0198 0.941447 15.3162 0.711316C14.6125 0.481184 13.8571 0.867632 13.6283 1.57539L11.7778 7.13618L10.0596 1.90539C9.88409 1.17882 9.23512 0.644737 8.46096 0.644737C7.73285 0.644737 7.11409 1.12092 6.89537 1.78382L5.15423 7.13473L3.36561 1.69842C3.13825 0.992105 2.38424 0.607105 1.68203 0.835789C0.979814 1.06447 0.595612 1.82289 0.824406 2.52921L3.80593 10.5563C3.9786 11.1034 4.48224 11.4971 5.08372 11.4971C5.68521 11.4971 6.19172 11.0976 6.36152 10.5505Z"
        fill={iconColor}
      />
    </svg>
  );
}

export default WamoWordmarkIcon;
