import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ProfileCircleIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 20v-.486c0-1.39-1.47-2.586-3.576-3.114M6 20v-.486c0-1.39 1.47-2.586 3.576-3.114M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-10 5.667c2.532 0 4.5-4.02 4.5-8.135C16.5 7.612 14.519 6 12 6S7.5 7.612 7.5 9.532c0 4.115 1.968 8.135 4.5 8.135Z"
      />
    </svg>
  );
};

export default ProfileCircleIcon;
