import { FC } from 'react';

import { ArrowsRightIcon } from 'components/Icons';

import { getBadgeIconByTransactionType, getIconByTransactionType } from 'enums/TransactionType';
import TransactionStatus, { getAvatarIconByTransactionStatus } from 'enums/TransactionStatus';
import { getIconByMcc } from 'enums/CardMerchantCategoryCode';
import { TAvatarProps } from 'components/v2/core/Avatar/Avatar.types';
import getAvatarLetters from 'helpers/avatarLetters';
import { getAvatarColorKeyByHash } from 'components/v2/BeneficiaryItem/BeneficiaryItem.helpers';
import { TIconProps } from 'components/v2/Icons/Icon.types';

import { TGetTransactionAvatarParams } from './transaction.types';

export function getTransactionAvatarProps({
  title,
  transactionStatus,
  transactionType,
  cardMerchantCategoryCode,
  merchantLogoLink,
  isBulkTransfer,
}: TGetTransactionAvatarParams): TAvatarProps {
  const AvatarIcon = getAvatarIcon();
  const BadgeIcon = getBadgeIcon();
  const avatarColor = getAvatarColorKeyByHash(title);
  const isRevert = [TransactionStatus.REVERSED, TransactionStatus.DECLINED].includes(
    transactionStatus
  );

  function getBadgeIcon() {
    if (isBulkTransfer) {
      return ArrowsRightIcon as FC<TIconProps>;
    }
    return getBadgeIconByTransactionType(transactionType);
  }

  function getAvatarIcon() {
    const iconByTransactionStatus = getAvatarIconByTransactionStatus(transactionStatus);

    if (cardMerchantCategoryCode) {
      return getIconByMcc(cardMerchantCategoryCode);
    }

    if (iconByTransactionStatus) {
      return iconByTransactionStatus;
    }

    return getIconByTransactionType(transactionType);
  }

  return {
    label: getAvatarLetters(title),
    icon: AvatarIcon,
    themeIconColor: 'textSecondary',
    imageUrl: merchantLogoLink,
    backgroundColor: AvatarIcon ? 'backgroundSecondary' : avatarColor,
    ...(BadgeIcon &&
      (!isRevert || isBulkTransfer) && {
        badgeProps: {
          icon: BadgeIcon,
          iconColor: 'textSecondary',
        },
      }),
  };
}
