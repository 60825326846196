import React, { ReactElement } from 'react';

import Text from 'components/core/Text';

function AtmIcon(): ReactElement {
  return (
    <Text variant="xSmallTextSemiBold" color="primary">
      ATM
    </Text>
  );
}

export default AtmIcon;
