import React, { useEffect, useRef, useState } from 'react';

import ListItem from 'components/core/ListItem';
import { InfoQuestionMarkIcon } from 'components/Icons';
import SwitchButton from 'components/core/SwitchButton';
import Box from 'components/core/Box';

import PopconfirmModal from 'modals/PopconfirmModal';

import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';

import { TRecipientAuthenticationSwitchProps } from '../RecipientDetails.types';

function RecipientAuthenticationSwitch({
  recipient,
  onChange,
  loading,
}: TRecipientAuthenticationSwitchProps) {
  const recipientAuthenticationToggleRef = useRef<TPopconfirmModalRef>(null);
  const [value, setValue] = useState<boolean>(recipient.alwaysAuthenticate);

  useEffect(() => {
    setValue(recipient.alwaysAuthenticate);
  }, [recipient.alwaysAuthenticate]);

  function handleToggleRecipientAuthentication() {
    // FIXME: (semih) we can't use e.target.checked since it can be clickable on both switch and list item
    const newValue = !value;
    if (newValue) {
      onChange(true);
      return;
    }
    recipientAuthenticationToggleRef.current?.show({
      titleId: 'screens.recipientDetails.authenticationPopConfirm.title',
      descriptionId: 'screens.recipientDetails.authenticationPopConfirm.description',
      descriptionValues: { recipientName: recipient.name },
      contentIcon: <InfoQuestionMarkIcon width={64} height={64} iconColor="warning" />,
      confirmButtonProps: { id: 'button.disable' },
      cancelButtonTitleId: 'button.notNow',
      onConfirm: () => onChange(false),
    });
  }

  if (!recipient.isUsedForPayment) {
    return <></>;
  }

  return (
    <>
      <ListItem
        titleId="screens.recipientDetails.authenticationPreference.title"
        description="screens.recipientDetails.authenticationPreference.description"
        rightComponent={
          <Box paddingLeft="m">
            <SwitchButton name="authenticationPreference" checked={value} />
          </Box>
        }
        onClick={handleToggleRecipientAuthentication}
        disabled={loading}
      />
      <PopconfirmModal ref={recipientAuthenticationToggleRef} />
    </>
  );
}

export default RecipientAuthenticationSwitch;
