import React, { ReactElement, memo } from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { TListItemShimmerProps } from '../ListItem.types';

function ListItemShimmer({ count = 1, rightIconShimmer }: TListItemShimmerProps): ReactElement {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Box key={i.toString()} alignment="row.verticalCenter" padding="m">
          {!rightIconShimmer && <Shimmer shape="square" marginRight="m" />}
          <Box alignment="col.top.left" flex="auto" height={40}>
            <Shimmer shape="line" width="50%" height={10} marginTop="xs" />
            <Shimmer shape="line" width="40%" marginTop="s" />
          </Box>
          {rightIconShimmer && <Shimmer shape="line" marginRight="m" width="10%" />}
        </Box>
      ))}
    </>
  );
}

export default memo(ListItemShimmer);
