import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const EarthIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeWidth="2"
        d="M3.336 7.004L9.16 15.74a5 5 0 01.84 2.774V21.8M3.336 7.004A9.954 9.954 0 002 12c0 4.838 3.436 8.873 8 9.8M3.336 7.004A9.996 9.996 0 0112 2c1.422 0 2.775.297 4 .832m0 0V6a1.5 1.5 0 01-1.5 1.5h-.25c-.69 0-1.25.56-1.25 1.25v.05a1.2 1.2 0 01-1.2 1.2 1.8 1.8 0 00-1.8 1.8v.6a1.6 1.6 0 001.6 1.6h2.9a3 3 0 013 3v3.353M16 2.833c3.532 1.542 6 5.066 6 9.167 0 5.523-4.477 10-10 10-.685 0-1.354-.069-2-.2"
      />
    </svg>
  );
};

export default EarthIcon;
