import styled from 'styled-components';

import { getColor } from 'helpers/theme';

export const StyledLabel = styled.label<{
  hasError?: boolean;
  disabled?: boolean;
  alignment?: 'center' | 'flex-start';
}>`
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  cursor: pointer;
  align-items: ${({ alignment }) => alignment};

  input[type='radio'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.5rem;
    height: 1.5rem;
    border: 1.5px solid ${({ hasError }) => getColor(hasError ? 'radioErrorBorder' : 'radioBorder')};
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-top: ${({ alignment }) => (alignment === 'flex-start' ? '0.25rem' : 0)};

    &:hover {
      border-color: ${getColor('radioHover')};
    }
  }

  input[type='radio']::before {
    content: '';
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em
      ${({ hasError }) => getColor(hasError ? 'radioErrorBorder' : 'radioBorder')};
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;
