import React from 'react';

import Box from 'components/core/Box';
import NavItem from 'components/v2/NavItem';
import MemberPermission from 'components/MemberPermission';
import {
  CreditCard01Icon,
  Home01Icon,
  ReceiptIcon,
  User01Icon,
  Link01Icon,
  Users01Icon,
} from 'components/v2/Icons';

import MemberType from 'enums/MemberType';
import routes from 'router/routes';
import { useMerchantFeatureFlagContext } from 'context/MerchantFeatureFlagProvider';

function MobileTabBar() {
  const { merchantFeatureFlagState } = useMerchantFeatureFlagContext();

  return (
    <Box alignment="row.verticalCenter" width="100%" px="xl">
      <Box alignment="center" flex={1}>
        <NavItem to={routes.home.path} icon={Home01Icon} />
      </Box>
      <Box alignment="center" flex={1}>
        <NavItem to={routes.cardDashboard.path} icon={CreditCard01Icon} />
      </Box>
      {merchantFeatureFlagState.accountsReceivableInvoiceEnabled && (
        <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
          <Box alignment="center" flex={1}>
            <NavItem to={routes.invoicesDashboard.path} icon={ReceiptIcon} />
          </Box>
        </MemberPermission>
      )}
      <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
        <Box alignment="center" flex={1}>
          <NavItem to={routes.teamMemberDashboard.path} icon={Users01Icon} />
        </Box>
      </MemberPermission>
      <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
        <Box alignment="center" flex={1}>
          <NavItem to={routes.onlinePaymentsDashboard.path} icon={Link01Icon} />
        </Box>
      </MemberPermission>
      <Box alignment="center" flex={1}>
        <NavItem to={routes.account.path} icon={User01Icon} />
      </Box>
    </Box>
  );
}

export default MobileTabBar;
