import { TI18nId } from 'types/common';
import { TThemePrimitives } from 'theme/theme.types';

enum PaymentStatus {
  FAILURE = -1,
  SUCCESS = 1,
}

const LABEL_BY_STATUS_MAP: Record<
  PaymentStatus,
  {
    id: TI18nId;
    color: keyof TThemePrimitives;
  }
> = {
  [PaymentStatus.SUCCESS]: {
    id: 'label.success',
    color: 'paymentItemStatusSuccess',
  },
  [PaymentStatus.FAILURE]: {
    id: 'label.failure',
    color: 'paymentItemStatusFailure',
  },
};

export function getLabelIdByPaymentStatus(paymentStatus: PaymentStatus): TI18nId {
  return LABEL_BY_STATUS_MAP[paymentStatus].id;
}

export function getColorByPaymentStatus(paymentStatus: PaymentStatus) {
  return LABEL_BY_STATUS_MAP[paymentStatus]?.color;
}

export default PaymentStatus;
