import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Dot from 'components/v2/core/Dot';

import ibanFormatter from 'helpers/ibanFormatter';

import { TBeneficiaryAccountInformationProps } from '../BeneficiaryItem.types';

function BeneficiaryAccountInformation({
  iban,
  accountNumber,
  sortCode,
  accountInformationColor = 'textSecondary',
}: TBeneficiaryAccountInformationProps) {
  if (iban) {
    return (
      <Text variant="textBodySmall" color={accountInformationColor}>
        {ibanFormatter(iban)}
      </Text>
    );
  }
  if (sortCode) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Text variant="textBodySmall" color={accountInformationColor}>
          {accountNumber}
        </Text>
        <Dot size={4} color="textPassive" mx="xs" />
        <Text variant="textBodySmall" color={accountInformationColor}>
          {sortCode}
        </Text>
      </Box>
    );
  }
  return (
    <Text variant="textBodySmall" color={accountInformationColor}>
      {accountNumber}
    </Text>
  );
}

export default BeneficiaryAccountInformation;
