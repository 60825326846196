import React from 'react';

import Form from 'components/Form';
import Card from 'components/core/Card';
import Text from 'components/core/Text';

import { TCardSecurityQuestion } from 'types/card';
import useForm from 'hooks/useForm';

import {
  TCardSecurityQuestionFormData,
  TCardSecurityQuestionFormProps,
} from './CardSecurityQuestionForm.types';
import { FORM_VALIDATION_SCHEMA } from './CardSecurityQuestionForm.constants';
import SecurityQuestionRadioGroup from './views/SecurityQuestionRadioGroup';

function CardSecurityQuestionForm({
  formButtonProps,
  pageHeaderProps,
  securityQuestions,
  onSubmit,
}: TCardSecurityQuestionFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TCardSecurityQuestionFormData>({
    schema: FORM_VALIDATION_SCHEMA,
  });

  const selectedQuestion: TCardSecurityQuestion = watch('securityQuestion');

  return (
    <>
      <Text id={pageHeaderProps.titleId} variant="largeTitleBold" mb="s" />
      <Text id={pageHeaderProps.descriptionId} variant="largeTextRegular" mb="l" />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        buttonProps={{
          disabled: !selectedQuestion?.value,
          ...formButtonProps,
        }}
        isSticky>
        <Card p="m">
          <SecurityQuestionRadioGroup
            selectedSecurityQuestion={selectedQuestion}
            securityQuestions={securityQuestions as TCardSecurityQuestion[]}
            control={control}
            errors={errors}
          />
        </Card>
      </Form>
    </>
  );
}

export default CardSecurityQuestionForm;
