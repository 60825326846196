import styled from 'styled-components';

import Box from 'components/core/Box';

import { getColor } from 'helpers/theme';
import media from 'helpers/media';

export const StyledBox = styled(Box)`
  width: 40px;

  span {
    display: none;
  }

  svg > path {
    stroke: ${getColor('textSecondary')};
  }

  &.selected {
    background-color: ${getColor('buttonGhostPrimary')};

    span {
      color: ${getColor('textActive')};
    }

    svg > path {
      stroke: ${getColor('textActive')};
    }
  }

  &:hover:not(.selected) {
    background-color: ${getColor('backgroundTertiaryHover')};

    span {
      color: ${getColor('buttonPrimaryHover')};
    }

    svg > path {
      stroke: ${getColor('buttonPrimaryHover')};
    }
  }

  ${media.l} {
    width: 100%;

    span {
      display: block;
    }
  }
`;
