import React, { memo } from 'react';

import Box from 'components/core/Box';

import { TI18nId } from 'types/common';

import RadioItem from './RadioItem';

import { RadioGroupContextProvider } from '../RadioContext';
import { TRadioGroupProps } from '../Radio.types';

function RadioGroup({
  id = '',
  options = [],
  value,
  disabled,
  onChange,
  children,
  radioItemBoxProps,
}: TRadioGroupProps) {
  function handleRenderRadioGroup() {
    if (options?.length > 0) {
      return options.map(({ value: optionValue, label, ...restRadioItemProps }, i) => (
        <Box key={i.toString()} {...radioItemBoxProps}>
          <RadioItem
            id={`radio-${id}-${i}`}
            titleId={label as TI18nId}
            value={optionValue}
            {...restRadioItemProps}
          />
        </Box>
      ));
    }
    return children;
  }

  return (
    <RadioGroupContextProvider
      value={{
        value,
        onChange,
        disabled,
      }}>
      <Box display="flex" flexDirection="column" gap="l">
        {handleRenderRadioGroup()}
      </Box>
    </RadioGroupContextProvider>
  );
}

export default memo(RadioGroup);
