import React from 'react';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

import { TChildrenOnly } from 'types/common';

import { FINGERPRINT_LOAD_OPTIONS } from './FingerprintProvider.constants';

function FingerprintProvider({ children }: TChildrenOnly) {
  return <FpjsProvider loadOptions={FINGERPRINT_LOAD_OPTIONS}>{children}</FpjsProvider>;
}

export default FingerprintProvider;
