import i18next from 'i18next';
import { formatRelative, isValid } from 'date-fns';
import dateFormat from 'date-fns/format';
import dateFormatWithTimeZone from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import isThisYear from 'date-fns/isThisYear';
import isTomorrow from 'date-fns/isTomorrow';

import { TDateFormatParams, TFormatPrettierDateParams } from './date.types';

function format({ date, formatType = 'dd/MM/yyyy' }: TDateFormatParams): string {
  return dateFormat(new Date(date), formatType);
}

function formatRelativeAsToday(date: string): string {
  return formatRelative(new Date(date), new Date());
}

function formatWithTimeZone({ date, formatType = 'dd-MM-yyyy HH:mm' }: TDateFormatParams) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return dateFormatWithTimeZone(utcToZonedTime(new Date(date), timeZone), formatType, { timeZone });
}

function parse(dateString?: string | null): Date | undefined {
  const dateParams = dateString?.split('-') ?? '';

  if (dateParams?.length === 3) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [day, month, year] = dateParams;
    const dateValue = new Date(Number(year), Number(month) - 1, day);

    if (isValid(dateValue)) {
      return dateValue;
    }
  }

  return undefined;
}

export function findDateLabel(date: Date) {
  const dateObj = new Date(date);
  const isDateToday = isToday(dateObj);
  const isDateTomorrow = isTomorrow(dateObj);
  const isDateYesterday = isYesterday(dateObj);

  if (isDateTomorrow) {
    return i18next.t('label.tomorrow');
  }
  if (isDateToday) {
    return i18next.t('label.today');
  }
  if (isDateYesterday) {
    return i18next.t('label.yesterday');
  }
  return undefined;
}

function formatPrettierDate({ date, hourFormat }: TFormatPrettierDateParams) {
  const dateObj = new Date(date);
  const isDateThisYear = isThisYear(dateObj);
  const dateLabel = findDateLabel(dateObj);

  if (dateLabel) {
    return `${dateLabel}${
      hourFormat
        ? formatWithTimeZone({
            date: dateObj,
            formatType: `, ${hourFormat}`,
          })
        : ''
    }`;
  }

  return formatWithTimeZone({
    date: dateObj,
    formatType: isDateThisYear ? 'd MMM, HH:mm' : 'd MMM yyyy, HH:mm',
  });
}

function replaceBirthOfDate(date = '') {
  const unmaskedDate = date.replace(/ |[/]/g, '');
  if (Number(unmaskedDate[0]) > 3) {
    return '';
  }
  if (
    (unmaskedDate[0] === '0' && unmaskedDate[1] === '0') ||
    (unmaskedDate[0] === '3' && unmaskedDate[1] > '1')
  ) {
    return unmaskedDate[0];
  }
  if (
    Number(unmaskedDate[2]) > 1 ||
    (unmaskedDate[2] === '1' && Number(unmaskedDate[3]) > 2) ||
    (unmaskedDate[2] === '0' && unmaskedDate[3] === '0')
  ) {
    return date.slice(0, date.length - 1);
  }
  return date;
}

function epochToDate(epochTime: number): Date {
  return new Date(epochTime * 1000);
}

export default {
  format,
  formatWithTimeZone,
  formatRelativeAsToday,
  parse,
  replaceBirthOfDate,
  formatPrettierDate,
  epochToDate,
};
