import React, { ReactElement } from 'react';

import { BaseTextButton, StyledSpinner, StyledText } from './TextButton.styles';
import { TTextButtonProps } from './TextButton.types';
import { SPINNER_SIZE } from './TextButton.constants';

function TextButton({
  id,
  variant = 'primary',
  textVariant = 'mediumTextBold',
  type = 'button',
  loading = false,
  ...rest
}: TTextButtonProps): ReactElement {
  return (
    <BaseTextButton variant={variant} type={type} disabled={loading} {...rest}>
      {loading && <StyledSpinner size={SPINNER_SIZE} currentColor />}
      <StyledText loading={loading} id={id} variant={textVariant} color="inherit" />
    </BaseTextButton>
  );
}

export default TextButton;
