import { useFetch } from 'use-http';
import React, { createContext, useContext, useState } from 'react';
import * as Sentry from '@sentry/react';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TReferralCampaignItem } from 'types/referral';

import { TReferralCampaignContext, TReferralCampaignState } from './ReferralCampaignProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './ReferralCampaignProvider.constants';

const referralCampaignContext = createContext<TReferralCampaignContext>({
  referralCampaignState: INITIAL_STATE,
  referralCampaignDispatch: INITIAL_DISPATCH,
});

function ReferralCampaignProvider({ children }: TChildrenOnly) {
  const [state, setState] = useState<TReferralCampaignState>(INITIAL_STATE);
  const { response, get: requestActiveReferralCampaigns } = useFetch<
    TCommonResponse<TReferralCampaignItem[]>
  >(API_ENDPOINT.CAMPAIGN_REFERRALS);

  async function getActiveReferralCampaigns() {
    try {
      const data = await requestActiveReferralCampaigns();
      if (response.ok && data) {
        setState((prevState) => ({
          ...prevState,
          activeReferralCampaigns: data?.result,
        }));
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TReferralCampaignContext = {
    referralCampaignState: state,
    referralCampaignDispatch: { getActiveReferralCampaigns, clear },
  };
  return (
    <referralCampaignContext.Provider value={value}>{children}</referralCampaignContext.Provider>
  );
}

export const useReferralCampaignContext = (): TReferralCampaignContext =>
  useContext(referralCampaignContext);

export default ReferralCampaignProvider;
