import { useFetch } from 'use-http';
import { useState } from 'react';
import * as Sentry from '@sentry/react';

import replaceUrlParams from 'helpers/replaceUrlParams';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';
import { useCardsContext } from 'context/CardsProvider';

import { TUserCardActivateParams, TUseCardActivateReturn } from './useCardActivate.types';

function useCardActivate({ cardId, onSuccess }: TUserCardActivateParams): TUseCardActivateReturn {
  const [loading, setLoading] = useState(false);
  const { cardsDispatch } = useCardsContext();
  const { post: requestCardActivate } = useFetch(
    replaceUrlParams(API_ENDPOINT.CARD_ACTIVATE, {
      cardId,
    })
  );

  async function handleCardActivate() {
    try {
      setLoading(true);
      const { errorMessage } = await requestCardActivate();

      if (errorMessage) {
        return showErrorAlert({
          titleId: errorMessage,
        });
      }
      await cardsDispatch.getCards();
      return onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'label.unexpectedError' });
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    handleCardActivate,
  };
}

export default useCardActivate;
