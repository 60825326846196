import React from 'react';

import NavBar from 'components/v2/NavBar';
import Box from 'components/core/Box';
import MobileTabBar from 'components/v2/MobileTabBar';

import { StyledNav } from '../../DashboardLayout.styles';

function HomeNavLayout() {
  return (
    <StyledNav>
      <Box display={{ _: 'none', s: 'flex' }} flexDirection="column" gap="l" height="100%">
        <NavBar />
      </Box>
      <Box display={{ _: 'flex', s: 'none' }}>
        <MobileTabBar />
      </Box>
    </StyledNav>
  );
}

export default HomeNavLayout;
