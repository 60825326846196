import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const LocationIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="m12 22-.544.839a1 1 0 0 0 1.088 0L12 22Zm7-11.75c0 2.886-1.778 5.583-3.727 7.647a24.515 24.515 0 0 1-3.507 3.05c-.103.074-.183.129-.236.165-.027.018-.047.03-.06.04l-.013.008-.002.002L12 22c.544.839.545.839.545.838l.003-.001a26.496 26.496 0 0 0 4.18-3.566C18.777 17.097 21 13.92 21 10.25h-2ZM12 22c.544-.839.545-.839.545-.838l-.002-.002-.014-.009-.059-.04a21.827 21.827 0 0 1-1.103-.812 24.515 24.515 0 0 1-2.64-2.402C6.778 15.833 5 13.137 5 10.25H3c0 3.67 2.222 6.848 4.273 9.02a26.494 26.494 0 0 0 3.806 3.31 15.233 15.233 0 0 0 .373.257h.002v.001c.001 0 .002 0 .546-.838ZM5 10.25C5 6.217 8.163 3 12 3V1c-5 0-9 4.17-9 9.25h2ZM12 3c3.837 0 7 3.217 7 7.25h2C21 5.17 17 1 12 1v2Zm3 7a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm-3 3a3 3 0 0 1-3-3H7a5 5 0 0 0 5 5v-2Zm-3-3a3 3 0 0 1 3-3V5a5 5 0 0 0-5 5h2Zm3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default LocationIcon;
