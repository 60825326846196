import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import { TBillingPackage } from 'types/billing';
import API_ENDPOINT from 'constants/apiEndpoint';

import { INITIAL_DISPATCH, INITIAL_STATE } from './BillingPackagesProvider.constants';
import { TBillingPackagesState, TBillingPackagesContext } from './BillingPackagesProvider.types';

const billingPackagesContext = createContext<TBillingPackagesContext>({
  billingPackagesState: INITIAL_STATE,
  billingPackagesDispatch: INITIAL_DISPATCH,
});

function BillingPackagesProvider({ children }: TChildrenOnly): ReactElement {
  const {
    data,
    get: requestGetBillingPackages,
    response,
  } = useFetch<TCommonResponse<TBillingPackage[]>>(API_ENDPOINT.BILLING_PACKAGES);
  const [{ billingPackages, hasError, loading }, setState] =
    useState<TBillingPackagesState>(INITIAL_STATE);

  useEffect(() => {
    if (data?.result && response.ok) {
      setState({ billingPackages: data.result, loading: false, hasError: false });
    }
  }, [data]);

  function clear() {
    setState(INITIAL_STATE);
  }

  async function getBillingPackages() {
    await requestGetBillingPackages();
  }

  const value: TBillingPackagesContext = {
    billingPackagesState: {
      loading,
      billingPackages,
      hasError,
    },
    billingPackagesDispatch: { getBillingPackages, clear },
  };

  return (
    <billingPackagesContext.Provider value={value}>{children}</billingPackagesContext.Provider>
  );
}

export default BillingPackagesProvider;

export const useBillingPackagesContext = (): TBillingPackagesContext =>
  useContext(billingPackagesContext);
