import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import storage, { STORAGE_KEYS } from 'helpers/storage';

import { TReferralState, TUseReferralUrlHandlerReturn } from './useReferralUrlHandler.types';

function useReferralUrlHandler(): TUseReferralUrlHandlerReturn {
  const location = useLocation();

  function setReferralLink() {
    const queryParams = queryString.parse(location.search);

    if (queryParams.mc && queryParams.cc) {
      storage.writeStorage(
        STORAGE_KEYS.REFERRAL,
        JSON.stringify({
          memberCode: queryParams.mc,
          campaignCode: queryParams.cc,
        })
      );
    }
  }

  function getReferralParams(): TReferralState {
    const referralResult = storage.readStorage(STORAGE_KEYS.REFERRAL);

    if (referralResult) {
      const referralJson = JSON.parse(referralResult);
      if (referralJson?.memberCode && referralJson.campaignCode) {
        return JSON.parse(referralResult);
      }
      return undefined;
    }
    return undefined;
  }

  function clearReferralParams() {
    storage.removeStorage(STORAGE_KEYS.REFERRAL);
  }

  return { setReferralLink, getReferralParams, clearReferralParams };
}

export default useReferralUrlHandler;
