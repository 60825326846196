import React from 'react';

import SideLayoutPage from 'components/SideLayoutPage';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';

import { useMerchantContext } from 'context/MerchantProvider';

function MerchantDetail() {
  const { merchantState } = useMerchantContext();

  if (!merchantState.merchant) {
    return <></>;
  }

  const { registrationName, registrationNumber, registrationAddress, tradingAddress } =
    merchantState.merchant;

  return (
    <SideLayoutPage headerId="screens.headerTitle.merchantDetails">
      <Card marginX="m" paddingX="m">
        <InfoItem titleId="label.businessName" value={registrationName} />
        {registrationNumber && (
          <InfoItem titleId="label.registrationNumber" value={registrationNumber} />
        )}
        {registrationAddress && (
          <InfoItem
            titleId="screens.merchantDetails.registrationAddress"
            value={`${registrationAddress.street}, ${registrationAddress.city}, ${registrationAddress.country.name}`}
          />
        )}
        {tradingAddress && (
          <InfoItem
            titleId="label.tradingAddress"
            value={`${tradingAddress.street}, ${tradingAddress.city}, ${tradingAddress.country.name}`}
          />
        )}
      </Card>
    </SideLayoutPage>
  );
}

export default MerchantDetail;
