import React from 'react';

import Box from 'components/core/Box';
import ListItemBase from 'components/v2/core/ListItemBase';
import { TListItemBaseStates } from 'components/v2/core/ListItemBase/ListItemBase.types';

import { TListItemProps } from './ListItem.types';
import { getIconBackgroundByState } from './ListItem.helpers';
import { LIST_ITEM_ICON_CONTAINER_SIZE } from './ListItem.constants';

function ListItem({
  labelId,
  labelProps,
  descriptionId,
  icon: Icon,
  iconColor = 'textSecondary',
  onPress,
  disabled,
  descriptionProps,
  badgeProps,
  hasError,
}: TListItemProps) {
  function renderRightComponent({
    pressed,
    hasError: hasErrorValue,
    hovered,
  }: TListItemBaseStates) {
    return (
      <Box
        width={LIST_ITEM_ICON_CONTAINER_SIZE}
        height={LIST_ITEM_ICON_CONTAINER_SIZE}
        borderRadius={LIST_ITEM_ICON_CONTAINER_SIZE}
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={getIconBackgroundByState({ pressed, hovered, disabled })}>
        <Icon width={24} height={24} iconColor={hasErrorValue ? 'statusErrorPrimary' : iconColor} />
      </Box>
    );
  }

  return (
    <ListItemBase
      labelId={labelId}
      descriptionId={descriptionId}
      onPress={onPress}
      right={renderRightComponent}
      disabled={disabled}
      descriptionProps={descriptionProps}
      labelProps={labelProps}
      badgeProps={badgeProps}
      hasError={hasError}
    />
  );
}

export default ListItem;
