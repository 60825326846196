import styled from 'styled-components';
import { margin, padding } from 'styled-system';

import Box from 'components/core/Box';

import { getColor } from 'helpers/theme';
import ZIndex from 'constants/zIndex';

export const StyledHeader = styled(Box)`
  box-sizing: border-box;
  font: inherit;
  top: 0;
  position: sticky;
  background-color: ${getColor('primaryBackground')};
  z-index: ${ZIndex.STICKY_HEADER};
  ${padding}
  ${margin}
`;
