import React from 'react';
import { isNil } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import { formatAmount } from 'helpers/formatAmount';
import TransactionStatus from 'enums/TransactionStatus';
import getTransactionExchangedAmount from 'helpers/getTransactionExchangedAmount';

import { StyledAmountText } from '../TransactionItem.styles';
import { TTransactionAmountContentProps } from '../TransactionItem.types';

function TransactionItemAmountContent({ item }: TTransactionAmountContentProps) {
  const lineThroughAmount = [TransactionStatus.REVERSED, TransactionStatus.DECLINED].includes(
    item.status
  );

  function getFormattedConvertedAmount(): string | undefined {
    if (item.fxOrder) {
      return getTransactionExchangedAmount({
        direction: item.direction,
        fxOrder: item.fxOrder,
        directionSymbolVisible: true,
      });
    }
    if (!isNil(item.convertedAmount) && item.convertedAmount > 0) {
      return formatAmount({
        amount: item.convertedAmount,
        currency: item.convertedCurrency,
        direction: item.direction,
      });
    }
    return undefined;
  }

  const formattedConvertedAmount = getFormattedConvertedAmount();

  return (
    <Box display="flex" flexDirection="column" alignSelf="flex-start">
      {item.amount > 0 && (
        <StyledAmountText
          color="textPrimary"
          variant="mediumTextBold"
          fontWeight="700"
          textAlign="right"
          mb="xs"
          lineThroughAmount={lineThroughAmount}>
          {formatAmount({
            amount: item.amount,
            currency: item.currency,
            direction: item.direction,
          })}
        </StyledAmountText>
      )}
      <Box alignment="row.verticalCenter" justifyContent="flex-end" gap="s">
        {formattedConvertedAmount && (
          <Text
            variant="smallTextSemiBold"
            color="transactionItemConvertedAmount"
            textAlign="right">
            {formattedConvertedAmount}
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default TransactionItemAmountContent;
