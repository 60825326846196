import React from 'react';

import Box from 'components/core/Box';
import { VisaBusinessIcon } from 'components/v2/BrandIcons';

import { getWamoCardColorsByVariant, getWamoCardSize } from 'components/WamoCard/WamoCard.helpers';

import CardLabel from './CardLabel';
import { TCardBottomContentProps } from '../WamoCard.types';

function CardBottomContent({
  nameOnCard,
  truncatedPan,
  opacity,
  variant,
  size,
}: TCardBottomContentProps) {
  const colors = getWamoCardColorsByVariant(variant);
  const { visaWidth, visaHeight, padding } = getWamoCardSize(size);

  return (
    <Box
      flex={1}
      p={padding}
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      opacity={opacity}>
      <Box display="flex" flex={1}>
        {(nameOnCard || truncatedPan) && (
          <CardLabel
            nameOnCard={nameOnCard}
            truncatedPan={truncatedPan}
            textColor={colors.textColor}
          />
        )}
      </Box>
      <VisaBusinessIcon iconColor={colors.visaColor} width={visaWidth} height={visaHeight} />
    </Box>
  );
}

export default CardBottomContent;
