import React, { ReactElement, createContext, useState, useContext } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import { TDirector, TShareholder } from 'types/businessOwner';
import API_ENDPOINT from 'constants/apiEndpoint';

import { TBusinessOwnersContext, TBusinessOwnersState } from './BusinessOwnersProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './BusinessOwnersProvider.constants';

const businessOwnersContext = createContext<TBusinessOwnersContext>({
  businessOwnersState: INITIAL_STATE,
  businessOwnersDispatch: INITIAL_DISPATCH,
});

function BusinessOwnersProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TBusinessOwnersState>(INITIAL_STATE);
  const { get: requestGetDirectors } = useFetch<TCommonResponse<TDirector[]>>(
    API_ENDPOINT.DIRECTORS
  );
  const { get: requestGetShareholders } = useFetch<TCommonResponse<TShareholder[]>>(
    API_ENDPOINT.SHAREHOLDERS_V2
  );

  async function getDirectors(): Promise<void> {
    try {
      setState((prevState) => ({
        ...prevState,
        directorsLoading: true,
      }));
      const { result } = await requestGetDirectors();
      if (result) {
        setState((prevState) => ({
          ...prevState,
          directors: result,
        }));
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setState((prevState) => ({
        ...prevState,
        directorsLoading: false,
      }));
    }
  }

  async function getShareholders(): Promise<void> {
    try {
      setState((prevState) => ({
        ...prevState,
        shareholdersLoading: true,
      }));
      const { result } = await requestGetShareholders();
      if (result) {
        setState((prevState) => ({
          ...prevState,
          shareholders: result,
        }));
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setState((prevState) => ({
        ...prevState,
        shareholdersLoading: false,
      }));
    }
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TBusinessOwnersContext = {
    businessOwnersState: state,
    businessOwnersDispatch: {
      getDirectors,
      getShareholders,
      clear,
    },
  };
  return <businessOwnersContext.Provider value={value}>{children}</businessOwnersContext.Provider>;
}

export default BusinessOwnersProvider;

export const useBusinessOwnersContext = (): TBusinessOwnersContext =>
  useContext(businessOwnersContext);
