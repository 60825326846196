import { useEffect, useState, useRef } from 'react';

import { formatSecondsToTime } from 'helpers/time';

import { TUseTimer, TUseTimerProps } from './useTimer.types';

export default function useTimer({
  defaultSeconds = 0,
  onCallback,
  onLimitCallback,
  limit,
}: TUseTimerProps): TUseTimer {
  const [seconds, setSeconds] = useState<number>(defaultSeconds);
  const intervalRef = useRef<NodeJS.Timer>();
  const defaultSecondsRef = useRef(defaultSeconds);

  function countdown() {
    const newSeconds = seconds - 1;
    setSeconds(newSeconds);

    if (limit && limit === seconds) {
      onLimitCallback && onLimitCallback();
    }
    if (newSeconds === 0) {
      handleClearInterval();
      onCallback && onCallback();
    }
  }

  function handleClearInterval() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }

  useEffect(() => {
    if (seconds > 0) {
      intervalRef.current = setInterval(countdown, 1000);
    }
    if (defaultSecondsRef.current !== defaultSeconds) {
      defaultSecondsRef.current = defaultSeconds;
      setSeconds(defaultSeconds);
    }
    return handleClearInterval;
  }, [seconds, defaultSeconds]);

  function handleResetTimer(newSeconds: number | undefined) {
    setSeconds(newSeconds || defaultSeconds);
  }

  return { time: formatSecondsToTime(seconds), resetTimer: handleResetTimer };
}
