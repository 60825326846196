import React from 'react';
import { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

import Box from 'components/core/Box';
import NavItem from 'components/v2/NavItem';
import {
  CreditCard01Icon,
  Home01Icon,
  ReceiptCheckIcon,
  ReceiptIcon,
  Users01Icon,
  Link01Icon,
} from 'components/v2/Icons';
import ProfileAvatarItem from 'components/v2/ProfileAvatarItem';
import MemberPermission from 'components/MemberPermission';

import { useMerchantFeatureFlagContext } from 'context/MerchantFeatureFlagProvider';
import MemberType from 'enums/MemberType';
import routes from 'router/routes';

import { WamoIcon, WamoWordmarkIcon } from '../BrandIcons';

function NavBar() {
  const { colors } = useTheme();
  const { merchantFeatureFlagState } = useMerchantFeatureFlagContext();

  return (
    <>
      <NavLink to="/">
        <Box
          alignment="row.verticalCenter"
          justifyContent={{ _: 'center', l: 'flex-start' }}
          px="m">
          <WamoIcon width={32} height={16} iconColor={colors.textActive} />
          <Box width={88} height={16} display={{ _: 'none', l: 'block' }}>
            <WamoWordmarkIcon width={88} height={16} iconColor={colors.textPrimary} />
          </Box>
        </Box>
      </NavLink>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ _: 'center', l: 'stretch' }}
        gap="s"
        px="s"
        flex={1}>
        <NavItem to={routes.home.path} titleId={routes.home.titleId} icon={Home01Icon} />
        <NavItem
          to={routes.cardDashboard.path}
          titleId={routes.cardDashboard.titleId}
          icon={CreditCard01Icon}
        />
        {merchantFeatureFlagState.accountsReceivableInvoiceEnabled && (
          <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
            <NavItem
              to={routes.invoicesDashboard.path}
              titleId={routes.invoicesDashboard.titleId}
              icon={ReceiptIcon}
            />
          </MemberPermission>
        )}
        <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
          <NavItem
            to={routes.statements.path}
            titleId={routes.statements.titleId}
            icon={ReceiptCheckIcon}
          />
        </MemberPermission>
        <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
          <NavItem
            to={routes.teamMemberDashboard.path}
            titleId={routes.teamMemberDashboard.titleId}
            icon={Users01Icon}
          />
        </MemberPermission>
        <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
          <NavItem
            to={routes.onlinePaymentsDashboard.path}
            titleId={routes.onlinePaymentsDashboard.titleId}
            icon={Link01Icon}
          />
        </MemberPermission>
      </Box>
      <Box px="s" pt="s" borderTop="1px solid" borderColor="borderDivider">
        <ProfileAvatarItem />
      </Box>
    </>
  );
}

export default NavBar;
