import { TStatusSizeMap } from './Status.types';

export const STATUS_SIZE_MAP: TStatusSizeMap = {
  medium: {
    iconSize: 24,
    textVariant: 'textBodySub',
    gap: 'xs',
  },
  small: {
    iconSize: 16,
    textVariant: 'textBodySmall',
    gap: 'xxs',
  },
};
