import React, { memo } from 'react';

import Text from 'components/core/Text';

import date from 'helpers/date';

import { TDescriptionProps } from '../TransactionItem.types';

function Description({ transactionDate, amount, dateFormat = 'HH:mm' }: TDescriptionProps) {
  const isCardVerification = amount === 0;

  return (
    <Text variant="smallTextRegular" color="textPrimary">
      {date.formatWithTimeZone({
        date: transactionDate,
        formatType: dateFormat,
      })}
      {isCardVerification && (
        <Text
          variant="smallTextRegular"
          id="label.transactionItemCardVerification"
          color="textPrimary"
          as="span"
        />
      )}
    </Text>
  );
}

export default memo(Description);
