import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import Spinner from 'components/core/Spinner';

import { isPhysicalCard } from 'enums/CardType';
import { CARD_ACTIVATING_POLL_INTERVAL } from '../CardDetail.constants';
import { TCardActivatingProps } from '../CardDetail.types';

function CardActivating({ type, onPolling }: TCardActivatingProps) {
  const { t } = useTranslation();
  const titleValue = t(isPhysicalCard(type) ? 'label.physical' : 'label.virtual');

  useEffect(() => {
    const intervalId = setInterval(() => {
      onPolling();
    }, CARD_ACTIVATING_POLL_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box marginTop="xxl" alignment="col.horizontalCenter" paddingX="l">
      <Text
        id="screens.cardList.cardActivating.title"
        variant="mediumTitleBold"
        textAlign="center"
        color="primary"
        mb="m"
        values={{
          cardType: titleValue.toLowerCase(),
        }}
      />
      <Spinner />
    </Box>
  );
}

export default CardActivating;
