export enum ZIndex {
  COMMON_MODAL = 1000,
  ALERT_MODAL = 1100,
  SIDE_LAYOUT = 10,
  BUSINESS_CARD_ICON = 5,
  STICKY_HEADER = 10,
  ONBOARDING_LOGOUT_BUTTON = STICKY_HEADER + 1,
  ONFIDO_MODAL = ONBOARDING_LOGOUT_BUTTON + 1,
  BILLING_PACKAGE_ACTIVE_TAB = 3,
  DIGIT_CODE_INPUT = -1,
  PROOF_OF_IDENTITY_MODAL = 10,
}

export default ZIndex;
