import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import ActivityIndicator from 'components/v2/core/ActivityIndicator';
import Pressable from 'components/v2/core/Pressable/Pressable';

import { TButtonProps } from './Button.types';
import { getButtonSize, getButtonStyle } from './Button.helpers';

function Button({
  labelId,
  labelValues,
  variant = 'primary',
  size = 'medium',
  loading = false,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  disabled,
  block = true,
  type = 'button',
  ...rest
}: TButtonProps) {
  return (
    <Pressable
      type={type}
      style={block ? styles.blockButton : styles.fitButton}
      disabled={disabled || loading}
      {...rest}>
      {({ pressed, hovered }) => {
        const buttonStyle = getButtonStyle({ variant, pressed, disabled, loading, hovered });
        const buttonSize = getButtonSize({ size });
        const buttonBorderWidth = buttonSize.borderWidth[variant];
        return (
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            minHeight={buttonSize.minHeight}
            padding={buttonSize.padding}
            borderStyle="solid"
            borderWidth={buttonBorderWidth}
            borderColor={buttonStyle.border}
            borderRadius={buttonSize.borderRadius}
            backgroundColor={buttonStyle.background}>
            {loading && (
              <Box
                mr="s"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={buttonSize.iconSize}
                height={buttonSize.iconSize}>
                <ActivityIndicator color={buttonStyle.text} />
              </Box>
            )}
            {!loading && LeftIcon && (
              <Box mr="s" display="flex">
                <LeftIcon
                  width={buttonSize.iconSize}
                  height={buttonSize.iconSize}
                  iconColor={buttonStyle.text}
                />
              </Box>
            )}
            <Text
              textId={labelId}
              values={labelValues}
              color={buttonStyle.text}
              variant={buttonSize.textVariant}
              numberOfLines={1}
            />
            {RightIcon && (
              <Box ml="s" display="flex">
                <RightIcon
                  width={buttonSize.iconSize}
                  height={buttonSize.iconSize}
                  iconColor={buttonStyle.text}
                />
              </Box>
            )}
          </Box>
        );
      }}
    </Pressable>
  );
}

export default Button;

const styles = {
  fitButton: {
    display: 'inline',
    alignSelf: 'flex-start',
  },
  blockButton: {
    width: '100%',
  },
};
