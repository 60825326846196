import React, { SyntheticEvent, useRef } from 'react';

import { CloseIcon } from 'components/Icons';
import Box from 'components/core/Box';

import { MODAL_TRANSITION } from '../Modal.constants';
import { ModalCloseButton, StyledModalContent } from '../Modal.styles';
import { TModalContentProps } from '../Modal.types';

import ModalHeader from './ModalHeader';

function ModalContent({
  className,
  onClose,
  header,
  children,
  isFullPage,
  hideCloseButton,
  ...restProps
}: TModalContentProps) {
  const ref = useRef(null);

  function handleStopPropagation(e: SyntheticEvent) {
    e.stopPropagation();
  }

  return (
    <StyledModalContent
      ref={ref}
      onClick={handleStopPropagation}
      {...(!isFullPage && {
        animate: { y: [50, 0] },
        transition: MODAL_TRANSITION,
      })}
      className={className}
      isFullPage={isFullPage}
      {...restProps}>
      {!hideCloseButton && !isFullPage && (
        <ModalCloseButton onClick={onClose}>
          <CloseIcon width={32} height={32} />
        </ModalCloseButton>
      )}
      {header && <ModalHeader header={header} navButton={{ onClick: onClose }} />}
      <Box overflow="auto" px={isFullPage ? 'none' : '1.5rem'}>
        {children}
      </Box>
    </StyledModalContent>
  );
}

export default ModalContent;
