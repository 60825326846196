import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CheckListAltIcon = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  iconColor = 'tertiary',
  ...rest
}: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="m9 11 3 3L22 4m-1 8v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckListAltIcon;
