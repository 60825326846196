import React, { createContext, ReactElement, useContext } from 'react';

import usePollingFetch from 'hooks/usePollingFetch';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TChildrenOnly } from 'types/common';

import { TActionRequestContext } from './ActionRequestProvider.types';
import { INITIAL_STATE, INITIAL_DISPATCH } from './ActionRequestProvider.constants';

const actionRequestContext = createContext<TActionRequestContext>({
  actionRequestState: INITIAL_STATE,
  actionRequestDispatch: INITIAL_DISPATCH,
});

function ActionRequestContext({ children }: TChildrenOnly): ReactElement {
  const { data, stopPolling, reFetch, loading } = usePollingFetch({
    apiUrl: API_ENDPOINT.DASHBOARD_ACTIONS,
    pollInterval: 5000,
    auto: false,
    fetchOnMount: false,
    stopPollingOnError: false,
  });

  function clear() {
    stopPolling();
  }

  const value: TActionRequestContext = {
    actionRequestState: {
      pendingComplianceRequests: data?.result?.pendingComplianceRequests || [],
      loading,
    },
    actionRequestDispatch: {
      startPolling: reFetch,
      stopPolling,
      clear,
    },
  };

  return <actionRequestContext.Provider value={value}>{children}</actionRequestContext.Provider>;
}

export const useActionRequestContext = (): TActionRequestContext =>
  useContext(actionRequestContext);

export default ActionRequestContext;
