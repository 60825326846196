import NOOP, { ASYNC_NOOP } from 'constants/noop';

import {
  TBillingPackageCurrentState,
  TBillingPackageCurrentDispatch,
} from './BillingPackageCurrentProvider.types';

export const INITIAL_STATE: TBillingPackageCurrentState = {
  currentPackage: undefined,
  loading: true,
};

export const INITIAL_DISPATCH: TBillingPackageCurrentDispatch = {
  getCurrentBillingPackage:
    ASYNC_NOOP as unknown as TBillingPackageCurrentDispatch['getCurrentBillingPackage'],
  clear: NOOP,
};
