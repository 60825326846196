import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import Dot from 'components/core/Dot';
import { CheckmarkIcon } from 'components/Icons';

import { StyledStepStatusBox } from '../OnboardingProgressSteps.styles';
import { TOnboardingProgressStepsItemStatusProps } from '../OnboardingProgressSteps.types';

function OnboardingProgressStepsItemStatus({
  type,
  order,
  completed,
  active,
}: TOnboardingProgressStepsItemStatusProps) {
  function renderParentStatusComponent() {
    if (completed) {
      return (
        <StyledStepStatusBox backgroundColor="onboardingProgressStepsCheckBackground">
          <CheckmarkIcon width="12px" height="12px" strokeWidth={4} iconColor="whiteBackground" />
        </StyledStepStatusBox>
      );
    }
    if (active) {
      return (
        <StyledStepStatusBox backgroundColor="onboardingProgressStepsItemActiveStatus">
          <Dot size={8} color="primaryBackground" />
        </StyledStepStatusBox>
      );
    }
    return (
      <StyledStepStatusBox backgroundColor="onboardingProgressStepsItemDisabledStatus">
        <Text variant="xSmallTextBold" color="textWhite">
          {order}
        </Text>
      </StyledStepStatusBox>
    );
  }

  function renderChildStatusComponent() {
    return (
      <StyledStepStatusBox backgroundColor="onboardingProgressStepsLineBackground">
        <Dot
          size={8}
          color={
            active
              ? 'onboardingProgressStepsItemActiveStatus'
              : 'onboardingProgressStepsItemDisabledStatus'
          }
        />
      </StyledStepStatusBox>
    );
  }

  return (
    <Box px="xxs" alignment="center">
      {type === 'child' ? renderChildStatusComponent() : renderParentStatusComponent()}
    </Box>
  );
}

export default OnboardingProgressStepsItemStatus;
