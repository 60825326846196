import styled from 'styled-components';
import {
  variant,
  color,
  compose,
  margin,
  padding,
  textAlign,
  letterSpacing,
  fontWeight,
  lineHeight,
} from 'styled-system';

import { textVariants } from 'theme/variants';

import { TTextProps } from './Text.types';

export const StyledText = styled.p<Partial<TTextProps>>`
  font-family: inherit;

  ${(props) =>
    props.ellipsis &&
    `
     display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
     overflow: hidden;
  `}
  ${(props) =>
    props.preWrap &&
    `
     white-space: pre-wrap;
  `}
  ${compose(
    color,
    variant({
      variants: textVariants,
    }),
    margin,
    padding,
    textAlign,
    letterSpacing,
    fontWeight,
    lineHeight
  )};
`;
