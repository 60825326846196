import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function WamoIcon({ width = 24, height = 24, iconColor = 'textPrimary' }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 161 76" fill="none">
      <path
        d="M8.1991 7.8C9.89891 7.8 10.4988 9.3 10.4988 9.4L18.298 32.9H4.69948C2.79969 32.8 1.49983 32.2 0.799905 31.1C-0.499954 29.1 0.19997 25.7 0.599926 24.6L5.3994 10C5.49939 9.7 5.69937 9.3 5.89935 9.1C6.29931 8.6 7.09922 7.8 8.1991 7.8Z"
        fill={colors[iconColor as never]}
      />
      <path
        d="M45.1951 76C40.2956 76 34.6962 71.7 32.9964 66.6L13.6985 8.3C13.1986 6.9 11.6987 4.6 8.79903 4.4C11.2988 1.8 14.6984 0 17.898 0H32.4965C37.4959 0 42.9953 4.3 44.6951 9.4L63.993 67.7C64.493 69.1 66.0928 71.3 68.8925 71.6C66.3928 74.2 62.9931 76 59.8935 76H45.1951Z"
        fill={colors[iconColor as never]}
      />
      <path
        d="M72.1921 66.4C71.2922 68.2 69.7924 68.2 69.6924 68.2H69.5924C67.8926 68.2 67.2927 66.7 67.1927 66.5L54.2941 27.8L60.3934 9.6C61.2933 7.8 62.7932 7.7 62.8931 7.8H62.9931C64.6929 7.8 65.2929 9.3 65.3929 9.5L78.1915 48.2L72.1921 66.4Z"
        fill={colors[iconColor as never]}
      />
      <path
        d="M100.089 76C95.1896 76 89.5902 71.7 87.8904 66.6L68.5925 8.3C68.0926 6.9 66.4928 4.7 63.6931 4.4C66.0928 1.8 69.4924 0 72.6921 0H87.3905C92.2899 0 97.8893 4.3 99.5891 9.4L118.887 67.7C119.387 69.1 120.987 71.3 123.787 71.6C121.287 74.2 118.087 76 114.987 76H100.089Z"
        fill={colors[iconColor as never]}
      />
      <path
        d="M126.986 66.5C126.086 68.3 124.586 68.3 124.486 68.3H124.386C122.687 68.3 122.087 66.8 121.987 66.6L109.188 27.8L115.187 9.7C116.087 7.9 117.587 7.9 117.687 7.9H117.787C119.487 7.9 120.087 9.4 120.187 9.6L125.286 24.8C127.086 30.2 131.886 34.6 137.085 36.1L126.986 66.5Z"
        fill={colors[iconColor as never]}
      />
      <path
        d="M123.387 8.3C122.887 6.9 121.287 4.7 118.387 4.4C120.887 1.8 124.286 0.1 127.486 0.1H142.185C147.084 0.1 152.683 4.4 154.383 9.5L159.483 24.8C159.483 24.8 160.882 28.9 159.283 31.3C158.583 32.4 157.283 33 155.383 33.1H140.685C135.785 33.1 130.186 28.8 128.486 23.7L123.387 8.3Z"
        fill={colors[iconColor as never]}
      />
    </svg>
  );
}

export default WamoIcon;
