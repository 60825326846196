import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import { TChildrenOnly } from 'types/common';
import { TInvoiceDetailResponse } from 'types/invoice';
import { useInvoiceDetailContext } from 'context/InvoiceDetailProvider';

import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoicePreviewPdfProvider.constants';
import {
  TInvoicePreviewPdfState,
  TInvoicePreviewPdfContext,
} from './InvoicePreviewPdfProvider.types';

const invoicePreviewPdfContext = createContext<TInvoicePreviewPdfContext>({
  invoicePreviewPdfState: INITIAL_STATE,
  invoicePreviewPdfDispatch: INITIAL_DISPATCH,
});

function InvoicePreviewPdfProvider({ children }: TChildrenOnly): ReactElement {
  const { invoiceDetailState } = useInvoiceDetailContext();
  const [state, setState] = useState<TInvoicePreviewPdfState>(INITIAL_STATE);

  useEffect(() => {
    if (invoiceDetailState.invoice) {
      setInvoice(invoiceDetailState.invoice);
    }
  }, [invoiceDetailState.invoice]);

  function setInvoice(invoice: TInvoiceDetailResponse) {
    setState({
      invoice,
    });
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TInvoicePreviewPdfContext = {
    invoicePreviewPdfState: state,
    invoicePreviewPdfDispatch: {
      setInvoice,
      clear,
    },
  };

  return (
    <invoicePreviewPdfContext.Provider value={value}>{children}</invoicePreviewPdfContext.Provider>
  );
}

export default InvoicePreviewPdfProvider;

export const useInvoicePreviewPdfContext = (): TInvoicePreviewPdfContext =>
  useContext(invoicePreviewPdfContext);
