import storage from 'helpers/storage';
import intercom from 'helpers/intercom';
import routes from 'router/routes';
import { useLedgersContext } from 'context/LedgersProvider';
import { useMemberContext } from 'context/MemberProvider';
import { useMerchantContext } from 'context/MerchantProvider';
import { useOnboardingApplicationContext } from 'context/OnboardingApplicationProvider';
import { useMerchantFeatureFlagContext } from 'context/MerchantFeatureFlagProvider';
import { useBillingPackageCurrentContext } from 'context/BillingPackageCurrentProvider';
import { useBusinessOwnerCreateContext } from 'context/BusinessOwnerCreateProvider';
import { useBusinessOwnersContext } from 'context/BusinessOwnersProvider';
import { useCardsContext } from 'context/CardsProvider';
import { useOnlinePaymentsContext } from 'context/OnlinePaymentsProvider';
import { useSendMoneyContext } from 'context/SendMoneyProvider';
import { useTeamMembersContext } from 'context/TeamMembersProvider';
import { useReferralCampaignContext } from 'context/ReferralCampaignProvider';
import { useActionRequestContext } from 'context/ActionRequestProvider';

function useLogout(): (route?: string) => void {
  const { ledgersDispatch } = useLedgersContext();
  const { memberDispatch } = useMemberContext();
  const { merchantDispatch } = useMerchantContext();
  const { onboardingApplicationDispatch } = useOnboardingApplicationContext();
  const { merchantFeatureFlagDispatch } = useMerchantFeatureFlagContext();
  const { billingPackageCurrentDispatch } = useBillingPackageCurrentContext();
  const { businessOwnerCreateDispatch } = useBusinessOwnerCreateContext();
  const { businessOwnersDispatch } = useBusinessOwnersContext();
  const { cardsDispatch } = useCardsContext();
  const { onlinePaymentsDispatch } = useOnlinePaymentsContext();
  const { sendMoneyDispatch } = useSendMoneyContext();
  const { teamMembersDispatch } = useTeamMembersContext();
  const { referralCampaignDispatch } = useReferralCampaignContext();
  const { actionRequestDispatch } = useActionRequestContext();

  function onLogout(route?: string) {
    storage.removePersonalData();
    intercom.clear();
    window.location.replace(route ?? routes.login.path);
    ledgersDispatch.clear?.();
    memberDispatch.clear?.();
    merchantDispatch.clearState?.();
    onboardingApplicationDispatch.clearState?.();
    merchantFeatureFlagDispatch.clear?.();
    billingPackageCurrentDispatch.clear?.();
    businessOwnerCreateDispatch.clear?.();
    businessOwnersDispatch.clear?.();
    cardsDispatch.clear?.();
    onlinePaymentsDispatch.clearState?.();
    sendMoneyDispatch.clear?.();
    teamMembersDispatch.clear?.();
    referralCampaignDispatch.clear?.();
    actionRequestDispatch.clear?.();
  }

  return onLogout;
}

export default useLogout;
