import React from 'react';
import { Controller } from 'react-hook-form';

import Box from 'components/core/Box';
import DigitCode from 'components/core/DigitCode';
import FormErrorLabel from 'components/FormErrorLabel';
import Spinner from 'components/core/Spinner';

import { TControllerRenderProps } from 'types/form';
import { TI18nId } from 'types/common';

import { TFieldDigitCodeProps } from './FieldDigitCode.types';

function FieldDigitCode({
  control,
  errors,
  name,
  onSubmit,
  showErrorMessage = true,
  loading,
  autoComplete = 'one-time-password',
}: TFieldDigitCodeProps) {
  const errorMessageId = errors[name]?.message;

  function handleRender({ field }: TControllerRenderProps) {
    const { onChange, onBlur, value, ref } = field;
    return (
      <DigitCode
        ref={ref}
        size={6}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onSubmit={onSubmit}
        disabled={loading}
        autoComplete={autoComplete}
      />
    );
  }

  return (
    <Box className="field-box" mb="m">
      <Box display="inline-block">
        <Controller name={name} control={control} render={handleRender} />
        {loading && (
          <Box alignment="center" mt="m">
            <Spinner size={24} color="tertiary" />
          </Box>
        )}
      </Box>
      {showErrorMessage && errorMessageId && <FormErrorLabel labelId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldDigitCode;
