import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { TStatusProps } from './Status.types';
import { STATUS_SIZE_MAP } from './Status.constants';

function Status({ labelId, labelValues, size = 'medium', icon: Icon, color }: TStatusProps) {
  const { iconSize, textVariant, gap } = STATUS_SIZE_MAP[size];

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={gap}>
      <Icon width={iconSize} height={iconSize} iconColor={color} />
      {labelId && (
        <Text textId={labelId} variant={textVariant} color={color} values={labelValues} />
      )}
    </Box>
  );
}

export default Status;
