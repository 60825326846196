import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_ENABLED } from 'constants/environment';

async function sentryInit() {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (SENTRY_ENABLED) {
  sentryInit();
}
