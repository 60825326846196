import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const PinCodeIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeWidth={2}
        d="M7 4v6M10 7H4M9.121 4.879 4.88 9.12M9.121 9.121 4.88 4.88M7 14v6M10 17H4M9.121 14.879 4.88 19.12M9.121 19.121 4.88 14.88M17 4v6M20 7h-6M19.121 4.879 14.88 9.12M19.121 9.121 14.88 4.88M17 14v6M20 17h-6M19.121 14.879 14.88 19.12M19.121 19.121 14.88 14.88"
      />
    </svg>
  );
};

export default PinCodeIcon;
