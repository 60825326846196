import { FC } from 'react';

import { Hourglass01Icon, ReceiptIcon, CheckIcon, CheckCircleIcon } from 'components/v2/Icons';

import { TI18nId } from 'types/common';
import { TThemePrimitives } from 'theme/v2/theme.types';
import { TIconProps } from 'components/v2/Icons/Icon.types';

enum InvoiceStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  OVERDUE = 'OVERDUE',
  COMPLETED = 'COMPLETED',
}

const MAP: Record<
  InvoiceStatus,
  {
    statusIconColor: keyof TThemePrimitives;
    statusIcon: FC<TIconProps>;
    invoiceDetailStatusIcon: FC<TIconProps>;
    invoiceItemDescriptionColor: keyof TThemePrimitives;
    amountColor: keyof TThemePrimitives;
    statusLabelId: TI18nId;
  }
> = {
  [InvoiceStatus.DRAFT]: {
    statusIconColor: 'statusNaturalPrimary',
    statusIcon: ReceiptIcon,
    invoiceDetailStatusIcon: ReceiptIcon,
    invoiceItemDescriptionColor: 'textPrimary',
    amountColor: 'textPassive',
    statusLabelId: 'label.draft.lowercase',
  },
  [InvoiceStatus.PENDING]: {
    statusIconColor: 'textSecondary',
    statusIcon: Hourglass01Icon,
    invoiceDetailStatusIcon: Hourglass01Icon,
    invoiceItemDescriptionColor: 'textPrimary',
    amountColor: 'textPassive',
    statusLabelId: 'label.pending.lowercase',
  },
  [InvoiceStatus.OVERDUE]: {
    statusIconColor: 'statusWarningPrimary',
    statusIcon: Hourglass01Icon,
    invoiceDetailStatusIcon: Hourglass01Icon,
    invoiceItemDescriptionColor: 'statusWarningPrimary',
    amountColor: 'textPassive',
    statusLabelId: 'label.overdue.lowercase',
  },
  [InvoiceStatus.COMPLETED]: {
    statusIconColor: 'statusPositivePrimary',
    statusIcon: CheckIcon,
    invoiceDetailStatusIcon: CheckCircleIcon,
    invoiceItemDescriptionColor: 'textPrimary',
    amountColor: 'textPrimary',
    statusLabelId: 'label.paid.lowercase',
  },
};

export function getStatusIconColorByInvoiceStatus(status: InvoiceStatus) {
  return MAP[status].statusIconColor;
}

export function getStatusIconByInvoiceStatus(status: InvoiceStatus) {
  return MAP[status].statusIcon;
}

export function getInvoiceItemDescriptionColorByInvoiceStatus(status: InvoiceStatus) {
  return MAP[status].invoiceItemDescriptionColor;
}

export function getAmountColorByInvoiceStatus(status: InvoiceStatus) {
  return MAP[status].amountColor;
}

export function getStatusLabelIdByStatus(status: InvoiceStatus) {
  return MAP[status].statusLabelId;
}

export function getInvoiceDetailStatusIconByStatus(status: InvoiceStatus) {
  return MAP[status].invoiceDetailStatusIcon;
}

export default InvoiceStatus;
