import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

function MasterCardIcon(props: TIconProps): ReactElement {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M20.327 8.239h-8.653v15.55h8.653V8.238Z" fill="#FF5F00" />
      <path
        d="M12.223 16.014A9.871 9.871 0 0 1 16 8.24a9.889 9.889 0 1 0 0 15.55 9.87 9.87 0 0 1-3.777-7.775Z"
        fill="#EB001B"
      />
      <path
        d="M32 16.014a9.888 9.888 0 0 1-16 7.775 9.891 9.891 0 0 0 0-15.55 9.89 9.89 0 0 1 16 7.775ZM31.057 22.142v-.319h.128v-.065h-.327v.065h.128v.319h.07Zm.634 0v-.384h-.1l-.115.264-.115-.264h-.1v.384h.07v-.29l.108.25h.073l.109-.25v.29h.07Z"
        fill="#F79E1B"
      />
    </svg>
  );
}

export default MasterCardIcon;
