import NOOP from 'constants/noop';

import {
  TInvoiceCreatePreviewPdfDispatch,
  TInvoiceCreatePreviewPdfState,
} from './InvoiceCreatePreviewPdfProvider.types';

export const INITIAL_STATE: TInvoiceCreatePreviewPdfState = {
  invoice: undefined,
};

export const INITIAL_DISPATCH: TInvoiceCreatePreviewPdfDispatch = {
  setInvoice: NOOP,
  setCustomer: NOOP,
  setSellItem: NOOP,
  calculateAmountDetailsBySellItemPrice: NOOP,
  clear: NOOP,
};

export const PREVIEW_SELL_ITEM_ID = 'PREVIEW_SELL_ITEM_ID';
