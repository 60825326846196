import React, { memo, ReactElement } from 'react';

import Avatar from 'components/v2/core/Avatar';

import { getTransactionAvatarProps } from 'helpers/transaction';

import { TTransactionIconProps } from '../TransactionItem.types';

function TransactionIcon({
  title,
  transactionType,
  transactionStatus,
  cardMerchantCategoryCode,
  merchantLogoLink,
  isBulkTransfer,
}: TTransactionIconProps): ReactElement {
  const avatarProps = getTransactionAvatarProps({
    title,
    transactionType,
    transactionStatus,
    cardMerchantCategoryCode,
    merchantLogoLink,
    isBulkTransfer,
  });

  return <Avatar {...avatarProps} iconColor="textSecondary" size="small" />;
}

export default memo(TransactionIcon);
