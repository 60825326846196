import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

function WamoLogo(props: TIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="338"
      height="164"
      viewBox="0 0 338 164"
      fill="none"
      {...props}>
      <path
        d="M335.763 53.6748L325.577 23.6749C321.183 10.711 307.511 0.554707 294.493 0.554707L264.801 0.510838C251.764 0.510838 238.111 10.667 233.722 23.6309L224.391 51.17L215.025 23.5333C210.631 10.5694 196.959 0.413086 183.941 0.413086H154.244C143.458 0.413086 132.272 7.36141 126.222 17.0977L126.168 17.0635C124.892 19.0937 123.855 21.2647 123.077 23.5333L113.653 51.3408L104.234 23.5528H104.293C99.8985 10.5889 86.2266 0.432636 73.209 0.432636H43.5117C30.4746 0.432636 16.8223 10.5889 12.4278 23.5528L2.25686 53.5186C2.09573 53.9727 -1.64934 64.8125 3.39948 72.3125C6.09968 76.3359 10.5869 78.5333 16.7392 78.8507H46.8174L67.7012 140.481C72.0957 153.445 85.7676 163.602 98.7852 163.602H128.443C141.48 163.602 155.133 153.445 159.527 140.481L169.029 112.649L178.458 140.481C182.853 153.445 196.5 163.602 209.537 163.602H239.239C252.272 163.602 265.929 153.445 270.318 140.481H270.284L291.119 78.9923H321.266C327.418 78.6749 331.905 76.4776 334.601 72.4541C339.63 64.9346 335.909 54.0948 335.763 53.6748Z"
        fill="#051726"
      />
      <path
        d="M17.0126 72.0341C13.1064 71.8242 10.5185 70.6717 9.0683 68.5184C6.46088 64.6561 7.90618 57.9862 8.69231 55.7157L18.7558 26.0721L18.8339 26.1015C20.582 22.4882 23.6435 22.4491 23.9169 22.4687H24.1318C27.5058 22.4687 28.7216 25.4325 28.829 25.7108L44.5225 72.0292L17.0126 72.0341Z"
        fill="#FBF9F4"
      />
      <path
        d="M128.443 156.79H98.7851C88.8242 156.79 77.54 148.318 74.1416 138.289L35.25 23.5087C34.3076 20.789 31.2021 16.3017 25.3672 15.7353C30.4062 10.6474 37.2861 7.21484 43.624 7.21484H73.165C83.1259 7.21484 94.4101 15.6865 97.8085 25.7158L136.69 140.462L136.725 140.555C137.701 143.255 140.919 147.693 146.72 148.26C141.661 153.352 134.786 156.79 128.443 156.79Z"
        fill="#FBF9F4"
      />
      <path
        d="M153.287 137.918H153.243C151.5 141.531 148.448 141.56 148.16 141.555H147.945C144.581 141.555 143.258 138.65 143.063 138.245L117.218 61.9657L129.366 26.1112H129.415C131.163 22.4979 134.215 22.4589 134.498 22.4784H134.713C138.077 22.4784 139.405 25.408 139.596 25.7889L165.44 102.063L153.287 137.918Z"
        fill="#FBF9F4"
      />
      <path
        d="M239.181 156.79H209.557C199.601 156.79 188.312 148.318 184.918 138.289H184.879L145.997 23.5429L145.963 23.4502C144.986 20.75 141.773 16.3066 135.968 15.7451C141.007 10.6572 147.887 7.22461 154.23 7.22461H183.927C193.888 7.22461 205.172 15.6962 208.57 25.7255L221.168 62.9081H221.134L247.413 140.462L247.447 140.555C248.424 143.255 251.637 147.693 257.438 148.26C252.418 153.352 245.528 156.79 239.181 156.79Z"
        fill="#FBF9F4"
      />
      <path
        d="M263.927 138.025C262.169 141.497 259.195 141.531 258.917 141.526H258.702C255.338 141.526 254.01 138.621 253.819 138.216L227.94 61.8242L240.03 26.1407C241.783 22.6348 244.781 22.5859 245.055 22.6103H245.27C248.644 22.6103 249.967 25.54 250.152 25.9258L260.348 55.8525C263.937 66.4434 273.707 75.1543 284.273 77.9913L263.927 138.025Z"
        fill="#FBF9F4"
      />
      <path
        d="M328.956 68.6455C327.491 70.8086 324.903 71.9609 321.002 72.1758H291.427C281.466 72.1758 270.182 63.7042 266.783 53.67L256.559 23.6651L256.524 23.5821C255.548 20.8819 252.335 16.4386 246.529 15.8771C251.568 10.7892 258.448 7.35156 264.791 7.35156H294.488C304.449 7.35156 315.733 15.8282 319.132 25.8575L329.327 55.8917C329.361 55.9942 332.057 64.0215 328.956 68.6455Z"
        fill="#FBF9F4"
      />
      <path
        d="M17.0126 72.0341C13.1064 71.8242 10.5185 70.6717 9.0683 68.5184C6.46088 64.6561 7.90618 57.9862 8.69231 55.7157L18.7558 26.0721L18.8339 26.1015C20.582 22.4882 23.6435 22.4491 23.9169 22.4687H24.1318C27.5058 22.4687 28.7216 25.4325 28.829 25.7108L44.5225 72.0292L17.0126 72.0341Z"
        fill="#0FE3FF"
      />
      <path
        d="M128.443 156.79H98.7851C88.8242 156.79 77.54 148.318 74.1416 138.289L35.25 23.5087C34.3076 20.789 31.2021 16.3017 25.3672 15.7353C30.4062 10.6474 37.2861 7.21484 43.624 7.21484H73.165C83.1259 7.21484 94.4101 15.6865 97.8085 25.7158L136.69 140.462L136.725 140.555C137.701 143.255 140.919 147.693 146.72 148.26C141.661 153.352 134.786 156.79 128.443 156.79Z"
        fill="#00E883"
      />
      <path
        d="M153.287 137.918H153.243C151.5 141.531 148.448 141.56 148.16 141.555H147.945C144.581 141.555 143.258 138.65 143.063 138.245L117.218 61.9657L129.366 26.1112H129.415C131.163 22.4979 134.215 22.4589 134.498 22.4784H134.713C138.077 22.4784 139.405 25.408 139.596 25.7889L165.44 102.063L153.287 137.918Z"
        fill="#FF6F00"
      />
      <path
        d="M239.181 156.79H209.557C199.601 156.79 188.312 148.318 184.918 138.289H184.879L145.997 23.5429L145.963 23.4502C144.986 20.75 141.773 16.3066 135.968 15.7451C141.007 10.6572 147.887 7.22461 154.23 7.22461H183.927C193.888 7.22461 205.172 15.6962 208.57 25.7255L221.168 62.9081H221.134L247.413 140.462L247.447 140.555C248.424 143.255 251.637 147.693 257.438 148.26C252.418 153.352 245.528 156.79 239.181 156.79Z"
        fill="#FE5151"
      />
      <path
        d="M263.927 138.025C262.169 141.497 259.195 141.531 258.917 141.526H258.702C255.338 141.526 254.01 138.621 253.819 138.216L227.94 61.8242L240.03 26.1407C241.783 22.6348 244.781 22.5859 245.055 22.6103H245.27C248.644 22.6103 249.967 25.54 250.152 25.9258L260.348 55.8525C263.937 66.4434 273.707 75.1543 284.273 77.9913L263.927 138.025Z"
        fill="#0048FF"
      />
      <path
        d="M328.956 68.6455C327.491 70.8086 324.903 71.9609 321.002 72.1758H291.427C281.466 72.1758 270.182 63.7042 266.783 53.67L256.559 23.6651L256.524 23.5821C255.548 20.8819 252.335 16.4386 246.529 15.8771C251.568 10.7892 258.448 7.35156 264.791 7.35156H294.488C304.449 7.35156 315.733 15.8282 319.132 25.8575L329.327 55.8917C329.361 55.9942 332.057 64.0215 328.956 68.6455Z"
        fill="#B347FF"
      />
    </svg>
  );
}

export default WamoLogo;
