import React from 'react';

import Box from 'components/core/Box';
import Button from 'components/core/Button';

import { TFormProps } from './Form.types';
import { ButtonStickyWrapper, ButtonWrapper, StyledForm } from './Form.styles';

function Form({
  onSubmit,
  buttonProps,
  extra,
  secondary,
  children,
  buttonExtraContent,
  isFullPage = true,
  isSticky,
}: TFormProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Wrapper: any = isSticky ? ButtonStickyWrapper : ButtonWrapper;

  return (
    <StyledForm onSubmit={onSubmit} secondary={secondary} noValidate isFullPage={isFullPage}>
      <Box>{children}</Box>
      {extra}
      {buttonProps && (
        <Wrapper isFullPage={isFullPage} block>
          {buttonExtraContent}
          <Button variant="primary" {...buttonProps} type="submit" />
        </Wrapper>
      )}
    </StyledForm>
  );
}

export default Form;
