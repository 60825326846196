import React, { memo, ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { TI18nId } from 'types/common';

import { TTextProps } from './Text.types';
import { StyledText } from './Text.styles';

function Text({
  id,
  as = 'p',
  children,
  values,
  hasTransComponents = true,
  ...rest
}: TTextProps): ReactElement {
  return (
    <StyledText as={as} variant="mediumTextRegular" color="textPrimary" {...rest}>
      {id &&
        (hasTransComponents ? (
          <Trans<TI18nId>
            i18nKey={id}
            values={values}
            components={{
              b: <b />,
              u: <u />,
            }}
          />
        ) : (
          <Trans<TI18nId> i18nKey={id} values={values} />
        ))}
      {children}
    </StyledText>
  );
}

export default memo(Text);
