import React from 'react';

import Box from 'components/core/Box';
import CurrencyPairs from 'components/v2/CurrencyPairs';

import { TExchangeIconProps } from '../TransactionItem.types';

function ExchangeIcon({ sourceCurrencyCode, targetCurrencyCode }: TExchangeIconProps) {
  return (
    <Box display="flex" alignItems="center" width={48} height={48}>
      <CurrencyPairs frontCurrencyCode={targetCurrencyCode} backCurrencyCode={sourceCurrencyCode} />
    </Box>
  );
}

export default ExchangeIcon;
