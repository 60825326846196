import { TTextButtonVariants } from './TextButton.types';

export const TEXT_BUTTON_VARIANTS: TTextButtonVariants = {
  primary: {
    color: 'textButtonPrimaryText',

    '&:hover, &:active': {
      color: 'textButtonPrimaryHoverText',
    },
  },
  secondary: {
    color: 'textButtonSecondaryText',

    '&:hover, &:active': {
      color: 'textButtonSecondaryHoverText',
    },
  },
};
