import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TInvoiceSettingsDispatch, TInvoiceSettingsState } from './InvoiceSettingsProvider.types';

export const INITIAL_STATE: TInvoiceSettingsState = {
  settings: undefined,
  loading: true,
};

export const INITIAL_DISPATCH: TInvoiceSettingsDispatch = {
  updateMerchantLogo: ASYNC_NOOP,
  updateBusinessDetails: ASYNC_NOOP,
  updateDefaultSettings: ASYNC_NOOP,
  updateEmailMessages: ASYNC_NOOP,
  updateDefaultFooter: ASYNC_NOOP,
  setupInvoiceSettings: ASYNC_NOOP,
  getInvoiceSettings: ASYNC_NOOP,
  clear: NOOP,
};
