import React from 'react';
import { Outlet } from 'react-router-dom';

import InvoicePreviewPdf from 'components/v2/InvoicePreviewPdf';
import NavBar from 'components/v2/NavBar';
import Box from 'components/core/Box';
import MobileTabBar from 'components/v2/MobileTabBar';
import Header from 'components/v2/Header';

import {
  Container,
  HeaderContainer,
  MainContent,
  RightContent,
  LeftContent,
  NavbarContainer,
} from './InvoicePreviewLayout.styles';
import { TInvoicePreviewLayoutProps } from './InvoicePreviewLayout.types';

function InvoicePreviewLayout({ invoice, children }: TInvoicePreviewLayoutProps) {
  return (
    <Container>
      <NavbarContainer>
        <Box display={{ _: 'none', s: 'flex' }} flexDirection="column" gap="l" height="100%">
          <NavBar />
        </Box>
        <Box display={{ _: 'flex', s: 'none' }}>
          <MobileTabBar />
        </Box>
      </NavbarContainer>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <MainContent>
        <LeftContent className="left-scroll">{children || <Outlet />}</LeftContent>
        <RightContent>
          <InvoicePreviewPdf invoice={invoice} />
        </RightContent>
      </MainContent>
    </Container>
  );
}

export default InvoicePreviewLayout;
