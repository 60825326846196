import React, { Ref, useImperativeHandle, forwardRef } from 'react';

import Form from 'components/Form';
import Text from 'components/core/Text';
import FieldTextInput from 'components/FieldTextInput';

import useForm from 'hooks/useForm';

import { FORM_VALIDATION_SCHEMA } from '../PasswordAuthenticationPageModal.constants';
import {
  TPasswordFormData,
  TPasswordFormProps,
  TPasswordFormRef,
} from '../PasswordAuthenticationPageModal.types';

function PasswordForm(
  { loading, descriptionId, onSubmit }: TPasswordFormProps,
  ref: Ref<TPasswordFormRef>
) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<TPasswordFormData>({
    schema: FORM_VALIDATION_SCHEMA,
  });

  useImperativeHandle(ref, () => ({
    clear,
  }));

  function clear() {
    reset();
    setValue('password', '');
  }

  return (
    <Form
      isFullPage={false}
      onSubmit={handleSubmit(onSubmit)}
      buttonProps={{
        id: 'button.continue',
        loading,
      }}>
      <Text id={descriptionId} mb="l" />
      <FieldTextInput
        name="password"
        type="password"
        labelId="label.password"
        placeholderId="placeholder.password"
        control={control}
        errors={errors}
        inputMode="numeric"
        autoFocus
      />
    </Form>
  );
}

export default forwardRef(PasswordForm);
