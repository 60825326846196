import { isNil } from 'lodash/fp';

import Locale from 'enums/Locale';
import { NORMALIZE_PRICE_FORMAT } from 'constants/regex';
import TransactionDirectionType, {
  getSignByTransactionDirectionType,
} from 'enums/TransactionDirectionType';

import { TFormatAmountParams } from './formatAmount.types';

export function formatAmount({
  amount,
  currency,
  direction,
  numberFormatOptions,
}: TFormatAmountParams) {
  const currencySymbol = currency?.symbol ?? '';
  const amountStr = amount.toLocaleString(Locale.EN, numberFormatOptions);
  return normalizeWithDirection(`${currencySymbol}${amountStr}`, direction);
}

function normalizeWithDirection(amount: string, direction?: TransactionDirectionType) {
  if (isNil(direction)) {
    return amount;
  }
  return `${getSignByTransactionDirectionType(direction)}${amount}`;
}

export function normalizePrice(price: string) {
  return price.replace(NORMALIZE_PRICE_FORMAT, '');
}

export function formatInvoiceAmount({ amount, currency, direction }: TFormatAmountParams) {
  return formatAmount({
    amount,
    currency,
    direction,
    numberFormatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  });
}
