import { isAfter } from 'date-fns/fp';

import { TInvoiceCustomerFormData } from 'components/v2/Forms/InvoiceCustomerForm/InvoiceCustomerForm.types';
import { TInvoiceSellItemFormData } from 'components/v2/Forms/InvoiceSellItemForm/InvoiceSellItemForm.types';
import InvoiceItemDiscountType from 'enums/InvoiceItemDiscountType';
import { TInvoiceItemVat } from 'types/invoice';

import {
  TConvertToDraftInvoiceUpdateRequestParams,
  TConvertToDraftInvoiceUpdateRequestReturn,
} from './InvoiceCreateProvider.types';

export function convertToAddCustomerRequest(customer: TInvoiceCustomerFormData) {
  return {
    customerTemplateId: customer?.customerTemplateId ?? customer?.id,
    customerName: customer.customerName,
    contactName: customer.contactName,
    type: customer.type,
    email: customer.email,
    tin: customer.tin,
    address: customer.address,
    city: customer.city,
    postCode: customer.postCode,
    countryCode: customer.country.code,
  };
}

export function convertToItemRequest(item: TInvoiceSellItemFormData) {
  function getDiscountParams() {
    if (item.discount?.type) {
      return {
        type: item.discount.type,
        ...(item.discount.type === InvoiceItemDiscountType.PERCENTAGE
          ? {
              percentage: item.discount.percentage ? Number(item.discount.percentage) : undefined,
            }
          : {
              amount: item.discount.amount ? Number(item.discount.amount) : undefined,
            }),
      };
    }
    return undefined;
  }

  function getVatParams() {
    if (item.vat) {
      return {
        title: item.vat.title,
        rate: Number(item.vat.rate ?? 0),
      };
    }
    return undefined;
  }

  return {
    title: item.title,
    description: item.description,
    unitType: item.unitType,
    vat: getVatParams() as TInvoiceItemVat,
    quantity: Number(item.quantity),
    unitPrice: Number(item.unitPrice),
    discount: getDiscountParams(),
  };
}

export function convertToDraftInvoiceUpdateRequest(
  params: TConvertToDraftInvoiceUpdateRequestParams
): TConvertToDraftInvoiceUpdateRequestReturn {
  return {
    issueDate: params?.issueDate as string,
    dueDate: params?.dueDate as string,
    footer: params?.footer,
    preferredReceivingLedgerId: params?.preferredReceivingLedger?.id as string,
    currencyCode: params?.amountDetails?.currency.code as string,
  };
}

export function getDueDate(issueDate: string, dueDate: string) {
  if (isAfter(new Date(dueDate), new Date(issueDate))) {
    return issueDate;
  }
  return dueDate;
}
