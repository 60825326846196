import styled from 'styled-components';

import spacing from 'theme/spacing';

export const StyledHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: ${spacing.s};
  padding: 0 1.5rem;
  width: 100%;
`;
