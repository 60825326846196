import React from 'react';

import Box from 'components/core/Box';

import { getIconByCurrency } from 'enums/Currency';

import { TCurrencyPairsProps } from './CurrencyPairs.types';
import { getCurrencyPairsSize } from './CurrencyPairs.helpers';
import CurrencyWrapper from './views/CurrencyWrapper';

function CurrencyPairs({
  frontCurrencyCode,
  backCurrencyCode,
  size = 'medium',
}: TCurrencyPairsProps) {
  const FrontCurrencyIcon = getIconByCurrency(frontCurrencyCode);
  const BackCurrencyIcon = getIconByCurrency(backCurrencyCode);
  const { width, height, smallIconSize, largeIconSize } = getCurrencyPairsSize({ size });
  return (
    <Box position="relative" width={width} height={height}>
      <CurrencyWrapper iconSize={largeIconSize} icon={BackCurrencyIcon} top={0} right={0} />
      <CurrencyWrapper iconSize={smallIconSize} icon={FrontCurrencyIcon} left={0} bottom={0} />
    </Box>
  );
}

export default CurrencyPairs;
