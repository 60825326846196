import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const MultiAccountIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M5 6L19 6M7 3L17 3M5 21L19 21C20.1046 21 21 20.1046 21 19L21 11C21 9.89543 20.1046 9 19 9L5 9C3.89543 9 3 9.89543 3 11L3 19C3 20.1046 3.89543 21 5 21Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MultiAccountIcon;
