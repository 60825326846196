import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { useInvoiceSettingsContext } from 'context/InvoiceSettingsProvider';
import { useMerchantContext } from 'context/MerchantProvider';
import { useMemberContext } from 'context/MemberProvider';
import { formatInvoiceAmount } from 'helpers/formatAmount';
import dateHelpers from 'helpers/date';

import InvoicePreviewPdfHeader from './views/InvoicePreviewPdfHeader';
import InvoicePreviewPdfMerchantDetails from './views/InvoicePreviewPdfMerchantDetails';
import InvoicePreviewPdfCustomerDetails from './views/InvoicePreviewPdfCustomerDetails';
import InvoicePreviewPdfItems from './views/InvoicePreviewPdfItems';
import InvoicePreviewPdfAmountDetails from './views/InvoicePreviewPdfAmountDetails';
import InvoicePreviewPdfPaymentDetails from './views/InvoicePreviewPdfPaymentDetails';
import InvoicePreviewPdfFooter from './views/InvoicePreviewPdfFooter';

import { StyledContainer } from './InvoicePreviewPdf.styles';
import { TInvoicePreviewPdfProps } from './InvoicePreviewPdf.types';

function InvoicePreviewPdf({ invoice }: TInvoicePreviewPdfProps) {
  const {
    invoiceSettingsState: { settings },
  } = useInvoiceSettingsContext();
  const { merchantState } = useMerchantContext();
  const { memberState } = useMemberContext();

  if (!invoice || !merchantState.merchant || !memberState) {
    return <></>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="backgroundTertiary"
      borderRadius="8px"
      px="l"
      minHeight="100%">
      <StyledContainer>
        <InvoicePreviewPdfHeader
          issueDate={invoice.issueDate}
          dueDate={invoice.dueDate}
          invoiceNumber={invoice.invoiceNumber}
          merchantLogoLink={settings?.merchantLogoLink}
        />
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" py="m">
            <InvoicePreviewPdfMerchantDetails
              registrationName={merchantState.merchant.registrationName}
              merchantVatNumber={settings?.merchantVatNumber}
              registrationAddress={merchantState.merchant.registrationAddress}
              email={memberState.email}
              phoneNumber={memberState.phoneNumber}
            />
            <InvoicePreviewPdfCustomerDetails customer={invoice.customer} />
          </Box>
          <InvoicePreviewPdfItems items={invoice.items} />
          <Box display="flex" justifyContent="flex-end">
            <InvoicePreviewPdfAmountDetails amountDetails={invoice.amountDetails} />
          </Box>
        </Box>
        <Box>
          <Box display="flex" flexDirection="column" py="m" gap="s">
            <Text
              textId="label.totalGrossAmount.withDueDateAndAmount"
              variant="textBodyBold"
              values={{
                amount: formatInvoiceAmount({
                  amount: invoice.amountDetails.totalGrossAmount,
                  currency: invoice.amountDetails.currency,
                }),
                dueDate: dateHelpers.format({
                  date: invoice.dueDate,
                  formatType: 'dd MMMM, yyyy',
                }),
              }}
            />
          </Box>
          <InvoicePreviewPdfPaymentDetails
            registrationName={merchantState.merchant.registrationName}
            ledger={invoice.preferredReceivingLedger}
            paymentReferenceNumber={invoice.paymentReferenceNumber}
            footer={invoice.footer}
          />
          <InvoicePreviewPdfFooter
            registrationName={merchantState.merchant.registrationName}
            invoiceNumber={invoice.invoiceNumber}
          />
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default InvoicePreviewPdf;
