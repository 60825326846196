import { TThemePrimitives } from 'theme/v2/theme.types';

import { TBeneficiaryItemMap } from './BeneficiaryItem.types';

export const BENEFICIARY_ITEM_MIN_HEIGHT = 56;
export const BENEFICIARY_STATE_BORDER_RADIUS = 12;

export const AVATAR_COLORS: Array<keyof TThemePrimitives> = [
  'avatarOrchid',
  'avatarTurquoise',
  'avatarSky',
  'avatarSpring',
  'avatarSunburst',
  'avatarFire',
  'avatarSand',
  'avatarRose',
];

export const BENEFICIARY_ITEM_STATE_MAP: TBeneficiaryItemMap = {
  onTap: {
    backgroundColor: 'buttonGhostPrimary',
    borderRadius: BENEFICIARY_STATE_BORDER_RADIUS,
  },
  hover: {
    backgroundColor: 'backgroundTertiaryHover',
    borderRadius: BENEFICIARY_STATE_BORDER_RADIUS,
  },
  default: {
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
};
