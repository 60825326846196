import React from 'react';

import Box from 'components/core/Box';
import ActivityIndicator from 'components/v2/core/ActivityIndicator';

import { TIconWrapperProps } from './Icon.types';

function IconWrapper({
  size,
  icon: Icon,
  iconColor,
  iconSize = size / 2,
  backgroundColor = 'buttonGhostPrimary',
  loading,
  ...rest
}: TIconWrapperProps) {
  return (
    <Box
      className="icon-wrapper"
      width={size}
      height={size}
      borderRadius={size}
      backgroundColor={backgroundColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <Icon width={iconSize} height={iconSize} iconColor={iconColor} />
      )}
    </Box>
  );
}

export default IconWrapper;
