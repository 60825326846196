import { TI18nId } from 'types/common';

export type TOnboardingProgressStepsProps = {
  steps: TOnboardingProgressSteps[];
  activeParentStepIndex: number;
  activeChildStepIndex: number;
};

export enum TOnboardingProgressStepsItemType {
  CHILD = 'child',
  PARENT = 'parent',
}

export type TOnboardingProgressStepsItemProps = {
  labelId: TI18nId;
  completed?: boolean;
  active?: boolean;
  type: TOnboardingProgressStepsItemType;
  order?: number;
};

export type TOnboardingProgressStepsItemStatusProps = Omit<
  TOnboardingProgressStepsItemProps,
  'labelId'
>;

export type TOnboardingProgressSteps = {
  index: number;
  labelId: TI18nId;
  type: TOnboardingProgressStepsItemType;
  subSteps?: TOnboardingProgressSteps[];
};
