import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const BagOfCashIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M6.91215 6C6.96894 5.67165 7 5.33777 7 5C7 3.9391 6.79349 2.92643 6.41848 2H17.5815C17.2065 2.92643 17 3.9391 17 5C17 5.33742 17.0313 5.67134 17.0884 6M6.91215 6C6.68841 7.29369 6.06521 8.5016 5.36827 9.52417C4.50446 10.8016 4 12.3419 4 14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14C20 12.3461 19.4981 10.8095 18.6384 9.53403C17.9413 8.51118 17.3137 7.29663 17.0884 6M6.91215 6H17.0884"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.7993 17.283C14.2114 17.735 13.4964 18 12.7252 18C10.7207 18 9.09561 16.2091 9.09561 14C9.09561 11.7909 10.7207 10 12.7252 10C13.4964 10 14.2114 10.265 14.7993 10.717M7.79932 13.1429H13.7623M7.79932 15.1429H13.7623"
        stroke={colors[iconColor]}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BagOfCashIcon;
