import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const MediaIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...rest}>
      <path
        d="m1.492 17.018.998-.056-.998.056Zm17.016 0 .999.055-.999-.055Zm.463-8.333-.998-.056.998.056Zm-17.942 0 .998-.056-.998.056Zm7.406.8.509-.86-.012-.007-.497.867Zm4.46 2.638-.51.86.003.002.506-.862Zm0 1.087-.507-.863-.003.002.51.86Zm-4.46 2.637.497.868.012-.007-.51-.86Zm7.232-10.68a1 1 0 1 0 2 0h-2Zm-13.334 0a1 1 0 1 0 2 0h-2Zm2.024 1h11.286v-2H4.357v2Zm13.616 2.462-.463 8.333 1.997.111.463-8.333-1.997-.11ZM15.18 19.166H4.82v2h10.36v-2ZM2.49 16.962 2.027 8.63.03 8.74l.463 8.333 1.997-.11Zm2.33 2.204a2.333 2.333 0 0 1-2.33-2.204l-1.997.111a4.333 4.333 0 0 0 4.327 4.093v-2Zm12.69-2.204a2.333 2.333 0 0 1-2.33 2.204v2c2.3 0 4.2-1.796 4.327-4.093l-1.997-.11ZM15.643 6.166a2.333 2.333 0 0 1 2.33 2.463l1.997.111a4.333 4.333 0 0 0-4.327-4.574v2Zm-11.286-2A4.333 4.333 0 0 0 .03 8.74l1.997-.11a2.333 2.333 0 0 1 2.33-2.464v-2ZM7.983 15c.136 0 .301.063.416.2.05.059.076.116.088.155a.228.228 0 0 1 .013.064h-2c0 .352.097.744.367 1.066.284.339.69.515 1.116.515v-2Zm.517.42V9.912h-2v5.506h2Zm0-5.507c0 .002 0 .025-.013.064a.468.468 0 0 1-.088.156.558.558 0 0 1-.416.2v-2c-.425 0-.832.176-1.116.515-.27.322-.367.713-.367 1.065h2Zm-.517.42a.384.384 0 0 1-.117-.019l.007.004.064.035.995-1.735c-.196-.112-.517-.285-.949-.285v2Zm-.057.013 4.46 2.638 1.017-1.722-4.46-2.637-1.017 1.721Zm4.462 2.64c.06.035.092.057.106.067.014.01-.005 0-.033-.036a.573.573 0 0 1-.128-.35h2c0-.325-.1-.641-.325-.917a2.22 2.22 0 0 0-.607-.49l-1.013 1.725Zm-.055-.32a.575.575 0 0 1 .128-.351c.028-.035.046-.046.033-.036-.014.01-.046.033-.106.068l1.013 1.725a2.22 2.22 0 0 0 .607-.49c.226-.275.325-.591.325-.916h-2Zm.052-.317-4.46 2.638 1.019 1.721 4.46-2.638-1.019-1.721Zm-4.448 2.63a1.324 1.324 0 0 1-.064.036c-.013.006-.014.006-.007.004A.384.384 0 0 1 7.984 15v2c.431 0 .752-.173.948-.285l-.995-1.735ZM14.167.834H5.833v2h8.334v-2Zm1.5 3.5v.833h2v-.833h-2Zm-13.334 0v.833h2v-.833h-2Zm3.5-3.5a3.5 3.5 0 0 0-3.5 3.5h2a1.5 1.5 0 0 1 1.5-1.5v-2Zm8.334 2a1.5 1.5 0 0 1 1.5 1.5h2a3.5 3.5 0 0 0-3.5-3.5v2Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default MediaIcon;
