import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DocumentIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 10v9a2 2 0 01-2 2H7a2 2 0 01-2-2V5a2 2 0 012-2h5m7 7l-7-7m7 7h-6.5a.5.5 0 01-.5-.5V3m3 10H8m5 4H8m1-8H8"
      />
    </svg>
  );
};

export default DocumentIcon;
