import React, { Ref, useImperativeHandle, forwardRef, useState } from 'react';
import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';

import Modal, { useModal } from 'components/core/Modal';
import Box from 'components/core/Box';
import Text from 'components/core/Text';
import Button from 'components/core/Button';

import useLogout from 'hooks/useLogout';
import ZIndex from 'constants/zIndex';
import { IDLE_SESSION_TERMINATE_TIME } from 'constants/idleSessionTimes';

import { TIdleModalRef } from './IdleModal.types';

function IdleModal(props: unknown, ref: Ref<TIdleModalRef>) {
  const logout = useLogout();
  const {
    start: startIdleTimer,
    reset,
    pause,
  } = useReactIdleTimer({
    timeout: IDLE_SESSION_TERMINATE_TIME,
    onIdle: handleOnIdle,
    debounce: 1000,
    startManually: true,
  });
  const [state, setState] = useState<{ onClickContinue?: () => void }>();

  const modalRef = useModal();

  useImperativeHandle(ref, () => ({
    show,
  }));

  function handleOnIdle() {
    logout('/logged-out');
  }

  function show(onClickContinue: () => void) {
    setState({ onClickContinue });
    reset();
    startIdleTimer();
    modalRef.current?.show(true);
  }

  function handleContinue() {
    pause();
    state?.onClickContinue?.();
    modalRef.current?.show(false);
  }

  return (
    <Modal
      ref={modalRef}
      zIndex={ZIndex.ALERT_MODAL}
      rootId="idle-modal-root"
      maskClosable={false}
      afterClose={() => setState(undefined)}
      alertModal
      hideCloseButton>
      <Box p="m">
        <Text id="modals.idleModal.title" variant="mediumTitleSemiBold" mb="s" />
        <Text id="modals.idleModal.description" mb="xl" />
        <Button id="button.continue" mb="m" onClick={handleContinue} />
        <Button id="button.logout" variant="secondary" onClick={() => logout()} />
      </Box>
    </Modal>
  );
}

export default forwardRef(IdleModal);
