import React, { useMemo, useRef } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import SideLayoutPage from 'components/SideLayoutPage';
import Card from 'components/core/Card';
import { TrashIcon } from 'components/Icons';
import Box from 'components/core/Box';
import InfoItem from 'components/core/InfoItem';
import Button from 'components/core/Button';
import ScheduledTransactionItem from 'components/ScheduledTransactionItem';
import MemberPermission from 'components/MemberPermission';
import PopconfirmModal from 'modals/PopconfirmModal';
import ScheduledTransactionOrderHistorySection from 'components/ScheduledTransactionOrderHistorySection';

import API_ENDPOINT from 'constants/apiEndpoint';
import replaceUrlParams from 'helpers/replaceUrlParams';
import ScheduledTransactionType, {
  getLabelIdByScheduledTransactionType,
} from 'enums/ScheduledTransactionType';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { showErrorAlert } from 'helpers/showAlertModal';
import { useSideLayoutContext } from 'context/SideLayoutProvider';
import date from 'helpers/date';
import MemberType from 'enums/MemberType';
import { TScheduledTransactionDetail } from 'types/scheduledTransaction';
import { TCommonResponse } from 'types/common';

import ScheduledTransactionDetailPageShimmer from './views/ScheduledTransactionDetailPageShimmer';

import { TScheduledTransactionDetailProps } from './ScheduledTransactionDetail.types';

function ScheduledTransactionDetail({
  id,
  onCallback: onRefetchScheduledTransactions,
}: TScheduledTransactionDetailProps) {
  const scheduledTransactionDeleteModalRef = useRef<TPopconfirmModalRef>(null);
  const { sideLayoutDispatch } = useSideLayoutContext();
  const { t } = useTranslation();
  const { data, loading } = useFetch<TCommonResponse<TScheduledTransactionDetail>>(
    replaceUrlParams(API_ENDPOINT.SCHEDULED_SINGLE_TRANSACTION_ORDER_DETAIL, { id }),
    [id]
  );
  const { delete: requestDeleteScheduledTransaction } = useFetch(
    replaceUrlParams(API_ENDPOINT.SCHEDULED_TRANSACTION_ORDER_DETAIL, {
      id,
    })
  );

  const scheduledTransactionDetail = useMemo(() => data?.result, [data]);

  function handleOpenDeleteModal() {
    scheduledTransactionDeleteModalRef.current?.show({
      contentIcon: <TrashIcon width={64} height={64} />,
      titleId: 'modals.deleteTransactionScheduledModal.title',
      confirmButtonProps: {
        id: 'button.delete',
        variant: 'danger',
      },
      onConfirm: handleDeleteScheduledTransaction,
    });
  }

  async function handleDeleteScheduledTransaction() {
    try {
      const { errorMessage } = await requestDeleteScheduledTransaction();

      if (errorMessage) {
        showErrorAlert({ title: errorMessage });
        return;
      }
      onRefetchScheduledTransactions?.();
      sideLayoutDispatch.hideSideLayout();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ title: 'label.unexpectedError' });
    }
  }

  if (!scheduledTransactionDetail || loading) {
    return <ScheduledTransactionDetailPageShimmer />;
  }

  return (
    <>
      <SideLayoutPage headerId="screens.headerTitle.scheduledTransactionDetail">
        <Box paddingX="m">
          <Card mb="m">
            <ScheduledTransactionItem item={scheduledTransactionDetail} />
          </Card>
        </Box>
        <Card marginX="m" paddingX="m">
          <InfoItem titleId="label.fromAccount" value={scheduledTransactionDetail.ledger.name} />
          {!!scheduledTransactionDetail.description && (
            <InfoItem titleId="label.description" value={scheduledTransactionDetail.description} />
          )}
          <InfoItem
            titleId="label.nextPayment"
            value={date.format({
              date: scheduledTransactionDetail.scheduledAt,
              formatType: 'd MMM yyyy',
            })}
          />
          {scheduledTransactionDetail.recurringPeriod !== ScheduledTransactionType.ONLY_ONCE && (
            <InfoItem
              titleId="label.recurringPayment"
              value={t(
                getLabelIdByScheduledTransactionType(scheduledTransactionDetail.recurringPeriod)
              )}
            />
          )}
        </Card>
        <ScheduledTransactionOrderHistorySection scheduledId={scheduledTransactionDetail.id} />
        <MemberPermission allowTo={[MemberType.DIRECTOR]}>
          <Box
            marginTop="l"
            flex="auto"
            alignment="col.bottom.left"
            px="m"
            justifyContent="flex-end"
            marginBottom="m">
            <Button
              id="button.cancelTransfer"
              mb="m"
              variant="danger"
              onClick={handleOpenDeleteModal}
            />
          </Box>
        </MemberPermission>
      </SideLayoutPage>
      <PopconfirmModal ref={scheduledTransactionDeleteModalRef} />
    </>
  );
}

export default ScheduledTransactionDetail;
