import React, { ReactElement, memo } from 'react';

import { WarningIcon } from 'components/Icons';
import Box from 'components/core/Box';
import Text from 'components/core/Text';

import { TFormErrorLabelProps } from './FormErrorLabel.types';

function FormErrorLabel({ labelId, values, ...rest }: TFormErrorLabelProps): ReactElement {
  return (
    <Box alignment="row.top.left" mt="s" ml="s" {...rest}>
      <WarningIcon width={16} height={16} iconColor="error" />
      <Text marginLeft="xs" id={labelId} values={values} variant="xSmallTextBold" color="error" />
    </Box>
  );
}

export default memo(FormErrorLabel);
