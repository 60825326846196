import { TPalette } from './theme.types';

const palette: TPalette = {
  orchid100: '#F1EBFF',
  orchid200: '#D1B8FF',
  orchid300: '#A97AFE',
  orchid400: '#823DFE',
  orchid500: '#5A00FE',
  orchid600: '#4400C1',
  orchid700: '#2F0084',
  orchid800: '#190047',
  orchid900: '#140033',

  turquoise100: '#F6FFFD',
  turquoise200: '#C2FFEF',
  turquoise300: '#8DFFE1',
  turquoise400: '#59FFD3',
  turquoise500: '#24FFC5',
  turquoise600: '#1BC296',
  turquoise700: '#138566',
  turquoise800: '#0A4737',
  turquoise900: '#03170D',

  moon100: '#FEFEFE',
  moon200: '#F4F5F8',
  moon300: '#E8E9ED',
  moon400: '#D0D3DA',
  moon500: '#9DA0A7',
  moon600: '#80838C',
  moon700: '#4D4F57',
  moon800: '#252529',
  moon900: '#1A1A1D',

  sky100: '#EFF5FC',
  sky200: '#B3D3F6',
  sky300: '#78B0F0',
  sky400: '#3D8DEA',
  sky500: '#0070D9',
  sky600: '#0A68B9',
  sky700: '#084D8C',
  sky800: '#002C74',
  sky900: '#001657',

  spring100: '#E9F1E9',
  spring200: '#C4D9C4',
  spring300: '#9FC19F',
  spring400: '#7AA979',
  spring500: '#2E7D32',
  spring600: '#296F2C',
  spring700: '#235F25',
  spring800: '#1E501F',
  spring900: '#173A16',

  sunburst100: '#FEF9F5',
  sunburst200: '#FADAB5',
  sunburst300: '#F5BB75',
  sunburst400: '#F09B35',
  sunburst500: '#E07400',
  sunburst600: '#8D3C00',
  sunburst700: '#6B2E00',
  sunburst800: '#492000',
  sunburst900: '#2C1700',

  fire100: '#FCE8E8',
  fire200: '#F9B9B9',
  fire300: '#F58A8A',
  fire400: '#F05B5B',
  fire500: '#E53935',
  fire600: '#C5302C',
  fire700: '#A62823',
  fire800: '#861F1A',
  fire900: '#671612',

  sand100: '#FDFCFA',
  sand200: '#FBF8F2',
  sand300: '#F8F4EB',
  sand400: '#F6F0E4',
  sand500: '#F3ECDD',
  sand600: '#C0BAAF',
  sand700: '#8D8980',
  sand800: '#5A5752',
  sand900: '#272623',

  rose100: '#FDF7F7',
  rose200: '#FAEEED',
  rose300: '#F7E4E3',
  rose400: '#F5DAD8',
  rose500: '#F2D0CE',
  rose600: '#BFA4A3',
  rose700: '#8C7977',
  rose800: '#5A4D4C',
  rose900: '#272121',

  flagYellow: '#FFD700',
  flagLightBlue: '#007FFF',
  flagPurple: '#6F4E7C',
  flagRed: '#C8102E',
  flagOrange: '#FF6600',
  flagGreen: '#008000',

  white: '#FFFFFF',
  silver: '#CFCFCF',
  transparent: 'transparent',
};

export default palette;
