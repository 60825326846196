import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFetch } from 'use-http';

import { TMeResponse } from 'types/member';
import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';

import { INITIAL_DISPATCH, INITIAL_STATE } from './MemberProvider.constants';

import { TMemberState, TMemberContext } from './MemberProvider.types';

const memberContext = createContext<TMemberContext>({
  memberState: INITIAL_STATE,
  memberDispatch: INITIAL_DISPATCH,
});

function MemberProvider({ children }: TChildrenOnly): ReactElement {
  const { get: requestMe, data } = useFetch<TCommonResponse<TMeResponse>>(API_ENDPOINT.MEMBERS_ME);
  const [state, setState] = useState<TMemberState>(INITIAL_STATE);

  useEffect(() => {
    if (data?.result) {
      setState(data.result);
    }
  }, [data]);

  const setMember = useCallback((data = {}) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  }, []);

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = useMemo(
    (): TMemberContext => ({
      memberState: state,
      memberDispatch: { setMember, getRequestMe: requestMe, clear },
    }),
    [state, setMember]
  );

  return <memberContext.Provider value={value}>{children}</memberContext.Provider>;
}

export default MemberProvider;

export const useMemberContext = (): TMemberContext => useContext(memberContext);
