import React, { createContext, ReactElement, useContext, useState, useEffect } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';

import { INITIAL_DISPATCH, INITIAL_STATE } from './MerchantProvider.constants';
import { TMerchantState, TMerchantContext, TMerchant } from './MerchantProvider.types';

const merchantContext = createContext<TMerchantContext>({
  merchantState: INITIAL_STATE,
  merchantDispatch: INITIAL_DISPATCH,
});

function MerchantProvider({ children }: TChildrenOnly): ReactElement {
  const { get: requestGetMerchant, data: merchantData } = useFetch<TCommonResponse<TMerchant>>(
    API_ENDPOINT.MERCHANT_CURRENT
  );
  const [state, setState] = useState<TMerchantState>(INITIAL_STATE);

  useEffect(() => {
    if (merchantData?.result) {
      setMerchant(merchantData.result);
    }
  }, [merchantData]);

  function setMerchant(merchant: TMerchant) {
    setState((state) => ({ ...state, merchant }));
  }

  function handleClearState() {
    setState(INITIAL_STATE);
  }

  const value = {
    merchantState: state,
    merchantDispatch: {
      getMerchant: requestGetMerchant,
      setMerchant,
      clearState: handleClearState,
    },
  };

  return <merchantContext.Provider value={value}>{children}</merchantContext.Provider>;
}

export default MerchantProvider;

export const useMerchantContext = (): TMerchantContext => useContext(merchantContext);
