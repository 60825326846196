import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/core/Box';

import { TCurrencyWrapperProps } from '../CurrencyPairs.types';

function CurrencyWrapper({ iconSize, icon: Icon, ...rest }: TCurrencyWrapperProps) {
  const { colors } = useTheme();
  return (
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={`1px solid ${colors.borderOutline}`}
      borderRadius={iconSize}
      width={iconSize}
      height={iconSize}
      {...rest}>
      <Icon width={iconSize} height={iconSize} />
    </Box>
  );
}

export default CurrencyWrapper;
