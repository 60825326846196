import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import Box from 'components/core/Box';
import SwitchButton from 'components/core/SwitchButton';
import FormErrorLabel from 'components/FormErrorLabel';

import { TControllerRenderProps } from 'types/form';
import { TI18nId } from 'types/common';

import { TFieldSwitchButtonProps } from './FieldSwitchButton.types';

function FieldSwitchButton({
  name,
  labelId,
  control,
  errors,
  disabled,
  ...rest
}: TFieldSwitchButtonProps): ReactElement {
  const errorMessageId = errors[name]?.message;

  function handleRender({ field: { onChange, value, ref } }: TControllerRenderProps): ReactElement {
    return (
      <SwitchButton
        checked={value}
        ref={ref}
        labelId={labelId}
        name={name}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <Box>
      <Controller name={name} control={control} render={handleRender} />
      {errorMessageId && <FormErrorLabel labelId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldSwitchButton;
