import React, { createElement } from 'react';
import { isNil } from 'lodash/fp';
import { Navigate, Outlet, Route } from 'react-router-dom';

import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';
import MemberType from 'enums/MemberType';
import MemberStatus from 'enums/MemberStatus';
import routes from 'router/routes';
import storage, { STORAGE_KEYS } from 'helpers/storage';

import {
  TFindOnboardingNavigationPathParams,
  THandleRenderOnboardingLayoutParams,
  TMapOnboardingRoutesParams,
} from './Onboarding.types';

export function findOnboardingNavigationPath({
  merchant,
  redirectTo,
}: TFindOnboardingNavigationPathParams) {
  if (isNil(merchant?.onboardingStatus)) {
    const onboardingMerchantType = storage.readStorage(STORAGE_KEYS.ONBOARDING_MERCHANT_TYPE);

    if (isNil(onboardingMerchantType)) {
      return routes.onboardingBusinessType.path;
    }

    return routes.onboardingApplicationSummary.path;
  }

  switch (merchant?.onboardingStatus) {
    case MerchantOnboardingStatus.IN_PROGRESS:
    case MerchantOnboardingStatus.APPROVING:
    case MerchantOnboardingStatus.MANUAL_REVIEW:
      return routes.onboardingApplicationSummary.path;
    case MerchantOnboardingStatus.REJECTED:
      return routes.onboardingApplicationReject.path;
    case MerchantOnboardingStatus.LIVE:
      return redirectTo ?? routes.home.path;
    default:
      return routes.home.path;
  }
}

export function handleRenderOnboardingLayout({
  memberState,
  merchantState,
  featureFlagsState,
}: THandleRenderOnboardingLayoutParams) {
  if (merchantState.merchant?.onboardingStatus === MerchantOnboardingStatus.LIVE) {
    return <Navigate to={routes.home.path} replace />;
  }
  if (!memberState?.id || memberState.status !== MemberStatus.ACTIVE) {
    return <Navigate to={routes.login.path} replace />;
  }
  const isOnboardingEnabled =
    featureFlagsState.ONBOARDING_COMPANY || featureFlagsState.ONBOARDING_SOLE_TRADER;

  if (memberState.memberType === MemberType.DIRECTOR && !isOnboardingEnabled) {
    return <Navigate to={routes.waitlist.path} replace />;
  }
  if (memberState.memberType === MemberType.DIRECTOR) {
    return <Outlet />;
  }
  return <Navigate to={routes.home.path} replace />;
}

export function mapOnboardingRoutes({ routes }: TMapOnboardingRoutesParams) {
  return Object.entries(routes).map(([key, { path, element: Element }]) => (
    <Route key={key} path={path} element={createElement(Element)} />
  ));
}
