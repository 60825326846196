import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ClothingStoresIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M12 11.5L3.18772 14.9721C2.46547 15.2931 2 16.0094 2 16.7997V18H22V16.7997C22 16.0094 21.5345 15.2931 20.8123 14.9721L12 11.5ZM12 11.5V9.5C10.8954 9.5 10 8.60457 10 7.5C10 6.39543 10.8954 5.5 12 5.5C13.1046 5.5 14 6.39543 14 7.5"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClothingStoresIcon;
