import React from 'react';

import borderRadius from 'theme/borderRadius';

import { TCardProps } from './Card.types';
import { StyledBox } from './Card.styles';

function Card({ children, ...rest }: TCardProps) {
  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      backgroundColor="cardBackground"
      borderRadius={borderRadius.m}
      {...rest}>
      {children}
    </StyledBox>
  );
}

export default Card;
