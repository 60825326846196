import React, { ReactElement } from 'react';

import Modal from 'components/core/Modal';
import Page from 'components/Page';

import DashboardLayout from 'layouts/DashboardLayout';
import { NavMenu } from 'layouts/DashboardLayout/DashboardLayout.types';
import { TPageModalShimmerProps } from '../PageModal.types';

function PageModalShimmer({ modalRef, children }: TPageModalShimmerProps): ReactElement {
  return (
    <Modal ref={modalRef} isFullPage secondary>
      <DashboardLayout navMenu={NavMenu.HOME} isPageModal>
        <Page.Shimmer navButton={{ onClick: () => modalRef.current?.show(false) }}>
          {children}
        </Page.Shimmer>
      </DashboardLayout>
    </Modal>
  );
}

export default PageModalShimmer;
