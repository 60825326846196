import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DocumentAddAltIcon = ({
  iconColor = 'primary',
  strokeWidth = 2,
  ...rest
}: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M19 10v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5m7 7-7-7m7 7h-6.5a.5.5 0 0 1-.5-.5V3M12 15h2m-2 0h-2m2 0v2m0-2v-2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default DocumentAddAltIcon;
