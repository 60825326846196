/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, Ref, useImperativeHandle, useState } from 'react';
import Lottie from 'react-lottie';

import Modal, { useModal } from 'components/core/Modal';
import Text from 'components/core/Text';
import Box from 'components/core/Box';

import ZIndex from 'constants/zIndex';
import spacing from 'theme/spacing';
import { TAlertModalRef, TAlertModalShowOptions, TAlertModalType } from './AlertModal.types';
import {
  ALERT_MODAL_ID,
  ALERT_MODAL_LOTTIE,
  INITIAL_STATE,
  SUCCESS_LOTTIE_OPTIONS,
} from './AlertModal.constants';

// TODO: (melih) fix spacing
function AlertModal(props: any, ref: Ref<TAlertModalRef>): ReactElement {
  const [
    {
      title,
      titleValues,
      titleId,
      text,
      textValues,
      textId,
      type = TAlertModalType.error,
      bottomContent: BottomContent,
      disableOnClose,
      showCancelButton,
      onCancel,
    },
    setModalOptions,
  ] = useState<TAlertModalShowOptions>(INITIAL_STATE);
  const modalRef = useModal();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  function show(options: TAlertModalShowOptions) {
    modalRef.current?.show(true);
    setModalOptions({ ...INITIAL_STATE, ...options });
  }

  function hide() {
    modalRef.current?.show(false);
    setModalOptions({ ...INITIAL_STATE });
  }

  return (
    <>
      <Modal
        ref={modalRef}
        zIndex={ZIndex.ALERT_MODAL}
        rootId={ALERT_MODAL_ID}
        hideCloseButton={!showCancelButton}
        maskClosable={!disableOnClose}
        afterClose={onCancel}
        alertModal>
        <Box alignment="center">
          <Box width={96} height={96} marginBottom="m">
            <Lottie
              style={{ marginBottom: spacing.m }}
              options={ALERT_MODAL_LOTTIE[type] || SUCCESS_LOTTIE_OPTIONS}
            />
          </Box>
          <Text
            marginBottom={text || textId ? 'm' : 'none'}
            values={titleValues}
            id={titleId}
            variant="smallTitleBold"
            textAlign="center">
            {title}
          </Text>
          <Text marginBottom="s" values={textValues} id={textId}>
            {text}
          </Text>
        </Box>
        {BottomContent && <BottomContent show={show} hide={hide} />}
      </Modal>
      <div id={ALERT_MODAL_ID} />
    </>
  );
}

export default React.forwardRef<TAlertModalRef>(AlertModal);
