import { isNil } from 'lodash/fp';

import { TFormatAddressParams } from './formatAddress.types';

function formatAddress({ street, city, postCode, country, number }: TFormatAddressParams): string {
  const addressFields = [street, number, postCode, city, country?.name];
  return addressFields.filter((field) => !isNil(field)).join(' ');
}

export default formatAddress;
