import React, { createContext, ReactElement, useContext, useState } from 'react';

import { TChildrenOnly } from 'types/common';
import { TAddressFormData } from 'components/Form/AddressForm/AddressForm.types';

import {
  TIdentityDocumentParams,
  TBusinessOwnerCreateContext,
  TBusinessOwnerCreateState,
  TDirectorPersonalInfo,
  TShareholderPersonalInfo,
} from './BusinessOwnerCreateProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './BusinessOwnerCreateProvider.constants';
import {
  useAddDirectorRequest,
  useAddShareholderRequest,
} from './BusinessOwnerCreateProvider.hooks';

const businessOwnerCreateContext = createContext<TBusinessOwnerCreateContext>({
  businessOwnerCreateState: INITIAL_STATE,
  businessOwnerCreateDispatch: INITIAL_DISPATCH,
});

function BusinessOwnerCreateProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TBusinessOwnerCreateState>(INITIAL_STATE);
  const { handleAddDirector } = useAddDirectorRequest(state);
  const { handleAddShareholder } = useAddShareholderRequest(state);

  function setDirectorPersonalInformation(personalInformation: TDirectorPersonalInfo) {
    clear();
    setState((prevState) => ({
      ...prevState,
      personalInformation,
    }));
  }

  function setCreateDirectorFromMember(shouldCreateDirectorFromMember: boolean) {
    clear();
    setState((prevState) => ({
      ...prevState,
      shouldCreateDirectorFromMember,
    }));
  }

  function setShareholderPersonalInformation(personalInformation: TShareholderPersonalInfo) {
    clear();
    setState((prevState) => ({
      ...prevState,
      personalInformation,
    }));
  }

  function setAddressInformation(addressInformation: TAddressFormData) {
    setState((prevState) => ({
      ...prevState,
      addressInformation,
    }));
  }

  function setAddressDocument(document: File) {
    setState((prevState) => ({
      ...prevState,
      addressDocument: document,
    }));
  }

  function removeAddressDocument() {
    setState((prevState) => ({
      ...prevState,
      addressDocument: undefined,
    }));
  }

  function setIdentityDocumentFront(document: TIdentityDocumentParams) {
    setState((prevState) => ({
      ...prevState,
      identityDocumentFront: document,
    }));
  }

  function setIdentityDocumentBack(document: TIdentityDocumentParams) {
    setState((prevState) => ({
      ...prevState,
      identityDocumentBack: document,
    }));
  }

  function removeIdentityDocumentFront() {
    setState((prevState) => ({
      ...prevState,
      identityDocumentFront: undefined,
    }));
  }

  function removeIdentityDocumentBack() {
    setState((prevState) => ({
      ...prevState,
      identityDocumentBack: undefined,
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TBusinessOwnerCreateContext = {
    businessOwnerCreateState: state,
    businessOwnerCreateDispatch: {
      setDirectorPersonalInformation,
      setCreateDirectorFromMember,
      setShareholderPersonalInformation,
      setAddressInformation,
      setAddressDocument,
      removeAddressDocument,
      setIdentityDocumentFront,
      removeIdentityDocumentFront,
      setIdentityDocumentBack,
      removeIdentityDocumentBack,
      clear,
      createDirector: handleAddDirector,
      createShareholder: handleAddShareholder,
    },
  };

  return (
    <businessOwnerCreateContext.Provider value={value}>
      {children}
    </businessOwnerCreateContext.Provider>
  );
}

export default BusinessOwnerCreateProvider;

export const useBusinessOwnerCreateContext = (): TBusinessOwnerCreateContext =>
  useContext(businessOwnerCreateContext);
