const ERROR_CODES = {
  MERCHANT_FROZEN: '040002',
  ACTIVE_APPLICATION_NOT_FOUND: '020002',
  OTP_VERIFICATION_REQUIRED: '000004',
  PASSWORD_REQUIRED: '000006',
  MOBILE_VERIFICATION_REQUIRED: '030007',
  ACTIVE_CASHBACK_CAMPAIGN_NOT_FOUND: '000005',
  PAY_LATER_LIMIT_REACHED: '060017',
  INSUFFICIENT_BALANCE: '060002',
  CAPTCHA_REQUIRED: '010062',
  XERO_CONNECTION_NOT_FOUND: '130001',
  FX_QUOTE_EXPIRED: '160005',
  FX_QUOTE_CHANGED: '160006',
  FX_NOT_AVAILABLE: '160011',
  CARD_PIN_NOT_ACTIVE: 'PIN_NOT_AVAILABLE_BEFORE_PRINTING',
};

export default ERROR_CODES;
