import React from 'react';

import { TIconProps } from '../Icon.types';

const NokIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#C8102E"
        d="M24.944 48.043c13.255 0 24-10.745 24-24s-10.745-24-24-24c-13.254 0-24 10.745-24 24s10.745 24 24 24Z"
      />
      <path
        fill="#FEFEFE"
        fillRule="evenodd"
        d="M23.86.023C11.107.59.944 11.108.944 24c0 13.255 10.745 24 24 24s24-10.745 24-24c0-6.987-2.986-13.277-7.752-17.663a23.995 23.995 0 0 1 6.925 11.402h-25.26V.09c.332-.029.667-.051 1.003-.067ZM10.336 43.04A24.02 24.02 0 0 1 1.77 30.26h8.564v12.78ZM24.944 48c-.703 0-1.399-.033-2.087-.092V30.259h25.26C45.363 40.477 36.033 48 24.944 48ZM1.771 17.737a24.02 24.02 0 0 1 8.564-12.78v12.78H1.771Z"
        clipRule="evenodd"
      />
      <path
        fill="#1A2A6C"
        d="M48.741 20.869H19.727V.569a23.838 23.838 0 0 0-6.261 2.349v17.95H1.147a24.207 24.207 0 0 0 0 6.262h12.319V45.08a23.838 23.838 0 0 0 6.26 2.348V27.13h29.015a24.24 24.24 0 0 0 0-6.261Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.944 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default NokIcon;
