import styled from 'styled-components';
import { motion } from 'framer-motion';

import Button from 'components/core/Button';
import Box from 'components/core/Box';
import StickyHeader from 'components/StickyHeader';

import spacing from 'theme/spacing';
import { getColor } from 'helpers/theme';

export const BaseButton = styled(Button)`
  width: 2rem;
  height: 2rem;
  position: relative;

  svg {
    width: auto;
    height: auto;
  }
`;

export const NavBar = styled(Box)`
  position: sticky;
  height: 2.75rem;
  align-items: center;
  display: grid;
  grid-template-areas: 'nav-button title actions';
  grid-template-columns: 1fr minmax(0, auto) 1fr;

  .right-button {
    justify-self: end;
    grid-area: actions / actions / actions / actions;
  }
`;

export const NavButton = styled(BaseButton)`
  justify-content: flex-start;
  grid-area: nav-button / nav-button / nav-button / nav-button;
  margin-right: ${spacing.s};

  svg {
    width: 32px;
    height: 32px;
  }
  svg > path {
    stroke: ${getColor('stickyHeaderButtonIcon')};
  }
`;

export const TitleWrapper = styled(motion.div)`
  grid-area: title / title / title / title;
`;

export const StyledStickyHeader = styled(StickyHeader)`
  margin: 0;
`;

export const LargeTextContainer = styled(Box)`
  white-space: pre-line;
`;
