import React, { createRef, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { debounce } from 'lodash/fp';

import { useSideLayoutContext } from 'context/SideLayoutProvider';

import {
  StyledASide,
  StyledSideMenuBox,
  StyledSideMenuWrapper,
  StyledDialog,
} from './SideLayout.styles';
import { SideLayoutComponentPath, SideLayoutComponents } from './SideLayout.constants';

function SideLayout() {
  const location = useLocation();
  const elementTargetRef = createRef<HTMLElement>();
  const prevLocationPath = useRef<string>(location.pathname);

  const { sideLayoutState, sideLayoutDispatch } = useSideLayoutContext();
  const Component = SideLayoutComponents[sideLayoutState?.path as SideLayoutComponentPath];

  useEffect(() => {
    if (prevLocationPath.current !== location.pathname) {
      sideLayoutDispatch.hideSideLayout();
      prevLocationPath.current = location.pathname;
    }
  }, [location.pathname]);

  useEffect(() => {
    const recalculateBodyScroll = debounce(500, () => {
      handleBodyScroll();
    });

    window.addEventListener('resize', recalculateBodyScroll);
    return () => {
      window.removeEventListener('resize', recalculateBodyScroll);
    };
  }, []);

  useEffect(() => {
    handleBodyScroll();
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [sideLayoutState?.path]);

  function handleBodyScroll() {
    if (sideLayoutState?.path && !!elementTargetRef.current && window.innerWidth < 1024) {
      disableBodyScroll(elementTargetRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }

  return (
    <StyledASide ref={elementTargetRef}>
      <StyledSideMenuWrapper aria-hidden={!sideLayoutState.path}>
        <AnimatePresence>
          {!!Component && (
            <StyledSideMenuBox
              initial={{ opacity: 0, x: '5rem' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '1rem' }}>
              <StyledDialog>
                <Component {...sideLayoutState?.params} onCallback={sideLayoutState.onCallback} />
              </StyledDialog>
            </StyledSideMenuBox>
          )}
        </AnimatePresence>
      </StyledSideMenuWrapper>
    </StyledASide>
  );
}

export default SideLayout;
