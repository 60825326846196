export const DATE_MASK_SEPARATOR = '/';
export const EXPIRY_DATE_SEPARATOR = '/';

export const MASKS = {
  BIRTHDATE: `[09]{${DATE_MASK_SEPARATOR}}[00]{${DATE_MASK_SEPARATOR}}[0000]`,
  CARD_NUMBER: '[0000] [0000] [0000] [0000]',
  EXPIRY_DATE: `[00]{${EXPIRY_DATE_SEPARATOR}[00]`,
  SORT_CODE: '[99]-[99]-[99]',
  IBAN: '[____] [____] [____] [____] [____] [____] [____] [____] [__]',
  FILTER_AMOUNT: '[999999990]{.}[09]',
};
