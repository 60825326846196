import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CardRentalIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M3.23822 9.71429L5.01274 4.39073C5.09052 4.15739 5.30889 4 5.55485 4H18.4454C18.6914 4 18.9097 4.15739 18.9875 4.39073L20.762 9.71429M3.23822 9.71429L2.85727 10.8571V19.7714C2.85727 19.8977 2.9596 20 3.08584 20H3.77155C3.89779 20 4.00013 19.8977 4.00013 19.7714V17.7143H20.0001V19.7714C20.0001 19.8977 20.1025 20 20.2287 20H20.9144C21.0406 20 21.143 19.8977 21.143 19.7714V10.8571L20.762 9.71429M3.23822 9.71429H20.762"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
      <path
        d="M8.57112 13.7143C8.57112 14.6611 7.80361 15.4286 6.85683 15.4286C5.91006 15.4286 5.14255 14.6611 5.14255 13.7143C5.14255 12.7675 5.91006 12 6.85683 12C7.80361 12 8.57112 12.7675 8.57112 13.7143Z"
        fill={colors[iconColor]}
      />
      <path
        d="M18.8568 13.7143C18.8568 14.6611 18.0893 15.4286 17.1425 15.4286C16.1958 15.4286 15.4283 14.6611 15.4283 13.7143C15.4283 12.7675 16.1958 12 17.1425 12C18.0893 12 18.8568 12.7675 18.8568 13.7143Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default CardRentalIcon;
