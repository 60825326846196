import NOOP from 'constants/noop';

import { TMemberState, TMemberDispatch } from './MemberProvider.types';

export const INITIAL_STATE: TMemberState = undefined;

export const INITIAL_DISPATCH: TMemberDispatch = {
  setMember: NOOP,
  getRequestMe: NOOP as TMemberDispatch['getRequestMe'],
  clear: NOOP,
};
