import { useFetch } from 'use-http';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import API_ENDPOINT from 'constants/apiEndpoint';
import usePollingFetch from 'hooks/usePollingFetch';
import { TCommonResponse, TPageResponse } from 'types/common';
import { useMemberContext } from 'context/MemberProvider';
import { THomeUpcomingTransfer, THomeUseLocation } from 'pages/Main/Home/Home.types';
import { TCurrentCashbackResponse } from 'types/cashback';
import { TBeneficiary } from 'types/beneficiary';
import MemberType from 'enums/MemberType';
import { TScheduledTransaction } from 'types/scheduledTransaction';

import { TUseHomeRequestsReturn } from './HomeProvider.types';

function useHomeRequests(): TUseHomeRequestsReturn {
  const { search } = useLocation() as THomeUseLocation;
  const { memberState } = useMemberContext();

  const selectedLedgerId = queryString.parse(search)?.ledgerId as string;
  const ledgerQuery = selectedLedgerId ? `&ledgerId=${selectedLedgerId}` : '';
  const transactionSize = memberState?.memberType === MemberType.EMPLOYEE ? 5 : 2;

  const {
    loading: homeActivitiesLoading,
    data: homeActivitiesData,
    reFetch: fetchHomeActivities,
    stopPolling: homeActivitiesStopPolling,
  } = usePollingFetch({
    apiUrl: API_ENDPOINT.HOME_ACTIVITIES,
    auto: false,
    fetchOnMount: false,
    query: `?page=0&size=2${ledgerQuery}`,
    args: [selectedLedgerId],
  });
  const {
    loading: transactionsLoading,
    data: transactionsData,
    reFetch: fetchTransactions,
    stopPolling: transactionsStopPolling,
  } = usePollingFetch({
    apiUrl: API_ENDPOINT.TRANSACTIONS,
    auto: false,
    fetchOnMount: false,
    query: `?page=0&size=${transactionSize}${ledgerQuery}`,
    args: [selectedLedgerId],
  });
  const {
    data: totalDebtsData,
    loading: totalDebtsLoading,
    stopPolling: debtsStopPolling,
    reFetch: fetchTotalDebts,
  } = usePollingFetch({
    apiUrl: API_ENDPOINT.TOTAL_DEBTS,
    args: [],
    auto: false,
    fetchOnMount: false,
  });
  const {
    loading: pendingTopUpPayoutsLoading,
    data: payoutsTopUpsData,
    reFetch: fetchPayoutsTopUps,
    stopPolling: payoutsTopUpsStopPolling,
  } = usePollingFetch({
    apiUrl: API_ENDPOINT.PAYOUTS_TOP_UPS,
    pollInterval: 30 * 1000,
    auto: false,
    fetchOnMount: false,
  });
  const {
    data: upcomingTransfersData,
    get: fetchUpcomingTransfers,
    loading: upcomingTransferLoading,
  } = useFetch<TCommonResponse<THomeUpcomingTransfer[]>>(API_ENDPOINT.DASHBOARD_UPCOMING);
  const {
    data: scheduledTransfersData,
    get: fetchScheduledTransfers,
    loading: scheduledTransfersLoading,
  } = useFetch<TPageResponse<TScheduledTransaction>>(
    `${API_ENDPOINT.SCHEDULED_TRANSACTION_ORDERS}?page=0&size=1`
  );
  const {
    data: cashbackData,
    get: fetchCashback,
    loading: cashbackLoading,
  } = useFetch<TCommonResponse<TCurrentCashbackResponse>>(API_ENDPOINT.CASHBACK_CURRENT);
  const {
    data: homeRecipientsData,
    get: fetchHomeRecipients,
    loading: recipientsLoading,
  } = useFetch<TPageResponse<TBeneficiary>>(`${API_ENDPOINT.BENEFICIARIES}?page=0&size=10`);

  const transactions = transactionsData?.result?.content ?? [];
  const totalBalance = homeActivitiesData?.result?.totalBalance ?? 0;
  const balanceCurrency = homeActivitiesData?.result?.currency;

  const totalDebts = totalDebtsData?.result ?? [];

  const upcomingTransfers = upcomingTransfersData?.result ?? [];

  const scheduledTransfers = scheduledTransfersData?.result?.content ?? [];

  const recipients = homeRecipientsData?.result?.content ?? [];

  const pendingTopUpPayouts = payoutsTopUpsData?.result?.pendingTopUpPayouts ?? [];

  function stopHomePollingRequests() {
    homeActivitiesStopPolling();
    debtsStopPolling();
    transactionsStopPolling();
    payoutsTopUpsStopPolling();
  }

  return {
    homeActivitiesLoading,
    transactions,
    totalBalance,
    balanceCurrency,
    fetchHomeActivities,
    transactionsLoading,
    fetchTransactions,
    totalDebts,
    totalDebtsLoading,
    upcomingTransfers,
    upcomingTransferLoading,
    scheduledTransfers,
    scheduledTransfersLoading,
    fetchUpcomingTransfers,
    fetchScheduledTransfers,
    recipients,
    recipientsLoading,
    fetchHomeRecipients,
    cashback: cashbackData?.result,
    cashbackLoading,
    fetchCashback,
    pendingTopUpPayoutsLoading,
    pendingTopUpPayouts,
    fetchPayoutsTopUps,
    fetchTotalDebts,
    stopHomePollingRequests,
  };
}

export { useHomeRequests };
