import NOOP from 'constants/noop';
import THEME from 'constants/theme';
import { TThemeState, TThemeDispatch } from './ThemeProvider.types';

export const INITIAL_STATE: TThemeState = {
  theme: THEME.LIGHT,
};

export const INITIAL_DISPATCH: TThemeDispatch = {
  changeTheme: NOOP,
};
