import styled from 'styled-components';

import { getColor } from 'helpers/theme';

import { AVATAR_BORDER_WIDTH } from './Avatar.constants';

const StyledImage = styled.img<{ size: number; borderRadius: number; imageResizeMode?: string }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.borderRadius}px;
  border-width: ${AVATAR_BORDER_WIDTH}px;
  border-style: solid;
  border-color: ${getColor('borderOutline')};
  object-fit: ${(props) => props.imageResizeMode ?? 'contain'};
`;

export default StyledImage;
