import React, { createContext, ReactElement, useContext } from 'react';

import { useHomeRequests } from 'context/HomeProvider/HomeProvider.hooks';
import { useMerchantFeatureFlagContext } from 'context/MerchantFeatureFlagProvider';
import { TChildrenOnly } from 'types/common';
import { useMemberContext } from 'context/MemberProvider';
import MemberType from 'enums/MemberType';

import { INITIAL_DISPATCH, INITIAL_STATE } from './HomeProvider.constants';
import { THomeContext } from './HomeProvider.types';

const homeContext = createContext<THomeContext>({
  homeState: INITIAL_STATE,
  homeDispatch: INITIAL_DISPATCH,
});

function HomeProvider({ children }: TChildrenOnly): ReactElement {
  const { merchantFeatureFlagState } = useMerchantFeatureFlagContext();
  const { memberState } = useMemberContext();
  const {
    homeActivitiesLoading,
    transactions,
    transactionsLoading,
    totalBalance,
    balanceCurrency,
    totalDebts,
    totalDebtsLoading,
    upcomingTransfers,
    upcomingTransferLoading,
    scheduledTransfers,
    scheduledTransfersLoading,
    recipients,
    recipientsLoading,
    cashback,
    cashbackLoading,
    pendingTopUpPayouts,
    pendingTopUpPayoutsLoading,
    fetchHomeActivities,
    fetchUpcomingTransfers,
    fetchScheduledTransfers,
    fetchHomeRecipients,
    fetchCashback,
    fetchTransactions,
    fetchPayoutsTopUps,
    stopHomePollingRequests,
    fetchTotalDebts,
  } = useHomeRequests();

  async function fetchHomeRequests() {
    if (memberState?.memberType === MemberType.EMPLOYEE) {
      if (memberState?.showBalance) {
        await fetchHomeActivities();
      }
      await fetchTransactions();
      return;
    }
    if (merchantFeatureFlagState.showCashback) {
      fetchCashback();
    }
    fetchHomeActivities();
    await Promise.all([
      fetchTransactions(),
      fetchUpcomingTransfers(),
      fetchScheduledTransfers(),
      fetchPayoutsTopUps(),
      fetchHomeRecipients(),
      fetchTotalDebts(),
    ]);
  }

  return (
    <homeContext.Provider
      value={{
        homeState: {
          homeActivitiesLoading,
          transactions,
          transactionsLoading,
          totalBalance,
          balanceCurrency,
          totalDebts,
          totalDebtsLoading,
          upcomingTransfers,
          upcomingTransferLoading,
          scheduledTransfers,
          scheduledTransfersLoading,
          cashback,
          cashbackLoading,
          recipients,
          recipientsLoading,
          pendingTopUpPayouts,
          pendingTopUpPayoutsLoading,
        },
        homeDispatch: {
          fetchHomeRequests,
          fetchHomeActivities,
          fetchTransactions,
          fetchUpcomingTransfers,
          fetchScheduledTransfers,
          fetchCashback,
          fetchPayoutsTopUps,
          fetchHomeRecipients,
          stopHomePollingRequests,
          fetchTotalDebts,
        },
      }}>
      {children}
    </homeContext.Provider>
  );
}

export default HomeProvider;

export const useHomeContext = (): THomeContext => useContext(homeContext);
