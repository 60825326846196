import React, { createContext, ReactElement, useContext, useMemo } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';

import { TActiveCampaignContext } from './ActiveCampaignProvider.types';
import { INITIAL_STATE } from './ActiveCampaignProvider.constants';

const activeCampaignContext = createContext<TActiveCampaignContext>({
  activeCampaignState: INITIAL_STATE,
});

function ActiveCampaignProvider({ children }: TChildrenOnly): ReactElement {
  const { data, loading } = useFetch(API_ENDPOINT.ACTIVE_CAMPAIGNS_CASHBACK, []);
  const activeCampaign = useMemo(() => data?.result, [data]);

  return (
    <activeCampaignContext.Provider
      value={{
        activeCampaignState: { activeCampaign, loading },
      }}>
      {children}
    </activeCampaignContext.Provider>
  );
}

export default ActiveCampaignProvider;

export const useActiveCampaignContext = (): TActiveCampaignContext =>
  useContext(activeCampaignContext);
