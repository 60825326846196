import React, { useImperativeHandle, useRef, useState } from 'react';

import PageModal from 'components/PageModal';
import PasswordForm from 'components/Form/PasswordForm';
import CardViewPinModal from 'modals/CardViewPinModal';
import CardItem from 'components/v2/CardItem';
import Box from 'components/core/Box';
import Button from 'components/v2/core/Button';
import Text from 'components/core/Text';

import { useModal } from 'components/core/Modal';
import {
  TPasswordFormData,
  TPasswordFormRef,
} from 'components/Form/PasswordForm/PasswordForm.types';
import { TCardViewPinModalRef } from 'modals/CardViewPinModal/CardViewPinModal.types';
import useCardPin from 'hooks/useCardPin';
import { TCard } from 'types/card';
import { showErrorAlert } from 'helpers/showAlertModal';
import intercom from 'helpers/intercom';
import errorCodes from 'constants/errorCodes';
import { TI18nId } from 'types/common';

import {
  TCardViewPinAuthenticationModalProps,
  TModalState,
} from './CardViewPinAuthenticationModal.types';

function CardViewPinAuthenticationModal({ modalRef }: TCardViewPinAuthenticationModalProps) {
  const ref = useModal();
  const cardViewPinModalRef = useRef<TCardViewPinModalRef>(null);
  const [state, setState] = useState<TModalState>();
  const formRef = useRef<TPasswordFormRef>(null);
  const { getContent, loading } = useCardPin(state?.card as TCard);

  useImperativeHandle(
    modalRef,
    () => ({
      show: (state) => {
        setState(state);
        ref.current?.show(true);
      },
    }),
    []
  );

  async function handleCardViewPinFlow(values: TPasswordFormData) {
    const result = await getContent({ password: values.password });
    if (!result.content) {
      const isCardPinNotActive = result.error?.errorCode === errorCodes.CARD_PIN_NOT_ACTIVE;
      if (isCardPinNotActive) {
        openCardPinNotActiveAlert();
      } else {
        showErrorAlert({
          titleId: result.error?.errorMessage as TI18nId,
        });
      }
      formRef.current?.clear();
      return;
    }

    cardViewPinModalRef.current?.show({
      card: state?.card as TCard,
      cardPinContent: result.content,
      onClose: hideModal,
    });
    formRef.current?.clear();
  }

  function hideModal() {
    ref.current?.show(false);
  }

  function openCardPinNotActiveAlert() {
    showErrorAlert({
      titleId: 'messages.cardPinIsNotActive.title',
      onCancel: hideModal,
      bottomContent: ({ hide }) => (
        <>
          <Text id="messages.cardPinIsNotActive.description" textAlign="center" />
          <Box display="flex" flexDirection="column" gap="m" mt="xl">
            <Button labelId="button.getHelp" variant="secondary" onClick={() => intercom.show()} />
            <Button labelId="button.gotIt" onClick={hide} />
          </Box>
        </>
      ),
    });
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId="screens.headerTitle.cardViewPinAuthentication"
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}>
        {state?.card && (
          <>
            <PasswordForm
              ref={formRef}
              onSubmit={handleCardViewPinFlow}
              descriptionId="screens.cardViewPinAuthentication.description"
              loading={loading}>
              <Box mb="l">
                <CardItem card={state.card} />
              </Box>
            </PasswordForm>
          </>
        )}
      </PageModal>
      <CardViewPinModal modalRef={cardViewPinModalRef} />
    </>
  );
}

export default CardViewPinAuthenticationModal;
