import { useEffect } from 'react';
import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';

import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';
import { useMerchantContext } from 'context/MerchantProvider';
import { IDLE_MODAL_TIME } from 'constants/idleSessionTimes';

import { idleModalRef } from '../../App';

function useIdleTimer() {
  const { start: startIdleTimer, reset: resetIdleTimer } = useReactIdleTimer({
    timeout: IDLE_MODAL_TIME,
    onIdle: handleOnIdle,
    debounce: 1000,
    crossTab: true,
    startManually: true,
  });
  const { merchantState } = useMerchantContext();

  useEffect(() => {
    if (merchantState.merchant?.onboardingStatus === MerchantOnboardingStatus.LIVE) {
      startIdleTimer();
    }
  }, [merchantState.merchant?.onboardingStatus]);

  function handleOnIdle() {
    idleModalRef.current?.show(resetIdleTimer);
  }
}

export default useIdleTimer;
