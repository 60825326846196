import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const ScheduledTransferIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M6 6V3M10 6V3M14 6V3M21 10H3M18 6V3M13 21H5C3.89543 21 3 20.1046 3 19V7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V12"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 21.3727C20.4961 21.7681 19.8832 22 19.2222 22C17.504 22 16.1111 20.433 16.1111 18.5C16.1111 16.567 17.504 15 19.2222 15C19.8832 15 20.4961 15.2319 21 15.6273M15 18.5H20.1111"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScheduledTransferIcon;
