import { FC } from 'react';

import { TimeIcon, ErrorMarkIcon, CheckListIcon } from 'components/Icons';
import { XSquareIcon, Hourglass03Icon, CheckCircleIcon } from 'components/v2/Icons';

import { TI18nId } from 'types/common';
import { TThemePrimitives as TOldThemePrimitives } from 'theme/theme.types';
import { TThemePrimitives } from 'theme/v2/theme.types';
import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TIconProps as TOldIconProps } from 'components/Icons/Icon.types';

enum PayoutStatus {
  FAILED = -1,
  PENDING = 0,
  COMPLETED = 20,
  STARTED = 30,
}

const MAP: Record<
  PayoutStatus,
  {
    id: TI18nId;
    color: keyof TOldThemePrimitives;
    oldIcon: FC<TOldIconProps>;
    icon: FC<TIconProps>;
    iconColor: keyof TThemePrimitives;
  }
> = {
  [PayoutStatus.FAILED]: {
    id: 'label.payoutFailed',
    color: 'error',
    oldIcon: ErrorMarkIcon,
    icon: XSquareIcon,
    iconColor: 'statusErrorPrimary',
  },
  [PayoutStatus.PENDING]: {
    id: 'label.payoutPending',
    color: 'paymentItemStatusPending',
    oldIcon: TimeIcon,
    icon: Hourglass03Icon,
    iconColor: 'statusWarningPrimary',
  },
  [PayoutStatus.COMPLETED]: {
    id: 'label.payoutCompleted',
    color: 'success',
    oldIcon: CheckListIcon,
    icon: CheckCircleIcon,
    iconColor: 'statusPositivePrimary',
  },
  [PayoutStatus.STARTED]: {
    id: 'label.payoutPending',
    color: 'paymentItemStatusPending',
    oldIcon: TimeIcon,
    icon: Hourglass03Icon,
    iconColor: 'statusWarningPrimary',
  },
};

export function getLabelIdByPayoutStatus(paymentStatus: PayoutStatus) {
  return MAP[paymentStatus]?.id as TI18nId;
}

export function getColorByPayoutStatus(paymentStatus: PayoutStatus) {
  return MAP[paymentStatus]?.color as keyof TOldThemePrimitives;
}

export function getIconByPayoutStatus(paymentStatus: PayoutStatus) {
  return MAP[paymentStatus]?.icon;
}

export function getIconColorByPayoutStatus(paymentStatus: PayoutStatus) {
  return MAP[paymentStatus]?.iconColor as keyof TThemePrimitives;
}

export function getOldIconByPayoutStatus(paymentStatus: PayoutStatus) {
  return MAP[paymentStatus]?.oldIcon;
}

export default PayoutStatus;
