import { FC } from 'react';

import {
  CardIcon,
  PayoutIcon,
  SendMoneyIcon,
  WalletIcon,
  WamoIcon as OldWamoIcon,
  FxTransactionIcon,
} from 'components/Icons';
import {
  ArrowUpRightIcon,
  ArrowDownLeftIcon,
  BankNote01Icon,
  CoinsSwap02Icon,
  WamoIcon,
} from 'components/v2/Icons';

import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TIconProps as TOldIconProps } from 'components/Icons/Icon.types';
import { TI18nId } from 'types/common';

enum TransactionType {
  SEND = 0,
  RECEIVE = 1,
  CARD_DEBIT = 4,
  CARD_RECEIVE = 5,
  FEE = 6,
  PAYOUT = 7,
  FX = 8,
  CARD_RESERVATION = 9,
}

const MAP: Record<
  TransactionType,
  {
    labelId: TI18nId;
    oldIcon: FC<TOldIconProps>;
    icon?: FC<TIconProps>;
    badgeIcon?: FC<TIconProps>;
  }
> = {
  [TransactionType.SEND]: {
    labelId: 'modals.transactionDetailModal.transactionType.send',
    oldIcon: SendMoneyIcon,
    icon: undefined,
    badgeIcon: ArrowUpRightIcon,
  },
  [TransactionType.RECEIVE]: {
    labelId: 'modals.transactionDetailModal.transactionType.receive',
    oldIcon: WalletIcon,
    icon: undefined,
    badgeIcon: ArrowDownLeftIcon,
  },
  [TransactionType.CARD_DEBIT]: {
    labelId: 'modals.transactionDetailModal.transactionType.cardDebit',
    oldIcon: CardIcon,
    icon: undefined,
    badgeIcon: undefined,
  },
  [TransactionType.CARD_RECEIVE]: {
    labelId: 'modals.transactionDetailModal.transactionType.cardReceive',
    oldIcon: CardIcon,
    icon: undefined,
    badgeIcon: undefined,
  },
  [TransactionType.FEE]: {
    labelId: 'modals.transactionDetailModal.transactionType.fee',
    oldIcon: OldWamoIcon,
    icon: WamoIcon,
    badgeIcon: undefined,
  },
  [TransactionType.PAYOUT]: {
    labelId: 'modals.transactionDetailModal.transactionType.payout',
    oldIcon: PayoutIcon,
    icon: BankNote01Icon,
    badgeIcon: undefined,
  },
  [TransactionType.FX]: {
    labelId: 'modals.transactionDetailModal.transactionType.convertAndSend',
    oldIcon: FxTransactionIcon,
    icon: CoinsSwap02Icon,
    badgeIcon: undefined,
  },
  [TransactionType.CARD_RESERVATION]: {
    labelId: 'modals.transactionDetailModal.transactionType.cardReservation',
    oldIcon: CardIcon,
    icon: undefined,
    badgeIcon: undefined,
  },
};

export function getLabelIdByTransactionType(transactionType: TransactionType): TI18nId {
  return MAP[transactionType]?.labelId;
}

export function getOldIconByTransactionType(transactionType: TransactionType) {
  return MAP[transactionType]?.oldIcon ?? WalletIcon;
}

export function getIconByTransactionType(transactionType: TransactionType) {
  return MAP[transactionType]?.icon;
}

export function getDirectionTitleByTransactionType(
  transactionType: TransactionType
): TI18nId | undefined {
  if ([TransactionType.SEND, TransactionType.FX].includes(transactionType)) {
    return 'label.transactionItem.direction.to';
  }
  if (transactionType === TransactionType.RECEIVE) {
    return 'label.transactionItem.direction.from';
  }
  return undefined;
}

export function getBadgeIconByTransactionType(transactionType: TransactionType) {
  return MAP[transactionType]?.badgeIcon;
}

export default TransactionType;
