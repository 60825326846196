import queryString from 'query-string';

import replaceUrlParams from 'helpers/replaceUrlParams';

import { TNormalizeUrlOptions } from './normalizeUrl.types';

function normalizeUrl(apiEndpoint: string, options: TNormalizeUrlOptions): string {
  const url = getUrlWithPathVariable(apiEndpoint, options.pathVariables);
  return queryString.stringifyUrl({ url, query: options.queryParams });
}

function getUrlWithPathVariable(url: string, pathVariable: TNormalizeUrlOptions['pathVariables']) {
  if (pathVariable) {
    return replaceUrlParams(url, pathVariable);
  }
  return url;
}

export default normalizeUrl;
