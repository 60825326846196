import React from 'react';

import IMAGES from 'constants/images';
import Box from 'components/core/Box';

import { RightContentImage, RightContentWrapper } from '../AuthLayout.styles';
import { TRightContentProps } from '../AuthLayout.types';

function RightContent({ backgroundColor, children }: TRightContentProps) {
  return (
    <RightContentWrapper backgroundColor={backgroundColor}>
      <Box maxWidth="480px" display="flex" marginX="auto">
        {children || <RightContentImage src={IMAGES.banner} alt="banner" />}
      </Box>
    </RightContentWrapper>
  );
}

export default RightContent;
