import React from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

function ActionButtonShimmer() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p="m"
      flex={1}>
      <Shimmer shape="square" width={32} height={32} />
      <Shimmer shape="square" width="100%" mt="xs" height={20} />
    </Box>
  );
}

export default ActionButtonShimmer;
