import NOOP from 'constants/noop';

import { TExchangeState, TExchangeDispatch } from './ExchangeProvider.types';

export const INITIAL_STATE: TExchangeState = {
  quoteDetail: undefined,
};

export const INITIAL_DISPATCH: TExchangeDispatch = {
  setQuoteDetail: NOOP,
  clear: NOOP,
};
