import styled, { css } from 'styled-components';

import { getColor } from 'helpers/theme';

export const StyledHoverBox = styled.div<{ active?: boolean }>`
  position: relative;
  z-index: 1;
  transition: background-color 0.2s linear;
  background-color: transparent;

  ${(props) =>
    props.active &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        left: -8px;
        bottom: -8px;
        background-color: ${getColor('cardActiveHoverBackground')};
        z-index: -1;
        border-radius: 8px;
      }

      .list-hover {
        background-color: transparent;
      }
    `}
`;
