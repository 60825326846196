import React from 'react';

import Box from 'components/core/Box';

import Step from './views/Step';
import { TStepScreenProps } from './StepScreen.types';

function StepScreen({ steps, activeStep, children }: TStepScreenProps) {
  if (!steps || !activeStep) {
    return <Box backgroundColor="primaryBackground">{children}</Box>;
  }

  return (
    <Box backgroundColor="primaryBackground">
      {children}
      <Box alignment="row.top.left" alignItems="center" my="s" opacity={steps === 1 ? 0 : 1}>
        {[...Array(steps)].map((_, i) => (
          <Step
            key={i.toString()}
            active={activeStep === i + 1}
            completed={i < activeStep - 1}
            isLast={steps === i + 1}
          />
        ))}
      </Box>
    </Box>
  );
}

export default StepScreen;
