import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { sumBy } from 'lodash/fp';

import { TChildrenOnly } from 'types/common';
import { TInvoiceDetailResponse } from 'types/invoice';
import { useInvoiceCreateContext } from 'context/InvoiceCreateProvider';
import { TInvoiceCustomerFormData } from 'components/v2/Forms/InvoiceCustomerForm/InvoiceCustomerForm.types';
import { TInvoiceSellItemFormData } from 'components/v2/Forms/InvoiceSellItemForm/InvoiceSellItemForm.types';

import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoiceCreatePreviewPdfProvider.constants';
import {
  TInvoiceCreatePreviewPdfState,
  TInvoiceCreatePreviewPdfContext,
} from './InvoiceCreatePreviewPdfProvider.types';
import {
  calculateTotalTaxAmount,
  handleSetInvoiceItems,
} from './InvoiceCreatePreviewPdfProvider.helpers';

const invoiceCreatePreviewPdfContext = createContext<TInvoiceCreatePreviewPdfContext>({
  invoiceCreatePreviewPdfState: INITIAL_STATE,
  invoiceCreatePreviewPdfDispatch: INITIAL_DISPATCH,
});

function InvoiceCreatePreviewPdfProvider({ children }: TChildrenOnly): ReactElement {
  const { invoiceCreateState } = useInvoiceCreateContext();
  const [state, setState] = useState<TInvoiceCreatePreviewPdfState>(INITIAL_STATE);

  useEffect(() => {
    if (invoiceCreateState.invoice) {
      setInvoice(invoiceCreateState.invoice);
    }
  }, [invoiceCreateState.invoice]);

  function setInvoice(invoice: TInvoiceDetailResponse) {
    setState({
      invoice,
    });
  }

  function setCustomer(customer: TInvoiceCustomerFormData) {
    setState((prevState) => ({
      ...prevState,
      ...(prevState.invoice && {
        invoice: {
          ...prevState.invoice,
          customer: {
            id: prevState.invoice?.customer?.id ?? '1',
            ...customer,
          } as TInvoiceDetailResponse['customer'],
        },
      }),
    }));
  }

  function setSellItem(sellItem: TInvoiceSellItemFormData) {
    setState((prevState) => {
      const items = prevState.invoice
        ? handleSetInvoiceItems(prevState.invoice.items, sellItem)
        : [];

      return {
        ...prevState,
        ...(prevState.invoice && {
          invoice: {
            ...prevState.invoice,
            items,
          },
        }),
      };
    });
  }

  function calculateAmountDetailsBySellItemPrice(sellItem: TInvoiceSellItemFormData) {
    setState((prevState) => {
      const items = prevState.invoice
        ? handleSetInvoiceItems(prevState.invoice.items, sellItem)
        : [];
      const totalNetAmount = sumBy('totalPrice', items);
      const totalTaxAmount = calculateTotalTaxAmount(items);

      return {
        ...prevState,
        ...(prevState.invoice && {
          invoice: {
            ...prevState.invoice,
            items,
            amountDetails: {
              ...prevState.invoice.amountDetails,
              totalNetAmount,
              totalTaxAmount,
              totalGrossAmount: totalNetAmount + totalTaxAmount,
            },
          },
        }),
      };
    });
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TInvoiceCreatePreviewPdfContext = {
    invoiceCreatePreviewPdfState: state,
    invoiceCreatePreviewPdfDispatch: {
      setInvoice,
      setCustomer,
      setSellItem,
      calculateAmountDetailsBySellItemPrice,
      clear,
    },
  };

  return (
    <invoiceCreatePreviewPdfContext.Provider value={value}>
      {children}
    </invoiceCreatePreviewPdfContext.Provider>
  );
}

export default InvoiceCreatePreviewPdfProvider;

export const useInvoiceCreatePreviewPdfContext = (): TInvoiceCreatePreviewPdfContext =>
  useContext(invoiceCreatePreviewPdfContext);
