import React from 'react';
import { useViewportScroll } from 'framer-motion';

import StickyScrollHeader from 'components/StickyScrollHeader';

import { TPageProps } from './Page.types';
import PageShimmer from './views/PageShimmer';

function Page({
  children,
  navButton,
  headerTitleId,
  headerText,
  rightButton,
  hideNavBackButton,
  isStaticSmallHeader,
}: TPageProps) {
  const { scrollY } = useViewportScroll();

  return (
    <>
      <StickyScrollHeader
        header={{ id: headerTitleId, text: headerText }}
        scrollY={scrollY}
        navButton={navButton}
        rightButton={rightButton}
        hideNavBackButton={hideNavBackButton}
        paddingX={0}
        isStaticSmallHeader={isStaticSmallHeader}
      />
      {children}
    </>
  );
}

Page.Shimmer = PageShimmer;

export default Page;
