import React, { ChangeEvent, useRef, useState, forwardRef, useEffect } from 'react';

import Box from 'components/core/Box';

import { NUMBER } from 'constants/regex';

import DigitCodeBox from './views/DigitCodeBox';
import { StyledDigitCodeInput, StyledButton } from './DigitCode.styles';
import { TDigitCodeProps } from './DigitCode.types';

function DigitCode({
  size = 6,
  value,
  onChange,
  onBlur,
  secret,
  autoComplete,
  onSubmit,
  disabled,
}: TDigitCodeProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const pinCodeViewArr = value?.split('') ?? [];
  const [isInputFocused, setIsInputFocused] = useState(true);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (value?.length === size) {
      onSubmit();
      handleBlur();
    }
  }, [size, value]);

  function handlePinCodeInput(e: ChangeEvent<HTMLInputElement>) {
    const normalizedCode = e.target.value.replace(NUMBER, '');
    onChange(normalizedCode);
  }

  function handleFocus() {
    setIsInputFocused(true);
  }

  function handleBlur() {
    setIsInputFocused(false);
    onBlur?.();
  }

  return (
    <StyledButton type="button" onClick={() => inputRef.current?.focus()}>
      <Box position="relative" alignment="row.verticalCenter" gap="s">
        {[...Array(size)].map((_, i) => (
          <DigitCodeBox
            key={i.toString()}
            value={pinCodeViewArr[i]}
            isInputFocused={isInputFocused && pinCodeViewArr.length === i}
            secret={secret}
          />
        ))}
        <StyledDigitCodeInput
          ref={inputRef}
          type={secret ? 'password' : 'text'}
          inputMode="numeric"
          onChange={handlePinCodeInput}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxLength={size}
          autoComplete={autoComplete}
          disabled={disabled}
          autoFocus
        />
      </Box>
    </StyledButton>
  );
}

export default forwardRef(DigitCode);
