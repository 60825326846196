import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function CreditCardIcon({ iconColor = 'primary', ...rest }: TIconProps): ReactElement {
  const { colors } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M2 10V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V10M2 10V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V10M2 10H22M19 14H13"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CreditCardIcon;
