import React from 'react';

import CardSecurityQuestionForm from 'components/Form/CardSecurityQuestionForm';
import CardStaticPasswordForm from 'components/Form/CardStaticPasswordForm';

import Card3DSType from 'enums/Card3DSType';
import { TCardSecurityQuestionFormData } from 'components/Form/CardSecurityQuestionForm/CardSecurityQuestionForm.types';
import { TCardStaticPasswordFormData } from 'components/Form/CardStaticPasswordForm/CardStaticPasswordForm.types';

import { TModalContentProps } from '../CardReset3DSecurityModal.types';

function ModalContent({ card, onSubmit }: TModalContentProps) {
  const { threedSecurityMethod } = card;

  function submitSecurityQuestion(values: TCardSecurityQuestionFormData) {
    onSubmit({
      securityQuestion: {
        type: values.securityQuestion.value,
        answer: values.answer,
      },
    });
  }

  function submitStaticPassword(values: TCardStaticPasswordFormData) {
    onSubmit({
      staticPassword: values.staticPassword,
    });
  }

  switch (threedSecurityMethod.type) {
    case Card3DSType.SECURITY_QUESTION:
      return (
        <CardSecurityQuestionForm
          formButtonProps={{ id: 'button.resetQuestion' }}
          pageHeaderProps={{
            titleId: 'screens.cardReset3DSecurity.securityQuestion.title',
            descriptionId: 'screens.cardReset3DSecurity.securityQuestion.description',
          }}
          securityQuestions={threedSecurityMethod.securityQuestions}
          onSubmit={submitSecurityQuestion}
        />
      );
    case Card3DSType.STATIC_PASSWORD:
      return (
        <CardStaticPasswordForm
          formButtonProps={{ id: 'button.resetPassword' }}
          pageHeaderProps={{
            titleId: 'screens.cardReset3DSecurity.staticPassword.title',
            descriptionId: 'screens.cardReset3DSecurity.staticPassword.description',
          }}
          onSubmit={submitStaticPassword}
        />
      );
    default:
      return <></>;
  }
}

export default ModalContent;
