import React from 'react';

import Box from 'components/core/Box';

import { TIconWrapperProps } from './Icon.types';

function IconWrapper({
  size = '80px',
  backgroundColor = 'tertiary',
  children,
  ...rest
}: TIconWrapperProps) {
  return (
    <Box
      alignment="center"
      width={size}
      height={size}
      borderRadius={size}
      overflow="hidden"
      backgroundColor={backgroundColor}
      {...rest}>
      {children}
    </Box>
  );
}

export default IconWrapper;
