import React from 'react';

import Modal from 'components/core/Modal';
import Page from 'components/Page';

import DashboardLayout from 'layouts/DashboardLayout';

import { useSideLayoutContext } from 'context/SideLayoutProvider';
import { NavMenu } from 'layouts/DashboardLayout/DashboardLayout.types';

import { TPageModalProps } from './PageModal.types';
import PageModalShimmer from './views/PageModalShimmer';

function PageModal({
  modalRef,
  children,
  headerTitleId,
  headerText,
  rightButton,
  afterOpen,
  afterClose,
  className,
  navButton,
  isPageModal = true,
  visibleMenu = false,
  hideNavBackButton,
  sideLayoutVisible = true,
  isStaticSmallHeader = false,
  shouldHideSideLayoutOnVisibilityChange = true,
  zIndex,
}: TPageModalProps) {
  const { sideLayoutDispatch } = useSideLayoutContext();

  function handleAfterOpen() {
    afterOpen?.();
    shouldHideSideLayoutOnVisibilityChange && sideLayoutDispatch.hideSideLayout();
  }

  function handleAfterClose() {
    afterClose?.();
    shouldHideSideLayoutOnVisibilityChange && sideLayoutDispatch.hideSideLayout();
  }

  function hideModal() {
    modalRef.current?.show(false);
  }

  return (
    <Modal
      className={className}
      ref={modalRef}
      afterOpen={handleAfterOpen}
      afterClose={handleAfterClose}
      zIndex={zIndex}
      isFullPage
      secondary>
      <DashboardLayout
        navMenu={NavMenu.HOME}
        isPageModal={isPageModal}
        visibleMenu={visibleMenu}
        sideLayoutVisible={sideLayoutVisible}>
        <Page
          headerTitleId={headerTitleId}
          headerText={headerText}
          navButton={{ onClick: hideModal }}
          rightButton={rightButton}
          hideNavBackButton={hideNavBackButton}
          isStaticSmallHeader={isStaticSmallHeader}
          {...(navButton && { navButton })}>
          {children}
        </Page>
      </DashboardLayout>
    </Modal>
  );
}

PageModal.Shimmer = PageModalShimmer;

export default PageModal;
