import { useEffect, useState } from 'react';

import { useSideLayoutContext } from 'context/SideLayoutProvider';
import { SideLayoutComponentPath } from 'layouts/SideLayout/SideLayout.constants';

import { TSelectedItem, TUseTransactionItemSelect } from './useTransactionItemSelect.types';

function useTransactionItemSelect(): TUseTransactionItemSelect {
  const [selectedItem, setSelectedItem] = useState<TSelectedItem>();
  const { sideLayoutDispatch } = useSideLayoutContext();

  useEffect(() => {
    if (selectedItem) {
      sideLayoutDispatch.showSideLayout({
        path: selectedItem.path,
        params: { id: selectedItem.transactionId },
        onClose: handleClose,
      });
    }
  }, [selectedItem]);

  function handleClose() {
    setSelectedItem(undefined);
  }

  function handleSelectItem(transactionId: string) {
    setSelectedItem({
      path: SideLayoutComponentPath.TRANSACTION_DETAIL,
      transactionId,
    });
  }

  return {
    selectedTransactionId: selectedItem?.transactionId,
    handleSelectItem,
  };
}

export default useTransactionItemSelect;
