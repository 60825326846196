import React, { createContext, ReactElement, useContext, useState } from 'react';
import { omit } from 'lodash/fp';

import { TChildrenOnly } from 'types/common';

import { THeaderContext, THeaderState, THeader } from './HeaderProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './HeaderProvider.constants';

const headerContext = createContext<THeaderContext>({
  headerState: INITIAL_STATE,
  headerDispatch: INITIAL_DISPATCH,
});

function HeaderProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<THeaderState>(INITIAL_STATE);

  function setOptions(id: string, header: THeader) {
    setState((state) => ({
      ...state,
      headers: {
        ...state.headers,
        [id]: header,
      },
    }));
  }

  function removeHeaderOption(id: string) {
    setState((state) => ({
      ...state,
      headers: omit(id, state.headers),
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: THeaderContext = {
    headerState: state,
    headerDispatch: {
      setOptions,
      removeHeaderOption,
      clear,
    },
  };

  return <headerContext.Provider value={value}>{children}</headerContext.Provider>;
}

export default HeaderProvider;

export const useHeaderContext = (): THeaderContext => useContext(headerContext);
