import React from 'react';

import { useTheme } from 'styled-components';
import { TIconProps } from '../Icon.types';

const FreezeIcon = ({ iconColor = 'freezeIcon', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M2.66667 16.0001H11.3333M29.3333 16.0001H20.3333M16 2.66675V11.3334M16 29.3334V20.3334M16 11.3334L9.33334 4.66675M16 11.3334L22.6667 4.66675M16 11.3334V20.3334M11.3333 16.0001L4.66667 9.33342M11.3333 16.0001L4.66667 22.6668M11.3333 16.0001H20.3333M16 20.3334L9.33334 27.0001M16 20.3334L22.6667 27.0001M20.3333 16.0001L27 9.33342M20.3333 16.0001L27 22.6668"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default FreezeIcon;
