import lazyWithRetry from 'helpers/lazyWithRetry';

import { TAuthOpenBankingRoutes } from './AuthOpenBanking.types';

const OpenBankingLoginEmail = lazyWithRetry(() => import('./OpenBankingLoginEmail'));
const OpenBankingTransactionSummary = lazyWithRetry(
  () => import('./OpenBankingTransactionSummary')
);

const routes: TAuthOpenBankingRoutes = {
  openBankingLoginEmail: {
    path: '/auth/o/login',
    element: OpenBankingLoginEmail,
    titleId: 'screens.login.title',
  },
  openBankingTransactionSummary: {
    path: '/o/transaction-summary/:connectionId',
    element: OpenBankingTransactionSummary,
    titleId: 'label.transactionSummary',
  },
};

export default routes;
