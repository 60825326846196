import React, { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import masks from '@wamoio/common/lib/masks';

import FieldTextInput from 'components/FieldTextInput';
import FormErrorLabel from 'components/FormErrorLabel';
import FieldCountryCode from 'components/FieldCountryCode';
import Box from 'components/core/Box';

import { COUNTRY_DEFAULT_VALUE } from 'constants/form';
import { TCountry, TI18nId } from 'types/common';

import { StyledPhoneNumberInputContainer } from './FieldPhoneNumberInput.styles';
import { TFieldPhoneNumberInputProps } from './FieldPhoneNumberInput.types';

function FieldPhoneNumberInput({
  control,
  errors,
  trigger,
  autoFocus,
  isSubmitted,
  placeholderId,
  disabled = false,
  defaultPhoneNumberCountry = COUNTRY_DEFAULT_VALUE,
  marginBottom = 'l',
}: TFieldPhoneNumberInputProps) {
  const [focused, setFocus] = useState(autoFocus);
  const errorMessageId = errors.phoneNumber?.message;

  const countryField: TCountry = useWatch({
    name: 'phoneNumberCountry',
    control,
    defaultValue: defaultPhoneNumberCountry,
  });

  useEffect(() => {
    if (isSubmitted) {
      trigger('phoneNumber');
    }
  }, [countryField?.code, isSubmitted]);

  return (
    <Box marginBottom={marginBottom}>
      <StyledPhoneNumberInputContainer alignment="row.verticalCenter">
        <FieldCountryCode
          control={control}
          name="phoneNumberCountry"
          hasError={!!errorMessageId}
          focused={focused}
          headerTitleId="label.selectPhoneCode"
          disabled={disabled}
        />
        <FieldTextInput
          name="phoneNumber"
          labelId="label.phoneNumber"
          placeholderId={placeholderId}
          control={control}
          errors={errors}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          mask={(value) => masks.phoneNumber.mask(value, countryField.code as any)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          showErrorMessage={false}
          autoCapitalize="none"
          autoComplete="tel-national"
          disabled={disabled}
        />
      </StyledPhoneNumberInputContainer>
      {isSubmitted && errorMessageId && <FormErrorLabel labelId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldPhoneNumberInput;
