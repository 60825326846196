import { FC } from 'react';

import { BritishFlagIcon, ItalyFlagIcon } from 'components/Icons';

import { TI18nId } from 'types/common';
import { TIconProps } from 'types/icon';

enum Locale {
  EN = 'en',
  IT = 'it',
}

const MAP: Record<Locale, { labelId: TI18nId; icon: FC<TIconProps> }> = {
  [Locale.EN]: {
    labelId: 'label.english',
    icon: BritishFlagIcon,
  },
  [Locale.IT]: {
    labelId: 'label.italiano',
    icon: ItalyFlagIcon,
  },
};

export function getLocaleLabelIdByLocale(locale: Locale): TI18nId {
  return MAP[locale].labelId ?? MAP.en.labelId;
}

export function getIconByLocale(locale: Locale): FC<TIconProps> {
  return MAP[locale].icon ?? MAP.en.icon;
}

export function getLocaleOptions() {
  return Object.keys(MAP).map((key) => ({
    label: getLocaleLabelIdByLocale(key as Locale),
    value: key as Locale,
  }));
}

export default Locale;
