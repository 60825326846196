import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DocumentTextAltIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M19 10h1a1 1 0 0 0-.293-.707L19 10Zm-7-7 .707-.707A1 1 0 0 0 12 2v1Zm3 11a1 1 0 1 0 0-2v2Zm-7-2a1 1 0 1 0 0 2v-2Zm5 6a1 1 0 1 0 0-2v2Zm-5-2a1 1 0 1 0 0 2v-2Zm1-6a1 1 0 0 0 0-2v2ZM8 8a1 1 0 0 0 0 2V8Zm9 12H7v2h10v-2ZM6 19V5H4v14h2Zm12-9v9h2v-9h-2ZM7 4h5V2H7v2Zm12.707 5.293-7-7-1.414 1.414 7 7 1.414-1.414ZM11 3v6.5h2V3h-2Zm1.5 8H19V9h-6.5v2ZM7 20a1 1 0 0 1-1-1H4a3 3 0 0 0 3 3v-2Zm10 2a3 3 0 0 0 3-3h-2a1 1 0 0 1-1 1v2ZM11 9.5a1.5 1.5 0 0 0 1.5 1.5V9a.5.5 0 0 1 .5.5h-2ZM6 5a1 1 0 0 1 1-1V2a3 3 0 0 0-3 3h2Zm9 7H8v2h7v-2Zm-2 4H8v2h5v-2ZM9 8H8v2h1V8Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default DocumentTextAltIcon;
