import { TI18nId } from 'types/common';

enum MemberType {
  DIRECTOR = 100,
  EMPLOYEE = 200,
  MANAGER = 300,
}

const MAP: Record<
  MemberType,
  { labelId: TI18nId; detailHeaderTitleId: TI18nId; deleteButtonId: TI18nId }
> = {
  [MemberType.DIRECTOR]: {
    labelId: 'label.director',
    detailHeaderTitleId: 'screens.headerTitle.directorDetail',
    deleteButtonId: 'button.delete',
  },
  [MemberType.EMPLOYEE]: {
    labelId: 'label.employee',
    detailHeaderTitleId: 'screens.headerTitle.employeeDetail',
    deleteButtonId: 'button.deleteEmployee',
  },
  [MemberType.MANAGER]: {
    labelId: 'label.managerAndAccountant',
    detailHeaderTitleId: 'screens.headerTitle.managerDetail',
    deleteButtonId: 'button.deleteManager',
  },
};

export function getLabelIdByMemberType(type: MemberType) {
  return MAP[type]?.labelId;
}

export function getDetailHeaderTitleIdByMemberType(type: MemberType) {
  return MAP[type]?.detailHeaderTitleId;
}

export function getDeleteButtonIdByMemberType(type: MemberType) {
  return MAP[type]?.deleteButtonId ?? 'button.delete';
}

export default MemberType;
