import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import {
  TOnboardingProgressStepsItemProps,
  TOnboardingProgressStepsItemType,
} from '../OnboardingProgressSteps.types';
import OnboardingProgressStepsItemStatus from './OnboardingProgressStepsItemStatus';

function OnboardingProgressStepsItem({ labelId, ...rest }: TOnboardingProgressStepsItemProps) {
  return (
    <Box alignment="row.verticalCenter">
      <OnboardingProgressStepsItemStatus {...rest} />
      <Text
        id={labelId}
        variant={
          rest.type === TOnboardingProgressStepsItemType.CHILD ? 'xSmallTextBold' : 'smallTextBold'
        }
        color={
          rest.active
            ? 'onboardingProgressStepsItemActiveStatus'
            : 'onboardingProgressStepsItemDisabledStatus'
        }
        ml="m"
      />
    </Box>
  );
}

export default OnboardingProgressStepsItem;
