import React from 'react';
import { useNavigate } from 'react-router-dom';

import ListItemBase from 'components/v2/core/ListItemBase';
import Avatar from 'components/v2/core/Avatar';

import { TI18nId } from 'types/common';
import { useMemberContext } from 'context/MemberProvider';
import { useMerchantContext } from 'context/MerchantProvider';
import routes from 'router/routes';

import { TProfileAvatarItemProps } from './ProfileAvatarItem.types';

function ProfileAvatarItem({ path = routes.account.path }: TProfileAvatarItemProps) {
  const navigate = useNavigate();
  const { memberState } = useMemberContext();
  const { merchantState } = useMerchantContext();

  function renderAvatar() {
    return (
      <Avatar
        label={`${memberState?.firstName?.[0]}${memberState?.lastName?.[0]}`}
        backgroundColor="backgroundSecondary"
        labelColor="textSecondary"
        size="small"
      />
    );
  }

  return (
    <ListItemBase
      labelId={merchantState.merchant?.registrationName as TI18nId}
      labelProps={{
        variant: 'textBodySubBold',
        numberOfLines: 1,
      }}
      descriptionId={memberState?.email as TI18nId}
      descriptionProps={{
        variant: 'textBodySmallBold',
        color: 'textSecondary',
        numberOfLines: 1,
      }}
      left={renderAvatar}
      minHeight={40}
      onPress={() => navigate(path)}
    />
  );
}

export default ProfileAvatarItem;
