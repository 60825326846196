import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const InfoQuestionMarkIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <circle cx={12} cy={12} r={10} stroke={colors[iconColor]} strokeWidth="2" />
      <path
        d="M8.688 9.799c0-1.914 1.585-3.3 3.347-3.3a3.26 3.26 0 0 1 3.274 3.3c0 1.473-1.218 1.945-2.338 3.183-.637.705-.936 1.807-.936 2.298m0 2.72v.5"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InfoQuestionMarkIcon;
