import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';
import { TCommonResponse } from 'types/common';

import {
  TBusinessOwnerCreateState,
  THandleAddBusinessOwnerParams,
} from './BusinessOwnerCreateProvider.types';
import {
  convertToAddShareholderFormDataRequest,
  convertToAddDirectorFormDataRequest,
  convertToAddDirectorFromMemberRequest,
} from './BusinessOwnerCreateProvider.helpers';

export function useAddDirectorRequest(state: TBusinessOwnerCreateState) {
  const { post: requestAddDirector } = useFetch(API_ENDPOINT.DIRECTORS_V2);
  const { post: requestAddDirectorFromMember } = useFetch(API_ENDPOINT.DIRECTORS_FROM_MEMBER);

  async function handleAddDirector({ onSuccess }: THandleAddBusinessOwnerParams) {
    try {
      const { errorMessage } = await addDirector(state);

      if (errorMessage) {
        showErrorAlert({
          title: errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({
        titleId: 'label.unexpectedError',
      });
    }
  }

  function addDirector(state: TBusinessOwnerCreateState): Promise<TCommonResponse<void>> {
    if (state.shouldCreateDirectorFromMember) {
      return requestAddDirectorFromMember(convertToAddDirectorFromMemberRequest(state));
    }
    return requestAddDirector(convertToAddDirectorFormDataRequest(state));
  }

  return { handleAddDirector };
}

export function useAddShareholderRequest(state: TBusinessOwnerCreateState) {
  const { post: requestAddShareholder } = useFetch(API_ENDPOINT.SHAREHOLDERS_V2);

  async function handleAddShareholder({ onSuccess }: THandleAddBusinessOwnerParams) {
    try {
      const { errorMessage } = await requestAddShareholder(
        convertToAddShareholderFormDataRequest(state)
      );

      if (errorMessage) {
        showErrorAlert({
          title: errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({
        titleId: 'label.unexpectedError',
      });
    }
  }

  return { handleAddShareholder };
}
