import authRoutes from 'pages/Auth/Auth.routes';
import authOpenBankingRoutes from 'pages/AuthOpenBanking/AuthOpenBanking.routes';
import onboardingRoutes from 'pages/Onboarding/Onboarding.routes';
import mainRoutes from 'pages/Main/Main.routes';

import { TRoutes } from './routes.types';

const routes: TRoutes = {
  ...authRoutes,
  ...authOpenBankingRoutes,
  ...onboardingRoutes,
  ...mainRoutes,
};

export default routes;
