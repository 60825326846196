import { FC } from 'react';

import {
  AgriculturalServicesIcon,
  AirlinesIcon,
  AtmIcon,
  BankIcon as OldBankIcon,
  CardRentalIcon,
  ClothingStoresIcon,
  ContractedServicesIcon,
  LodgingIcon,
  MerchantIcon,
  MiscellaneousStoresIcon,
  MultiAccountIcon,
  SuitcaseIcon,
  TransportationServicesIcon,
  UtilityServicesIcon,
  CardIcon,
} from 'components/Icons';
import {
  PlaneIcon,
  Car01Icon,
  Building01Icon,
  TrainIcon,
  LightBulb03Icon,
  ShoppingBag01Icon,
  Tag02Icon,
  Building02Icon,
  BriefCase01Icon,
  Tool02Icon,
  BankIcon,
  Wallet01Icon,
  CreditCard01Icon,
  FilePlus02Icon,
} from 'components/v2/Icons';

import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TIconProps as TOldIconProps } from 'components/Icons/Icon.types';

const CARD_ATM_CATEGORY_CODE = 6011;

const CARD_MERCHANT_CATEGORY_KEYS = {
  AGRICULTURAL_SERVICES: 'agriculturalServices',
  CONTRACTED_SERVICES: 'contractedServices',
  AIRLINES: 'airlines',
  CAR_RENTAL: 'carRental',
  LODGING: 'lodging',
  TRANSPORTATION_SERVICES: 'transportationServices',
  UTILITY_SERVICES: 'utilityServices',
  RETAIL_OUTLET_SERVICES: 'retailOutletServices',
  CLOTHING_STORES: 'clothingStores',
  MISCELLANEOUS_STORES: 'miscellaneousStores',
  BUSINESS_SERVICES: 'businessServices',
  PROFESSIONAL_SERVICES: 'professionalServices',
  GOVERNMENT_SERVICES: 'governmentServices',
  ATM: 'atm',
};

const CARD_MERCHANT_CODES: Record<string, Array<number>> = {
  agriculturalServices: [1, 1499],
  contractedServices: [1500, 2999],
  airlines: [3000, 3299],
  carRental: [3300, 3499],
  lodging: [3500, 3999],
  transportationServices: [4000, 4799],
  utilityServices: [4800, 4999],
  retailOutletServices: [5000, 5599],
  clothingStores: [5600, 5699],
  miscellaneousStores: [5700, 7299],
  businessServices: [7300, 7999],
  professionalServices: [8000, 8999],
  governmentServices: [9000, 9999],
};

const OLD_ICON_BY_MCC_MAP: Record<string, FC<TOldIconProps>> = {
  [CARD_MERCHANT_CATEGORY_KEYS.AGRICULTURAL_SERVICES]: AgriculturalServicesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.CONTRACTED_SERVICES]: ContractedServicesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.AIRLINES]: AirlinesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.CAR_RENTAL]: CardRentalIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.LODGING]: LodgingIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.TRANSPORTATION_SERVICES]: TransportationServicesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.UTILITY_SERVICES]: UtilityServicesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.RETAIL_OUTLET_SERVICES]: MerchantIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.CLOTHING_STORES]: ClothingStoresIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.MISCELLANEOUS_STORES]: MiscellaneousStoresIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.BUSINESS_SERVICES]: SuitcaseIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.PROFESSIONAL_SERVICES]: MultiAccountIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.GOVERNMENT_SERVICES]: OldBankIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.ATM]: AtmIcon,
};

const ICON_BY_MCC_MAP: Record<never, FC<TIconProps>> = {
  [CARD_MERCHANT_CATEGORY_KEYS.AGRICULTURAL_SERVICES]: AgriculturalServicesIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.CONTRACTED_SERVICES]: FilePlus02Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.AIRLINES]: PlaneIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.CAR_RENTAL]: Car01Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.LODGING]: Building01Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.TRANSPORTATION_SERVICES]: TrainIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.UTILITY_SERVICES]: LightBulb03Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.RETAIL_OUTLET_SERVICES]: ShoppingBag01Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.CLOTHING_STORES]: Tag02Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.MISCELLANEOUS_STORES]: Building02Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.BUSINESS_SERVICES]: BriefCase01Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.PROFESSIONAL_SERVICES]: Tool02Icon,
  [CARD_MERCHANT_CATEGORY_KEYS.GOVERNMENT_SERVICES]: BankIcon,
  [CARD_MERCHANT_CATEGORY_KEYS.ATM]: Wallet01Icon,
};

function getTransactionIconKey(code: number) {
  if (code === CARD_ATM_CATEGORY_CODE) {
    return CARD_MERCHANT_CATEGORY_KEYS.ATM;
  }
  return Object.keys(CARD_MERCHANT_CODES).find(
    (mccCodeKey) =>
      CARD_MERCHANT_CODES[mccCodeKey][0] <= code && code <= CARD_MERCHANT_CODES[mccCodeKey][1]
  );
}

export function getIconByMcc(cardMerchantCategoryCode: number) {
  const iconKey = getTransactionIconKey(cardMerchantCategoryCode);
  return ICON_BY_MCC_MAP[iconKey as never] ?? CreditCard01Icon;
}

export function getOldIconByMcc(cardMerchantCategoryCode: number) {
  const iconKey = getTransactionIconKey(cardMerchantCategoryCode) as string;
  return OLD_ICON_BY_MCC_MAP[iconKey] ?? CardIcon;
}
