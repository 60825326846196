import NOOP from 'constants/noop';

import {
  TOnboardingApplicationDispatch,
  TOnboardingApplicationState,
} from './OnboardingApplicationProvider.types';

export const INITIAL_STATE: TOnboardingApplicationState = {
  summary: undefined,
  documents: undefined,
  documentTypes: undefined,
  summaryLoading: true,
  merchantType: undefined,
  countryOfIncorporation: undefined,
};

export const INITIAL_DISPATCH: TOnboardingApplicationDispatch = {
  getOnboardingSections: NOOP as TOnboardingApplicationDispatch['getOnboardingSections'],
  getDocuments: NOOP as TOnboardingApplicationDispatch['getDocuments'],
  getDocumentTypes: NOOP as TOnboardingApplicationDispatch['getDocumentTypes'],
  clearState: NOOP as TOnboardingApplicationDispatch['clearState'],
  setMerchantType: NOOP as TOnboardingApplicationDispatch['setMerchantType'],
  setCountryOfIncorporation: NOOP as TOnboardingApplicationDispatch['setCountryOfIncorporation'],
};
