import onboardingInformation from 'assets/images/onboardingInformation.png';
import onboardingReview from 'assets/images/onboardingReview.png';
import onboardingResult from 'assets/images/onboardingResult.png';
import billingPricing from 'assets/images/billingPricing.png';
import banner from 'assets/images/banner.png';
import phoneNotification from 'assets/images/phoneNotification.png';
import inviteBanner from 'assets/images/inviteBanner.png';
import wamoLogo from 'assets/images/wamoLogo.png';
import wamoCards from 'assets/images/wamoCards.png';
import cardPromotionBanner from 'assets/images/cardPromotionBanner.png';
import cardFreezeMask from 'assets/images/cardFreezeMask.png';
import teamMembersUpSell from 'assets/images/teamMembersUpSell.png';
import cardsUpSell from 'assets/images/cardsUpSell.png';
import csvTemplateIban from 'assets/images/csv-template-iban.png';
import csvTemplateSortCode from 'assets/images/csv-template-sort-code.png';
import csvTemplateEurAmountReference from 'assets/images/csv-template-eur-amount-reference.png';
import csvTemplateGbpAmountReference from 'assets/images/csv-template-gbp-amount-reference.png';
import csvTemplateEurCurrencyCode from 'assets/images/csv-template-eur-currency-code.png';
import csvTemplateGbpCurrencyCode from 'assets/images/csv-template-gbp-currency-code.png';
import csvEurTemplateNameType from 'assets/images/csv-template-eur-name-type.png';
import csvGbpTemplateNameType from 'assets/images/csv-template-gbp-name-type.png';
import referralIntroduction from 'assets/images/referral-introduction.png';
import invoiceIntro from 'assets/images/invoiceIntro.png';

const IMAGES = {
  onboardingInformation,
  onboardingReview,
  onboardingResult,
  billingPricing,
  banner,
  phoneNotification,
  inviteBanner,
  wamoLogo,
  wamoCards,
  cardPromotionBanner,
  cardFreezeMask,
  teamMembersUpSell,
  cardsUpSell,
  csvTemplateIban,
  csvTemplateSortCode,
  csvTemplateEurAmountReference,
  csvTemplateGbpAmountReference,
  csvTemplateEurCurrencyCode,
  csvTemplateGbpCurrencyCode,
  csvEurTemplateNameType,
  csvGbpTemplateNameType,
  referralIntroduction,
  invoiceIntro,
};

export default IMAGES;
