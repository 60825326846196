import React, { forwardRef, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';

import Modal from 'components/core/Modal';

import { showErrorAlert } from 'helpers/showAlertModal';

import {
  TPasswordAuthenticationPageModalProps,
  TPasswordFormData,
  TPasswordFormRef,
} from './PasswordAuthenticationPageModal.types';
import PasswordForm from './views/PasswordForm';

// TODO: (semih) convert it to PageModal when side layout bug is fixed
function PasswordAuthenticationPageModal({
  modalRef,
  titleId = 'components.passwordAuthenticationPageModal.title',
  descriptionId = 'components.passwordAuthenticationPageModal.description',
  onSubmit,
  onSuccess,
}: TPasswordAuthenticationPageModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<TPasswordFormRef>(null);

  async function handleSubmit({ password }: TPasswordFormData) {
    try {
      setLoading(true);
      const { errorMessage } = await onSubmit({ password });

      if (errorMessage) {
        showErrorAlert({ title: errorMessage, onCancel: clearForm });
        return;
      }

      onSuccess();
      modalRef.current?.show(false);
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'label.unexpectedError', onCancel: clearForm });
    } finally {
      setLoading(false);
    }
  }

  function clearForm() {
    formRef.current?.clear();
  }

  return (
    <Modal ref={modalRef} header={{ id: titleId }}>
      <PasswordForm descriptionId={descriptionId} loading={loading} onSubmit={handleSubmit} />
    </Modal>
  );
}

export default forwardRef(PasswordAuthenticationPageModal);
