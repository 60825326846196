import { TI18nId } from 'types/common';

enum ScheduledTransactionType {
  ONLY_ONCE = 'ONLY_ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export type TSendMoneyScheduleType = { titleId: TI18nId; value: ScheduledTransactionType };

const MAP: Record<ScheduledTransactionType, TSendMoneyScheduleType> = {
  [ScheduledTransactionType.ONLY_ONCE]: {
    titleId: 'label.onlyOnce',
    value: ScheduledTransactionType.ONLY_ONCE,
  },
  [ScheduledTransactionType.DAILY]: {
    titleId: 'label.daily',
    value: ScheduledTransactionType.DAILY,
  },
  [ScheduledTransactionType.WEEKLY]: {
    titleId: 'label.weekly',
    value: ScheduledTransactionType.WEEKLY,
  },
  [ScheduledTransactionType.MONTHLY]: {
    titleId: 'label.monthly',
    value: ScheduledTransactionType.MONTHLY,
  },
};

export function getScheduledTransactionTypeOptions() {
  return Object.values(MAP);
}

export function getScheduledTransactionType(type: ScheduledTransactionType) {
  return MAP[type];
}

export function getLabelIdByScheduledTransactionType(type: ScheduledTransactionType) {
  return MAP[type]?.titleId;
}

export default ScheduledTransactionType;
