import styled from 'styled-components';
import { motion } from 'framer-motion';

import media from 'helpers/media';
import borderRadius from 'theme/borderRadius';
import ZIndex from 'constants/zIndex';

export const StyledASide = styled.aside`
  grid-area: side / side / side / side;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.SIDE_LAYOUT};
  padding: 0;
  position: fixed;
  width: auto;
  background-color: ${({ theme }) => theme.colors.primaryBackground};

  ${media.m} {
    position: sticky;
    width: auto;
    max-height: 100vh;
    padding-bottom: 4rem;
    margin-top: 1rem;
  }
`;

export const StyledSideMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: width 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
  position: relative;

  ${media.m} {
    &[aria-hidden='true'] {
      width: 4rem;
    }

    &[aria-hidden='false'] {
      width: 20rem;
    }
  }

  ${media.l} {
    &[aria-hidden='false'] {
      width: 25rem;
    }
  }

  ${media.xl} {
    &[aria-hidden='false'] {
      width: 30rem;
    }
  }
`;

export const StyledDialog = styled.div`
  margin: auto;
  max-width: 33rem;
  box-sizing: border-box;
  display: contents;

  ${media.m} {
    border-radius: ${borderRadius.xl};
    margin: 0 auto;
  }
`;

export const StyledSideMenuBox = styled(motion.div)`
  box-shadow: rgb(25 28 31 / 5%) 0 2px 4px, rgb(25 28 31 / 10%) 0 3px 16px;
  height: 100vh;

  ${media.m} {
    border-radius: ${borderRadius.xl};
    height: 100%;
    min-width: 20rem;
  }

  ${media.l} {
    min-width: 25rem;
  }

  ${media.xl} {
    min-width: 30rem;
  }
`;
