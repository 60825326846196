import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const StarIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M10.4136 18.423L2.88028 12.177C2.80819 12.1172 2.85045 12 2.94411 12H12.6668L15.9071 2.27916C15.9373 2.18855 16.0652 2.1878 16.0965 2.27805L19.4639 12H29.0566C29.1502 12 29.1925 12.1171 29.1205 12.1769L21.6024 18.423L23.9356 27.7423C23.958 27.8318 23.8576 27.9013 23.7817 27.8488L16.008 22.4667L8.21896 27.8488C8.14302 27.9013 8.04259 27.8316 8.06515 27.7421L10.4136 18.423Z"
        stroke={colors[iconColor]}
        fill={colors[iconColor]}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
