import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function WamoIcon({ iconColor = 'primary', ...rest }: TIconProps): ReactElement {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M2.96756 12.3854C2.73505 12.375 2.58342 12.3021 2.50254 12.1667C2.35091 11.9271 2.43178 11.5208 2.48233 11.3854L3.07876 9.57292C3.17985 9.35417 3.36181 9.35417 3.38203 9.35417H3.39214C3.59432 9.35417 3.66508 9.53125 3.67519 9.55208L4.60523 12.3854H2.96756ZM9.58899 17.5729H7.83001C7.23358 17.5729 6.56638 17.0521 6.3642 16.4375L4.05934 9.41667C3.99868 9.25 3.72574 8.96875 3.38203 8.9375C3.6853 8.625 4.18065 8.41667 4.55468 8.41667H4.6861H4.69621H6.31366C6.89998 8.41667 7.56718 8.9375 7.76936 9.55208L10.0843 16.5729V16.5833C10.145 16.75 10.4179 16.94 10.7616 16.9712C10.4584 17.2837 9.97313 17.5729 9.58899 17.5729ZM11.0649 16.4167C10.9537 16.6354 10.7819 16.6354 10.7616 16.6354H10.7515C10.5494 16.6354 10.4685 16.4583 10.4584 16.4375L8.92179 11.7708L9.63954 9.57292C9.74063 9.35417 9.92259 9.35417 9.94281 9.35417H9.95292C10.1551 9.35417 10.236 9.53125 10.2461 9.55208L11.7827 14.2188L11.0649 16.4167ZM14.411 17.5729C13.8146 17.5729 13.1474 17.0521 12.9452 16.4375L10.6403 9.41667V9.40625C10.5797 9.23958 9.98324 8.96875 9.63954 8.9375C9.94281 8.625 10.7515 8.41667 11.1256 8.41667H12.8947C13.4911 8.41667 14.1583 8.9375 14.3605 9.55208L15.1085 11.8333L16.6653 16.5729V16.5833C16.726 16.75 17.1 16.9944 17.4437 17.0256C17.1405 17.3381 16.5541 17.5729 16.1801 17.5729H14.411ZM17.6459 16.4271C17.5448 16.6354 17.3629 16.6458 17.3527 16.6458H17.3426C17.1405 16.6458 17.0596 16.4688 17.0495 16.4479L15.5129 11.7708L16.2306 9.58333C16.3317 9.36458 16.5137 9.36458 16.5339 9.36458H16.544C16.7462 9.36458 16.8271 9.54167 16.8372 9.5625L17.4437 11.3958C17.656 12.0417 18.2423 12.5729 18.8691 12.75L17.6459 16.4271ZM21.5076 12.1771C21.4166 12.3125 21.2649 12.375 21.0324 12.3958H19.2735C18.677 12.3958 18.0098 11.875 17.8077 11.2604L17.2011 9.42708V9.41667C17.1405 9.25 16.8437 8.97917 16.5 8.94792C16.8033 8.63542 17.3123 8.42708 17.6863 8.42708H19.4554C20.0519 8.42708 20.7191 8.94792 20.9212 9.5625L21.5278 11.4062C21.5278 11.4062 21.6895 11.8958 21.5076 12.1771Z"
        stroke={colors[iconColor]}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WamoIcon;
