import styled, { css } from 'styled-components';

import palette from 'theme/v2/palette';
import { FONT_FAMILY } from 'theme/v2/fonts';

export const StyledContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  p {
    line-height: 18px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid ${palette.moon400};
  gap: 1rem;
`;

export const StyledRightBox = styled.div<{ divider?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 152px;
  ${(props) =>
    props.divider &&
    css`
      border-left: 1.5px solid ${palette.moon400};
      border-right: 1.5px solid ${palette.moon400};
    `}
`;

export const StyledItemsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.textPrimary};
  }

  td {
    vertical-align: top;
  }

  th {
    height: 36px;
    border-top: 1px solid ${palette.moon400};
    border-bottom: 1px solid ${palette.moon400};
    font-weight: 400;
    padding-right: 32px;
  }

  th:first-child {
    width: 40%;
    padding-right: 0;
  }

  th:last-child {
    text-align: right;
    padding-right: 0;
  }

  > tbody > tr > td {
    font-weight: 400;
    color: ${(props) => props.theme.colors.textPrimary};
    vertical-align: top;
  }

  tbody > tr:first-child > td {
    padding-top: 16px;
  }

  tbody > tr:last-child > td {
    padding-bottom: 16px;
    border-bottom: 1px solid ${palette.moon200};
  }

  table > tr:first-child > td {
    font-weight: 600;
  }

  .text-align-right {
    text-align: right;
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.textSecondary};
  }
`;

export const StyledAmountDetailsTable = styled.table`
  border-collapse: collapse;
  width: 40%;

  th,
  td {
    font-family: ${FONT_FAMILY};
    padding: 16px 0;
    border-bottom: 1.5px solid ${palette.moon200};
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 12px;
    line-height: 16px;
  }

  th {
    text-align: left;
    font-weight: 600;
  }

  td {
    text-align: right;
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.textSecondary};
  }

  tr:last-child > td {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const StyledPaymentDetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.textPrimary};
    vertical-align: top;
  }

  th {
    color: ${(props) => props.theme.colors.textSecondary};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table > tbody > tr > td:first-child {
    font-weight: 600;
    padding-right: 0;
    width: 45%;
  }

  tr > th:first-child {
    border-right: 1px solid ${palette.moon300};
  }

  tr > td:first-child {
    padding-right: 16px;
    border-right: 1px solid ${palette.moon300};
  }

  table > tbody > tr > td {
    padding-bottom: 8px;
  }

  table > tbody > tr:last-child > td {
    padding-bottom: 0;
  }

  table > tbody > tr > td:first-child {
    border-right: 0;
  }
`;
