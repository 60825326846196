import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import ListItemButton from 'components/core/ListItemButton';

import { TI18nId } from 'types/common';

import { TListCardButtonProps } from './ListCardButton.types';
import ListCardButtonShimmer from './views/ListCardButtonShimmer';

function ListCardButton({
  title,
  titleId,
  description,
  descriptionId,
  onClick,
  rightComponent,
  leftComponent,
  cardBoxProps,
  titleProps,
  descriptionProps,
  containerBoxProps,
  ...restProps
}: TListCardButtonProps) {
  return (
    <ListItemButton onClick={onClick} mb="m" {...restProps}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        padding="m"
        {...cardBoxProps}>
        {leftComponent}
        <Box
          flex={1}
          paddingLeft={leftComponent ? 'm' : 0}
          paddingRight={rightComponent ? 'm' : 0}
          {...containerBoxProps}>
          {titleId && (
            <Text
              id={titleId as TI18nId}
              variant="mediumTextRegular"
              mb="xxs"
              ellipsis
              {...titleProps}
            />
          )}
          {title}
          {descriptionId && (
            <Text
              id={descriptionId as TI18nId}
              variant="xSmallTextRegular"
              ellipsis
              textAlign="left"
              {...descriptionProps}
            />
          )}
          {description}
        </Box>
        {rightComponent}
      </Box>
    </ListItemButton>
  );
}

ListCardButton.Shimmer = ListCardButtonShimmer;

export default ListCardButton;
