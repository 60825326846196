import styled from 'styled-components';

import Box from 'components/core/Box';

export const StyledPhoneNumberInputContainer = styled(Box)`
  .field-box {
    margin-bottom: 0;
    width: 100%;
  }
`;
