import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const LockIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 11V6a4 4 0 118 0v5M9 23h6a4 4 0 004-4v-6a2 2 0 00-2-2H7a2 2 0 00-2 2v6a4 4 0 004 4z"
      />
      <path stroke={colors[iconColor]} d="M14 17a2 2 0 11-4 0 2 2 0 014 0z" />
    </svg>
  );
};

export default LockIcon;
