import React from 'react';
import { useFetch } from 'use-http';
import { isEmpty } from 'lodash/fp';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Box from 'components/core/Box';
import Card from 'components/core/Card';
import Text from 'components/core/Text';
import TransactionItem from 'components/TransactionItem';
import { EmptyTransactionIcon } from 'components/Icons';
import SectionItem from 'components/core/SectionItem';

import { TPageResponse } from 'types/common';
import { TTransaction } from 'types/transaction';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TRANSACTION_DATE_FORMAT } from 'constants/dateFormat';
import routes from 'router/routes';

import { TCardDetailTransactionsProps } from '../CardDetail.types';

function CardDetailTransactions({ card }: TCardDetailTransactionsProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: cardTransactionsData, loading: cardTransactionsLoading } = useFetch<
    TPageResponse<TTransaction>
  >(`${API_ENDPOINT.TRANSACTIONS}?page=0&size=4&cardId=${card.id}`, []);

  const transactions = cardTransactionsData?.result?.content ?? [];

  function handleNavigateCardTransactions() {
    navigate(
      `${routes.transactions.path}?${queryString.stringify({
        cardId: card.id,
      })}`,
      {
        state: {
          cardTruncatedPan: card.truncatedPan,
          previousRoute: `${location.pathname}${location.search}`,
        },
      }
    );
  }

  function handleClickTransaction(transactionId: string) {
    navigate(
      `${routes.transactions.path}?${queryString.stringify({
        cardId: card.id,
        txId: transactionId,
      })}`,
      {
        state: {
          cardTruncatedPan: card.truncatedPan,
          previousRoute: `${location.pathname}${location.search}`,
        },
      }
    );
  }

  function renderContent() {
    if (isEmpty(transactions)) {
      return (
        <Box alignment="row.verticalCenter" p="m">
          <EmptyTransactionIcon width={24} height={24} />
          <Text
            id="messages.transactions.empty.title"
            variant="smallTextRegular"
            color="textTertiary"
            marginLeft="s"
          />
        </Box>
      );
    }
    return transactions.map((transaction) => (
      <TransactionItem.Button
        key={transaction.id}
        item={transaction}
        onClickTransaction={handleClickTransaction}
        dateFormat={TRANSACTION_DATE_FORMAT}
      />
    ));
  }

  if (cardTransactionsLoading) {
    return (
      <>
        <SectionItem.Shimmer p="none" pb="s" />
        <Card py="s" mb="m">
          <TransactionItem.Shimmer count={4} />
        </Card>
      </>
    );
  }

  return (
    <Box mb="l">
      <SectionItem
        titleId="label.transactions"
        rightButton={
          !isEmpty(transactions)
            ? {
                id: 'button.seeAll',
                onClick: handleNavigateCardTransactions,
              }
            : undefined
        }
        p="none"
        pb="s"
      />
      <Card>{renderContent()}</Card>
    </Box>
  );
}

export default CardDetailTransactions;
