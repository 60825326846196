import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import formatAddress from 'helpers/formatAddress';

import { TInvoicePreviewPdfMerchantDetailsProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfMerchantDetails({
  registrationName,
  merchantVatNumber,
  registrationAddress,
  email,
  phoneNumber,
}: TInvoicePreviewPdfMerchantDetailsProps) {
  return (
    <Box flex={1}>
      <Box maxWidth={310}>
        <Text textId="label.from" variant="textBodySmall" color="textSecondary" mb="s" />
        <Box display="flex" flexDirection="column" mb="m" gap="xs">
          <Text variant="textBodySubBold">{registrationName}</Text>
          {merchantVatNumber && (
            <Text variant="textBodySmallBold" color="textSecondary">
              {merchantVatNumber}
            </Text>
          )}
        </Box>
        <Text variant="textBodySmall">
          {formatAddress({
            street: registrationAddress?.street as string,
            city: registrationAddress?.city as string,
            country: registrationAddress?.country,
            postCode: registrationAddress?.postCode,
          })}
        </Text>
        <Text variant="textBodySmall">{email}</Text>
        <Text variant="textBodySmall">
          +{phoneNumber.country.callingCode} {phoneNumber.nationalNumber}
        </Text>
      </Box>
    </Box>
  );
}

export default InvoicePreviewPdfMerchantDetails;
