import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { THomeState, THomeDispatch } from './HomeProvider.types';

export const INITIAL_STATE: THomeState = {
  homeActivitiesLoading: true,
  transactionsLoading: true,
  transactions: [],
  totalBalance: 0,
  balanceCurrency: undefined,
  totalDebts: [],
  totalDebtsLoading: true,
  upcomingTransfers: [],
  upcomingTransferLoading: true,
  scheduledTransfers: [],
  scheduledTransfersLoading: true,
  recipients: [],
  recipientsLoading: true,
  cashback: undefined,
  cashbackLoading: false,
  pendingTopUpPayoutsLoading: true,
  pendingTopUpPayouts: [],
};

export const INITIAL_DISPATCH: THomeDispatch = {
  fetchHomeRequests: ASYNC_NOOP,
  fetchTransactions: ASYNC_NOOP,
  fetchHomeActivities: ASYNC_NOOP,
  fetchPayoutsTopUps: ASYNC_NOOP,
  fetchHomeRecipients: ASYNC_NOOP as never,
  fetchCashback: NOOP as THomeDispatch['fetchCashback'],
  fetchUpcomingTransfers: NOOP as THomeDispatch['fetchUpcomingTransfers'],
  fetchScheduledTransfers: NOOP as THomeDispatch['fetchScheduledTransfers'],
  stopHomePollingRequests: NOOP,
  fetchTotalDebts: NOOP as THomeDispatch['fetchTotalDebts'],
};
