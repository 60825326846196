import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const PaymentStatementIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}>
      <path
        d="M19 10V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12M19 10L12 3M19 10H12.5C12.2239 10 12 9.77614 12 9.5V3"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 12.5H9.5V13.75H10.75V12.5Z"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 12.5H13.25V13.75H14.5V12.5Z"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 16.25H9.5V17.5H10.75V16.25Z"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 16.25V17.5H14.5V16.25H14.0833"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5835 13.75V12.5H12.4168"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4165 13.3334V14.5834"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15.4167H11.5833V14.5834"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5835 16.25V17.5H12.4168"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5835 16.25H12.4168"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14.5834H10.75"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 14.5834H13.25V15"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4165 15.4166H14.4998"
        stroke={colors[iconColor]}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaymentStatementIcon;
