import { DEGULAR_FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from 'theme/fonts';

import { TTextVariants } from './theme.types';
// TODO: (melih) when font family changed we'll update font weight 700
const textVariants: TTextVariants = {
  defaults: {
    ...FONT_SIZE.mediumText,
    fontWeight: FONT_WEIGHT.normal,
  },

  degularBigLargeTitleBold: {
    fontFamily: DEGULAR_FONT_FAMILY,
    ...FONT_SIZE.xxLargeTitle,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 1.2,
  },

  degularMediumLargeTitleBold: {
    fontFamily: DEGULAR_FONT_FAMILY,
    ...FONT_SIZE.xLargeTitle,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 1.2,
  },

  degularLargeTitleBold: {
    fontFamily: DEGULAR_FONT_FAMILY,
    ...FONT_SIZE.largeTitle,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 1.2,
  },

  largeTitleBold: {
    ...FONT_SIZE.largeTitle,
    fontWeight: FONT_WEIGHT.bold,
  },
  largeTitleSemiBold: {
    ...FONT_SIZE.largeTitle,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  largeTitleRegular: {
    ...FONT_SIZE.largeTitle,
    fontWeight: FONT_WEIGHT.normal,
  },

  degularMediumTitleBold: {
    fontFamily: DEGULAR_FONT_FAMILY,
    ...FONT_SIZE.mediumTitle,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 1.2,
  },

  degularSmallTitleBold: {
    fontFamily: DEGULAR_FONT_FAMILY,
    ...FONT_SIZE.smallTitle,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 1,
  },

  mediumTitleBold: {
    ...FONT_SIZE.mediumTitle,
    fontWeight: FONT_WEIGHT.bold,
  },
  mediumTitleSemiBold: {
    ...FONT_SIZE.mediumTitle,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  mediumTitleRegular: {
    ...FONT_SIZE.mediumTitle,
    fontWeight: FONT_WEIGHT.normal,
  },

  smallTitleBold: {
    ...FONT_SIZE.smallTitle,
    fontWeight: FONT_WEIGHT.bold,
  },
  smallTitleSemiBold: {
    ...FONT_SIZE.smallTitle,
    fontWeight: FONT_WEIGHT.semiBold,
    lineHeight: 1.273,
  },
  smallTitleRegular: {
    ...FONT_SIZE.smallTitle,
    fontWeight: FONT_WEIGHT.normal,
    lineHeight: 1.273,
  },

  xSmallTitleBold: {
    ...FONT_SIZE.xSmallTitle,
    fontWeight: FONT_WEIGHT.bold,
  },
  xSmallTitleSemiBold: {
    ...FONT_SIZE.xSmallTitle,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  xSmallTitleRegular: {
    ...FONT_SIZE.xSmallTitle,
    fontWeight: FONT_WEIGHT.normal,
  },

  largeTextBold: {
    ...FONT_SIZE.largeText,
    fontWeight: FONT_WEIGHT.bold,
  },
  largeTextSemiBold: {
    ...FONT_SIZE.largeText,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  largeTextRegular: {
    ...FONT_SIZE.largeText,
    fontWeight: FONT_WEIGHT.normal,
  },

  mediumTextBold: {
    ...FONT_SIZE.mediumText,
    fontWeight: FONT_WEIGHT.bold,
  },
  mediumTextSemiBold: {
    ...FONT_SIZE.mediumText,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  mediumTextRegular: {
    ...FONT_SIZE.mediumText,
    fontWeight: FONT_WEIGHT.normal,
  },

  smallTextBold: {
    ...FONT_SIZE.smallText,
    fontWeight: FONT_WEIGHT.bold,
  },
  smallTextSemiBold: {
    ...FONT_SIZE.smallText,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  smallTextRegular: {
    ...FONT_SIZE.smallText,
    fontWeight: FONT_WEIGHT.normal,
  },

  xSmallTextBold: {
    ...FONT_SIZE.xSmallText,
    fontWeight: FONT_WEIGHT.bold,
  },
  xSmallTextSemiBold: {
    ...FONT_SIZE.xSmallText,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  xSmallTextRegular: {
    ...FONT_SIZE.xSmallText,
    fontWeight: FONT_WEIGHT.normal,
  },

  xxSmallTextBold: {
    ...FONT_SIZE.xxSmallText,
    fontWeight: FONT_WEIGHT.bold,
  },
  xxSmallTextSemiBold: {
    ...FONT_SIZE.xxSmallText,
    fontWeight: FONT_WEIGHT.semiBold,
  },
  xxSmallTextRegular: {
    ...FONT_SIZE.xxSmallText,
    fontWeight: FONT_WEIGHT.normal,
  },
};

export { textVariants };
