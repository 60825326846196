import React, { createContext, useContext, useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import FeatureFlagType from 'enums/FeatureFlagType';

import {
  TFeatureFlagsContext,
  TFeatureFlagsResponse,
  TFeatureFlagsState,
} from './FeatureFlagsProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './FeatureFlagsProvider.constants';

const featureFlagsContext = createContext<TFeatureFlagsContext>({
  featureFlagsState: INITIAL_STATE,
  featureFlagsDispatch: INITIAL_DISPATCH,
});

function FeatureFlagsProvider({ children }: TChildrenOnly) {
  const { get: requestFeatureFlags } = useFetch<TCommonResponse<TFeatureFlagsResponse>>(
    API_ENDPOINT.FEATURE_FLAGS
  );
  const [state, setState] = useState<TFeatureFlagsState>(INITIAL_STATE);

  async function getFeatureFlagsByType(type: FeatureFlagType): Promise<boolean | undefined> {
    try {
      const { result } = await requestFeatureFlags(`/${type}`);
      if (result) {
        setState((state) => ({
          ...state,
          [type]: result.enabled,
        }));
        return result.enabled;
      }
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return undefined;
    }
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = {
    featureFlagsState: state,
    featureFlagsDispatch: {
      getFeatureFlagsByType,
      clear,
    },
  };

  return <featureFlagsContext.Provider value={value}>{children}</featureFlagsContext.Provider>;
}

export default FeatureFlagsProvider;

export const useFeatureFlagsContext = (): TFeatureFlagsContext => useContext(featureFlagsContext);
