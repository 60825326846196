import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const PostIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M21 14.66a1 1 0 1 0-2 0h2ZM2 20H1h1ZM4 4V3v1Zm5.34 1a1 1 0 0 0 0-2v2ZM18 2l.707-.707a1 1 0 0 0-1.414 0L18 2Zm4 4 .707.707a1 1 0 0 0 0-1.414L22 6ZM12 16v1a1 1 0 0 0 .707-.293L12 16Zm-4 0H7a1 1 0 0 0 1 1v-1Zm0-4-.707-.707A1 1 0 0 0 7 12h1Zm11 2.66V20h2v-5.34h-2ZM19 20a1 1 0 0 1-.293.707l1.414 1.414A3 3 0 0 0 21 20h-2Zm-.293.707A1 1 0 0 1 18 21v2a3 3 0 0 0 2.121-.879l-1.414-1.414ZM18 21H4v2h14v-2ZM4 21a1 1 0 0 1-.707-.293l-1.414 1.414A3 3 0 0 0 4 23v-2Zm-.707-.293A1 1 0 0 1 3 20H1a3 3 0 0 0 .879 2.121l1.414-1.414ZM3 20V6H1v14h2ZM3 6a1 1 0 0 1 .293-.707L1.879 3.879A3 3 0 0 0 1 6h2Zm.293-.707A1 1 0 0 1 4 5V3a3 3 0 0 0-2.121.879l1.414 1.414ZM4 5h5.34V3H4v2Zm13.293-2.293 4 4 1.414-1.414-4-4-1.414 1.414Zm4 2.586-10 10 1.414 1.414 10-10-1.414-1.414ZM12 15H8v2h4v-2Zm-3 1v-4H7v4h2Zm-.293-3.293 10-10-1.414-1.414-10 10 1.414 1.414Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default PostIcon;
