import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import API_ENDPOINT from 'constants/apiEndpoint';
import { TChildrenOnly, TCommonResponse } from 'types/common';
import { TOnlinePaymentLedger, TOnlinePaymentsLedgersResponse } from 'types/onlinePayments';

import { INITIAL_DISPATCH, INITIAL_STATE } from './OnlinePaymentsProvider.constants';
import { TOnlinePaymentsContext, TOnlinePaymentsState } from './OnlinePaymentsProvider.types';

const onlinePaymentsContext = createContext<TOnlinePaymentsContext>({
  onlinePaymentsState: INITIAL_STATE,
  onlinePaymentsDispatch: INITIAL_DISPATCH,
});

function OnlinePaymentsProvider({ children }: TChildrenOnly): ReactElement {
  const { get: requestGetOnlinePaymentsLedgers, data: ledgersData } = useFetch<
    TCommonResponse<TOnlinePaymentsLedgersResponse>
  >(API_ENDPOINT.PAYMENTS_LEDGERS);
  const [{ ledgers, selectedLedger }, setState] = useState<TOnlinePaymentsState>(INITIAL_STATE);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (ledgersData?.result?.ledgers) {
      setLedgers({
        ledgers: ledgersData?.result?.ledgers ?? [],
      });
    }
  }, [ledgersData]);

  const setLedgers = useCallback((data = {}) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  }, []);

  async function handleRequestGetOnlinePaymentsLedgers() {
    try {
      setLoading(true);
      await requestGetOnlinePaymentsLedgers();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  }

  const setSelectedLedger = useCallback(
    (selectedLedgerValue) =>
      setState((state) => ({
        ...state,
        selectedLedger: selectedLedgerValue,
      })),
    []
  );

  function findMainLedger(): TOnlinePaymentLedger {
    return ledgers.find((ledger) => ledger.isMainLedger) as TOnlinePaymentLedger;
  }

  function clearState() {
    setState(INITIAL_STATE);
  }

  return (
    <onlinePaymentsContext.Provider
      value={{
        onlinePaymentsState: { ledgers, selectedLedger, loading },
        onlinePaymentsDispatch: {
          setLedgers,
          setSelectedLedger,
          requestGetOnlinePaymentsLedgers: handleRequestGetOnlinePaymentsLedgers,
          findMainLedger,
          clearState,
        },
      }}>
      {children}
    </onlinePaymentsContext.Provider>
  );
}

export default OnlinePaymentsProvider;

export const useOnlinePaymentsContext = (): TOnlinePaymentsContext =>
  useContext(onlinePaymentsContext);
