import TransactionDirectionType from 'enums/TransactionDirectionType';
import { formatAmount } from 'helpers/formatAmount';

import { TTransactionGetExchangeAmountParams } from './getTransactionExchangedAmount.types';

function getTransactionExchangeAmount({
  direction,
  fxOrder,
  directionSymbolVisible,
}: TTransactionGetExchangeAmountParams): string {
  const convertedAmountDirection = getConvertedAmountDirection();

  function getConvertedAmountDirection() {
    if (direction === TransactionDirectionType.OUTBOUND) {
      return TransactionDirectionType.INBOUND;
    }
    return TransactionDirectionType.OUTBOUND;
  }

  return formatAmount({
    amount:
      convertedAmountDirection === TransactionDirectionType.INBOUND
        ? fxOrder.targetAmount
        : fxOrder.sourceAmount,
    currency:
      convertedAmountDirection === TransactionDirectionType.INBOUND
        ? fxOrder.targetCurrency
        : fxOrder.sourceCurrency,
    ...(directionSymbolVisible && {
      direction: convertedAmountDirection,
    }),
  });
}

export default getTransactionExchangeAmount;
