import React, { ReactElement, memo } from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { TSectionItemShimmerProps } from '../SectionItem.types';

function SectionItemShimmer(props: TSectionItemShimmerProps): ReactElement {
  return (
    <Box padding="m" alignment="row.verticalCenter" justifyContent="space-between" {...props}>
      <Shimmer shape="line" width="30%" height="20px" />
      <Shimmer shape="line" width="15%" height="20px" />
    </Box>
  );
}

export default memo(SectionItemShimmer);
