import { TInvoiceDetailResponse, TInvoiceItem } from 'types/invoice';
import { TInvoiceSellItemFormData } from 'components/v2/Forms/InvoiceSellItemForm/InvoiceSellItemForm.types';
import InvoiceItemDiscountType from 'enums/InvoiceItemDiscountType';
import { convertToItemRequest } from 'context/InvoiceCreateProvider/InvoiceCreateProvider.helpers';

import { PREVIEW_SELL_ITEM_ID } from './InvoiceCreatePreviewPdfProvider.constants';

export function handleSetInvoiceItems(
  items: TInvoiceDetailResponse['items'] = [],
  sellItem: TInvoiceSellItemFormData
): TInvoiceDetailResponse['items'] {
  const item = convertToItemRequest(sellItem);
  const existingItem = items.find((item) => item.id === sellItem?.id);
  const totalPrice = calculateItemTotalPrice(item as never as TInvoiceItem);
  const discountAmount = calculateDiscountAmount(item as never as TInvoiceItem);

  const previewItem = {
    id: sellItem?.id ?? PREVIEW_SELL_ITEM_ID,
    title: item.title,
    description: item.description,
    quantity: item.quantity,
    unitPrice: !item.unitPrice ? 0 : item.unitPrice,
    totalPrice,
    unitType: item.unitType,
    vat: item.vat,
    currency: sellItem.currency,
    ...(hasDiscount(item.discount) &&
      item.discount?.type && {
        discount: {
          ...item.discount,
          amount: discountAmount,
        },
      }),
  };

  if (existingItem) {
    return items.map((item) => (item.id === previewItem.id ? previewItem : item));
  }

  return [...items, previewItem];
}

function hasDiscount(discount: TInvoiceItem['discount']) {
  return (
    discount &&
    ((discount.percentage && discount.percentage > 0) || (discount.amount && discount.amount > 0))
  );
}

function calculateDiscountAmount(item: TInvoiceItem): number {
  if (!item.unitPrice || !item.quantity) {
    return 0;
  }
  if (!item.discount?.type) {
    return 0;
  }
  const totalPrice = item.unitPrice * item.quantity;

  if (
    item.discount.type === InvoiceItemDiscountType.PERCENTAGE &&
    item.discount.percentage &&
    item.discount.percentage > 0
  ) {
    return totalPrice * (item.discount.percentage / 100);
  }
  return item.quantity * (item.discount?.amount ?? 0);
}

function calculateUnitPriceByDiscountPercentage(unitPrice: number, percentage: number) {
  return unitPrice - unitPrice * (percentage / 100);
}

function calculateUnitPriceByDiscountAbsolute(unitPrice: number, amount: number) {
  return unitPrice - amount;
}

export function calculateItemTotalPrice(item: TInvoiceItem): number {
  if (!item.unitPrice || !item.quantity) {
    return 0;
  }
  if (item.discount) {
    if (
      item.discount.type === InvoiceItemDiscountType.PERCENTAGE &&
      item.discount.percentage &&
      item.discount.percentage > 0
    ) {
      return (
        calculateUnitPriceByDiscountPercentage(item.unitPrice, item.discount.percentage) *
        item.quantity
      );
    }
    if (
      item.discount.type === InvoiceItemDiscountType.ABSOLUTE &&
      item.discount.amount &&
      item.discount.amount > 0
    ) {
      return (
        calculateUnitPriceByDiscountAbsolute(item.unitPrice, item.discount.amount) * item.quantity
      );
    }
  }
  return item.unitPrice * item.quantity;
}

export function calculateTotalTaxAmount(items: TInvoiceDetailResponse['items']): number {
  return items.reduce((acc, cur) => {
    if (!cur.vat?.rate || !cur.totalPrice) {
      return acc;
    }
    return acc + cur.totalPrice * (cur.vat.rate / 100);
  }, 0);
}
