const IS_DEV = process.env.REACT_APP_ENV === 'development';

const IS_PROD = process.env.REACT_APP_ENV === 'production';

const SENTRY_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED === 'true';
const CAPTCHA_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED === 'true';
const ANALYTICS_ENABLED = process.env.REACT_APP_ANALYTICS_ENABLED === 'true';
const FINGERPRINT_ENABLED = process.env.REACT_APP_FINGERPRINT_ENABLED === 'true';

export { IS_DEV, IS_PROD, CAPTCHA_ENABLED, SENTRY_ENABLED, ANALYTICS_ENABLED, FINGERPRINT_ENABLED };
