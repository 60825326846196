import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const HelpIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M10.667 3.77742C12.3002 3.06383 14.104 2.66797 16.0003 2.66797C17.8966 2.66797 19.7004 3.06383 21.3337 3.77742M10.667 3.77742C7.59115 5.12131 5.12034 7.59212 3.77644 10.668M10.667 3.77742C11.4279 5.52139 12.3516 7.63857 13.3337 9.88936M21.3337 28.2252C19.7004 28.9388 17.8966 29.3346 16.0003 29.3346C14.104 29.3346 12.3002 28.9388 10.667 28.2252M21.3337 28.2252C24.4095 26.8813 26.8803 24.4105 28.2242 21.3346M21.3337 28.2252C21.3337 28.2252 20.2263 25.6871 18.667 22.1132M21.3337 3.77742C24.4095 5.12131 26.8803 7.59212 28.2242 10.668M21.3337 3.77742L18.667 9.88936M10.667 28.2252C7.59115 26.8813 5.12034 24.4105 3.77644 21.3346M10.667 28.2252L13.3337 22.1132M3.77644 10.668C3.06285 12.3012 2.66699 14.105 2.66699 16.0013C2.66699 17.8976 3.06285 19.7014 3.77644 21.3346M3.77644 10.668L9.88839 13.3346M28.2242 21.3346C28.9378 19.7014 29.3337 17.8976 29.3337 16.0013C29.3337 14.105 28.9378 12.3012 28.2242 10.668M28.2242 21.3346L22.1123 18.668M28.2242 10.668L22.1123 13.3346M3.77644 21.3346L9.88839 18.668M18.667 9.88936C17.8504 9.53257 16.9485 9.33464 16.0003 9.33464C15.0522 9.33464 14.1503 9.53257 13.3337 9.88936M18.667 9.88936C20.2049 10.5613 21.4403 11.7967 22.1123 13.3346M22.1123 13.3346C22.4691 14.1513 22.667 15.0532 22.667 16.0013C22.667 16.9494 22.4691 17.8514 22.1123 18.668M22.1123 18.668C21.4403 20.2059 20.2049 21.4413 18.667 22.1132M18.667 22.1132C17.8504 22.47 16.9485 22.668 16.0003 22.668C15.0522 22.668 14.1503 22.47 13.3337 22.1132M13.3337 22.1132C11.7957 21.4413 10.5603 20.2059 9.88839 18.668M9.88839 18.668C9.53159 17.8514 9.33366 16.9494 9.33366 16.0013C9.33366 15.0532 9.53159 14.1513 9.88839 13.3346M9.88839 13.3346C10.5603 11.7967 11.7957 10.5613 13.3337 9.88936"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default HelpIcon;
