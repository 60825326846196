import React, { useImperativeHandle, useState } from 'react';
import { debounce, reject } from 'lodash/fp';

import Modal, { useModal } from 'components/core/Modal';
import Card from 'components/core/Card';
import Text from 'components/core/Text';
import Box from 'components/core/Box';
import EmptyContent from 'components/EmptyContent';
import { SearchIcon } from 'components/Icons';

import { TCountry } from 'types/common';
import { TCountryDetailResponse } from 'context/CountriesProvider/CountriesProvider.types';

import { TSelectCountryModalProps, TSelectCountryModalState } from './SelectCountryModal.types';
import { StyledUnavailableContent } from './SelectCountryModal.styles';
import CountryItem from './views/CountryItem';

function SelectCountryModal({ modalRef, showCallingCode, options }: TSelectCountryModalProps) {
  const ref = useModal();
  const [state, setState] = useState<TSelectCountryModalState | undefined>();
  const [filteredCountries, setFilteredCountries] = useState<TCountryDetailResponse[] | undefined>(
    undefined
  );

  useImperativeHandle(modalRef, () => ({
    show: showModal,
  }));

  function showModal(state?: TSelectCountryModalState) {
    setState(state);
    ref.current?.show(true);
  }

  function handleClearCountrySearch() {
    return setFilteredCountries(undefined);
  }

  const handleCountrySearch = debounce(300, (searchText: string) => {
    if (searchText.length === 0) {
      return handleClearCountrySearch();
    }
    const country = options.filter(
      ({ code, name, callingCode }: TCountry) =>
        code.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        (showCallingCode && callingCode.indexOf(searchText.replace('+', '')) > -1)
    );

    return setFilteredCountries(country);
  });

  function handleSelectCountry(country: TCountryDetailResponse) {
    state?.onSelect(country);
    ref.current?.show(false);
  }

  function handleUnavailableSelectCountry(country: TCountryDetailResponse) {
    ref.current?.show(false);
    state?.onSelectUnavailableCountry?.(country);
  }

  const countries = filteredCountries ?? options;

  const selectAvailableListOptions = state?.filterOption
    ? countries.filter(state.filterOption)
    : countries;

  const unavailableListOptions = state?.filterOption ? reject(state.filterOption, countries) : [];

  return (
    <Modal
      ref={ref}
      header={{
        id: (state?.headerTitleId ?? 'label.selectCountry') as never,
        onSearch: handleCountrySearch,
      }}
      afterClose={handleClearCountrySearch}>
      <Box minHeight="42rem">
        {selectAvailableListOptions?.length > 0 && (
          <Card mb="l">
            {selectAvailableListOptions.map((country) => (
              <CountryItem
                key={country.code}
                country={country}
                onClick={handleSelectCountry}
                showCallingCode={showCallingCode}
              />
            ))}
          </Card>
        )}
        {unavailableListOptions?.length > 0 && (
          <>
            <Text
              id={state?.showWaitingList ? 'label.waitingListOnly' : 'label.unavailableCountries'}
              variant="mediumTextRegular"
              mb="s"
            />
            <Card>
              {unavailableListOptions.map((country) => (
                <StyledUnavailableContent
                  key={country.code}
                  showWaitingList={state?.showWaitingList}>
                  <CountryItem
                    key={country.code}
                    country={country}
                    onClick={handleUnavailableSelectCountry}
                    showCallingCode={showCallingCode}
                  />
                </StyledUnavailableContent>
              ))}
            </Card>
          </>
        )}
        {filteredCountries?.length === 0 && (
          <Card>
            <EmptyContent textId="label.noResultFound" icon={SearchIcon} />
          </Card>
        )}
      </Box>
    </Modal>
  );
}

export default SelectCountryModal;
