import React, { ReactElement, Ref, forwardRef } from 'react';

import { StyledBox } from './Box.style';
import { TBoxProps } from './Box.types';

function Box({ children, ...rest }: TBoxProps, ref: Ref<HTMLInputElement | null>): ReactElement {
  return (
    <StyledBox ref={ref} {...rest}>
      {children}
    </StyledBox>
  );
}

export default forwardRef(Box);
