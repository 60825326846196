import React, { PropsWithChildren } from 'react';

import StickyScrollHeader from 'components/StickyScrollHeader';

import { TPageProps } from '../Page.types';

function PageShimmer({ navButton, children }: PropsWithChildren<Pick<TPageProps, 'navButton'>>) {
  return (
    <div>
      <StickyScrollHeader.Shimmer navButton={navButton} paddingX={0} />
      {children}
    </div>
  );
}

export default PageShimmer;
