import React from 'react';

import { TIconProps } from '../Icon.types';

const AudIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#1A2A6C"
        d="M24.996 48.004c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24Z"
      />
      <path fill="#FEFEFE" d="M24.932 23.996h.064v-.064a12.97 12.97 0 0 1-.064.064Z" />
      <path
        fill="#FEFEFE"
        d="M24.996 12.518V-.004h-.004C11.739-.002.996 10.742.996 23.996h12.522v-7.051l7.05 7.051h4.364l.064-.064v-4.363l-7.051-7.051h7.051Z"
      />
      <path
        fill="#C8102E"
        d="M13.138 3.128a24.115 24.115 0 0 0-9.012 9.011v11.858h6.26V9.39h14.61V3.128H13.137Z"
      />
      <path fill="#C8102E" d="M24.995 21.046 16.47 12.52h-2.952l11.478 11.478v-2.952Z" />
      <path
        fill="#FEFEFE"
        d="m15.47 28.172 1.317 2.754 2.975-.687-1.332 2.747 2.392 1.897-2.979.67.009 3.054-2.382-1.91-2.381 1.91.008-3.053-2.978-.672 2.392-1.896-1.332-2.747 2.974.687 1.317-2.754ZM36.928 33.389l.659 1.377 1.487-.344-.666 1.373 1.196.949-1.49.336.005 1.526-1.19-.955-1.192.955.005-1.526-1.49-.336 1.196-.949-.666-1.373 1.488.344.658-1.377ZM30.802 18.78l.659 1.378 1.487-.344-.666 1.374 1.196.948-1.489.336.004 1.526-1.19-.955-1.192.955.005-1.526-1.49-.336 1.196-.948-.666-1.374 1.488.344.658-1.377ZM36.928 10.433l.659 1.377 1.487-.344-.666 1.373 1.196.949-1.49.336.005 1.526-1.19-.955-1.192.955.005-1.526-1.49-.336 1.196-.949-.666-1.373 1.488.344.658-1.377ZM42.28 16.693l.658 1.377 1.488-.343-.666 1.373 1.196.949-1.49.335.005 1.527-1.191-.955-1.191.955.004-1.527-1.489-.335L40.8 19.1l-.666-1.373 1.487.343.659-1.377ZM38.454 23.998l.518 1.594h1.676l-1.356.985.518 1.595-1.356-.986-1.357.986.518-1.595-1.356-.985h1.677l.518-1.594Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.996 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default AudIcon;
