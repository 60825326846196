import { FpjsClientOptions, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react';

export const FINGERPRINT_LOAD_OPTIONS: FpjsClientOptions['loadOptions'] = {
  scriptUrlPattern: [
    `${process.env.REACT_APP_FINGERPRINT_URL}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
    FingerprintJSPro.defaultScriptUrlPattern,
  ],
  endpoint: [process.env.REACT_APP_FINGERPRINT_URL as string, FingerprintJSPro.defaultEndpoint],
  apiKey: process.env.REACT_APP_FINGERPRINT_PUBLIC_KEY as string,
  region: 'eu',
};
