import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import TextButton from 'components/core/TextButton';

import { TSectionItemProps } from './SectionItem.types';
import SectionItemShimmer from './views/SectionItemShimmer';

function SectionItem({ titleId, count, rightButton, ...rest }: TSectionItemProps) {
  const { t } = useTranslation();

  return (
    <Box padding="m" alignment="row.verticalCenter" {...rest}>
      <Box flex="auto">
        {count ? (
          <Text
            id="label.textWithCount"
            values={{
              text: t(titleId),
              count,
            }}
            variant="smallTextSemiBold"
            color="textSecondary"
          />
        ) : (
          <Text id={titleId} variant="smallTextSemiBold" color="textSecondary" />
        )}
      </Box>
      {rightButton && (
        <Box>
          <TextButton
            id={rightButton.id}
            onClick={rightButton.onClick}
            textVariant="smallTextSemiBold"
          />
        </Box>
      )}
    </Box>
  );
}

SectionItem.Shimmer = SectionItemShimmer;

export default SectionItem;
