import styled, { css } from 'styled-components';

import Avatar from 'components/Avatar';

import media from 'helpers/media';
import { getColor } from 'helpers/theme';

export const StyledLayout = styled.div`
  display: flex;
  min-height: calc(100vh - 2rem);
  position: relative;
  font: inherit;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
`;

export const StyledWrapper = styled(StyledContainer)(({ theme }) => ({
  minHeight: '100vh',
  color: theme.colors.primary,
  backgroundColor: theme.colors.primaryBackground,
}));

export const StyledContent = styled.div<{ visibleMenu?: boolean; isProgressSteps?: boolean }>`
  grid-template-rows: 1fr auto auto 0 0;
  grid-template-areas:
    'main'
    'acts'
    'menu'
    'side'
    'toast';
  box-sizing: border-box;
  font: inherit;
  min-height: 100vh;
  display: grid;
  padding: 0 2rem;

  ${media.s} {
    grid-template-areas:
      'menu . main . .'
      'menu . acts . .'
      'side side side side side'
      'toast toast toast toast toast';
    grid-template-columns: 4rem 1fr 26rem 1fr 0;
    grid-template-rows: 1fr auto auto 0 0;
    ${(props) =>
      props.isProgressSteps &&
      css`
        grid-template-columns: 12.5rem 1fr 26rem 1fr 0;
      `}
    padding: 0;
  }

  ${media.m} {
    grid-template-areas:
      'menu . main . side'
      'menu . acts . side'
      '. . toast . .';
    grid-template-columns: 4.5rem minmax(2rem, 1fr) 30rem minmax(2rem, 1fr) minmax(4rem, auto);

    ${(props) =>
      props.isProgressSteps &&
      css`
        grid-template-columns: 12.5rem minmax(2rem, 1fr) 30rem minmax(2rem, 1fr) minmax(4rem, auto);
      `}
  }

  ${media.l} {
    grid-template-columns:
      ${(props) => (props.visibleMenu ? '12.5rem' : '4rem')} minmax(2rem, 1fr)
      32rem minmax(2rem, 1fr) minmax(4rem, auto);
  }

  ${media.xl} {
    width: 100%;
    max-width: 100rem;
    grid-template-columns:
      ${(props) => (props.visibleMenu ? '15rem' : '4rem')} minmax(2rem, 1fr) 36rem minmax(2rem, 1fr)
      minmax(4rem, auto);
  }
`;

export const StyledNav = styled.nav<{ showMobileNav?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  pointer-events: auto;
  box-sizing: border-box;
  font: inherit;
  grid-area: menu / menu / menu / menu;
  position: sticky;
  bottom: 0;
  z-index: 10;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: ${getColor('backgroundTertiary')};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);

  ${media.s} {
    padding: 1.5rem 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 0;
    bottom: unset;
    box-shadow: none;
    border-right: 1px solid ${getColor('borderDivider')};
  }
`;

export const StyledMain = styled.main<{ isPageModal?: boolean }>`
  box-sizing: border-box;
  margin: 0;
  font: inherit;
  display: flex;
  flex-direction: column;
  grid-area: main / main / main / main;
  min-width: 0;
  padding-bottom: 1rem;
`;

export const FrozenAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.primary};

  svg > path {
    stroke: ${({ theme }) => theme.colors.freezeIcon} !important;
  }
`;
