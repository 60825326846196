import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DeclineAltIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M1 21L23 21L12 2.66667L1 21Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12 10L12 14M12 17L12 16.5"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeclineAltIcon;
