import { TThemePrimitives as TThemePrimitivesV2 } from 'theme/v2/theme.types';

import darkThemePrimitives from './darkThemePrimitives';
import darkThemePrimitives2 from './v2/darkThemePrimitives';
import lightThemePrimitives from './lightThemePrimitives';
import lightThemePrimitives2 from './v2/lightThemePrimitives';
import spacing from './spacing';
import borderRadius from './borderRadius';
import BREAKPOINTS from './breakpoints';
import { TThemePrimitives, TTheme } from './theme.types';

function createTheme(themePrimitives: TThemePrimitives & Partial<TThemePrimitivesV2>): TTheme {
  return {
    colors: themePrimitives,
    space: spacing,
    breakpoints: BREAKPOINTS,
    borderRadius,
  };
}

export const lightTheme = createTheme({
  ...lightThemePrimitives,
  // TODO: (semih) you need to put REACT_APP_IS_NEW_DESIGN=true to .env file to work this
  ...(process.env.REACT_APP_IS_NEW_DESIGN && lightThemePrimitives2),
});

export const darkTheme = createTheme({
  ...darkThemePrimitives,
  // TODO: (furkan) you need to put REACT_APP_IS_NEW_DESIGN=true to .env file to work this
  ...(process.env.REACT_APP_IS_NEW_DESIGN && darkThemePrimitives2),
});
