import React, { ReactElement, memo } from 'react';

import Box from 'components/core/Box';
import ListCardButton from 'components/ListCardButton';
import SideLayoutPage from 'components/SideLayoutPage';
import Shimmer from 'components/core/Shimmer';

import ActionButtonShimmer from './ActionButtonShimmer';

function CardDetailShimmer(): ReactElement {
  return (
    <SideLayoutPage.Shimmer>
      <Box alignment="col.top.left">
        <Box alignSelf="center" p="m">
          <Shimmer color="secondary" shape="square" width={232} height={148} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" marginY="l">
        <ActionButtonShimmer />
        <ActionButtonShimmer />
        <ActionButtonShimmer />
      </Box>
      <Box marginX="m">
        <Box height={16}>
          <Shimmer shape="line" width="25%" />
        </Box>
        <ListCardButton.Shimmer description leftComponent rightComponent />
        <Box height={16}>
          <Shimmer shape="line" width="25%" />
        </Box>
        <ListCardButton.Shimmer description leftComponent rightComponent />
        <Box height={16}>
          <Shimmer shape="line" width="25%" />
        </Box>
        <ListCardButton.Shimmer description leftComponent rightComponent />
        <ListCardButton.Shimmer leftComponent rightComponent mt="s" />
      </Box>
    </SideLayoutPage.Shimmer>
  );
}

export default memo(CardDetailShimmer);
