import React, { useRef } from 'react';
import addDate from 'date-fns/add';

import Box from 'components/core/Box';
import {
  CheckListIcon,
  InfoQuestionMarkIcon,
  HomeDeliveryIcon,
  LocationIcon,
} from 'components/Icons';
import Button from 'components/core/Button';
import PopconfirmModal from 'modals/PopconfirmModal';
import Card from 'components/core/Card';

import { useCardsContext } from 'context/CardsProvider';
import useCardActivate from 'hooks/useCardActivate';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { showSuccessAlert } from 'helpers/showAlertModal';
import { TI18nId } from 'types/common';
import formatAddress from 'helpers/formatAddress';

import OrderStatusItem from './OrderStatusItem';
import { TCardOrderStatusesProps } from '../CardDetail.types';

function CardOrderStatuses({
  createdAt,
  cardId,
  shippingAddress,
  refetchCardDetail,
}: TCardOrderStatusesProps) {
  const cardActivationConfirmModalRef = useRef<TPopconfirmModalRef>(null);
  const { cardsDispatch } = useCardsContext();
  const estimatedDate = addDate(new Date(createdAt), {
    weeks: 1,
  });
  const { handleCardActivate } = useCardActivate({
    cardId,
    onSuccess: handleCardActivateSuccess,
  });

  function handleCardActivateSuccess() {
    showSuccessAlert({
      titleId: 'screens.cardDetail.activation.success',
    });
    cardsDispatch.getCards();
    refetchCardDetail();
  }

  function handleOpenActivateModal() {
    cardActivationConfirmModalRef.current?.show({
      titleId: 'screens.cardDetail.activation.title',
      descriptionId: 'screens.cardDetail.activation.description',
      contentIcon: <InfoQuestionMarkIcon iconColor="warning" width="80px" height="80px" />,
      cancelButtonTitleId: 'button.notNow',
      confirmButtonProps: {
        id: 'button.activate',
      },
      onConfirm: () => handleCardActivate(),
    });
  }

  return (
    <>
      <Box alignment="col.verticalCenter" py="1rem" px="2rem">
        <Card p="m" gap="m" mb="m">
          <OrderStatusItem
            icon={HomeDeliveryIcon}
            titleId="screens.cardList.orderStatuses.willBeDelivered"
            descriptionId="screens.cardList.orderStatuses.estimatedDate"
            descriptionDate={estimatedDate.toISOString()}
            titleColor="primary"
            iconColor="primary"
          />
          <OrderStatusItem
            icon={CheckListIcon}
            titleId="screens.cardList.orderStatuses.orderConfirmed"
            descriptionDate={createdAt}
            titleColor="success"
            iconColor="success"
          />
        </Card>
        {shippingAddress && (
          <Card p="m">
            <OrderStatusItem
              icon={LocationIcon}
              titleId="label.willBeDeliveredTo"
              descriptionId={formatAddress(shippingAddress as never) as TI18nId}
              iconColor="tertiary"
              titleColor="textPrimary"
            />
          </Card>
        )}
        <Button marginTop="xxl" id="label.activate" onClick={handleOpenActivateModal} />
      </Box>
      <PopconfirmModal ref={cardActivationConfirmModalRef} />
    </>
  );
}

export default CardOrderStatuses;
