import {
  THandleSetupInvoiceSettingsValues,
  THandleUpdateBusinessDetailsParams,
  THandleUpdateDefaultSettingsParams,
  TInvoiceSettingsState,
} from './InvoiceSettingsProvider.types';

export function covertToUpdateInvoiceSettingsBusinessLogoRequest(
  values: THandleUpdateBusinessDetailsParams['values']
) {
  const formData = new FormData();
  if (values.merchantLogo) {
    formData.append('merchantLogo', values.merchantLogo);
  }
  return formData;
}

export function covertToUpdateInvoiceDefaultSettingsRequest(
  values: THandleUpdateDefaultSettingsParams['values']
) {
  return {
    invoiceNumberPrefix: values.invoiceNumberPrefix,
    nextInvoiceNumber: values.nextInvoiceNumber,
    contactPhoneNumber: {
      callingCode: values.contactPhoneNumber.country.callingCode,
      nationalNumber: values.contactPhoneNumber.nationalNumber,
    },
    contactEmail: values.contactEmail,
    defaultCurrencyCode: values.defaultCurrency.code,
  };
}

export function covertToSetupInvoiceSettingsRequest(values: THandleSetupInvoiceSettingsValues) {
  return {
    merchantVatNumber: values.merchantVatNumber,
    invoiceNumberPrefix: values.invoiceNumberPrefix,
    nextInvoiceNumber: values.nextInvoiceNumber,
    contactPhoneNumber: {
      callingCode: values.contactPhoneNumber.country.callingCode,
      nationalNumber: values.contactPhoneNumber.nationalNumber,
    },
    contactEmail: values.contactEmail,
    defaultCurrencyCode: values.defaultCurrency.code,
    shareInvoiceEmailTitle: values.shareInvoiceEmailTitle,
    shareInvoiceEmailBody: values.shareInvoiceEmailBody,
    defaultReceivingLedgerId: values.defaultReceivingLedgerId,
  };
}

export function getNextInvoiceNumberWithPrefix(state: TInvoiceSettingsState): string {
  if (state.settings?.invoiceNumberPrefix && state.settings.nextInvoiceNumber) {
    return `${state.settings.invoiceNumberPrefix}-${state.settings.nextInvoiceNumber}`;
  }
  return '';
}
