import React from 'react';

import { TCardFreezeMaskProps } from '../WamoCard.types';
import { StyledImage } from '../WamoCard.styles';

function CardFreezeMask({ children, isFrozen }: TCardFreezeMaskProps) {
  if (isFrozen) {
    return <StyledImage>{children}</StyledImage>;
  }
  return <>{children}</>;
}

export default CardFreezeMask;
