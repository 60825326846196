import { padCharsEnd, padCharsStart } from 'lodash/fp';

export function formatSecondsToTime(secs: number) {
  const hoursValue = padCharsEnd('0', 2, Math.floor(secs / (60 * 60)).toString());
  const divisorForMinutes = secs % (60 * 60);
  const minutesValue = padCharsStart('0', 2, Math.floor(divisorForMinutes / 60).toString());

  const divisorForSeconds = divisorForMinutes % 60;
  const secondsValue = padCharsStart('0', 2, Math.ceil(divisorForSeconds).toString());

  return {
    hours: hoursValue,
    minutes: minutesValue,
    seconds: secondsValue,
    time: secs,
  };
}
