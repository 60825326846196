import {
  TOnboardingProgressSteps,
  TOnboardingProgressStepsItemType,
} from 'components/OnboardingProgressSteps/OnboardingProgressSteps.types';

export const COMPANY_ONBOARDING_PROGRESS_STEPS: TOnboardingProgressSteps[] = [
  {
    index: 1,
    labelId: 'components.onboardingProgressSteps.createAccount',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 2,
    labelId: 'components.onboardingProgressSteps.verifyIdentity',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 21,
        labelId: 'components.onboardingProgressSteps.roleInCompany',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 22,
        labelId: 'components.onboardingProgressSteps.verify',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 3,
    labelId: 'components.onboardingProgressSteps.companyInformation',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 31,
        labelId: 'components.onboardingProgressSteps.corporateInfo',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 32,
        labelId: 'components.onboardingProgressSteps.address',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 33,
        labelId: 'components.onboardingProgressSteps.industry',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 34,
        labelId: 'components.onboardingProgressSteps.reasons',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 35,
        labelId: 'components.onboardingProgressSteps.forecast',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 36,
        labelId: 'components.onboardingProgressSteps.onlinePresence',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 37,
        labelId: 'components.onboardingProgressSteps.businessDetails',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 4,
    labelId: 'components.onboardingProgressSteps.businessOwners',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 41,
        labelId: 'components.onboardingProgressSteps.directors',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 42,
        labelId: 'components.onboardingProgressSteps.shareholders',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 5,
    labelId: 'components.onboardingProgressSteps.documents',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 51,
        labelId: 'screens.onboardingDocumentDetail.2.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 52,
        labelId: 'screens.onboardingDocumentDetail.3.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 53,
        labelId: 'screens.onboardingDocumentDetail.5.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 54,
        labelId: 'screens.onboardingDocumentDetail.7.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 6,
    labelId: 'components.onboardingProgressSteps.pricing',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 7,
    labelId: 'components.onboardingProgressSteps.welcome',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
];

export const SOLE_TRADER_ONBOARDING_PROGRESS_STEPS: TOnboardingProgressSteps[] = [
  {
    index: 1,
    labelId: 'components.onboardingProgressSteps.createAccount',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 2,
    labelId: 'components.onboardingProgressSteps.verifyIdentity',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 3,
    labelId: 'components.onboardingProgressSteps.companyInformation',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 31,
        labelId: 'components.onboardingProgressSteps.businessInfo',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 32,
        labelId: 'components.onboardingProgressSteps.tradingAddress',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 33,
        labelId: 'components.onboardingProgressSteps.industry',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 34,
        labelId: 'components.onboardingProgressSteps.reasons',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 35,
        labelId: 'components.onboardingProgressSteps.forecast',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 36,
        labelId: 'components.onboardingProgressSteps.onlinePresence',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 37,
        labelId: 'components.onboardingProgressSteps.businessDetails',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 4,
    labelId: 'components.onboardingProgressSteps.businessOwner',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 5,
    labelId: 'components.onboardingProgressSteps.documents',
    type: TOnboardingProgressStepsItemType.PARENT,
    subSteps: [
      {
        index: 51,
        labelId: 'screens.onboardingDocumentDetail.4.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
      {
        index: 52,
        labelId: 'screens.onboardingDocumentDetail.5.title',
        type: TOnboardingProgressStepsItemType.CHILD,
      },
    ],
  },
  {
    index: 6,
    labelId: 'components.onboardingProgressSteps.pricing',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
  {
    index: 7,
    labelId: 'components.onboardingProgressSteps.welcome',
    type: TOnboardingProgressStepsItemType.PARENT,
  },
];
