import React, { createContext, ReactElement, useContext, useState } from 'react';

import { TChildrenOnly } from 'types/common';
import { TInvoiceDetailResponse } from 'types/invoice';

import { TInvoiceCreateState, TInvoiceCreateContext } from './InvoiceCreateProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoiceCreateProvider.constants';
import { useUpdateDraftInvoice } from './InvoiceCreateProvider.hooks';

const invoiceCreateContext = createContext<TInvoiceCreateContext>({
  invoiceCreateState: INITIAL_STATE,
  invoiceCreateDispatch: INITIAL_DISPATCH,
});

function InvoiceCreateProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TInvoiceCreateState>(INITIAL_STATE);
  const {
    getDraftInvoiceDetail,
    updateIssueDate,
    updateDueDate,
    addCustomer,
    removeCustomer,
    addItem,
    editItem,
    deleteItem,
    updateCurrency,
    updatePreferredReceivingLedger,
    updateFooter,
    finalizeInvoice,
    deleteDraftInvoice,
    previewInvoicePdf,
  } = useUpdateDraftInvoice(state);

  function setInvoice(invoice: TInvoiceDetailResponse) {
    setState((prevState) => ({
      ...prevState,
      invoice,
    }));
  }

  function setIsSubmitted() {
    setState((prevState) => ({
      ...prevState,
      isSubmitted: true,
    }));
  }

  function setSelectAfterIssueDateDays(afterDays?: number) {
    setState((prevState) => ({
      ...prevState,
      selectedAfterIssueDateDays: afterDays,
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TInvoiceCreateContext = {
    invoiceCreateState: state,
    invoiceCreateDispatch: {
      setInvoice,
      setIsSubmitted,
      setSelectAfterIssueDateDays,
      getDraftInvoiceDetail,
      updateIssueDate,
      updateDueDate,
      addCustomer,
      removeCustomer,
      addItem,
      editItem,
      deleteItem,
      updateCurrency,
      updatePreferredReceivingLedger,
      updateFooter,
      finalizeInvoice,
      deleteDraftInvoice,
      previewInvoicePdf,
      clear,
    },
  };

  return <invoiceCreateContext.Provider value={value}>{children}</invoiceCreateContext.Provider>;
}

export default InvoiceCreateProvider;

export const useInvoiceCreateContext = (): TInvoiceCreateContext =>
  useContext(invoiceCreateContext);
