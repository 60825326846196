import NOOP, { ASYNC_NOOP } from 'constants/noop';
import { INVOICE_DUE_DATE_AFTER_DAYS } from 'constants/invoiceDueDateAfterDays';

import { TInvoiceCreateDispatch, TInvoiceCreateState } from './InvoiceCreateProvider.types';

export const INITIAL_STATE: TInvoiceCreateState = {
  invoice: undefined,
  selectedAfterIssueDateDays: INVOICE_DUE_DATE_AFTER_DAYS.AFTER_15_DAYS,
  isSubmitted: false,
};

export const INITIAL_DISPATCH: TInvoiceCreateDispatch = {
  setInvoice: NOOP,
  setIsSubmitted: NOOP,
  setSelectAfterIssueDateDays: NOOP,
  getDraftInvoiceDetail: ASYNC_NOOP,
  updateIssueDate: ASYNC_NOOP,
  updateDueDate: ASYNC_NOOP,
  addCustomer: ASYNC_NOOP,
  removeCustomer: ASYNC_NOOP,
  addItem: ASYNC_NOOP,
  editItem: ASYNC_NOOP,
  deleteItem: ASYNC_NOOP,
  updateCurrency: ASYNC_NOOP,
  updatePreferredReceivingLedger: ASYNC_NOOP,
  updateFooter: ASYNC_NOOP,
  finalizeInvoice: ASYNC_NOOP,
  deleteDraftInvoice: ASYNC_NOOP,
  previewInvoicePdf: ASYNC_NOOP,
  clear: NOOP,
};
