import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const HomeOutlineIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M10.4239 3.81309L9.7595 3.06568L10.4239 3.81309ZM14.5761 3.81309L13.9118 4.5605L14.5761 3.81309ZM20.8261 9.36864L21.4905 8.62123L20.8261 9.36864ZM4.17386 9.36864L4.83823 10.1161L4.17386 9.36864ZM20.875 11.7043V20.7176H22.875V11.7043H20.875ZM18.75 22.8426H6.25V24.8426H18.75V22.8426ZM4.125 20.7176V11.7043H2.125V20.7176H4.125ZM4.83823 10.1161L11.0882 4.5605L9.7595 3.06568L3.5095 8.62124L4.83823 10.1161ZM13.9118 4.5605L20.1618 10.1161L21.4905 8.62123L15.2405 3.06568L13.9118 4.5605ZM11.0882 4.5605C11.8934 3.84482 13.1066 3.84482 13.9118 4.5605L15.2405 3.06568C13.6776 1.67643 11.3224 1.67643 9.7595 3.06568L11.0882 4.5605ZM6.25 22.8426C5.0764 22.8426 4.125 21.8912 4.125 20.7176H2.125C2.125 22.9958 3.97183 24.8426 6.25 24.8426V22.8426ZM20.875 20.7176C20.875 21.8912 19.9236 22.8426 18.75 22.8426V24.8426C21.0282 24.8426 22.875 22.9958 22.875 20.7176H20.875ZM22.875 11.7043C22.875 10.526 22.3711 9.40403 21.4905 8.62123L20.1618 10.1161C20.6154 10.5193 20.875 11.0973 20.875 11.7043H22.875ZM4.125 11.7043C4.125 11.0973 4.38456 10.5193 4.83823 10.1161L3.5095 8.62124C2.62886 9.40403 2.125 10.526 2.125 11.7043H4.125Z"
        fill={colors[iconColor]}
      />
      <path
        d="M14.625 23.4375C14.625 23.9898 15.0727 24.4375 15.625 24.4375C16.1773 24.4375 16.625 23.9898 16.625 23.4375H14.625ZM8.375 23.4375C8.375 23.9898 8.82272 24.4375 9.375 24.4375C9.92728 24.4375 10.375 23.9898 10.375 23.4375H8.375ZM10.9375 16.1042H14.0625V14.1042H10.9375V16.1042ZM10.375 23.4375V16.6667H8.375V23.4375H10.375ZM14.0625 16.1042C14.3732 16.1042 14.625 16.356 14.625 16.6667H16.625C16.625 15.2514 15.4777 14.1042 14.0625 14.1042V16.1042ZM10.9375 14.1042C9.52227 14.1042 8.375 15.2514 8.375 16.6667H10.375C10.375 16.356 10.6268 16.1042 10.9375 16.1042V14.1042ZM14.625 16.6667V20.0521H16.625V16.6667H14.625ZM14.625 20.0521V23.4375H16.625V20.0521H14.625Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default HomeOutlineIcon;
