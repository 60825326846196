import { TAlertModalShowOptions, TAlertModalType } from 'components/AlertModal/AlertModal.types';
import { alertModalRef } from '../../App';

export function showErrorAlert(params: Omit<TAlertModalShowOptions, 'type'>): void {
  alertModalRef.current?.show({
    ...params,
    type: TAlertModalType.error,
  });
}

export function showSuccessAlert(params: Omit<TAlertModalShowOptions, 'type'>): void {
  alertModalRef.current?.show({
    ...params,
    type: TAlertModalType.success,
  });
}

export function showWarningAlert(params: Omit<TAlertModalShowOptions, 'type'>): void {
  alertModalRef.current?.show({
    ...params,
    type: TAlertModalType.warning,
  });
}
