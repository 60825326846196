import lazyWithRetry from 'helpers/lazyWithRetry';

import MemberType from 'enums/MemberType';

import {
  TInvoiceDetailRoutes,
  TInvoiceCreateRoutes,
  TMainRoutesByNavMenu,
  TMainRoutesWithoutNavMenu,
} from './Main.types';

const Home = lazyWithRetry(() => import('./Home'));
const AllBankAccounts = lazyWithRetry(() => import('./AllBankAccounts'));
const Account = lazyWithRetry(() => import('./Account'));
const Transactions = lazyWithRetry(() => import('./Transactions'));
const BankAccountDetail = lazyWithRetry(() => import('./BankAccountDetail'));
const CardDashboard = lazyWithRetry(() => import('./CardDashboard'));
const Cards = lazyWithRetry(() => import('./Cards'));
const GetCard = lazyWithRetry(() => import('./GetCard'));
const TeamMemberDashboard = lazyWithRetry(() => import('./TeamMemberDashboard'));
const TeamMembers = lazyWithRetry(() => import('./TeamMembers'));
const TeamMemberTypeSelect = lazyWithRetry(() => import('./TeamMemberTypeSelect'));
const AddDirector = lazyWithRetry(() => import('./AddDirector/AddDirector'));
const AddManager = lazyWithRetry(() => import('./AddManager'));
const AddEmployee = lazyWithRetry(() => import('./AddEmployee'));
const SendMoney = lazyWithRetry(() => import('./SendMoney'));
const ChangePassword = lazyWithRetry(() => import('./ChangePassword'));
const AccountSettings = lazyWithRetry(() => import('./AccountSettings'));
const SelectLanguage = lazyWithRetry(() => import('./SelectLanguage'));
const Statements = lazyWithRetry(() => import('./Statements'));
const CreateTransactionStatements = lazyWithRetry(() => import('./CreateTransactionStatements'));
const CreatePaymentStatements = lazyWithRetry(() => import('./CreatePaymentStatements'));
const CreateAccountStatements = lazyWithRetry(() => import('./CreateAccountStatements'));
const ComplianceRequest = lazyWithRetry(() => import('./ComplianceRequest'));
const UpcomingTransfers = lazyWithRetry(() => import('./UpcomingTransfers'));
const ScheduledTransactions = lazyWithRetry(() => import('./ScheduledTransactions'));
const ScheduledTransactionOrderHistory = lazyWithRetry(
  () => import('./ScheduledTransactionOrderHistory')
);
const ScheduledBulkTransferItems = lazyWithRetry(() => import('./ScheduledBulkTransferItems'));
const BillingPackageCurrent = lazyWithRetry(() => import('../PricingPlan/BillingPackageCurrent'));
const ChangePlan = lazyWithRetry(() => import('../PricingPlan/ChangePlan'));
const DebtDetails = lazyWithRetry(() => import('../PricingPlan/DebtDetails'));
const BillingPackageManage = lazyWithRetry(() => import('../PricingPlan/BillingPackageManage'));

const TopupOptions = lazyWithRetry(() => import('../Topup/TopupOptions'));
const TopupBankTransfer = lazyWithRetry(() => import('../Topup/TopupBankTransfer'));

const TopupCardAmount = lazyWithRetry(() => import('../Topup/TopupCardAmount'));

const SelectBankAccountCurrency = lazyWithRetry(() => import('./SelectBankAccountCurrency'));
const CashbackDetail = lazyWithRetry(() => import('./CashbackDetail'));

const OnlinePaymentsDashboard = lazyWithRetry(() => import('./OnlinePaymentsDashboard'));
const OnlinePayments = lazyWithRetry(() => import('./OnlinePayments'));

const ProofIdentityCheck = lazyWithRetry(
  () => import('./MemberProofOfIdentityCheck/MemberProofOfIdentityCheck')
);
const Xero = lazyWithRetry(() => import('./Xero'));
const XeroGetStarted = lazyWithRetry(() => import('./XeroGetStarted'));
const XeroConsentResult = lazyWithRetry(() => import('./XeroConsentResult'));
const XeroAddAccount = lazyWithRetry(() => import('./XeroAddAccount'));
const XeroSettings = lazyWithRetry(() => import('./XeroSettings'));

const BulkTransferDetail = lazyWithRetry(() => import('./BulkTransferDetail'));

const ReferralCampaignDetail = lazyWithRetry(() => import('../Campaign/ReferralCampaignDetail'));
const ReferralInviteeList = lazyWithRetry(() => import('../Campaign/ReferralInviteeList'));

const InvoicesDashboard = lazyWithRetry(() => import('../AccountsReceivable/InvoicesDashboard'));
const SetupInvoiceSettingsBusinessDetails = lazyWithRetry(
  () => import('../AccountsReceivable/SetupInvoiceSettingsBusinessDetails')
);
const InvoiceSettings = lazyWithRetry(() => import('../AccountsReceivable/InvoiceSettings'));
const InvoiceSettingsBusinessDetails = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceSettingsBusinessDetails')
);
const InvoiceSettingsDefault = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceSettingsDefault')
);
const InvoiceSettingsEmailMessage = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceSettingsEmailMessage')
);
const InvoiceSettingsFooterNote = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceSettingsFooterNote')
);
const InvoiceSettingsPaymentOptions = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceSettingsPaymentOptions')
);
const Invoices = lazyWithRetry(() => import('../AccountsReceivable/Invoices'));
const OverdueInvoices = lazyWithRetry(() => import('../AccountsReceivable/OverdueInvoices'));
const InvoiceCreate = lazyWithRetry(() => import('../AccountsReceivable/InvoiceCreate'));
const InvoiceSendEmail = lazyWithRetry(() => import('../AccountsReceivable/InvoiceSendEmail'));
const InvoiceDetail = lazyWithRetry(() => import('../AccountsReceivable/InvoiceDetail'));
const InvoiceCustomers = lazyWithRetry(() => import('../AccountsReceivable/InvoiceCustomers'));
const AddInvoiceCustomerInformation = lazyWithRetry(
  () => import('../AccountsReceivable/AddInvoiceCustomerInformation')
);
const InvoiceCustomerDetail = lazyWithRetry(
  () => import('../AccountsReceivable/InvoiceCustomerDetail')
);
const InvoiceItems = lazyWithRetry(() => import('../AccountsReceivable/InvoiceItems'));
const AddInvoiceItem = lazyWithRetry(() => import('../AccountsReceivable/AddInvoiceItem'));
const EditInvoiceItem = lazyWithRetry(() => import('../AccountsReceivable/EditInvoiceItem'));

export const mainRoutesByNavMenu: TMainRoutesByNavMenu = {
  statements: {
    path: '/statements',
    element: Statements,
    titleId: 'screens.headerTitle.statements',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  teamMemberDashboard: {
    path: '/team-member-dashboard',
    element: TeamMemberDashboard,
    titleId: 'screens.headerTitle.teamMembers',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  cardDashboard: {
    path: '/card-dashboard',
    element: CardDashboard,
    titleId: 'screens.headerTitle.cards',
  },
  home: {
    path: '/dashboard',
    element: Home,
    titleId: 'screens.headerTitle.home',
  },
  allBankAccounts: {
    path: '/bank-accounts',
    element: AllBankAccounts,
    titleId: 'label.allAccounts',
  },
  onlinePaymentsDashboard: {
    path: '/online-payments-dashboard',
    element: OnlinePaymentsDashboard,
    titleId: 'screens.headerTitle.onlinePayments',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  sendMoney: {
    path: '/send-money',
    element: SendMoney,
    titleId: 'screens.headerTitle.sendMoney',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  topupOptions: {
    path: '/add-money',
    element: TopupOptions,
    titleId: 'screens.headerTitle.topupOptions',
  },
  topupCard: {
    path: '/add-money/card',
    element: TopupCardAmount,
    titleId: 'screens.headerTitle.topupCardAmount',
  },
  bankTransfer: {
    path: '/bank-transfer/:id',
    element: TopupBankTransfer,
    titleId: 'screens.headerTitle.bankTransfer',
  },
  invoicesDashboard: {
    path: '/invoice-dashboard',
    element: InvoicesDashboard,
    titleId: 'label.invoices',
  },
};

export const mainRoutesWithoutNavMenu: TMainRoutesWithoutNavMenu = {
  addEmployee: {
    path: '/team-members/employee',
    element: AddEmployee,
    titleId: 'screens.headerTitle.addEmployee',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  selectBankAccountCurrency: {
    path: '/bank-accounts/currency',
    element: SelectBankAccountCurrency,
    titleId: 'screens.ledgerCreateCurrencySelect.title',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  addManager: {
    path: '/team-members/manager',
    element: AddManager,
    titleId: 'screens.headerTitle.addManager',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  addDirector: {
    path: '/team-members/director',
    element: AddDirector,
    titleId: 'screens.headerTitle.addDirector',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  teamMembers: {
    path: '/team-members',
    element: TeamMembers,
    titleId: 'screens.headerTitle.teamMembers',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  teamMemberTypeSelect: {
    path: '/team-members/select-type',
    element: TeamMemberTypeSelect,
    titleId: 'screens.headerTitle.teamMemberSelectType',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  bankAccountDetail: {
    path: '/bank-accounts/:id',
    element: BankAccountDetail,
    titleId: 'screens.headerTitle.bankAccountDetail',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  transactions: {
    path: '/transactions',
    element: Transactions,
    titleId: 'label.transactions',
  },
  onlinePayments: {
    path: '/online-payments',
    element: OnlinePayments,
    titleId: 'screens.headerTitle.onlinePayments',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  account: {
    path: '/account',
    element: Account,
    titleId: 'screens.headerTitle.account',
  },
  accountSettings: {
    path: '/account/settings',
    element: AccountSettings,
    titleId: 'screens.headerTitle.settings',
  },
  selectLanguage: {
    path: '/account/settings/select-language',
    element: SelectLanguage,
    titleId: 'label.selectLanguage',
  },
  currentPlan: {
    path: '/account/plan',
    element: BillingPackageCurrent,
    titleId: 'screens.headerTitle.currentPlan',
  },
  planChange: {
    path: '/account/plan/change',
    element: ChangePlan,
    titleId: 'screens.headerTitle.changePlan',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  billingPackageManage: {
    path: '/account/plan/manage',
    element: BillingPackageManage,
    titleId: 'label.managePlan',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  changePassword: {
    path: '/account/settings/change-pin',
    element: ChangePassword,
    titleId: 'screens.settings.changePin.title',
  },
  cards: {
    path: '/cards',
    element: Cards,
    titleId: 'screens.headerTitle.cards',
  },
  getCard: {
    path: '/cards/new',
    element: GetCard,
    titleId: 'screens.createCardSelectType.title',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR],
  },
  createTransactionStatements: {
    path: '/statements/transactions',
    element: CreateTransactionStatements,
    titleId: 'screens.headerTitle.transactionStatements',
  },
  createPaymentStatements: {
    path: '/statements/payment',
    element: CreatePaymentStatements,
    titleId: 'screens.headerTitle.paymentStatements',
  },
  createAccountStatements: {
    path: '/statements/account',
    element: CreateAccountStatements,
    titleId: 'screens.headerTitle.accountStatements',
  },
  complianceRequest: {
    path: '/compliance-request',
    element: ComplianceRequest,
    titleId: 'screens.headerTitle.complianceRequest',
  },
  upcomingTransfers: {
    path: '/upcoming-transfers',
    element: UpcomingTransfers,
    titleId: 'screens.headerTitle.upcoming',
  },
  scheduledTransactions: {
    path: '/scheduled-transactions',
    element: ScheduledTransactions,
    titleId: 'label.scheduledTransfers',
  },
  scheduledTransactionOrderHistory: {
    path: '/scheduled-transactions/:scheduledTransactionId/history',
    element: ScheduledTransactionOrderHistory,
    titleId: 'label.paymentHistory',
  },
  scheduledBulkTransferItems: {
    path: '/scheduled-transactions/:scheduledTransactionId/transfers',
    element: ScheduledBulkTransferItems,
    titleId: 'label.scheduledBulkTransfer',
  },
  debtDetails: {
    path: '/fee-details',
    element: DebtDetails,
    titleId: 'screens.headerTitle.debtDetails',
  },
  cashbackDetail: {
    path: '/cashback',
    element: CashbackDetail,
    titleId: 'screens.headerTitle.cashbackDetail',
  },
  proofOfIdentityCheck: {
    path: '/proof-of-identity/check',
    element: ProofIdentityCheck,
    titleId: 'label.updateDetails',
  },
  xero: {
    path: '/xero',
    element: Xero,
    titleId: 'screens.headerTitle.xero',
  },
  xeroGetStarted: {
    path: '/xero/getting-started',
    element: XeroGetStarted,
    titleId: 'screens.headerTitle.xeroGetStarted',
  },
  xeroConsentResult: {
    path: '/xero/result',
    element: XeroConsentResult,
    titleId: 'screens.headerTitle.xeroConsentResult',
  },
  xeroAddAccount: {
    path: '/xero/add-account',
    element: XeroAddAccount,
    titleId: 'screens.headerTitle.xeroAddAccount',
  },
  xeroSettings: {
    path: '/xero/settings',
    element: XeroSettings,
    titleId: 'screens.headerTitle.xeroSettings',
  },
  bulkTransferDetail: {
    path: '/bulk-transfers/:id',
    element: BulkTransferDetail,
    titleId: 'label.bulkTransfer',
  },
  referralDetail: {
    path: '/referrals/:campaignCode',
    element: ReferralCampaignDetail,
    titleId: 'label.referABusiness',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  referralInvitees: {
    path: '/referrals/:campaignCode/invitees',
    element: ReferralInviteeList,
    titleId: 'label.myReferrals',
    expectedPermissionByMemberTypes: [MemberType.DIRECTOR, MemberType.MANAGER],
  },
  setupInvoiceSettingsBusinessDetails: {
    path: '/invoices/settings/setup',
    element: SetupInvoiceSettingsBusinessDetails,
    titleId: 'label.invoiceSettingsSetup',
  },
  invoiceSettings: {
    path: '/invoices/settings',
    element: InvoiceSettings,
    titleId: 'label.invoiceSettings',
  },
  invoiceSettingsBusinessDetails: {
    path: '/invoices/settings/business-details',
    element: InvoiceSettingsBusinessDetails,
    titleId: 'label.businessDetails',
  },
  invoiceSettingsDefault: {
    path: '/invoices/settings/default',
    element: InvoiceSettingsDefault,
    titleId: 'label.defaultSettings',
  },
  invoiceSettingsEmailMessage: {
    path: '/invoices/settings/email-message',
    element: InvoiceSettingsEmailMessage,
    titleId: 'label.emailMessage',
  },
  invoiceSettingsFooterNote: {
    path: '/invoices/settings/footer-note',
    element: InvoiceSettingsFooterNote,
    titleId: 'label.footerNote',
  },
  invoiceSettingsPaymentOptions: {
    path: '/invoices/settings/payment-options',
    element: InvoiceSettingsPaymentOptions,
    titleId: 'label.paymentOptions',
  },
  invoices: {
    path: '/invoices',
    element: Invoices,
    titleId: 'label.invoices',
  },
  overdueInvoices: {
    path: '/overdue-invoices',
    element: OverdueInvoices,
    titleId: 'label.overdueInvoices',
  },
  invoiceCustomers: {
    path: '/invoices/customers',
    element: InvoiceCustomers,
    titleId: 'label.customers',
  },
  addInvoiceCustomerInformation: {
    path: '/invoices/customers/new',
    element: AddInvoiceCustomerInformation,
    titleId: 'label.addCustomer',
  },
  invoiceCustomerDetail: {
    path: '/invoices/customers/:invoiceCustomerId',
    element: InvoiceCustomerDetail,
    titleId: 'label.customerDetail',
  },
  invoiceItems: {
    path: '/invoices/items',
    element: InvoiceItems,
    titleId: 'label.items',
  },
  addInvoiceItem: {
    path: '/invoices/items/new',
    element: AddInvoiceItem,
    titleId: 'label.addNewItem',
  },
  editInvoiceItem: {
    path: '/invoices/items/:itemTemplateId/edit',
    element: EditInvoiceItem,
    titleId: 'label.editItem',
  },
};

export const invoiceDetailRoutes: TInvoiceDetailRoutes = {
  invoiceSendEmail: {
    path: '/invoices/:invoiceId/send/email',
    element: InvoiceSendEmail,
    titleId: 'label.invoiceDetail',
  },
  invoiceDetail: {
    path: '/invoices/:invoiceId',
    element: InvoiceDetail,
    titleId: 'label.invoiceDetail',
  },
};

export const invoiceCreateRoutes: TInvoiceCreateRoutes = {
  invoiceCreate: {
    path: '/invoices/:invoiceId/create',
    element: InvoiceCreate,
    titleId: 'label.invoice',
  },
};

const routes = {
  ...mainRoutesByNavMenu,
  ...mainRoutesWithoutNavMenu,
  ...invoiceCreateRoutes,
  ...invoiceDetailRoutes,
};

export default routes;
