import React, { useMemo, useRef } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SideLayoutPage from 'components/SideLayoutPage';
import Card from 'components/core/Card';
import { TrashIcon } from 'components/Icons';
import Box from 'components/core/Box';
import InfoItem from 'components/core/InfoItem';
import Button from 'components/core/Button';
import MemberPermission from 'components/MemberPermission';
import ScheduledTransactionItem from 'components/ScheduledTransactionItem';
import ScheduledTransactionOrderHistorySection from 'components/ScheduledTransactionOrderHistorySection';

import PopconfirmModal from 'modals/PopconfirmModal';

import API_ENDPOINT from 'constants/apiEndpoint';
import replaceUrlParams from 'helpers/replaceUrlParams';
import ScheduledTransactionType, {
  getLabelIdByScheduledTransactionType,
} from 'enums/ScheduledTransactionType';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { showErrorAlert } from 'helpers/showAlertModal';
import { useSideLayoutContext } from 'context/SideLayoutProvider';
import date from 'helpers/date';
import MemberType from 'enums/MemberType';
import { TScheduledBulkTransferDetail } from 'types/scheduledTransaction';
import { TCommonResponse } from 'types/common';
import toPath from 'helpers/toPath';
import routes from 'router/routes';

import ScheduledBulkTransferDetailShimmer from './views/ScheduledBulkTransferDetailShimmer';
import ScheduledBulkTransferDetailItems from './views/ScheduledBulkTransferDetailItems';

import { TScheduledBulkTransferDetailProps } from './ScheduledBulkTransferDetail.types';
import { convertToScheduledTransactionItem } from './ScheduledBulkTransferDetail.helpers';

function ScheduledBulkTransferDetail({
  id,
  onCallback: onRefetchScheduledTransactions,
}: TScheduledBulkTransferDetailProps) {
  const navigate = useNavigate();
  const scheduledTransactionDeleteModalRef = useRef<TPopconfirmModalRef>(null);
  const { sideLayoutDispatch } = useSideLayoutContext();
  const { t } = useTranslation();
  const { data, loading } = useFetch<TCommonResponse<TScheduledBulkTransferDetail>>(
    replaceUrlParams(API_ENDPOINT.SCHEDULED_BULK_TRANSACTION_ORDER_DETAIL, { id }),
    [id]
  );
  const { delete: requestDeleteScheduledTransaction } = useFetch(
    replaceUrlParams(API_ENDPOINT.SCHEDULED_TRANSACTION_ORDER_DETAIL, {
      id,
    })
  );

  const scheduledBulkTransferDetail = useMemo(() => data?.result, [data]);

  function handleOpenDeleteModal() {
    scheduledTransactionDeleteModalRef.current?.show({
      contentIcon: <TrashIcon width={64} height={64} />,
      titleId: 'modals.deleteTransactionScheduledModal.title',
      confirmButtonProps: {
        id: 'button.delete',
        variant: 'danger',
      },
      onConfirm: handleDeleteScheduledTransaction,
    });
  }

  async function handleDeleteScheduledTransaction() {
    try {
      const { errorMessage } = await requestDeleteScheduledTransaction();

      if (errorMessage) {
        showErrorAlert({ title: errorMessage });
        return;
      }
      onRefetchScheduledTransactions?.();
      sideLayoutDispatch.hideSideLayout();
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ title: 'label.unexpectedError' });
    }
  }

  if (!scheduledBulkTransferDetail || loading) {
    return <ScheduledBulkTransferDetailShimmer />;
  }

  return (
    <>
      <SideLayoutPage headerId="screens.headerTitle.scheduledTransactionDetail">
        <Card mx="m" mb="m">
          <ScheduledTransactionItem
            item={convertToScheduledTransactionItem(scheduledBulkTransferDetail)}
          />
        </Card>
        <Card marginX="m" paddingX="m">
          <InfoItem titleId="label.fromAccount" value={scheduledBulkTransferDetail.ledger.name} />
          <InfoItem
            titleId="label.nextPayment"
            value={date.format({
              date: scheduledBulkTransferDetail.scheduledAt,
              formatType: 'd MMM yyyy',
            })}
          />
          {scheduledBulkTransferDetail.recurringPeriod !== ScheduledTransactionType.ONLY_ONCE && (
            <InfoItem
              titleId="label.recurringPayment"
              value={t(
                getLabelIdByScheduledTransactionType(scheduledBulkTransferDetail.recurringPeriod)
              )}
            />
          )}
        </Card>
        <ScheduledBulkTransferDetailItems
          scheduledId={id}
          transferCount={scheduledBulkTransferDetail.transferCount}
          scheduledAt={scheduledBulkTransferDetail.scheduledAt}
          onNavigateToScheduledBulkTransferItems={() =>
            navigate(
              toPath(routes.scheduledBulkTransferItems.path, {
                scheduledTransactionId: scheduledBulkTransferDetail?.id as string,
              }),
              {
                state: {
                  scheduledBulkTransferDetail,
                },
              }
            )
          }
        />
        <ScheduledTransactionOrderHistorySection scheduledId={scheduledBulkTransferDetail.id} />
        <MemberPermission allowTo={[MemberType.DIRECTOR]}>
          <Box
            marginTop="l"
            flex="auto"
            alignment="col.bottom.left"
            px="m"
            justifyContent="flex-end"
            marginBottom="m">
            <Button
              id="button.cancelTransfer"
              mb="m"
              variant="danger"
              onClick={handleOpenDeleteModal}
            />
          </Box>
        </MemberPermission>
      </SideLayoutPage>
      <PopconfirmModal ref={scheduledTransactionDeleteModalRef} />
    </>
  );
}

export default ScheduledBulkTransferDetail;
