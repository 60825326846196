import React, { useRef } from 'react';
import { useElementScroll } from 'framer-motion';

import StickyScrollHeader from 'components/StickyScrollHeader';
import { CloseIcon } from 'components/Icons';

import { useSideLayoutContext } from 'context/SideLayoutProvider';

import { StyledContainer } from './SideLayoutPage.styles';
import { TSideLayoutProps } from './SideLayout.types';
import SideLayoutPageShimmer from './views/SideLayoutPageShimmer';

function SideLayoutPage({ className, onClose, children, headerId }: TSideLayoutProps) {
  const ref = useRef(null);
  const { scrollY } = useElementScroll(ref);
  const { sideLayoutDispatch } = useSideLayoutContext();

  return (
    <StyledContainer className={className} ref={ref}>
      <StickyScrollHeader
        scrollY={scrollY}
        header={{ id: headerId }}
        px="m"
        navButton={{
          icon: CloseIcon,
          onClick: () => {
            sideLayoutDispatch.hideSideLayout();
            onClose?.();
          },
        }}
      />
      {children}
    </StyledContainer>
  );
}

SideLayoutPage.Shimmer = SideLayoutPageShimmer;

export default SideLayoutPage;
