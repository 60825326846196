import React, { createContext, ReactElement, useCallback, useContext, useState } from 'react';

import { TChildrenOnly } from 'types/common';

import { INITIAL_DISPATCH, INITIAL_STATE } from './SendMoneyProvider.constants';
import { TScheduleParams, TSendMoneyContext, TSendMoneyState } from './SendMoneyProvider.types';

const sendMoneyContext = createContext<TSendMoneyContext>({
  sendMoneyState: INITIAL_STATE,
  sendMoneyDispatch: INITIAL_DISPATCH,
});

function SendMoneyProvider({ children }: TChildrenOnly): ReactElement {
  const [{ type, recipient, ledgerFrom, ledgerTo, schedule, reference }, setState] =
    useState<TSendMoneyState>(INITIAL_STATE);

  const setSendMoneyType = useCallback((sendMoneyType) => {
    setState({
      ...INITIAL_STATE,
      type: sendMoneyType,
    });
  }, []);

  const setSendMoneyLedgerFrom = useCallback((sendMoneyLedgerFrom) => {
    setState((state) => ({
      ...state,
      ledgerFrom: sendMoneyLedgerFrom,
    }));
  }, []);

  const setSendMoneyTo = useCallback((sendMoneyToType, sendMoneyToParams) => {
    setState((state) => ({
      ...state,
      [sendMoneyToType]: sendMoneyToParams,
    }));
  }, []);

  const setSendMoneyParams = (sendMoneyParams: TSendMoneyState) => {
    setState({
      ...INITIAL_STATE,
      ...sendMoneyParams,
    });
  };

  const setSendMoneySchedule = (scheduleParams: TScheduleParams) => {
    setState((state) => ({
      ...state,
      schedule: {
        ...state.schedule,
        ...scheduleParams,
      },
    }));
  };

  const setSendMoneyReference = (referenceValue?: string) => {
    setState((state) => ({
      ...state,
      reference: referenceValue,
    }));
  };

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = {
    sendMoneyState: { type, recipient, ledgerFrom, ledgerTo, schedule, reference },
    sendMoneyDispatch: {
      setSendMoneyType,
      setSendMoneyLedgerFrom,
      setSendMoneyTo,
      setSendMoneySchedule,
      setSendMoneyParams,
      setSendMoneyReference,
      clear,
    },
  };

  return <sendMoneyContext.Provider value={value}>{children}</sendMoneyContext.Provider>;
}

export default SendMoneyProvider;

export const useSendMoneyContext = (): TSendMoneyContext => useContext(sendMoneyContext);
