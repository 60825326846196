import styled, { css } from 'styled-components';

import { getColor } from 'helpers/theme';
import ZIndex from 'constants/zIndex';

export const StyledButton = styled.button`
  cursor: text;
`;

export const StyledDigitCodeBox = styled.div<{ isInputFocused: boolean; hasValue: boolean }>`
  width: 60px;
  height: 64px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isInputFocused
      ? getColor('textInputFocusedBackground')
      : getColor('textInputBlurBackground')};
  border: 1px solid
    ${(props) =>
      props.isInputFocused ? getColor('textInputFocusedBorder') : getColor('textInputBorder')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  ${(props) =>
    props.isInputFocused &&
    !props.hasValue &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: -2px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 1.25rem auto;
        width: 1.5px;
        background-color: ${getColor('textInputFocusedBorder')};
      }
    `}
`;

export const StyledDigitCodeInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  caret-color: transparent;
  color: transparent !important;
  z-index: ${ZIndex.DIGIT_CODE_INPUT};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
