import React from 'react';

import Box from 'components/core/Box';

import { TStepProps } from '../StepScreen.types';
import { AnimatedStepLine } from '../StepScreen.styles';
import { STEP_ACTIVE_LINE_VARIANTS } from '../StepScreen.constants';

function Step({ isLast, completed, active }: TStepProps) {
  return (
    <Box
      position="relative"
      height={4}
      borderRadius={2}
      backgroundColor={completed ? 'primary' : 'stepScreenLineBackground'}
      flex={1}
      overflow="hidden"
      mr={isLast ? 'none' : 's'}>
      {active && (
        <AnimatedStepLine
          variants={STEP_ACTIVE_LINE_VARIANTS}
          transition={{ duration: 0.75 }}
          animate="active"
        />
      )}
    </Box>
  );
}

export default Step;
