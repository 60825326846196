import React from 'react';

import { TIconProps } from '../Icon.types';

const NzdIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#1A2A6C"
        d="M48.944 23.999c0 13.255-10.745 24-24 24-13.254 0-24-10.745-24-24 0 .006 24-23.997 24-24 13.255 0 24 10.745 24 24Z"
      />
      <path fill="#FEFEFE" d="M24.88 23.998h.064v-.064a19.43 19.43 0 0 1-.064.064Z" />
      <path
        fill="#FEFEFE"
        d="M24.944 12.52V-.002h-.004C11.687 0 .944 10.745.944 23.998h12.522v-7.051l7.051 7.051h4.363l.064-.064V19.57l-7.05-7.051h7.05Z"
      />
      <path
        fill="#C8102E"
        d="M13.086 3.126a24.114 24.114 0 0 w0-9.011 9.012v11.857h6.26V9.387h14.61V3.126H13.085Z"
      />
      <path
        fill="#C8102E"
        d="m24.945 21.044-8.527-8.527h-2.952l11.478 11.478v-2.951ZM42.507 17.805l.518 1.594H44.7l-1.356.985.518 1.595-1.356-.986-1.357.986.518-1.595-1.356-.985h1.676l.519-1.594ZM36.514 29.282l.777 2.392h2.515l-2.035 1.478.778 2.391-2.035-1.478-2.034 1.478.777-2.391-2.034-1.478h2.514l.777-2.392ZM36.74 10.5l.648 1.993h2.095l-1.695 1.231.648 1.993-1.696-1.231-1.695 1.231.648-1.993-1.695-1.231h2.095l.647-1.993ZM30.974 17.735l.777 2.391h2.515l-2.035 1.478.777 2.392-2.034-1.478-2.034 1.478.777-2.392-2.034-1.478h2.514l.777-2.391Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.944 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default NzdIcon;
