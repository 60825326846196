import React, { ReactElement } from 'react';

import { ListItemWrapper } from './ListItemButton.styles';
import { TListItemButtonProps } from './ListItemButton.types';

function ListItemButton({ children, className, ...rest }: TListItemButtonProps): ReactElement {
  return (
    <ListItemWrapper className={className} {...rest}>
      {children}
    </ListItemWrapper>
  );
}

export default ListItemButton;
