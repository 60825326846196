import { useEffect } from 'react';

import storage, { STORAGE_KEYS } from 'helpers/storage';

function useDisableMultiTab() {
  let email: string | undefined;

  useEffect(() => {
    window.addEventListener('storage', () => {
      const storageEmail = storage.readStorage(STORAGE_KEYS.EMAIL);

      if (storageEmail && email && storageEmail !== email) {
        window.location.reload();
      }
      if (storageEmail) {
        email = storageEmail;
      }
    });

    return () =>
      window.removeEventListener('storage', () => {
        email = undefined;
      });
  }, []);
}

export default useDisableMultiTab;
