import React from 'react';

import Box from 'components/core/Box';
import SideLayoutPage from 'components/SideLayoutPage';
import InfoItem from 'components/core/InfoItem';
import Card from 'components/core/Card';

function RecipientDetailsLoading() {
  return (
    <SideLayoutPage.Shimmer>
      <Box paddingX="m">
        <Card>
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
        </Card>
      </Box>
    </SideLayoutPage.Shimmer>
  );
}

export default RecipientDetailsLoading;
