import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CartIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M7 12a1 1 0 1 0 2 0H7Zm8 0a1 1 0 1 0 2 0h-2Zm-8 5a1 1 0 1 0 0 2v-2Zm10 2a1 1 0 1 0 0-2v2Zm3.125-10.133.997-.067-.997.067Zm.733 11 .998-.067-.998.067Zm-17.716 0-.998-.067.998.067Zm.734-11 .997.066-.997-.066ZM9 12V6H7v6h2Zm6-6v6h2V6h-2Zm-3-3c1.03 0 1.756.336 2.226.823C14.7 4.314 15 5.05 15 6h2c0-1.375-.44-2.64-1.336-3.567C14.764 1.501 13.49 1 12 1v2Zm0-2c-1.49 0-2.764.5-3.664 1.433C7.44 3.36 7 4.625 7 6h2c0-.95.3-1.686.774-2.177C10.244 3.336 10.97 3 12 3V1ZM7 19h10v-2H7v2ZM5.871 8H18.13V6H5.87v2Zm13.256.933.733 11 1.996-.133-.734-11-1.995.133ZM18.862 21H5.138v2h13.724v-2ZM4.14 19.933l.733-11L2.878 8.8l-.734 11 1.996.133ZM5.138 21a1 1 0 0 1-.998-1.067L2.144 19.8A3 3 0 0 0 5.138 23v-2Zm14.722-1.067A1 1 0 0 1 18.862 21v2a3 3 0 0 0 2.994-3.2l-1.996.133ZM18.129 8a1 1 0 0 1 .998.933l1.995-.133A3 3 0 0 0 18.13 6v2ZM5.87 6a3 3 0 0 0-2.993 2.8l1.995.133A1 1 0 0 1 5.871 8V6Z"
        fill={colors[iconColor]}
      />
    </svg>
  );
};

export default CartIcon;
