import React from 'react';

import { TIconProps } from '../Icon.types';

const ChfIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#C8102E"
        d="M24.94 48c13.254 0 24-10.745 24-24 0-13.254-10.746-24-24-24-13.255 0-24 10.746-24 24 0 13.255 10.745 24 24 24Z"
      />
      <path
        fill="#FEFEFE"
        d="M37.461 19.827h-8.347v-8.348h-8.348v8.348h-8.348v8.348h8.348v8.347h8.348v-8.347h8.347v-8.348Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.94 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default ChfIcon;
