import NOOP from 'constants/noop';
import { TCountriesState, TCountriesDispatch } from './CountriesProvider.types';

export const INITIAL_STATE: TCountriesState = {
  countries: [],
  loading: false,
};

export const INITIAL_DISPATCH = {
  getCountries: NOOP,
} as TCountriesDispatch;
