import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const HomeDeliveryIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M5 11L5.43078 18.1797C5.52583 19.7639 6.83832 21 8.4254 21H15.5746C17.1617 21 18.4742 19.7639 18.5692 18.1797L19 11M2 10L10.7506 2.99954C11.481 2.41519 12.519 2.41519 13.2494 2.99954L22 10M14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15C10 13.8955 10.8954 13 12 13C13.1046 13 14 13.8955 14 15Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeDeliveryIcon;
