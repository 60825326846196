import React, { FC, useMemo } from 'react';

import Avatar from 'components/v2/core/Avatar';
import { ArrowsRightIcon } from 'components/Icons';

import getAvatarLetters from 'helpers/avatarLetters';
import { getIconByCurrency } from 'enums/Currency';
import { TAvatarProps } from 'components/v2/core/Avatar/Avatar.types';
import { TIconProps } from 'components/v2/Icons/Icon.types';

import { getAvatarColorKeyByHash } from '../BeneficiaryItem.helpers';
import { TBeneficiaryAvatarProps } from '../BeneficiaryItem.types';

function BeneficiaryAvatar({
  name,
  isBulkTransfer,
  currency,
  size = 'small',
}: TBeneficiaryAvatarProps) {
  const avatarColor = useMemo(() => getAvatarColorKeyByHash(name), [name]);

  function getBadgeProps(): Pick<TAvatarProps, 'badgeProps'> | undefined {
    if (isBulkTransfer) {
      return {
        badgeProps: {
          icon: ArrowsRightIcon as FC<TIconProps>,
          iconColor: 'textSecondary',
        },
      };
    }
    if (currency) {
      return {
        badgeProps: {
          icon: getIconByCurrency(currency.code),
          iconColor: 'textSecondary',
          size: 'currencyBadge',
        },
      };
    }
    return undefined;
  }

  return (
    <Avatar
      label={getAvatarLetters(name)}
      {...getBadgeProps()}
      backgroundColor={avatarColor}
      size={size}
    />
  );
}

export default BeneficiaryAvatar;
