import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function PlusIcon({ width = 24, height = 24, iconColor = 'textPrimary' }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 5v14m-7-7h14"
        stroke={colors[iconColor as never]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}

export default PlusIcon;
