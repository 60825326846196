import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DeclineIcon = ({ iconColor = 'error', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M4.5 4.5L19.5 19.5M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeclineIcon;
