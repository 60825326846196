import React from 'react';

import { TAvatarImageProps } from '../Avatar.types';
import StyledImage from '../Avatar.styles';

function AvatarImage({ size, imageResizeMode, borderRadius, uri }: TAvatarImageProps) {
  return (
    <StyledImage
      size={size}
      borderRadius={borderRadius}
      src={uri}
      imageResizeMode={imageResizeMode}
    />
  );
}

export default AvatarImage;
