import NOOP from 'constants/noop';

import { THeaderDispatch, THeaderState } from './HeaderProvider.types';

export const INITIAL_STATE: THeaderState = {
  headers: {},
};

export const INITIAL_DISPATCH: THeaderDispatch = {
  removeHeaderOption: NOOP,
  setOptions: NOOP,
  clear: NOOP,
};
