import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CheckListIcon = ({ iconColor = 'success', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M21 11.1771V12.0051C20.9989 13.9459 20.3704 15.8344 19.2084 17.3888C18.0463 18.9432 16.413 20.0804 14.5518 20.6307C12.6907 21.1809 10.7015 21.1149 8.88102 20.4423C7.06051 19.7697 5.50619 18.5266 4.44986 16.8985C3.39354 15.2704 2.89181 13.3444 3.01951 11.4078C3.14721 9.47126 3.89749 7.62784 5.15845 6.15252C6.41942 4.67719 8.12351 3.649 10.0166 3.22128C11.9096 2.79357 13.8902 2.98925 15.663 3.77915M21 4.80515L12 13.8141L9.3 11.1141"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckListIcon;
