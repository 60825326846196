import NOOP from 'constants/noop';

import {
  TReferralCampaignDispatch,
  TReferralCampaignState,
} from './ReferralCampaignProvider.types';

export const INITIAL_STATE: TReferralCampaignState = {
  activeReferralCampaigns: [],
};

export const INITIAL_DISPATCH: TReferralCampaignDispatch = {
  getActiveReferralCampaigns: NOOP,
  clear: NOOP,
};
