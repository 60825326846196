import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function UserQuestionIcon({
  width = 24,
  height = 24,
  strokeWidth = 2,
  iconColor = 'textPrimary',
}: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M16.34 15.5C16.5162 14.9991 16.8639 14.5768 17.3217 14.3077C17.7795 14.0387 18.3177 13.9404 18.841 14.0301C19.3643 14.1199 19.839 14.392 20.1809 14.7982C20.5229 15.2044 20.71 15.7185 20.7092 16.2494C20.7092 17.7483 18.4609 18.4978 18.4609 18.4978M18.49 21.4978H18.5M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      />
    </svg>
  );
}

export default UserQuestionIcon;
