import React, {
  useRef,
  useState,
  FocusEvent,
  ChangeEvent,
  forwardRef,
  Ref,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/core/Text';
import { EyeCrossedIcon, EyeOpenIcon } from 'components/Icons';

import { TInputProps } from './Input.types';
import { BaseInput, InputContainer, InputLabel, PasswordToggleButton } from './Input.styles';

function Input(
  {
    id,
    labelId,
    placeholderId,
    autoFocus = false,
    onFocus,
    onBlur,
    value = '',
    disabled,
    error,
    onChange,
    type,
    showPlaceholderOnBlur = false,
    className = '',
    ...rest
  }: TInputProps,
  forwardedRef: Ref<HTMLInputElement | null>
) {
  const { t } = useTranslation();
  const [focused, setFocus] = useState<boolean>(autoFocus);
  const inputRef = useRef<HTMLInputElement>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    }
  }, [focused]);

  useImperativeHandle(forwardedRef, () => inputRef?.current);

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    setFocus(true);
    onFocus && onFocus(event);
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    setFocus(false);
    onBlur && onBlur(event);
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e);
  }

  function togglePasswordVisible() {
    setPasswordVisible((oldPasswordVisible) => !oldPasswordVisible);
  }

  return (
    <InputContainer htmlFor={id} className={className} error={!!error}>
      {labelId && (
        <InputLabel error={error} focused={focused} hasValue={!!value} disabled={disabled}>
          <Text color="inherit" as="span" id={labelId} variant="mediumTextRegular" />
        </InputLabel>
      )}
      <BaseInput
        className="base-input"
        id={id}
        ref={inputRef}
        placeholder={showPlaceholderOnBlur || focused ? t(placeholderId) : ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        type={type === 'password' && passwordVisible ? 'text' : type}
        {...rest}
      />
      {type === 'password' && (
        <PasswordToggleButton
          icon={passwordVisible ? EyeCrossedIcon : EyeOpenIcon}
          block={false}
          variant="icon"
          onClick={togglePasswordVisible}
        />
      )}
    </InputContainer>
  );
}

export default forwardRef(Input);
