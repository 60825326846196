import React, { cloneElement } from 'react';
import { Outlet } from 'react-router-dom';

import { TAuthLayoutProps } from './AuthLayout.types';
import { Content, ContentWrapper, StyledWrapper } from './AuthLayout.styles';
import RightContent from './views/RightContent';

function AuthLayout({ children, layoutBanner }: TAuthLayoutProps) {
  return (
    <StyledWrapper>
      <ContentWrapper>
        <Content>{children || <Outlet />}</Content>
      </ContentWrapper>
      <RightContent backgroundColor={layoutBanner?.backgroundColor}>
        {layoutBanner?.component && cloneElement(layoutBanner.component)}
      </RightContent>
    </StyledWrapper>
  );
}

export default AuthLayout;
