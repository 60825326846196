import React, { ReactElement } from 'react';

import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const AppleIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" {...rest}>
      <path
        d="M9.50545 6.11238C10.702 6.20699 11.8985 5.5447 12.6463 4.70501C13.3817 3.84167 13.8678 2.68266 13.7431 1.5C12.6837 1.54731 11.375 2.16229 10.6272 3.02563C9.94169 3.77071 9.35589 4.97702 9.50545 6.11238Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M9.70487 8.77138C10.5275 8.77138 12.0107 7.74246 13.7431 7.83708C14.4162 7.88438 16.3605 8.07361 17.6069 9.82395C17.5072 9.89491 15.3011 11.1012 15.326 13.6321C15.351 16.6597 18.1179 17.665 18.1429 17.6886C18.1179 17.7596 17.7066 19.1197 16.6971 20.5152C15.8121 21.7452 14.9023 22.9515 13.4565 22.9751C12.0605 22.9988 11.5994 22.1827 9.99154 22.1827C8.39618 22.1827 7.8727 22.9515 6.55154 22.9988C5.15559 23.0461 4.09617 21.6979 3.21124 20.4679C1.40399 17.9843 0.0205144 13.4665 1.89008 10.4153C2.79994 8.88964 4.45762 7.93169 6.23994 7.90803C7.61096 7.88438 8.8698 8.77138 9.70487 8.77138Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AppleIcon;
