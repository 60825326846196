import { TThemePrimitives } from 'theme/v2/theme.types';
import { TListItemBaseStates } from 'components/v2/core/ListItemBase/ListItemBase.types';

export function getIconBackgroundByState({
  pressed,
  hovered,
  disabled,
}: TListItemBaseStates): keyof TThemePrimitives {
  if (disabled) {
    return 'backgroundPrimary';
  }
  if (pressed) {
    return 'buttonGhostPrimaryHover';
  }
  if (hovered) {
    return 'backgroundSecondary';
  }
  return 'backgroundPrimary';
}
