import React from 'react';
import { isUndefined } from 'lodash/fp';

import { TRadioProps } from './Radio.types';
import { useRadioGroupContext } from './RadioContext';
import { StyledLabel } from './Radio.styles';

function Radio({
  id = 'radio',
  selected,
  children,
  value,
  valueKey,
  onChange,
  hasError,
  disabled,
  alignment = 'center',
}: TRadioProps) {
  const context = useRadioGroupContext();
  const valueByValueKey = valueKey ? (value as never)[valueKey] : value;
  const contextValueByValueKey =
    valueKey && context?.value ? (context.value as never)[valueKey] : context?.value;
  const radioSelected = !isUndefined(value) ? contextValueByValueKey === valueByValueKey : selected;

  function handleOnChange() {
    if (onChange) {
      return onChange();
    }
    return context?.onChange?.(value);
  }

  return (
    <StyledLabel
      htmlFor={id}
      hasError={hasError}
      disabled={disabled}
      alignment={alignment}
      onClick={handleOnChange}>
      <input
        type="radio"
        name={id}
        value={value as never}
        checked={radioSelected}
        disabled={disabled}
        onChange={handleOnChange}
      />
      {children}
    </StyledLabel>
  );
}

export default Radio;
