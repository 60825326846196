import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const InformationIcon = ({ iconColor = 'warning', strokeWidth = '2', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <circle cx="12" cy="12" r="10" stroke={colors[iconColor]} strokeWidth={strokeWidth} />
      <path
        d="M12 7L12 8M12 11L12 17"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InformationIcon;
