import VMasker from 'vanilla-masker';

import date from 'helpers/date';
import { TMasks } from './masks.types';
import { MASKS } from './masks.constants';

const masks = {
  birthdate: {
    mask: (value) => VMasker.toPattern(value, MASKS.birthdate),
    unmask: (value) => {
      const [d, m, y] = value.replace(/\s/g, '').split('/');
      return date.format({
        date: new Date(Number(y), Number(m) - 1, Number(d)),
        formatType: 'yyyy-MM-dd',
      });
    },
  },
  sortCode: {
    mask: (value) => VMasker.toPattern(value, MASKS.sortCode),
    unmask: (value) => value.replaceAll('-', ''),
  },
} as TMasks;

export default masks;
