import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TCommonResponse } from 'types/common';
import { TInvoiceSettingsResponse } from 'types/invoice';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';

import {
  THandleSetupInvoiceSettingsParams,
  THandleUpdateBusinessDetailsParams,
  THandleUpdateDefaultFooterParams,
  THandleUpdateDefaultSettingsParams,
  THandleUpdateEmailMessagesParams,
  TUpdateMerchantLogoParams,
} from './InvoiceSettingsProvider.types';
import {
  covertToUpdateInvoiceSettingsBusinessLogoRequest,
  covertToUpdateInvoiceDefaultSettingsRequest,
  covertToSetupInvoiceSettingsRequest,
} from './InvoiceSettingsProvider.helpers';

export function useInvoiceSettingsUpdate() {
  const { post: requestUpdateSettings, response: updateSettingsResponse } = useFetch<
    TCommonResponse<TInvoiceSettingsResponse>
  >(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_SETTINGS);
  const { post: requestUpdateMerchantLogo, response: updateMerchantLogoResponse } = useFetch<
    TCommonResponse<TInvoiceSettingsResponse>
  >(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES_SETTINGS_MERCHANT_LOGO);

  async function updateMerchantLogo({ merchantLogo, onSuccess }: TUpdateMerchantLogoParams) {
    try {
      const data = await requestUpdateMerchantLogo(
        covertToUpdateInvoiceSettingsBusinessLogoRequest({
          merchantLogo,
        })
      );

      if (!updateMerchantLogoResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      onSuccess?.();
      return;
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function updateBusinessDetails({ values, onSuccess }: THandleUpdateBusinessDetailsParams) {
    try {
      const data = await requestUpdateSettings({
        merchantVatNumber: values.merchantVatNumber ?? null,
      });

      if (!updateSettingsResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function updateDefaultSettings({ values, onSuccess }: THandleUpdateDefaultSettingsParams) {
    try {
      const data = await requestUpdateSettings(covertToUpdateInvoiceDefaultSettingsRequest(values));

      if (!updateSettingsResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function updateEmailMessages({ values, onSuccess }: THandleUpdateEmailMessagesParams) {
    try {
      const data = await requestUpdateSettings({
        shareInvoiceEmailTitle: values.shareInvoiceEmailTitle,
        shareInvoiceEmailBody: values.shareInvoiceEmailBody,
      });

      if (!updateSettingsResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function updateDefaultFooter({ values, onSuccess }: THandleUpdateDefaultFooterParams) {
    try {
      const data = await requestUpdateSettings({
        defaultFooter: values.defaultFooter,
      });

      if (!updateSettingsResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function setupInvoiceSettings({ values, onSuccess }: THandleSetupInvoiceSettingsParams) {
    try {
      const data = await requestUpdateSettings(covertToSetupInvoiceSettingsRequest(values));

      if (!updateSettingsResponse.ok) {
        showErrorAlert({
          title: data?.errorMessage,
        });
        return;
      }
      await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return {
    updateMerchantLogo,
    updateBusinessDetails,
    updateDefaultSettings,
    updateEmailMessages,
    updateDefaultFooter,
    setupInvoiceSettings,
  };
}
