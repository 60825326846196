import { useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFetch } from 'use-http';

import { TUsePollingFetch } from './usePollingFetch.types';

function usePollingFetch({
  pollInterval = 10000,
  apiUrl,
  args = [],
  auto = true,
  fetchOnMount = true,
  stopPollingOnError = true,
  query,
}: TUsePollingFetch) {
  const dataRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const {
    data,
    response,
    get,
    loading: pollingLoading,
  } = useFetch(apiUrl, {}, fetchOnMount ? args : undefined);
  const pollingFetchId = useRef<NodeJS.Timer | number>();

  useEffect(() => {
    if (auto) {
      pollingFetchId.current = setInterval(handleFetch, pollInterval);
    }

    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (response?.status === 403) {
      return;
    }
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    if (!pollingLoading && loading) {
      setLoading(false);
    }
  }, [pollingLoading]);

  async function handleFetch(queryParam?: string) {
    try {
      const { errorCode } = await get(queryParam);

      if (errorCode && stopPollingOnError) {
        stopPolling();
      }
    } catch (e) {
      if (stopPollingOnError) {
        stopPolling();
      }
      Sentry.captureException(e);
    }
  }

  const stopPolling = useCallback(() => {
    clearInterval(pollingFetchId?.current as NodeJS.Timeout);
  }, [pollingFetchId?.current]);

  const reFetch = useCallback(async () => {
    stopPolling();
    const fetchResponse = await get(query);
    pollingFetchId.current = setInterval(() => handleFetch(query), pollInterval);
    return fetchResponse;
  }, [pollingFetchId?.current, query, ...args]);

  return {
    loading,
    data: response?.status === 403 ? dataRef.current : data,
    stopPolling,
    pollingLoading,
    reFetch,
  };
}

export default usePollingFetch;
