import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { margin, padding } from 'styled-system';

import shadowCss from 'theme/shadow';
import borderRadius from 'theme/borderRadius';
import { TThemePrimitives } from 'theme/theme.types';
import { getColor } from 'helpers/theme';

export const ListItemWrapper = styled.button<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    cursor?: 'pointer' | 'default';
    backgroundColor?: keyof TThemePrimitives;
    disabledHover?: boolean;
  }
>`
  cursor: ${(props) => (props.disabledHover ? 'auto' : props.cursor || 'pointer')};
  background-color: ${(props) => getColor(props.backgroundColor ?? 'listItemBackground')};
  border-radius: ${borderRadius.m};
  width: 100%;
  padding: 0;
  ${(props) =>
    !props.disabledHover &&
    shadowCss({
      background: 'listItemBackground',
      hoverColor: 'listItemHoverBackground',
      activeColor: 'listItemActiveBackground',
    })};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  ${margin}
  ${padding}
`;
