import { FC } from 'react';

import TransactionDetail from 'pages/Main/TransactionDetail';
import PaymentDetail from 'pages/Main/PaymentDetail';
import CardDetail from 'pages/Main/CardDetail';
import MerchantDetail from 'pages/Main/MerchantDetail';
import ProfileDetail from 'pages/Main/ProfileDetail';
import TeamMemberDetail from 'pages/Main/TeamMemberDetail';
import ScheduledTransactionDetail from 'pages/Main/ScheduledTransactionDetail';
import ScheduledBulkTransferDetail from 'pages/Main/ScheduledBulkTransferDetail';
import RecipientDetails from 'pages/Main/RecipientDetails';

export enum SideLayoutComponentPath {
  TRANSACTION_DETAIL = 'TransactionDetail',
  PAYMENT_DETAIL = 'PaymentDetail',
  CARD_DETAIL = 'CardDetail',
  MERCHANT_DETAIL = 'MerchantDetail',
  PROFILE_DETAIL = 'ProfileDetail',
  TEAM_MEMBER_DETAIL = 'TeamMemberDetail',
  SCHEDULED_TRANSACTION_DETAIL = 'ScheduledTransactionDetail',
  SCHEDULED_BULK_TRANSFER_DETAIL = 'ScheduledBulkTransferDetail',
  RECIPIENT_DETAILS = 'RecipientDetails',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SideLayoutComponents: Record<SideLayoutComponentPath, FC<any>> = {
  [SideLayoutComponentPath.TRANSACTION_DETAIL]: TransactionDetail,
  [SideLayoutComponentPath.PAYMENT_DETAIL]: PaymentDetail,
  [SideLayoutComponentPath.CARD_DETAIL]: CardDetail,
  [SideLayoutComponentPath.MERCHANT_DETAIL]: MerchantDetail,
  [SideLayoutComponentPath.PROFILE_DETAIL]: ProfileDetail,
  [SideLayoutComponentPath.TEAM_MEMBER_DETAIL]: TeamMemberDetail,
  [SideLayoutComponentPath.SCHEDULED_TRANSACTION_DETAIL]: ScheduledTransactionDetail,
  [SideLayoutComponentPath.SCHEDULED_BULK_TRANSFER_DETAIL]: ScheduledBulkTransferDetail,
  [SideLayoutComponentPath.RECIPIENT_DETAILS]: RecipientDetails,
};
