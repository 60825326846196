export const FONT_SIZE = {
  xxLargeTitle: {
    fontSize: '4rem',
    lineHeight: 2,
  },
  xLargeTitle: {
    fontSize: '2.5rem',
    lineHeight: 2,
  },
  largeTitle: {
    fontSize: '2rem',
    lineHeight: 1.25,
  },
  mediumTitle: {
    fontSize: '1.75rem',
    lineHeight: 1.414,
  },
  smallTitle: {
    fontSize: '1.375rem',
    lineHeight: 1.445,
  },
  xSmallTitle: {
    fontSize: '1.25rem',
    lineHeight: 1.46,
  },
  largeText: {
    fontSize: '1.125rem',
    lineHeight: 1.556,
  },
  mediumText: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  smallText: {
    fontSize: '0.875rem',
    lineHeight: 1.426,
  },
  xSmallText: {
    fontSize: '0.75rem',
    lineHeight: 1.34,
  },
  xxSmallText: {
    fontSize: '0.625rem',
    lineHeight: 1.25,
  },
};

export const FONT_WEIGHT = {
  bold: '700',
  semiBold: '600',
  normal: 'normal',
};

export const FONT_FAMILY =
  '"Nunito", -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, Arimo, sans-serif';

export const DEGULAR_FONT_FAMILY =
  '"Degular", -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, Arimo, sans-serif';
