import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const MiscellaneousStoresIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M5.11438 6H20.8856M16 10C16 11.6569 14.6569 13 13 13C11.3431 13 10 11.6569 10 10M21 6.66667V19C21 20.1046 20.1046 21 19 21H7C5.89543 21 5 20.1046 5 19V6.66667C5 6.23393 5.14036 5.81286 5.4 5.46667L7.7 2.4C7.88885 2.14819 8.18524 2 8.5 2H17.5C17.8148 2 18.1111 2.14819 18.3 2.4L20.6 5.46667C20.8596 5.81286 21 6.23393 21 6.66667Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MiscellaneousStoresIcon;
