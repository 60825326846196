import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import dateHelpers from 'helpers/date';

import { StyledHeaderContainer, StyledRightBox } from '../InvoicePreviewPdf.styles';
import { TInvoicePreviewPdfHeaderProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfHeader({
  issueDate,
  dueDate,
  invoiceNumber,
  merchantLogoLink,
}: TInvoicePreviewPdfHeaderProps) {
  return (
    <StyledHeaderContainer>
      <Box display="flex" flexDirection="row" alignItems="center" gap="s" height={18} flex={1}>
        {merchantLogoLink && (
          <Box alignment="center">
            <img src={merchantLogoLink} alt="invoice-merchant-logo" height={36} />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="xl">
        <StyledRightBox>
          <Text textId="label.issued" variant="textBodySmall" color="textSecondary" />
          <Text variant="textBodySmallBold" color="textPrimary">
            {dateHelpers.format({
              date: issueDate,
              formatType: 'dd.MM.yyyy',
            })}
          </Text>
        </StyledRightBox>
        <StyledRightBox divider>
          <Box px="xl">
            <Text textId="label.dueDate" variant="textBodySmall" color="textSecondary" />
            <Text variant="textBodySmallBold" color="textPrimary">
              {dateHelpers.format({
                date: dueDate,
                formatType: 'dd.MM.yyyy',
              })}
            </Text>
          </Box>
        </StyledRightBox>
        <StyledRightBox>
          <Text
            textId="label.invoiceNumber"
            variant="textBodySmall"
            color="textSecondary"
            textAlign="right"
          />
          <Text variant="textBodySubBold" color="textPrimary" textAlign="right">
            {invoiceNumber}
          </Text>
        </StyledRightBox>
      </Box>
    </StyledHeaderContainer>
  );
}

export default InvoicePreviewPdfHeader;
