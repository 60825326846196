import styled, { css } from 'styled-components';

import Button from 'components/core/Button';

import spacing from 'theme/spacing';
import borderRadius from 'theme/borderRadius';
import { getColor } from 'helpers/theme';
import { TInputContainerProps, TInputLabelProps } from './Input.types';
import { INPUT_HEIGHT } from './Input.constants';

export const InputContainer = styled.label<TInputContainerProps>`
  position: relative;
  width: 100%;
  height: ${INPUT_HEIGHT}px;
  padding: ${spacing.sm} 0;
  border-radius: ${borderRadius.s};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: border-color 0.2s linear;
  background-color: ${getColor('textInputBlurBackground')};

  &:hover {
    transition: background-color 0.2s linear;
    background-color: ${getColor('textInputHoverBackground')};
  }

  ${getInputContainerStyles}
`;

export function getInputContainerStyles({ error }: TInputContainerProps) {
  if (error) {
    return css`
      border: 1px solid ${getColor('textInputErrorBorder')};

      &:focus-within {
        background-color: ${getColor('textInputFocusedBackground')};
        border-color: ${getColor('textInputErrorBorder')};
      }
    `;
  }
  return css`
    border: 1px solid ${getColor('textInputBorder')};

    &:focus-within {
      background-color: ${getColor('textInputFocusedBackground')};
      border-color: ${getColor('textInputFocusedBorder')};
    }
  `;
}

export const InputLabel = styled.div<TInputLabelProps>`
  position: absolute;
  top: -1px;
  left: 16px;
  transform-origin: top left;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: ${({ hasValue, focused }) =>
    hasValue || focused ? 'translate(0, 12px) scale(0.75)' : 'translate(0, 20px) scale(1)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${getInputLabelColor};
  pointer-events: none;
`;

function getInputLabelColor({ focused, error, disabled }: TInputLabelProps) {
  if (error) {
    return getColor('textInputErrorLabel');
  }
  if (disabled) {
    return getColor('textInputTopLabelDisabled');
  }
  if (focused) {
    return getColor('textInputFocusedTopLabel');
  }
  return getColor('textInputLabel');
}

export const BaseInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: ${spacing.m};
  font-weight: 700;
  height: ${INPUT_HEIGHT}px;
  padding-top: 16px;

  ::placeholder {
    color: ${getColor('textInputPlaceholder')};
  }

  :-webkit-autofill {
    border-radius: ${borderRadius.s};
    -webkit-background-clip: text;
  }
`;

export const PasswordToggleButton = styled(Button)`
  position: absolute;
  right: ${spacing.s};
  padding: ${spacing.s};
  height: auto;
`;
