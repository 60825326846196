import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

const UserTearIcon = (props: TIconProps): ReactElement => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
    <circle cx="40" cy="40.0013" r="33.3333" stroke="white" strokeWidth="4" />
    <path
      d="M26.6666 53.332C28.2066 52.3019 30.2122 51.4641 32.5233 50.8853C34.8345 50.3066 37.3872 50.003 39.9778 49.9987C42.5683 49.9945 45.1251 50.2897 47.4439 50.8609C49.7628 51.432 51.7795 52.2632 53.3333 53.2883"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <ellipse cx="28.3333" cy="31.668" rx="5" ry="5" fill="white" />
    <path
      d="M56.6667 43.3346C56.6667 45.1756 55.1743 46.668 53.3333 46.668C51.4924 46.668 50 45.1756 50 43.3346C50 41.4937 53.3333 36.668 53.3333 36.668C53.3333 36.668 56.6667 41.4937 56.6667 43.3346Z"
      fill="white"
    />
    <ellipse cx="51.6667" cy="31.668" rx="5" ry="5" fill="white" />
  </svg>
);

export default UserTearIcon;
