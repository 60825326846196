import React from 'react';

import SideLayoutPage from 'components/SideLayoutPage';
import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';

import spacing from 'theme/spacing';

function LedgerEntrySideLayoutPageShimmer() {
  return (
    <SideLayoutPage.Shimmer>
      <Box paddingX="m" alignment="col.verticalCenter" flex="auto">
        <Box marginBottom="l" marginTop={`-${spacing.l}`}>
          <Shimmer marginTop="m" shape="line" height={12} color="secondary" width="30%" />
          <Shimmer marginTop="s" shape="line" color="secondary" width="40%" />
        </Box>
        <Card marginTop="l">
          <InfoItem.Shimmer />
          <InfoItem.Shimmer />
        </Card>
        <Box
          marginTop="l"
          flex="auto"
          alignment="col.bottom.left"
          justifyContent="flex-end"
          marginBottom="m">
          <Shimmer shape="square" color="secondary" width="100%" marginTop="m" />
          <Shimmer shape="square" color="secondary" width="100%" marginTop="m" />
        </Box>
      </Box>
    </SideLayoutPage.Shimmer>
  );
}

export default LedgerEntrySideLayoutPageShimmer;
