import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const SubscriptionIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M4.22222 4C3.66994 4 3.22222 4.44772 3.22222 5C3.22222 5.55228 3.66994 6 4.22222 6V4ZM19.7778 6C20.3301 6 20.7778 5.55228 20.7778 5C20.7778 4.44772 20.3301 4 19.7778 4V6ZM4.22222 9H19.7778V7H4.22222V9ZM21 10V18H23V10H21ZM19.7778 19H4.22222V21H19.7778V19ZM3 18V10H1V18H3ZM4.22222 19C3.4455 19 3 18.4558 3 18H1C1 19.7534 2.54435 21 4.22222 21V19ZM21 18C21 18.4558 20.5545 19 19.7778 19V21C21.4557 21 23 19.7534 23 18H21ZM19.7778 9C20.5545 9 21 9.54421 21 10H23C23 8.24665 21.4557 7 19.7778 7V9ZM4.22222 7C2.54435 7 1 8.24665 1 10H3C3 9.54422 3.4455 9 4.22222 9V7ZM4.22222 6H19.7778V4H4.22222V6Z"
        fill={colors[iconColor]}
      />
      <path
        d="M13 14C13 14.5523 12.5523 15 12 15V17C13.6569 17 15 15.6569 15 14H13ZM12 15C11.4477 15 11 14.5523 11 14H9C9 15.6569 10.3431 17 12 17V15ZM11 14C11 13.4477 11.4477 13 12 13V11C10.3431 11 9 12.3431 9 14H11ZM12 13C12.5523 13 13 13.4477 13 14H15C15 12.3431 13.6569 11 12 11V13Z"
        fill={colors[iconColor]}
      />
      <path d="M19 14L17 14" stroke={colors[iconColor]} strokeWidth="2" strokeLinecap="round" />
      <path d="M7 14L5 14" stroke={colors[iconColor]} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default SubscriptionIcon;
