import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const WalletIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        stroke={colors[iconColor]}
        strokeWidth="2"
        d="M20 16h-7a1 1 0 01-1-1V9a1 1 0 011-1h7m0 8a1 1 0 001-1V9a1 1 0 00-1-1m0 8v3a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h14a1 1 0 011 1v3"
      />
      <rect width="3" height="3" x="14.5" y="10.5" fill={colors[iconColor]} rx="1.5" />
    </svg>
  );
};

export default WalletIcon;
