import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const EyeOpenIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <circle cx="12" cy="12" r="3" stroke={colors[iconColor]} strokeWidth="2" />
      <path
        d="M12 18C7.45203 18 3.63257 15.4737 2.08396 12C3.63257 8.52634 7.45203 6 12 6C16.548 6 20.3674 8.52634 21.916 12C20.3674 15.4737 16.548 18 12 18Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default EyeOpenIcon;
