import styled from 'styled-components';

import { getColor } from 'helpers/theme';

export const SwitchButtonContainer = styled.label<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  color: ${({ disabled }) =>
    getColor(disabled ? 'switchButtonPassiveThumb' : 'switchButtonActiveTrack')};
`;

export const SwitchButtonInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

export const SwitchButtonShape = styled.div`
  display: inline-block;
  background: ${getColor('switchButtonPassiveTrack')};
  border-radius: 16px;
  width: 51px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: all 0.25s;

  &:before,
  &:after {
    content: '';
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    width: 27px;
    height: 27px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.25s;
  }

  &:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  input:checked + & {
    background: ${getColor('switchButtonActiveTrack')};
    &:before {
      left: 22px;
    }
  }
`;
