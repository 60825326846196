import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const EditIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M19 11.0001L9.08787 20.9122C9.03161 20.9685 8.9553 21.0001 8.87574 21.0001H3.3C3.13432 21.0001 3 20.8658 3 20.7001L3 15.1244C3 15.0448 3.03161 14.9685 3.08787 14.9122L13 5.00009M19 11.0001L21.2929 8.7072C21.6834 8.31668 21.6834 7.68351 21.2929 7.29299L16.7071 2.7072C16.3166 2.31668 15.6834 2.31668 15.2929 2.7072L13 5.00009M19 11.0001L13 5.00009"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
