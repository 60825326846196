import styled, { css } from 'styled-components';

import Box from 'components/core/Box';
import BottomButtonBox from 'components/BottomButtonBox';

import { getSpacing } from 'helpers/theme';

export const StyledForm = styled.form<{ secondary?: boolean; isFullPage: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isFullPage }) =>
    isFullPage &&
    css`
      flex: 1;
    `}
`;

export const ButtonWrapper = styled(Box)<{ isFullPage: boolean }>`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;

  ${({ isFullPage }) =>
    isFullPage &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: ${getSpacing('l')};
      max-width: 24rem;
    `}
`;

export const ButtonStickyWrapper = styled(BottomButtonBox)`
  margin-top: 5rem !important;
  z-index: 9;
`;
