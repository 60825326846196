import React, { useMemo } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import Text from 'components/core/Text';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';
import LedgerEntrySideLayoutPage from 'components/LedgerEntrySideLayoutPage';

import { TCommonResponse } from 'types/common';
import replaceUrlParams from 'helpers/replaceUrlParams';
import date from 'helpers/date';
import API_ENDPOINT from 'constants/apiEndpoint';
import PaymentStatus, {
  getColorByPaymentStatus,
  getLabelIdByPaymentStatus,
} from 'enums/PaymentStatus';
import { getColorByPayoutStatus, getLabelIdByPayoutStatus } from 'enums/PayoutStatus';
import { formatAmount } from 'helpers/formatAmount';

import { TPaymentDetailProps, TPaymentDetailResponse } from './PaymentDetail.types';

function PaymentDetail({ id }: TPaymentDetailProps) {
  const { t } = useTranslation();
  const { data, loading } = useFetch<TCommonResponse<TPaymentDetailResponse>>(
    replaceUrlParams(API_ENDPOINT.PAYMENT_DETAIL, {
      id,
    }),
    [id]
  );
  const paymentDetail = useMemo(() => data?.result, [data]);

  if (loading || !paymentDetail) {
    return <LedgerEntrySideLayoutPage.Shimmer />;
  }

  return (
    <LedgerEntrySideLayoutPage
      amount={paymentDetail.amount}
      currency={paymentDetail.currency}
      title={paymentDetail.title}
      createdAt={paymentDetail.createdAt}
      statementApiEndpoint={replaceUrlParams(API_ENDPOINT.PAYMENT_STATEMENT, { id })}
      statementVisible={paymentDetail.status === PaymentStatus.SUCCESS}>
      <Card paddingX="m" marginTop="m">
        <InfoItem
          titleId="label.status"
          value={
            <Text variant="largeTextSemiBold" color={getColorByPaymentStatus(paymentDetail.status)}>
              {t(getLabelIdByPaymentStatus(paymentDetail.status))}
            </Text>
          }
        />
        <InfoItem
          titleId="label.payoutStatus"
          value={
            <Text
              variant="largeTextSemiBold"
              color={getColorByPayoutStatus(paymentDetail.payoutStatus)}>
              {t(getLabelIdByPayoutStatus(paymentDetail.payoutStatus))}
            </Text>
          }
        />
        {paymentDetail.processingFeeAmount > 0 && (
          <InfoItem
            titleId="label.fee"
            value={formatAmount({
              amount: paymentDetail.processingFeeAmount,
              currency: paymentDetail.currency,
            })}
          />
        )}
        {paymentDetail.payoutCompletedAt && (
          <InfoItem
            titleId="label.payoutCompletedAt"
            value={date.formatWithTimeZone({
              date: paymentDetail.payoutCompletedAt,
              formatType: 'iii, d MMM yyyy',
            })}
          />
        )}
      </Card>
      <Card paddingX="m" marginTop="m">
        <InfoItem titleId="label.accountName" value={paymentDetail.ledgerName} />
      </Card>
    </LedgerEntrySideLayoutPage>
  );
}

export default PaymentDetail;
