import palette from 'theme/v2/palette';

import { TWamoCardColorMap, TWamoCardSizeMap } from './WamoCard.types';

export const CARD_DEFAULT_HEIGHT = 200;

export const WAMO_CARD_SIZE_MAP: TWamoCardSizeMap = {
  small: {
    width: 48,
    height: 32,
    lineHeight: 3,
    logoWidth: 8,
    logoHeight: 4,
    visaWidth: 12,
    visaHeight: 6,
    borderRadius: 4,
    padding: 'xs',
    borderOutlineWidth: 0.5,
    frozenIconSize: 16,
  },
  medium: {
    width: 64,
    height: 40,
    lineHeight: 3,
    logoWidth: 8,
    logoHeight: 4,
    visaWidth: 12,
    visaHeight: 6,
    borderRadius: 4,
    padding: 'xs',
    borderOutlineWidth: 0.5,
    frozenIconSize: 16,
  },
  large: {
    width: 80,
    height: 48,
    lineHeight: 3,
    logoWidth: 12,
    logoHeight: 8,
    visaWidth: 20,
    visaHeight: 12,
    borderRadius: 4,
    padding: 'xs',
    borderOutlineWidth: 0.5,
    frozenIconSize: 16,
  },
  default: {
    width: '100%',
    height: CARD_DEFAULT_HEIGHT,
    lineHeight: 8,
    logoWidth: 24,
    logoHeight: 24,
    visaWidth: 64,
    visaHeight: 32,
    borderRadius: 16,
    padding: 'm',
    borderOutlineWidth: 0,
    frozenIconSize: 56,
  },
};

export const CARD_COLORS: TWamoCardColorMap = {
  black: {
    backgroundColor: palette.moon800,
    lineColor: palette.orchid500,
    logoColor: palette.turquoise500,
    logoWordmark: palette.moon100,
    textColor: palette.moon100,
    visaColor: palette.moon100,
    backLineColor: palette.moon900,
    backSignatureLineColor: palette.moon100,
    backWamoIcon: palette.moon100,
  },
  purple: {
    backgroundColor: 'avatarOrchid',
    lineColor: palette.orchid500,
    logoColor: palette.turquoise500,
    logoWordmark: palette.moon100,
    textColor: palette.moon100,
    visaColor: palette.moon100,
    backLineColor: palette.moon800,
    backSignatureLineColor: palette.moon100,
    backWamoIcon: palette.moon100,
  },
  turquoise: {
    backgroundColor: 'avatarTurquoise',
    lineColor: palette.turquoise700,
    logoColor: palette.moon100,
    logoWordmark: palette.moon100,
    textColor: palette.moon100,
    visaColor: palette.moon100,
    backLineColor: palette.moon800,
    backSignatureLineColor: palette.moon100,
    backWamoIcon: palette.moon100,
  },
  silver: {
    backgroundColor: palette.silver,
    lineColor: palette.orchid500,
    logoColor: palette.moon900,
    logoWordmark: palette.moon100,
    textColor: palette.moon900,
    visaColor: palette.moon900,
    backLineColor: palette.moon800,
    backSignatureLineColor: palette.moon100,
    backWamoIcon: palette.moon100,
  },
};
