import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const DocumentIdentityIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="104" height="124" viewBox="0 0 104 124" fill="none" {...rest}>
      <path
        d="M46.4145 104.769C46.4145 95.6753 54.6978 96.5362 64.9158 96.5362C75.1337 96.5362 83.417 95.6753 83.417 104.769M41.6667 20.6665H62.3333M77.8333 69.7498C77.8333 76.8835 72.0503 82.6665 64.9167 82.6665C57.783 82.6665 52 76.8835 52 69.7498C52 62.6161 57.783 56.8332 64.9167 56.8332C72.0503 56.8332 77.8333 62.6161 77.8333 69.7498ZM21 118.833H83C91.5604 118.833 98.5 111.894 98.5 103.333V20.6665C98.5 12.1061 91.5604 5.1665 83 5.1665H21C12.4396 5.1665 5.5 12.1061 5.5 20.6665V103.333C5.5 111.894 12.4396 118.833 21 118.833Z"
        strokeWidth="10.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={colors[iconColor]}
      />
    </svg>
  );
};

export default DocumentIdentityIcon;
