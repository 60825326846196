import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const FilterIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M5.33334 15.9997L21.3333 15.9997M5.33334 22.6663L8.00001 22.6663M26.6667 22.6663L12 22.6663M12 19.9997L12 22.6663M12 25.333L12 22.6663M25.3333 13.333L25.3333 18.6663M14.6667 6.66634L14.6667 9.33301M14.6667 11.9997L14.6667 9.33301M5.33334 9.33301L10.6667 9.33301M26.6667 9.33301L14.6667 9.33301"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
