import React, { createContext, ReactElement, useContext, useState } from 'react';

import { TChildrenOnly } from 'types/common';

import { INITIAL_DISPATCH, INITIAL_STATE } from './ExchangeProvider.constants';
import { TExchangeState, TExchangeContext, TQuoteDetail } from './ExchangeProvider.types';

const exchangeContext = createContext<TExchangeContext>({
  exchangeState: INITIAL_STATE,
  exchangeDispatch: INITIAL_DISPATCH,
});

function ExchangeProvider({ children }: TChildrenOnly): ReactElement {
  const [{ quoteDetail }, setState] = useState<TExchangeState>(INITIAL_STATE);

  function setQuoteDetail(quoteDetail: TQuoteDetail) {
    setState((prevState) => ({ ...prevState, quoteDetail }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TExchangeContext = {
    exchangeState: {
      quoteDetail,
    },
    exchangeDispatch: {
      setQuoteDetail,
      clear,
    },
  };

  return <exchangeContext.Provider value={value}>{children}</exchangeContext.Provider>;
}

export default ExchangeProvider;

export const useExchangeContext = (): TExchangeContext => useContext(exchangeContext);
