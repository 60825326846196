import React, { createElement } from 'react';
import { Navigate, Route } from 'react-router-dom';

import DashboardLayout from 'layouts/DashboardLayout';

import { NavMenu } from 'layouts/DashboardLayout/DashboardLayout.types';
import { findOnboardingNavigationPath } from 'pages/Onboarding/Onboarding.helpers';
import MemberType from 'enums/MemberType';
import routes from 'router/routes';
import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';
import MemberStatus from 'enums/MemberStatus';

import { THandleRenderDashboardLayoutParams, TMapMainRoutesParams } from './Main.types';

export function handleRenderDashboardLayout({
  member,
  merchant,
  onboardingSections,
  visibleMenu,
}: THandleRenderDashboardLayoutParams) {
  if (!member || member.status !== MemberStatus.ACTIVE) {
    return (
      <Navigate to={routes.login.path} state={{ redirectTo: window.location.pathname }} replace />
    );
  }
  if (member.isPasswordAutoGenerated && !member.isOnboardingMember) {
    return <Navigate to={routes.authChangePassword.path} replace />;
  }

  if (merchant?.onboardingStatus !== MerchantOnboardingStatus.LIVE) {
    return (
      <Navigate
        to={findOnboardingNavigationPath({
          member,
          merchant,
          onboardingSections,
        })}
        replace
      />
    );
  }
  return <DashboardLayout navMenu={NavMenu.HOME} visibleMenu={visibleMenu} />;
}

export function mapMainRoutes({ routes: mainRoutes, memberType }: TMapMainRoutesParams) {
  return Object.entries(mainRoutes).map(
    ([key, { path, expectedPermissionByMemberTypes, element: Element }]) => (
      <Route
        key={key}
        path={path}
        element={
          !expectedPermissionByMemberTypes ||
          expectedPermissionByMemberTypes?.includes(memberType as MemberType) ? (
            createElement(Element)
          ) : (
            <Navigate to={routes.home.path} />
          )
        }
      />
    )
  );
}
