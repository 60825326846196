import styled, { css } from 'styled-components';

import { INPUT_HEIGHT } from 'components/core/Input/Input.constants';

import borderRadius from 'theme/borderRadius';
import spacing from 'theme/spacing';
import { getColor } from 'helpers/theme';
import { TFieldCountryCodeProps } from './FieldCountryCode.types';

export const StyledButton = styled.button`
  height: ${INPUT_HEIGHT}px;
  border-radius: ${borderRadius.s}px;
  padding: 0 ${spacing.m};
  border-radius: ${borderRadius.s};
  transition: outline-color 0.2s linear;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-right: ${spacing.s};
  background-color: ${getColor('textInputBlurBackground')};
  border: 1px solid transparent;

  &:hover {
    transition: background-color 0.2s linear;
    background-color: ${getColor('textInputHoverBackground')};
  }

  &:focus-visible {
    outline-color: ${getColor('textInputFocusedBorder')};
  }

  ${getInputContainerStyles};

  img {
    width: 24px;
    height: 24px;
  }
`;

function getInputContainerStyles({
  hasError,
  focused,
}: Pick<TFieldCountryCodeProps, 'hasError' | 'focused'>) {
  if (hasError) {
    return css`
      border: 1px solid ${getColor('textInputErrorBorder')} !important;
    `;
  }
  if (focused) {
    return css`
      border: 1px solid ${getColor('textInputFocusedBorder')};
      background-color: ${getColor('textInputFocusedBackground')};
    `;
  }
  return css`
    border: 1px solid ${getColor('textInputBorder')};
    background-color: ${getColor('textInputBlurBackground')};
  `;
}
