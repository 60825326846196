function generateTraceId(length = 16): string {
  if (global.crypto) {
    const bytes = new Uint8Array(length); // 128 bits = 16 bytes
    global.crypto.getRandomValues(bytes);

    let hexString = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < bytes.length; i++) {
      hexString += bytes[i].toString(16).padStart(2, '0');
    }

    return hexString;
  }
  return '';
}

export default generateTraceId;
