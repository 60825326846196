import React from 'react';

import { ProfileCircleIcon } from 'components/Icons';
import Box from 'components/core/Box';
import Text from 'components/core/Text';

import getCardHolderTitle from 'helpers/getCardHolderTitle';
import { useMemberContext } from 'context/MemberProvider';
import { getLabelIdByMemberType } from 'enums/MemberType';

import { TTeamMemberItemProps } from './TeamMemberItem.types';
import TeamMemberItemShimmer from './views/TeamMemberItemShimmer';

function TeamMemberItem({ item, iconColor }: TTeamMemberItemProps) {
  const { memberState } = useMemberContext();
  return (
    <Box display="flex" flexDirection="row">
      <ProfileCircleIcon width={40} height={40} strokeWidth={1.5} iconColor={iconColor} />
      <Box ml="s">
        <Text variant="mediumTextBold">{getCardHolderTitle(item, memberState?.id)}</Text>
        <Text
          variant="smallTextRegular"
          id={getLabelIdByMemberType(item.memberType)}
          color="infoItemGrayTitle"
        />
      </Box>
    </Box>
  );
}

TeamMemberItem.Shimmer = TeamMemberItemShimmer;

export default TeamMemberItem;
