import React, { ChangeEvent, ReactElement, useCallback } from 'react';
import { Controller } from 'react-hook-form';

import Box from 'components/core/Box';
import Input from 'components/core/Input';
import FormErrorLabel from 'components/FormErrorLabel';

import { TI18nId } from 'types/common';
import { TControllerRenderProps } from 'types/form';
import { TFieldTextInputProps } from './FieldTextInput.types';

function FieldTextInput({
  control,
  errors,
  labelId,
  name,
  showErrorMessage = true,
  placeholderId = 'placeholder.empty',
  mask,
  replaceChangeText,
  marginBottom = 'm',
  ...rest
}: TFieldTextInputProps): ReactElement {
  const errorMessageId = errors[name]?.message;

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (replaceChangeText) {
      return replaceChangeText(event.target.value);
    }

    return event.target.value;
  }, []);

  function handleRender({ field }: TControllerRenderProps): ReactElement {
    const { onChange, onBlur, value, ref } = field;

    return (
      <Input
        id={name}
        labelId={labelId}
        placeholderId={placeholderId}
        ref={ref}
        onBlur={onBlur}
        value={mask ? mask(value ?? '') : value}
        onChange={(nextValue) => onChange(handleOnChange(nextValue))}
        error={!!errorMessageId}
        {...rest}
      />
    );
  }

  return (
    <Box className="field-box" marginBottom={marginBottom}>
      <Controller name={name} control={control} render={handleRender} />
      {showErrorMessage && errorMessageId && <FormErrorLabel labelId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldTextInput;
