import React, { memo } from 'react';

import CountryImage from 'components/CountryImage';
import ListItem from 'components/core/ListItem';

import { TI18nId } from 'types/common';

import { TCountryItemProps } from '../SelectCountryModal.types';

function CountryItem({ country, onClick, showCallingCode }: TCountryItemProps) {
  return (
    <ListItem
      key={country.code}
      onClick={() => onClick(country)}
      titleId={country.name as TI18nId}
      description={(showCallingCode ? `+${country.callingCode}` : country.code) as TI18nId}
      leftIcon={<CountryImage code={country.code} size={32} />}
    />
  );
}

export default memo(CountryItem);
