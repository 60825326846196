import React, { memo, ReactElement } from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { TTransactionItemShimmerProps } from '../TransactionItem.types';

function TTransactionItemShimmer({ count, ...rest }: TTransactionItemShimmerProps): ReactElement {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Box key={i.toString()} alignment="row.verticalCenter" padding="m" {...rest}>
          <Shimmer shape="square" marginRight="m" />
          <Box alignment="col.top.left" flex={1}>
            <Box alignment="row.verticalCenter" width="100%">
              <Box flex="auto">
                <Shimmer shape="line" width="60%" />
              </Box>
              <Shimmer shape="line" width="10%" height={16} />
            </Box>
            <Shimmer marginTop="xs" shape="line" width="40%" />
          </Box>
        </Box>
      ))}
    </>
  );
}

export default memo(TTransactionItemShimmer);
