import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash/fp';

import { useMemberContext } from 'context/MemberProvider';
import { useLedgersContext } from 'context/LedgersProvider';
import { useMerchantContext } from 'context/MerchantProvider';
import { useMerchantFeatureFlagContext } from 'context/MerchantFeatureFlagProvider';
import MemberStatus from 'enums/MemberStatus';
import storage, { STORAGE_KEYS } from 'helpers/storage';
import { showErrorAlert } from 'helpers/showAlertModal';
import errorCodes from 'constants/errorCodes';
import MemberType from 'enums/MemberType';
import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';
import FeatureFlagType from 'enums/FeatureFlagType';
import { useOnboardingApplicationContext } from 'context/OnboardingApplicationProvider';
import { useFeatureFlagsContext } from 'context/FeatureFlagsProvider';
import { useBillingPackageCurrentContext } from 'context/BillingPackageCurrentProvider';
import useReferralUrlHandler from 'hooks/useReferralUrlHandler';
import { useReferralCampaignContext } from 'context/ReferralCampaignProvider';
import { useInvoiceSettingsContext } from 'context/InvoiceSettingsProvider';

function useInitialRequests() {
  const [loading, setLoading] = useState(true);

  const { featureFlagsDispatch } = useFeatureFlagsContext();
  const { ledgersDispatch } = useLedgersContext();
  const { memberDispatch } = useMemberContext();
  const { merchantDispatch } = useMerchantContext();
  const { onboardingApplicationDispatch } = useOnboardingApplicationContext();
  const { merchantFeatureFlagDispatch } = useMerchantFeatureFlagContext();
  const { billingPackageCurrentDispatch } = useBillingPackageCurrentContext();
  const { setReferralLink } = useReferralUrlHandler();
  const { referralCampaignDispatch } = useReferralCampaignContext();
  const { invoiceSettingsDispatch } = useInvoiceSettingsContext();

  useEffect(() => {
    setReferralLink();
    const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      handleInitialRequests();
    } else {
      setLoading(false);
    }
  }, []);

  async function liveMerchantInitialRequests() {
    referralCampaignDispatch.getActiveReferralCampaigns();
    await featureFlagsDispatch.getFeatureFlagsByType(FeatureFlagType.REFERRAL_CAMPAIGN);
    await featureFlagsDispatch.getFeatureFlagsByType(FeatureFlagType.BULK_TRANSFER);
    await merchantFeatureFlagDispatch.getMerchantFeatureFlags();
    await billingPackageCurrentDispatch.getCurrentBillingPackage();
    await ledgersDispatch.requestGetLedgers();
    await invoiceSettingsDispatch.getInvoiceSettings();
  }

  // eslint-disable-next-line consistent-return
  async function handleInitialRequests() {
    try {
      const { result: meResult } = await memberDispatch.getRequestMe();

      if (meResult) {
        await featureFlagsDispatch.getFeatureFlagsByType(FeatureFlagType.ONBOARDING_COMPANY);
        await featureFlagsDispatch.getFeatureFlagsByType(FeatureFlagType.ONBOARDING_SOLE_TRADER);
        Sentry.setUser({
          id: meResult.id,
          email: meResult.username ?? meResult.email ?? '',
        });
      }

      if (meResult?.status === MemberStatus.ACTIVE) {
        const { errorCode, result: merchantResult } = await merchantDispatch.getMerchant();

        if (errorCode === errorCodes.ACTIVE_APPLICATION_NOT_FOUND) {
          const onboardingMerchantType = storage.readStorage(STORAGE_KEYS.ONBOARDING_MERCHANT_TYPE);
          if (!isNil(onboardingMerchantType) && !Number.isNaN(Number(onboardingMerchantType))) {
            onboardingApplicationDispatch.setMerchantType(Number(onboardingMerchantType));
            return {
              me: meResult,
              merchantResult,
              onboardingMerchantType: Number(onboardingMerchantType),
            };
          }

          return {
            me: meResult,
            merchantResult,
          };
        }

        if (
          merchantResult.onboardingStatus === MerchantOnboardingStatus.IN_PROGRESS &&
          meResult.memberType === MemberType.DIRECTOR
        ) {
          const { result: onboardingSections } =
            (await onboardingApplicationDispatch.getOnboardingSections()) as never;
          await onboardingApplicationDispatch.getDocumentTypes();

          return {
            me: meResult,
            merchantResult,
            onboardingSections,
          };
        }

        if (merchantResult.onboardingStatus === MerchantOnboardingStatus.LIVE) {
          await liveMerchantInitialRequests();
        }

        return {
          me: meResult,
          merchantResult,
        };
      }

      return {
        me: meResult,
      };
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({
        titleId: 'label.unexpectedError',
      });
    } finally {
      setLoading(false);
    }
  }

  return { handleInitialRequests, liveMerchantInitialRequests, loading };
}

export default useInitialRequests;
