import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import formatAddress from 'helpers/formatAddress';

import { TInvoicePreviewPdfCustomerDetailsProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfCustomerDetails({ customer }: TInvoicePreviewPdfCustomerDetailsProps) {
  return (
    <Box flex={1}>
      <Box maxWidth={310}>
        <Text textId="label.to" variant="textBodySmall" color="textSecondary" mb="s" />
        <Box display="flex" flexDirection="column" mb="m" gap="xs">
          <Text variant="textBodySubBold">{customer?.customerName}</Text>
          {customer?.tin && (
            <Text variant="textBodySmallBold" color="textSecondary">
              {customer.tin}
            </Text>
          )}
        </Box>
        {customer && (
          <Text variant="textBodySmall">
            {formatAddress({
              street: customer.address,
              country: customer.country,
              city: customer.city,
              postCode: customer.postCode,
            })}
          </Text>
        )}
        {customer?.contactName && <Text variant="textBodySmall">{customer?.contactName}</Text>}
        {customer?.email && <Text variant="textBodySmall">{customer?.email}</Text>}
      </Box>
    </Box>
  );
}

export default InvoicePreviewPdfCustomerDetails;
