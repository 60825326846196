import React from 'react';

import MultipleSelect from 'components/core/MultipleSelect';
import { EarthIcon, CheckmarkIcon, ChevronRightIcon, SafeIcon } from 'components/Icons';
import ListItem from 'components/core/ListItem';
import Box from 'components/core/Box';
import BankAccountItem from 'components/BankAccountItem';

import { useLedgersContext } from 'context/LedgersProvider';
import { TLedger } from 'types/ledger';
import { TI18nId } from 'types/common';

import { TLedgerSelectItemProps } from './LedgerSelectItem.types';

function LedgerSelectItem({ modalTitleId, onSelect, selectedLedgers }: TLedgerSelectItemProps) {
  const { ledgersState } = useLedgersContext();

  function getDescription(ledgers: { ledgerName: string }[]): TI18nId {
    if (!ledgers.length) {
      return 'label.noAccount';
    }
    if (ledgers.length === ledgersState.ledgers.length) {
      return 'label.allAccounts';
    }
    return ledgers.map(({ ledgerName }) => ledgerName).join(', ') as TI18nId;
  }

  function getLedgersId(ledgers: { ledgerId: string }[]) {
    return ledgers.map(({ ledgerId }) => ledgerId);
  }

  function handleSelectLedgers(ledgerIds: string[]) {
    const selectedLedgers = ledgersState.ledgers
      .filter(({ id }) => ledgerIds.includes(id))
      .map(({ id, name }) => ({
        ledgerId: id,
        ledgerName: name,
      }));
    onSelect?.(selectedLedgers);
  }

  return (
    <MultipleSelect<TLedger>
      modalTitleId={modalTitleId}
      listItemProps={{
        titleId: 'label.accounts',
        description: getDescription(selectedLedgers) as TI18nId,
        leftIcon: <SafeIcon />,
        rightIcon: <ChevronRightIcon />,
        type: 'button',
      }}
      valueKey="id"
      options={ledgersState.ledgers}
      values={getLedgersId(selectedLedgers)}
      renderSelectAllOption={({ onClick, selected }) => (
        <ListItem
          titleId="label.allAccounts"
          leftIcon={<EarthIcon />}
          rightIcon={selected ? <CheckmarkIcon /> : undefined}
          onClick={onClick}
        />
      )}
      renderOptionItem={(listItem) => (
        <BankAccountItem
          item={listItem}
          rightIcon={<Box>{listItem.selected && <CheckmarkIcon />}</Box>}
          hideBalance
        />
      )}
      onSubmit={handleSelectLedgers}
    />
  );
}

export default LedgerSelectItem;
