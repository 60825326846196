import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const SuitcaseIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        d="M6.66666 6.66634H3.66666C2.56209 6.66634 1.66666 7.56177 1.66666 8.66634V15.4997C1.66666 16.6042 2.56209 17.4997 3.66666 17.4997H16.3333C17.4379 17.4997 18.3333 16.6042 18.3333 15.4997V8.66634C18.3333 7.56177 17.4379 6.66634 16.3333 6.66634H13.3333M6.66666 6.66634C6.66666 6.37014 6.66666 5.86336 6.66666 5.33313C6.66666 4.22856 7.56209 3.33301 8.66666 3.33301H11.3333C12.4379 3.33301 13.3333 4.22844 13.3333 5.33301V6.66634M6.66666 6.66634H13.3333M9.99999 9.99967V13.333"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SuitcaseIcon;
