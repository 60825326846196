import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFetch } from 'use-http';

import { useCardsContext } from 'context/CardsProvider';
import replaceUrlParams from 'helpers/replaceUrlParams';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';

import usePolling from 'hooks/usePolling';
import { isCardActivating, isCardFreezing } from 'enums/CardStatus';

import { TUseCardFreezeProps } from './useCardFreeze.types';

function useCardFreeze({ cardId, requestCardDetail, cardStatus }: TUseCardFreezeProps) {
  const [loading, setLoading] = useState(false);
  const { cardsDispatch } = useCardsContext();

  const { reFetch: startRequestCardDetailPolling, stopPolling: stopRequestCardDetailPolling } =
    usePolling({
      pollInterval: 1500,
      pollingFunc: requestCardDetail,
    });

  useEffect(() => {
    if (isCardFreezing(cardStatus) || isCardActivating(cardStatus)) {
      startRequestCardDetailPolling();
    } else {
      stopRequestCardDetailPolling();
    }
    return () => stopRequestCardDetailPolling();
  }, [cardStatus]);

  const { post: requestCardFreeze } = useFetch(
    replaceUrlParams(API_ENDPOINT.CARD_FREEZE, {
      cardId,
    })
  );

  const { post: requestCardUnfreeze } = useFetch(
    replaceUrlParams(API_ENDPOINT.CARD_UNFREEZE, {
      cardId,
    })
  );

  async function handleCardFreeze() {
    try {
      setLoading(true);
      const { errorMessage } = await requestCardFreeze();

      if (errorMessage) {
        return showErrorAlert({
          titleId: errorMessage,
        });
      }

      await requestCardDetail();
      return cardsDispatch.getCards();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'label.unexpectedError' });
    } finally {
      setLoading(false);
    }
  }

  async function handleCardUnfreeze() {
    try {
      setLoading(true);
      const { errorMessage } = await requestCardUnfreeze();

      if (errorMessage) {
        return showErrorAlert({
          titleId: errorMessage,
        });
      }

      await requestCardDetail();
      return cardsDispatch.getCards();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'label.unexpectedError' });
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    handleCardFreeze,
    handleCardUnfreeze,
  };
}

export default useCardFreeze;
