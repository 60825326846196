import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import { TInvoiceDetailResponse } from 'types/invoice';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';

import { TInvoiceDetailState, TInvoiceDetailContext } from './InvoiceDetailProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoiceDetailProvider.constants';

const invoiceDetailContext = createContext<TInvoiceDetailContext>({
  invoiceDetailState: INITIAL_STATE,
  invoiceDetailDispatch: INITIAL_DISPATCH,
});

function InvoiceDetailProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TInvoiceDetailState>(INITIAL_STATE);
  const { get: requestGetInvoiceDetail, data: invoiceDetailData } = useFetch<
    TCommonResponse<TInvoiceDetailResponse>
  >(API_ENDPOINT.ACCOUNTS_RECEIVABLE_INVOICES);

  useEffect(() => {
    if (invoiceDetailData?.result) {
      setState({
        invoice: invoiceDetailData.result,
        loading: false,
      });
    }
  }, [invoiceDetailData]);

  function setInvoice(invoice: TInvoiceDetailResponse) {
    setState((prevState) => ({
      ...prevState,
      invoice,
    }));
  }

  function setLoading(loading: boolean) {
    setState((prevState) => ({ ...prevState, loading }));
  }

  async function handleRequestGetInvoiceDetail(invoiceId: string) {
    try {
      setLoading(true);
      await requestGetInvoiceDetail(`/${invoiceId}`);
      setLoading(false);
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'errors.unexpectedError' });
      setLoading(false);
    }
  }

  async function handleRefetchInvoiceDetail() {
    await requestGetInvoiceDetail(`/${state.invoice?.id}`);
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TInvoiceDetailContext = {
    invoiceDetailState: state,
    invoiceDetailDispatch: {
      setInvoice,
      requestGetInvoiceDetail: handleRequestGetInvoiceDetail,
      refetchInvoiceDetail: handleRefetchInvoiceDetail,
      clear,
    },
  };

  return <invoiceDetailContext.Provider value={value}>{children}</invoiceDetailContext.Provider>;
}

export default InvoiceDetailProvider;

export const useInvoiceDetailContext = (): TInvoiceDetailContext =>
  useContext(invoiceDetailContext);
