import { useState } from 'react';
import { debounce, isEmpty } from 'lodash/fp';

import { TProcessedRow } from 'types/bulkTransfers';
import { TBeneficiary } from 'types/beneficiary';

import { useBulkTransfersContext } from './BulkTransfersProvider';

export function useBulkTransfersBeneficiariesList() {
  const { bulkTransfersState } = useBulkTransfersContext();
  const [filteredData, setFilteredData] = useState<TProcessedRow[]>();
  const [isSearchTextEmpty, setIsSearchTextEmpty] = useState<boolean>(true);

  function handleClearSearch() {
    setIsSearchTextEmpty(true);
    setFilteredData(undefined);
  }

  function filterBeneficiary(beneficiary: TBeneficiary, searchText: string): boolean {
    const textToSearch = searchText.toLowerCase();

    const { name } = beneficiary;
    return name?.toLowerCase().includes(textToSearch);
  }

  function handleSearch(searchText: string) {
    if (searchText.length === 0) {
      handleClearSearch();
      return;
    }

    setIsSearchTextEmpty(false);
    setFilteredData(
      bulkTransfersState.transfers.filter((item) => filterBeneficiary(item.beneficiary, searchText))
    );
  }

  return {
    data: filteredData ?? bulkTransfersState.transfers,
    clearSearch: handleClearSearch,
    isListEmpty: isEmpty(bulkTransfersState.transfers),
    isSearchResultEmpty: isEmpty(filteredData) && !isSearchTextEmpty,
    search: debounce(300, handleSearch),
    searchLoading: false,
  };
}
