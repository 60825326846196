import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Pressable from 'components/v2/core/Pressable';

import { TCardItemBaseProps } from './CardItemBase.types';
import { getCardItemBackgroundByState } from './CardItemBase.helpers';

function CardItemBase({
  titleId,
  titleProps,
  description,
  icon,
  right,
  onPress,
  selected = false,
  gap = 's',
}: TCardItemBaseProps) {
  return (
    <Pressable onClick={onPress} disabled={!onPress}>
      {({ pressed, hovered }) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          p="s"
          gap={gap}
          borderRadius={8}
          backgroundColor={getCardItemBackgroundByState({
            pressed,
            hovered,
            selected,
          })}>
          {icon}
          <Box gap="xxs" flex={1}>
            <Text textId={titleId} variant="textBodySubBold" color="textPrimary" {...titleProps} />
            {description}
          </Box>
          {typeof right === 'function' ? right(pressed) : right}
        </Box>
      )}
    </Pressable>
  );
}

export default CardItemBase;
