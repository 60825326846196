import styled from 'styled-components';

import media from 'helpers/media';
import { getColor } from 'helpers/theme';
import spacing from 'theme/spacing';
import { TThemePrimitives } from 'theme/theme.types';

export const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${getColor('primaryBackground')};
  margin: 0 auto;

  ${media.m} {
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: ${getColor('primaryBackground')};
  margin: 0 auto;
  justify-content: center;
  padding: 1rem ${spacing.l};

  ${media.xs} {
    padding: 1rem ${spacing.xxl};
  }

  ${media.s} {
    padding-top: 3rem;
  }

  ${media.m} {
    padding-right: 33.8%;
    justify-content: flex-start;
    flex: 3;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: block;
  height: 100%;

  ${media.s} {
    max-width: 26rem;
    margin: 0 auto;
    padding-bottom: ${spacing.m};
    height: auto;
  }
`;

export const RightContentWrapper = styled.div<{
  backgroundColor?: keyof TThemePrimitives;
}>`
  display: none;

  ${media.m} {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? getColor(backgroundColor) : '#252529'};
    height: 100%;
    display: flex;
    margin: 0;
    width: 33.8%;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    padding: 4.5rem 40px 40px 40px;
    overflow-y: auto;
  }

  ${media.l} {
    padding-right: 72px;
  }
`;

export const RightContentImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
