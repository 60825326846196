import React from 'react';

import Text from 'components/core/Text';

import { formatAmount } from 'helpers/formatAmount';

import { TLedgerDescriptionProps } from '../LedgerItem.types';

function LedgerDescription({ hideBalance, ledger }: TLedgerDescriptionProps) {
  if (!hideBalance) {
    return (
      <Text variant="smallTextBold" color="infoItemGrayTitle" mr="s">
        {formatAmount({ amount: ledger.balance, currency: ledger.currency })}
      </Text>
    );
  }

  return null;
}

export default LedgerDescription;
