import Currency from 'enums/Currency';
import { TCurrency, TI18nId } from 'types/common';
import SendMoneyType from 'enums/SendMoneyType';

import {
  BETWEEN_ACCOUNTS_DEFAULT_REFERENCE,
  DEFAULT_REFERENCE,
  SEND_MONEY_DESCRIPTION_MAX_LENGTH,
} from './sendMoney.constants';
import { TGetMaxLengthInfo } from './sendMoney.types';

function getMaxLength(
  senderCurrencyCode: TCurrency['code'],
  receiverCurrencyCode?: TCurrency['code']
): TGetMaxLengthInfo {
  if (senderCurrencyCode === Currency.EUR && receiverCurrencyCode === Currency.EUR) {
    return SEND_MONEY_DESCRIPTION_MAX_LENGTH.EUR_TO_EUR;
  }

  return SEND_MONEY_DESCRIPTION_MAX_LENGTH.EUR_OR_GBP_TO_ANY;
}

function getDefaultDescription(sendMoneyType?: SendMoneyType): TI18nId {
  if (sendMoneyType === SendMoneyType.MY_ACCOUNT) {
    return BETWEEN_ACCOUNTS_DEFAULT_REFERENCE;
  }

  return DEFAULT_REFERENCE;
}

const sendMoney = {
  getMaxLengthInfo: getMaxLength,
  getDefaultDescription,
};

export default sendMoney;
