import React, { ReactElement } from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import date from 'helpers/date';
import { TOrderStatusItemProps } from '../CardDetail.types';

function OrderStatusItem({
  icon: Icon,
  titleId,
  descriptionId,
  descriptionDate,
  iconColor,
  titleColor,
}: TOrderStatusItemProps): ReactElement {
  const formattedDate =
    descriptionDate &&
    date.format({
      date: descriptionDate,
      formatType: 'dd.MM.yyyy, iiii',
    });

  return (
    <Box alignment="row.verticalCenter">
      <Box alignSelf="flex-start" mr="m">
        <Icon iconColor={iconColor} />
      </Box>
      <Box>
        <Text id={titleId} variant="mediumTextBold" color={titleColor} mb="xs" />
        <Text
          variant="smallTextRegular"
          color="primary"
          {...(descriptionId && {
            id: descriptionId,
            values: {
              date: formattedDate,
            },
          })}>
          {!descriptionId && formattedDate}
        </Text>
      </Box>
    </Box>
  );
}

export default OrderStatusItem;
