import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Box from 'components/core/Box';
import OnboardingProgressSteps from 'components/OnboardingProgressSteps';
import { WamoWordmarkIcon, WamoIcon } from 'components/v2/BrandIcons';
import ProfileAvatarItem from 'components/v2/ProfileAvatarItem';
import { Home01Icon } from 'components/v2/Icons';
import NavItem from 'components/v2/NavItem';

import routes from 'router/routes';
import useGetOnboardingMerchantType from 'hooks/useGetOnboardingMerchantType';
import MerchantType from 'enums/MerchantType';

import {
  COMPANY_ONBOARDING_PROGRESS_STEPS,
  SOLE_TRADER_ONBOARDING_PROGRESS_STEPS,
} from '../../DashboardLayout.constants';
import { TOnboardingApplicationSummaryLayoutDesktopNavProps } from '../../DashboardLayout.types';

function OnboardingNavDesktopItems({
  activeParentStepIndex,
  activeChildStepIndex,
  hideProgressSteps,
}: TOnboardingApplicationSummaryLayoutDesktopNavProps) {
  const onboardingMerchantType = useGetOnboardingMerchantType();
  const { colors } = useTheme();

  function getSteps() {
    if (onboardingMerchantType === MerchantType.SOLE_TRADER) {
      return SOLE_TRADER_ONBOARDING_PROGRESS_STEPS;
    }
    return COMPANY_ONBOARDING_PROGRESS_STEPS;
  }

  return (
    <>
      <NavLink to={routes.onboardingApplicationSummary.path}>
        <Box
          alignment="row.verticalCenter"
          justifyContent={{ _: 'center', l: 'flex-start' }}
          px="m">
          <WamoIcon width={32} height={16} iconColor={colors.textActive} />
          <Box width={88} height={16} display={{ _: 'none', l: 'block' }}>
            <WamoWordmarkIcon width={88} height={16} iconColor={colors.textPrimary} />
          </Box>
        </Box>
      </NavLink>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ _: 'center', l: 'stretch' }}
        gap="s"
        px="s"
        flex={1}
        mt="xxl">
        {!hideProgressSteps ? (
          <OnboardingProgressSteps
            steps={getSteps()}
            activeParentStepIndex={activeParentStepIndex as number}
            activeChildStepIndex={activeChildStepIndex as number}
          />
        ) : (
          <NavItem
            to={routes.onboardingApplicationSummary.path}
            titleId={routes.onboardingApplicationSummary.titleId}
            icon={Home01Icon}
          />
        )}
      </Box>
      <Box px="s" pt="s" mt="l" borderTop="1px solid" borderColor="borderDivider">
        <ProfileAvatarItem path={routes.onboardingAccount.path} />
      </Box>
    </>
  );
}

export default OnboardingNavDesktopItems;
