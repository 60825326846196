import React, { ChangeEvent, useRef, useState } from 'react';

import Box from 'components/core/Box';
import { SearchIcon } from 'components/Icons';

import { StyledInput } from './SearchBar.styles';
import { TSearchBarProps } from './SearchBar.types';

function SearchBar({
  onSearch,
  placeholderId,
  autoFocus = true,
  disabled,
  ...rest
}: TSearchBarProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  function handleChangeText(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    onSearch?.(e.target.value);
  }

  return (
    <Box position="relative" mb="m" {...rest}>
      <StyledInput
        ref={inputRef}
        value={value}
        onChange={handleChangeText}
        autoCapitalize="words"
        placeholderId={placeholderId}
        autoFocus={autoFocus}
        disabled={disabled}
        showPlaceholderOnBlur
      />
      <Box position="absolute" top="0px" bottom="0px" right="m" alignment="center" zIndex={2}>
        <SearchIcon width={24} height={24} iconColor="primary" />
      </Box>
    </Box>
  );
}

export default SearchBar;
