import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import BeneficiaryAvatar from 'components/v2/BeneficiaryItem/views/BeneficiaryAvatar';

import { formatAmount } from 'helpers/formatAmount';
import date from 'helpers/date';
import TransactionDirectionType from 'enums/TransactionDirectionType';

import { TScheduledBulkTransferItemProps } from './ScheduledBulkTransferItem.types';

function ScheduledBulkTransferItem({ item, scheduledAt }: TScheduledBulkTransferItemProps) {
  return (
    <Box alignment="row.verticalCenter" p="m">
      <Box alignment="row.verticalCenter" flex={1}>
        <BeneficiaryAvatar name={item.titleDetail.title} isBulkTransfer />
        <Box flex={1} px="m">
          <Text variant="mediumTextSemiBold" mb="xs">
            {item.titleDetail.prefix} {item.titleDetail.title}
          </Text>
          <Text
            id="label.scheduledAt"
            variant="smallTextRegular"
            color="textPrimary"
            values={{
              date: date.format({ date: scheduledAt, formatType: 'd MMM' }),
            }}
          />
        </Box>
      </Box>
      <Box alignment="row.verticalCenter" alignSelf="flex-start">
        <Text variant="mediumTextBold" color="amountLabelSecondaryPassiveText" mb="xs">
          {formatAmount({
            amount: item.amount,
            currency: item.currency,
            direction: TransactionDirectionType.OUTBOUND,
          })}
        </Text>
      </Box>
    </Box>
  );
}

export default ScheduledBulkTransferItem;
