import React, { ReactElement } from 'react';

import LottieView from 'components/v2/LottieView';
import Box from 'components/core/Box';

import LOTTIES from 'constants/lotties';

function LoadingPage(): ReactElement {
  return (
    <Box backgroundColor="primaryBackground" alignment="row.horizontalCenter" height="100vh">
      <Box marginTop="15%">
        <LottieView source={LOTTIES.wamoLogoLoading} width={120} />
      </Box>
    </Box>
  );
}

export default LoadingPage;
