import React from 'react';

import Box from 'components/core/Box';
import Card from 'components/core/Card';
import Shimmer from 'components/core/Shimmer';

import { TListCardButtonShimmerProps } from '../ListCardButton.types';

function ListCardButtonShimmer({
  description,
  leftComponent,
  rightComponent,
  ...restProps
}: TListCardButtonShimmerProps) {
  return (
    <Card
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      marginBottom="m"
      padding="m"
      {...restProps}>
      {leftComponent && <Shimmer shape="square" width={24} height={24} />}
      <Box
        flex={1}
        paddingLeft={leftComponent ? 'm' : 'none'}
        paddingRight={rightComponent ? 'm' : 'none'}>
        <Box width="35%" alignItems="center" height={20}>
          <Shimmer shape="line" width="100%" />
        </Box>
        {description && (
          <Box mt="xxs" alignItems="center" width="75%" height={20}>
            <Shimmer shape="line" width="100%" />
          </Box>
        )}
      </Box>
      {rightComponent && <Shimmer shape="square" width={24} height={24} />}
    </Card>
  );
}

export default ListCardButtonShimmer;
