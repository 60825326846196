import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFetch } from 'use-http';

import { TChildrenOnly } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';

import { INITIAL_DISPATCH, INITIAL_STATE } from './BillingPackageCurrentProvider.constants';

import {
  TBillingPackageCurrentState,
  TBillingPackageCurrentContext,
} from './BillingPackageCurrentProvider.types';

const billingPackageCurrentContext = createContext<TBillingPackageCurrentContext>({
  billingPackageCurrentState: INITIAL_STATE,
  billingPackageCurrentDispatch: INITIAL_DISPATCH,
});

function BillingPackageCurrentProvider({ children }: TChildrenOnly): ReactElement {
  const {
    get: requestGetCurrentPackage,
    loading,
    data,
  } = useFetch(API_ENDPOINT.BILLING_PACKAGES_CURRENT);
  const [currentPackage, setCurrentPackage] = useState<
    TBillingPackageCurrentState['currentPackage']
  >(INITIAL_STATE.currentPackage);

  useEffect(() => {
    if (data?.result) {
      setCurrentPackage(data.result);
    }
  }, [data]);

  async function getCurrentBillingPackage() {
    try {
      const response = await requestGetCurrentPackage();
      return response;
    } catch (e) {
      Sentry.captureException(e);
      return undefined;
    }
  }

  function clear() {
    setCurrentPackage(INITIAL_STATE.currentPackage);
  }

  const value = {
    billingPackageCurrentState: {
      loading,
      currentPackage,
    },
    billingPackageCurrentDispatch: { getCurrentBillingPackage, clear },
  };

  return (
    <billingPackageCurrentContext.Provider value={value}>
      {children}
    </billingPackageCurrentContext.Provider>
  );
}

export default BillingPackageCurrentProvider;

export const useBillingPackageCurrentContext = (): TBillingPackageCurrentContext =>
  useContext(billingPackageCurrentContext);
