import React, { useEffect, useState } from 'react';

import ListItem from 'components/core/ListItem';
import Modal, { useModal } from 'components/core/Modal';

import MultipleSelectModalContent from './views/MultipleSelectModalContent';
import { TMultipleSelectProps } from './MultipleSelect.types';

function MultipleSelect<T>({
  options,
  listItemProps,
  valueKey = 'value',
  renderOptionItem,
  defaultValues = [],
  values = defaultValues,
  onSubmit,
  renderSelectAllOption,
  modalTitleId,
}: TMultipleSelectProps<T>) {
  const modalRef = useModal();
  const [selectedValues, setSelectedValues] = useState<string[]>(values);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  function handleSelectValues(newValues: string[]) {
    setSelectedValues(newValues);
    onSubmit(newValues);
    modalRef.current?.show(false);
  }

  return (
    <div>
      <ListItem {...listItemProps} onClick={() => modalRef.current?.show(true)} />
      <Modal ref={modalRef} header={{ id: modalTitleId }} secondary>
        <MultipleSelectModalContent
          options={options}
          onClick={(values) => handleSelectValues(values)}
          selectedValues={selectedValues}
          renderOptionItem={renderOptionItem}
          valueKey={valueKey}
          renderSelectAllOption={renderSelectAllOption}
        />
      </Modal>
    </div>
  );
}

export default MultipleSelect;
