import React, { useRef } from 'react';

import Text from 'components/core/Text';
import ListCardButton from 'components/ListCardButton';
import { LockUnlockedIcon, PinCodeIcon } from 'components/Icons';
import Card from 'components/core/Card';
import { PasscodeLockIcon } from 'components/v2/Icons';

import CardReset3DSecurityModal from 'modals/CardReset3DSecurityModal';
import { isCardFreezing, isCardFrozen } from 'enums/CardStatus';
import { isPhysicalCard } from 'enums/CardType';
import {
  getListItemDescriptionIdByCard3DSType,
  getResetLabelIdByCard3DSType,
} from 'enums/Card3DSType';
import { TCardReset3DSecurityModalRef } from 'modals/CardReset3DSecurityModal/CardReset3DSecurityModal.types';

import { TSecuritySectionProps } from '../CardDetailSettingsModal.types';
import { UNBLOCK_PIN_CVV_LABEL_MAP } from '../CardDetailSettingsModal.constants';

function CardSecuritySection({ card, onClickShowPin, onClickUnblockPin }: TSecuritySectionProps) {
  const cardReset3DSecurityModalRef = useRef<TCardReset3DSecurityModalRef>(null);
  const isFrozen = isCardFrozen(card.status) || isCardFreezing(card.status);
  const shouldShowPin = !isFrozen && isPhysicalCard(card.type);
  const shouldShowUnblockPin = !isFrozen;
  const unblockPinLabels = isPhysicalCard(card.type)
    ? UNBLOCK_PIN_CVV_LABEL_MAP.physical
    : UNBLOCK_PIN_CVV_LABEL_MAP.virtual;

  return (
    <>
      <Text id="label.security" variant="mediumTextBold" mb="s" />
      <Card mb="l">
        {shouldShowPin && (
          <ListCardButton
            titleId="screens.cardDetail.showPin.title"
            titleProps={{ variant: 'mediumTextBold', mb: 'none' }}
            leftComponent={<PinCodeIcon width={32} height={32} iconColor="primary" />}
            onClick={onClickShowPin}
            mb="none"
          />
        )}
        {shouldShowUnblockPin && (
          <ListCardButton
            {...unblockPinLabels}
            titleProps={{ variant: 'mediumTextBold' }}
            leftComponent={<LockUnlockedIcon width={32} height={32} iconColor="primary" />}
            onClick={onClickUnblockPin}
            mb="none"
          />
        )}
        <ListCardButton
          titleId={getResetLabelIdByCard3DSType(card.threedSecurityMethod.type)}
          descriptionId={getListItemDescriptionIdByCard3DSType(card.threedSecurityMethod.type)}
          titleProps={{ variant: 'mediumTextBold' }}
          leftComponent={<PasscodeLockIcon width={32} height={32} iconColor="textPrimary" />}
          onClick={() => cardReset3DSecurityModalRef.current?.show({ card })}
          mb="none"
        />
      </Card>
      <CardReset3DSecurityModal modalRef={cardReset3DSecurityModalRef} />
    </>
  );
}

export default CardSecuritySection;
