import React, { memo } from 'react';

import { FLAG_IMAGE_URL } from 'configs/cdn';
import { TCountryImageProps } from './CountryImage.types';

function CountryImage({ code, size }: TCountryImageProps) {
  return <img src={`${FLAG_IMAGE_URL}${code}.svg`} alt={code} width={size} height={size} />;
}

export default memo(CountryImage);
