import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const AgriculturalServicesIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M10.4973 11.6798L10.5032 11.6734L10.509 11.6669C10.8767 11.2562 11.4078 11 12 11C12.5922 11 13.1233 11.2562 13.491 11.6669L13.4968 11.6734L13.5027 11.6798L17.4662 15.9557C17.8009 16.3811 18 16.916 18 17.5C18 18.8807 16.8807 20 15.5 20C15.0325 20 14.5978 19.8726 14.2254 19.6513C14.177 19.6226 14.1297 19.5922 14.0834 19.5603L14.0534 19.5396L14.0219 19.5211C13.4278 19.1726 12.7357 18.9731 12 18.9731C11.2748 18.9731 10.5612 19.1842 9.98383 19.5178L9.94935 19.5377L9.91656 19.5603C9.51422 19.8377 9.02757 20 8.5 20C7.11929 20 6 18.8807 6 17.5C6 16.916 6.19906 16.3811 6.53384 15.9557L10.4973 11.6798ZM10.5 5.75C10.5 6.7165 9.7165 7.5 8.75 7.5C7.7835 7.5 7 6.7165 7 5.75C7 4.7835 7.7835 4 8.75 4C9.7165 4 10.5 4.7835 10.5 5.75ZM15.25 7.5C14.2835 7.5 13.5 6.7165 13.5 5.75C13.5 4.7835 14.2835 4 15.25 4C16.2165 4 17 4.7835 17 5.75C17 6.7165 16.2165 7.5 15.25 7.5ZM6.5 10.75C6.5 11.7165 5.7165 12.5 4.75 12.5C3.7835 12.5 3 11.7165 3 10.75C3 9.7835 3.7835 9 4.75 9C5.7165 9 6.5 9.7835 6.5 10.75ZM19.25 12.5C18.2835 12.5 17.5 11.7165 17.5 10.75C17.5 9.7835 18.2835 9 19.25 9C20.2165 9 21 9.7835 21 10.75C21 11.7165 20.2165 12.5 19.25 12.5Z"
        stroke={colors[iconColor]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default AgriculturalServicesIcon;
