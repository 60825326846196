import styled, { css } from 'styled-components';

import SideLayoutPage from 'components/SideLayoutPage';

import { getColor } from 'helpers/theme';

export const StyledSideLayoutPage = styled(SideLayoutPage)<{ lineThroughAmount?: boolean }>`
  ${(props) =>
    props.lineThroughAmount &&
    css`
      .headerTitle {
        p {
          color: ${getColor('transactionDeclinedReversedAmount')};
          text-decoration: line-through;
        }
      }
    `}
`;
