import React, { memo } from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Pressable from 'components/v2/core/Pressable';
import Badge from 'components/v2/core/Badge';

import ListItemBaseRightArrow from './views/ListItemBaseRightArrow';

import { TListItemBaseProps } from './ListItemBase.types';
import { LIST_ITEM_BASE_MIN_HEIGHT } from './ListItemBase.constants';
import { getListItemStateStyle } from './ListItemBase.helpers';

function ListItemBase({
  labelId,
  labelProps,
  descriptionId,
  descriptionProps,
  left,
  right,
  onPress,
  disabled,
  hasArrow,
  marginTop,
  marginBottom,
  badgeProps,
  minHeight = LIST_ITEM_BASE_MIN_HEIGHT,
  hasError,
}: TListItemBaseProps) {
  const hasDescription = descriptionId || descriptionProps;

  return (
    <Box width="100%" marginTop={marginTop} marginBottom={marginBottom} position="relative">
      <Pressable onClick={onPress} disabled={disabled}>
        {({ pressed, hovered }) => {
          const { labelColor, descriptionColor, borderRadius, backgroundColor } =
            getListItemStateStyle({
              pressed,
              disabled,
              hovered,
              hasError,
            });

          return (
            <Box
              minHeight={minHeight}
              display="flex"
              flexDirection="row"
              alignItems="center"
              backgroundColor={backgroundColor}
              borderRadius={borderRadius}
              p="s">
              {left && (
                <Box mr="s">
                  {typeof left === 'function'
                    ? left({ pressed, hovered, hasError, disabled })
                    : left}
                </Box>
              )}
              <Box display="flex" flexDirection="column" gap="xxs" flex={1}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="s">
                  <Text
                    textId={labelId}
                    variant="textBodyBold"
                    color={labelColor}
                    {...labelProps}
                  />
                  {badgeProps && <Badge {...badgeProps} />}
                </Box>
                {hasDescription && (
                  <Text
                    textId={descriptionId}
                    variant="textBodySub"
                    color={descriptionColor}
                    {...descriptionProps}
                  />
                )}
              </Box>
              {right && (
                <Box ml="s">
                  {typeof right === 'function'
                    ? right({ pressed, hovered, hasError, disabled })
                    : right}
                </Box>
              )}
              {hasArrow && <ListItemBaseRightArrow pressed={pressed} disabled={disabled} />}
            </Box>
          );
        }}
      </Pressable>
    </Box>
  );
}

export default memo(ListItemBase);
