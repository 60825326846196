import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import { getIconByCurrency } from 'enums/Currency';

import { TLedgerItemProps } from './LedgerItem.types';
import LedgerItemMainAccountBadge from './views/LedgerItemMainAccountBadge';
import LedgerDescription from './views/LedgerDescription';

function LedgerItem({
  ledger,
  hideIcon = false,
  hideBalance = false,
  rightComponent,
}: TLedgerItemProps) {
  const IconComponent = getIconByCurrency(ledger.currency.code);

  return (
    <Box alignment="row.verticalCenter">
      {!hideIcon && (
        <Box mr="xs" position="relative" display="flex" alignItems="center" width={48} height={40}>
          <Box display="flex">
            <IconComponent width={40} height={40} />
          </Box>
          {ledger.isMainLedger && <LedgerItemMainAccountBadge />}
        </Box>
      )}
      <Box justifyContent="center" flex={1}>
        <Text variant="mediumTextBold">{ledger.name}</Text>
        <LedgerDescription hideBalance={hideBalance} ledger={ledger} />
      </Box>
      {rightComponent && (
        <Box ml="xs" alignSelf="center">
          {rightComponent}
        </Box>
      )}
    </Box>
  );
}

export default LedgerItem;
