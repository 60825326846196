import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';

import MerchantType from 'enums/MerchantType';
import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import storage, { STORAGE_KEYS } from 'helpers/storage';
import { TCountryDetailResponse } from 'context/CountriesProvider/CountriesProvider.types';

import { INITIAL_DISPATCH, INITIAL_STATE } from './OnboardingApplicationProvider.constants';
import {
  TOnboardingApplicationContext,
  TOnboardingApplicationState,
  TOnboardingSections,
} from './OnboardingApplicationProvider.types';

const onboardingApplicationContext = createContext<TOnboardingApplicationContext>({
  onboardingApplicationState: INITIAL_STATE,
  onboardingApplicationDispatch: INITIAL_DISPATCH,
});

function OnboardingApplicationProvider({ children }: TChildrenOnly): ReactElement {
  const { get: requestOnboardingSections, data: onboardingSectionsData } = useFetch<
    TCommonResponse<TOnboardingSections>
  >(API_ENDPOINT.ONBOARDING_SECTIONS);
  const { get: requestDocuments, data: documentsData } = useFetch(API_ENDPOINT.DOCUMENTS);
  const { get: requestDocumentTypes, data: documentTypesData } = useFetch(
    API_ENDPOINT.ONBOARDING_DOCUMENT_TYPES
  );
  const [state, setState] = useState<TOnboardingApplicationState>(INITIAL_STATE);

  useEffect(() => {
    if (onboardingSectionsData) {
      setState((prevState) => ({
        ...prevState,
        summaryLoading: false,
        summary: (onboardingSectionsData?.result ?? {}) as TOnboardingSections,
      }));
    }
  }, [onboardingSectionsData]);

  useEffect(() => {
    if (documentsData?.result) {
      setState((prevState) => ({
        ...prevState,
        documents: documentsData.result,
      }));
    }
  }, [documentsData]);

  useEffect(() => {
    if (documentTypesData?.result) {
      setState((prevState) => ({
        ...prevState,
        documentTypes: documentTypesData?.result,
      }));
    }
  }, [documentTypesData]);

  function setMerchantType(type: MerchantType) {
    storage.writeStorage(STORAGE_KEYS.ONBOARDING_MERCHANT_TYPE, type.toString());
    setState((prevState) => ({ ...prevState, merchantType: type }));
  }

  function setCountryOfIncorporation(country: TCountryDetailResponse) {
    setState((prevState) => ({ ...prevState, countryOfIncorporation: country }));
  }

  function clearState() {
    setState(INITIAL_STATE);
    storage.removeStorage(STORAGE_KEYS.ONBOARDING_MERCHANT_TYPE);
  }

  const value = {
    onboardingApplicationState: state,
    onboardingApplicationDispatch: {
      setMerchantType,
      setCountryOfIncorporation,
      getOnboardingSections: requestOnboardingSections,
      getDocuments: requestDocuments,
      getDocumentTypes: requestDocumentTypes,
      clearState,
    },
  };

  return (
    <onboardingApplicationContext.Provider value={value}>
      {children}
    </onboardingApplicationContext.Provider>
  );
}

export default OnboardingApplicationProvider;

export const useOnboardingApplicationContext = (): TOnboardingApplicationContext =>
  useContext(onboardingApplicationContext);
