import React from 'react';

import { useMemberContext } from 'context/MemberProvider';
import MemberType from 'enums/MemberType';

import { TMemberPermissionProps } from './MemberPermission.types';

function MemberPermission({ children, allowTo }: TMemberPermissionProps) {
  const { memberState } = useMemberContext();

  if (allowTo.includes(memberState?.memberType as MemberType)) {
    return <>{children}</>;
  }

  return null;
}

export default MemberPermission;
