import React from 'react';

import { TIconProps } from '../Icon.types';

const CadIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FEFEFE"
        d="M24.996 47.998c13.254 0 24-10.746 24-24 0-13.255-10.746-24-24-24-13.255 0-24 10.745-24 24 0 13.254 10.745 24 24 24Z"
      />
      <path
        fill="#C8102E"
        d="M48.995 23.998c0-9.515-5.537-17.737-13.565-21.619v43.237c8.028-3.882 13.566-12.103 13.566-21.618ZM.996 23.997c0 9.516 5.537 17.737 13.565 21.619V2.379C6.533 6.26.996 14.482.996 23.997ZM29.17 27.128l4.173-2.087-2.087-1.044V21.91l-4.174 2.087 2.087-4.174h-2.087l-2.086-3.13-2.087 3.13h-2.087l2.087 4.174-4.174-2.087v2.087l-2.087 1.044 4.174 2.087-1.044 2.087h4.174v3.13h2.087v-3.13h4.174l-1.044-2.087Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.996 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default CadIcon;
