import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function SuccessIcon({ iconColor = 'success', ...rest }: TIconProps): ReactElement {
  const { colors } = useTheme();

  return (
    <svg width={124} height={124} viewBox="0 0 124 124" fill="none" {...rest}>
      <path
        d="M41.333 62l15.5 15.5 25.834-25.833M62 118.833C30.612 118.833 5.167 93.388 5.167 62 5.167 30.612 30.612 5.167 62 5.167c31.388 0 56.833 25.445 56.833 56.833 0 31.388-25.445 56.833-56.833 56.833z"
        stroke={colors[iconColor]}
        strokeWidth={10.333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessIcon;
