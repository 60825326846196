import React from 'react';

import { TBottomButtonBoxProps } from './BottomButtonBox.types';
import { StyledBox } from './BottomButtonBox.styles';

function BottomButtonBox({ className, children, block }: TBottomButtonBoxProps) {
  return (
    <StyledBox className={className} block={block}>
      {children}
    </StyledBox>
  );
}

export default BottomButtonBox;
