import { object } from 'yup';

import {
  CARD_SECURITY_QUESTION_ANSWER,
  ONBOARDING_BUSINESS_OWNER_DOCUMENT_VALIDATION,
} from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  securityQuestion: ONBOARDING_BUSINESS_OWNER_DOCUMENT_VALIDATION,
  answer: CARD_SECURITY_QUESTION_ANSWER,
});
