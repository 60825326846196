export const STORAGE_PREFIX = '@wamo:';

export enum STORAGE_KEYS {
  EMAIL = 'email',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  PAGE_HAS_BEEN_FORCE_REFRESHED = 'pageHasBeenForceRefreshed',
  ONBOARDING_MERCHANT_TYPE = 'onboardingMerchantType',
  CARD_PROMOTION_VISIBILITY = 'cardPromotionVisibility',
  IN_APP_UPGRADE_ANNUAL_SHOW_VISIBLE_DATE = 'inAppUpgradeAnnualShowVisibleDate',
  PREFERRED_LANGUAGE = 'preferredLanguage',
  REFERRAL = 'referral',
  FALLBACK_DEVICE_ID = 'fdi',
}
