import React from 'react';

import HomeNavLayout from './HomeNavItems/HomeNavLayout';
import OnboardingNavLayout from './OnboardingNavItems/OnboardingNavLayout';

import { NavMenu, TDashboardLayoutNavProps } from '../DashboardLayout.types';

function DashboardLayoutNav({
  navMenu,
  activeParentStepIndex,
  activeChildStepIndex,
  hideProgressSteps,
  showMobileNav,
}: TDashboardLayoutNavProps) {
  if (navMenu === NavMenu.HOME) {
    return <HomeNavLayout />;
  }
  if (navMenu === NavMenu.ONBOARDING) {
    return (
      <OnboardingNavLayout
        activeParentStepIndex={activeParentStepIndex}
        activeChildStepIndex={activeChildStepIndex}
        hideProgressSteps={hideProgressSteps}
        showMobileNav={showMobileNav}
      />
    );
  }

  return <></>;
}

export default DashboardLayoutNav;
