import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useInvoiceDetailContext } from 'context/InvoiceDetailProvider';
import { TChildrenOnly } from 'types/common';

import InvoicePreviewLayout from '../InvoicePreviewLayout';

function InvoiceDetailLayout({ children }: TChildrenOnly) {
  const urlParams = useParams<{ invoiceId: string }>();
  const {
    invoiceDetailState: { invoice },
    invoiceDetailDispatch,
  } = useInvoiceDetailContext();

  useEffect(() => {
    if (urlParams.invoiceId) {
      invoiceDetailDispatch.requestGetInvoiceDetail(urlParams.invoiceId);
    }
  }, []);

  return <InvoicePreviewLayout invoice={invoice}>{children}</InvoicePreviewLayout>;
}

export default InvoiceDetailLayout;
