import React, { useContext } from 'react';

import { TRadioGroupContextProps } from './Radio.types';

const RadioGroupContext = React.createContext<TRadioGroupContextProps | null>(null);

export const RadioGroupContextProvider = RadioGroupContext.Provider;
export const useRadioGroupContext = () => useContext(RadioGroupContext);

export default RadioGroupContext;
