import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';

import THEME from 'constants/theme';
import { TChildrenOnly } from 'types/common';
import { lightTheme, darkTheme } from 'theme';
import { TThemeContext, TThemeState } from './ThemeProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './ThemeProvider.constants';
import { GlobalStyle } from './ThemeProvider.styles';

const themeContext = createContext<TThemeContext>({
  themeState: INITIAL_STATE,
  themeDispatch: INITIAL_DISPATCH,
});

export const ThemeProvider = ({ children }: TChildrenOnly): ReactElement => {
  const [{ theme }, setState] = useState<TThemeState>(INITIAL_STATE);

  function getTheme() {
    return theme === THEME.LIGHT ? lightTheme : darkTheme;
  }

  const changeTheme = useCallback(() => {
    if (theme === THEME.LIGHT) {
      setState({ theme: THEME.DARK });
    } else {
      setState({ theme: THEME.LIGHT });
    }
  }, []);

  const value = useMemo(
    (): TThemeContext => ({
      themeState: { theme },
      themeDispatch: { changeTheme },
    }),
    [theme, changeTheme]
  );

  return (
    <themeContext.Provider value={value}>
      <StyledComponentProvider theme={getTheme()}>
        <>
          <GlobalStyle />
          {children}
        </>
      </StyledComponentProvider>
    </themeContext.Provider>
  );
};

export const useThemeContext = (): TThemeContext => useContext(themeContext);
