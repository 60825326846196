import { TScheduledTransactionOrderHistoryItem } from 'types/scheduledTransaction';
import { TTransaction } from 'types/transaction';
import TransactionType from 'enums/TransactionType';
import TransactionDirectionType from 'enums/TransactionDirectionType';
import TransactionStatus from 'enums/TransactionStatus';

export function convertToTransaction(item: TScheduledTransactionOrderHistoryItem): TTransaction {
  return {
    id: (item.batchId || item.transactionId) as string,
    amount: item.amount,
    currency: item.currency,
    createdAt: item.executedAt,
    type: TransactionType.SEND,
    title: `${item.titleDetail.prefix} ${item.titleDetail.title}`,
    direction: TransactionDirectionType.OUTBOUND,
    status: TransactionStatus.ACCEPTED,
    isBulkTransfer: item.isBulkTransfer,
    titleDetail: item.titleDetail,
  };
}
