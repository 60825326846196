import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash/fp';

import { formatInvoiceAmount } from 'helpers/formatAmount';
import InvoiceItemDiscountType from 'enums/InvoiceItemDiscountType';

import { StyledItemsTable } from '../InvoicePreviewPdf.styles';
import { TInvoicePreviewPdfItemsProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfItems({ items = [] }: TInvoicePreviewPdfItemsProps) {
  const { t } = useTranslation();

  return (
    <StyledItemsTable>
      <thead>
        <tr>
          <th>{t('label.description')}</th>
          <th>{t('label.qty')}</th>
          <th>{t('label.unitPrice')}</th>
          <th>{t('label.vatWithSymbol')}</th>
          <th>{t('label.totalExclVat')}</th>
        </tr>
      </thead>
      {isEmpty(items) && (
        <tbody>
          <tr>
            <td />
            <td />
            <td />
            <td>10%</td>
            <td />
          </tr>
        </tbody>
      )}
      {items.map((item) => (
        <tbody key={item.id}>
          <tr>
            <td>
              <table>
                <tr>
                  <td>{item.title}</td>
                </tr>
                {item.description && (
                  <tr>
                    <td style={{ paddingTop: '4px' }} className="text-secondary">
                      {item.description}
                    </td>
                  </tr>
                )}
              </table>
            </td>
            <td>{item.quantity}</td>
            <td>
              {formatInvoiceAmount({
                amount: item.unitPrice,
                currency: item.currency,
              })}
            </td>
            <td>{item.vat?.rate ?? ''}%</td>
            <td className="text-align-right">
              {formatInvoiceAmount({
                amount: item.unitPrice * item.quantity,
                currency: item.currency,
              })}
            </td>
          </tr>
          {item.discount?.type && !!(item.discount?.percentage || item.discount?.amount) && (
            <tr>
              <td
                style={{ paddingTop: '8px', paddingLeft: '24px' }}
                className="text-secondary"
                colSpan={4}>
                {item.discount.type === InvoiceItemDiscountType.PERCENTAGE
                  ? t('label.discount.withPercentage', {
                      percentage: item.discount.percentage,
                    })
                  : t('label.discount')}
              </td>
              {!isNil(item.discount.amount) && (
                <td style={{ paddingTop: '8px' }} className="text-secondary text-align-right">
                  {`-${formatInvoiceAmount({
                    amount: item.discount.amount,
                    currency: item.currency,
                  })}`}
                </td>
              )}
            </tr>
          )}
        </tbody>
      ))}
    </StyledItemsTable>
  );
}

export default InvoicePreviewPdfItems;
