import { isNil } from 'lodash/fp';

import { useMerchantContext } from 'context/MerchantProvider';
import MerchantType from 'enums/MerchantType';
import { useOnboardingApplicationContext } from 'context/OnboardingApplicationProvider';

function useGetOnboardingMerchantType(): MerchantType {
  const { merchantState } = useMerchantContext();
  const { onboardingApplicationState } = useOnboardingApplicationContext();

  function getMerchantType() {
    if (!isNil(merchantState.merchant?.merchantType)) {
      return merchantState.merchant?.merchantType;
    }
    return onboardingApplicationState.merchantType;
  }

  return getMerchantType() as MerchantType;
}

export default useGetOnboardingMerchantType;
