import styled from 'styled-components';

import borderRadius from 'theme/borderRadius';

export const StyledContainer = styled.div`
  overflow-x: scroll;
  overflow-y: auto;
  min-height: 100%;
  max-height: 100%;
  border-radius: ${borderRadius.xl};
  max-width: 33.25rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
