import React, { ReactElement, createContext, useState, useEffect, useContext } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import { TCard } from 'types/card';
import API_ENDPOINT from 'constants/apiEndpoint';

import { TCardsContext } from './CardsProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './CardsProvider.constants';

const cardsContext = createContext<TCardsContext>({
  cardsState: INITIAL_STATE,
  cardsDispatch: INITIAL_DISPATCH,
});

function CardsProvider({ children }: TChildrenOnly): ReactElement {
  const { get: requestGetCards, data } = useFetch<TCommonResponse<TCard[]>>(API_ENDPOINT.CARDS);
  const [cards, setCards] = useState<TCard[]>(INITIAL_STATE.cards);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data?.result) {
      setCards(data.result);
    }
  }, [data]);

  async function getCards() {
    try {
      setLoading(true);
      await requestGetCards();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  }

  function clear() {
    setCards(INITIAL_STATE.cards);
  }

  const value: TCardsContext = {
    cardsState: { cards, loading },
    cardsDispatch: { getCards, clear },
  };

  return <cardsContext.Provider value={value}>{children}</cardsContext.Provider>;
}

export default CardsProvider;

export const useCardsContext = (): TCardsContext => useContext(cardsContext);
