import { TCard } from 'types/card';
import CardType from 'enums/CardType';
import CardStatus from 'enums/CardStatus';

import { TGetCardStatuses } from './CardDetail.types';

export function getCardStatuses(card: TCard): TGetCardStatuses {
  const isPhysicalCard = card?.type === CardType.PHYSICAL;

  const isVirtualCard = card?.type === CardType.VIRTUAL;

  const isFreeze = [CardStatus.FROZEN, CardStatus.FREEZING].includes(card?.status);

  const isPhysicalCardOrdered =
    [CardStatus.CREATED, CardStatus.AWAITING_ACTIVATION].includes(card?.status) && isPhysicalCard;

  const isVirtualCardActivating =
    isVirtualCard && [CardStatus.CREATED, CardStatus.ACTIVATING].includes(card?.status);

  const isPhysicalCardActivating = isPhysicalCard && CardStatus.ACTIVATING === card?.status;
  const isCardActivating = isVirtualCardActivating || isPhysicalCardActivating;

  const isPhysicalCardAwaitingActivation =
    isPhysicalCard && [CardStatus.CREATED, CardStatus.AWAITING_ACTIVATION].includes(card?.status);

  const canSeeCardDetail = [
    CardStatus.ACTIVE,
    CardStatus.FREEZING,
    CardStatus.UNFREEZING,
    CardStatus.FROZEN,
  ].includes(card?.status);

  return {
    isPhysicalCard,
    isVirtualCard,
    isFreeze,
    isPhysicalCardOrdered,
    isCardActivating,
    isPhysicalCardAwaitingActivation,
    canSeeCardDetail,
  };
}
