import { TTheme } from 'theme/theme.types';
import { TShimmerShapeVariants, TShimmerColorVariants } from './Shimmer.types';

const DEFAULT_HEIGHT = 40;

const DEFAULT_LINE_HEIGHT = 8;

export const shimmerShapeVariants: TShimmerShapeVariants = {
  line: {
    width: '100%',
    height: DEFAULT_LINE_HEIGHT,
  },
  circle: {
    width: DEFAULT_HEIGHT,
    height: DEFAULT_HEIGHT,
    borderRadius: '50%',
  },
  square: {
    width: DEFAULT_HEIGHT,
    height: DEFAULT_HEIGHT,
  },
};

export const shimmerColorVariant: TShimmerColorVariants = {
  primary: {
    backgroundImage: ({ colors }: TTheme) =>
      renderLinearGradient(
        colors.shimmerPrimaryBackground,
        colors.shimmerPrimaryIndicatorBackground
      ),
  },
  secondary: {
    backgroundImage: ({ colors }: TTheme) =>
      renderLinearGradient(
        colors.shimmerSecondaryBackground,
        colors.shimmerSecondaryIndicatorBackground
      ),
  },
};

function renderLinearGradient(backgroundColor: string, indicatorColor: string) {
  return `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${indicatorColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor})`;
}
