import { object } from 'yup';

import {
  BIRTHDATE_VALIDATION,
  COUNTRY_VALIDATION,
  EMAIL_VALIDATION,
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  PHONE_NUMBER_VALIDATION,
} from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  firstName: FIRST_NAME_VALIDATION,
  lastName: LAST_NAME_VALIDATION,
  email: EMAIL_VALIDATION,
  phoneNumberCountry: COUNTRY_VALIDATION,
  phoneNumber: PHONE_NUMBER_VALIDATION,
  dateOfBirth: BIRTHDATE_VALIDATION,
});
