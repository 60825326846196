import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const GlobalIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M29.3333 15.9998C29.3333 23.3636 23.3638 29.3332 16 29.3332M29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665M29.3333 15.9998C21.5382 22.2359 10.4618 22.2359 2.66666 15.9998M29.3333 15.9998C21.5382 9.76373 10.4618 9.76373 2.66666 15.9998M16 29.3332C8.63619 29.3332 2.66666 23.3636 2.66666 15.9998M16 29.3332C22.2361 21.538 22.2361 10.4616 16 2.6665M16 29.3332C9.76389 21.538 9.76389 10.4616 16 2.6665M2.66666 15.9998C2.66666 8.63604 8.63619 2.6665 16 2.6665"
        stroke={colors[iconColor]}
        strokeWidth="2.66667"
      />
    </svg>
  );
};

export default GlobalIcon;
