import React from 'react';

import Text from 'components/core/Text';
import Dot from 'components/core/Dot';

import { StyledDigitCodeBox } from '../DigitCode.styles';
import { TDigitCodeBoxProps } from '../DigitCode.types';

function DigitCodeBox({ value, isInputFocused, secret }: TDigitCodeBoxProps) {
  return (
    <StyledDigitCodeBox isInputFocused={isInputFocused} hasValue={!!value}>
      {secret && value ? (
        <Dot size={8} color="textInput" />
      ) : (
        <Text variant="mediumTextBold" color="textInput">
          {value}
        </Text>
      )}
    </StyledDigitCodeBox>
  );
}

export default DigitCodeBox;
