import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatInvoiceAmount } from 'helpers/formatAmount';

import { StyledAmountDetailsTable } from '../InvoicePreviewPdf.styles';
import { TInvoicePreviewPdfAmountDetailsProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfAmountDetails({ amountDetails }: TInvoicePreviewPdfAmountDetailsProps) {
  const { t } = useTranslation();

  return (
    <StyledAmountDetailsTable>
      <tbody>
        <tr>
          <th className="text-secondary" colSpan={2}>
            {t('label.totalExclVat')}
          </th>
          <td>
            {formatInvoiceAmount({
              amount: amountDetails.totalNetAmount,
              currency: amountDetails.currency,
            })}
          </td>
        </tr>
        <tr>
          <th className="text-secondary" colSpan={2}>
            {t('label.totalVatAmount')}
          </th>
          <td>
            {formatInvoiceAmount({
              amount: amountDetails.totalTaxAmount,
              currency: amountDetails.currency,
            })}
          </td>
        </tr>
        <tr>
          <th className="text-secondary" colSpan={2}>
            {t('label.totalInclVat')}
          </th>
          <td>
            {formatInvoiceAmount({
              amount: amountDetails.totalGrossAmount,
              currency: amountDetails.currency,
            })}
          </td>
        </tr>
      </tbody>
    </StyledAmountDetailsTable>
  );
}

export default InvoicePreviewPdfAmountDetails;
