import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Dot from 'components/v2/core/Dot';
import { WamoIcon, WamoWordmarkIcon } from 'components/v2/BrandIcons';

import { TInvoicePreviewPdfFooterProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfFooter({
  registrationName,
  invoiceNumber,
}: TInvoicePreviewPdfFooterProps) {
  const { colors } = useTheme();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" py="sm">
      <Box display="flex" flexDirection="row" alignItems="center" gap="s" flex={1}>
        <Text variant="textBodySmall" color="textSecondary">
          {registrationName}
        </Text>
        <Dot color="textPassive" size={4} />
        <Text variant="textBodySmall" color="textPassive">
          {invoiceNumber}
        </Text>
      </Box>
      <Box display="flex" flexDirection="row" gap="xs" alignItems="center" justifyContent="center">
        <Text textId="label.createdWith.lowercase" variant="textBodySmall" color="textSecondary" />
        <Box display="flex" alignItems="center" width={24} height={16}>
          <WamoIcon width="100%" height="100%" iconColor={colors.textActive} />
        </Box>
        <Box display="flex" alignItems="center" maxWidth={64} height={16}>
          <WamoWordmarkIcon width="100%" height="100%" iconColor={colors.textPrimary} />
        </Box>
      </Box>
      <Box flex={1} />
    </Box>
  );
}

export default InvoicePreviewPdfFooter;
