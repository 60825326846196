import React from 'react';

import Box from 'components/core/Box';

import OnboardingNavDesktopItems from './OnboardingNavDesktopItems';
import OnboardingNavMobileItems from './OnboardingNavMobileItems';

import { TOnboardingApplicationSummaryLayoutNavProps } from '../../DashboardLayout.types';

import { StyledNav } from '../../DashboardLayout.styles';

function OnboardingNavLayout({
  activeParentStepIndex,
  activeChildStepIndex,
  hideProgressSteps,
  showMobileNav,
}: TOnboardingApplicationSummaryLayoutNavProps) {
  return (
    <StyledNav style={{ display: showMobileNav ? 'auto' : 'none' }}>
      <Box display={{ _: 'none', s: 'flex' }} flexDirection="column" gap="l" height="100%">
        <OnboardingNavDesktopItems
          activeParentStepIndex={activeParentStepIndex}
          activeChildStepIndex={activeChildStepIndex}
          hideProgressSteps={hideProgressSteps}
        />
      </Box>
      {showMobileNav && (
        <Box display={{ _: 'flex', s: 'none' }}>
          <OnboardingNavMobileItems />
        </Box>
      )}
    </StyledNav>
  );
}

export default OnboardingNavLayout;
