import { TCardStatusUpdateValue } from './CardDetail.types';

export const CARD_ACTIVATING_POLL_INTERVAL = 1500;

export enum CardStatusUpdate {
  FREEZE,
  UNFREEZE,
  TERMINATE,
  UNBLOCK_PIN,
}

export const CARD_STATUS_UPDATE_MAP: Record<CardStatusUpdate, TCardStatusUpdateValue> = {
  [CardStatusUpdate.FREEZE]: {
    titleId: 'screens.cardFreeze.title',
    buttonId: 'button.freezeCard',
    cardIconProps: { isFreeze: true },
  },
  [CardStatusUpdate.UNFREEZE]: {
    titleId: 'screens.cardUnfreeze.title',
    buttonId: 'button.unfreezeCard',
    cardIconProps: { isFreeze: false },
  },
  [CardStatusUpdate.UNBLOCK_PIN]: {
    titleId: 'screens.cardUnblockPin.title',
    buttonId: 'button.unblock',
    cardIconProps: {},
  },
  [CardStatusUpdate.TERMINATE]: {
    titleId: 'screens.cardTerminate.title',
    descriptionId: 'screens.cardTerminate.description',
    buttonId: 'button.terminateCard',
    buttonVariant: 'danger',
    cardIconProps: { isTerminate: true },
  },
};

export const CARD_SETTINGS_ANIMATION_VARIANTS = {
  initial: { opacity: 0, x: 40, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
};

export const CARD_SUMMARY_ANIMATION_VARIANTS = {
  initial: { opacity: 0, x: 0, y: -40 },
  enter: { opacity: 1, x: 0, y: 0 },
};
