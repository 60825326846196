import React from 'react';

function ItalyFlagIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g id="flags">
        <g clipPath="url(#clip0_17336_20565)">
          <rect width="48" height="48" rx="24" fill="#F4F5F8" />
          <path
            id="Vector"
            d="M24 47.997C37.2548 47.997 47.9999 37.2518 47.9999 23.997C47.9999 10.7422 37.2548 -0.00292969 24 -0.00292969C10.7451 -0.00292969 0 10.7422 0 23.997C0 37.2518 10.7451 47.997 24 47.997Z"
            fill="#FEFEFE"
          />
          <path
            id="Vector_2"
            d="M47.9998 23.9964C47.9998 13.6773 41.4869 4.88028 32.3477 1.48926V46.5037C41.4869 43.1126 47.9998 34.3156 47.9998 23.9964Z"
            fill="#C8102E"
          />
          <path
            id="Vector_3"
            d="M0 23.9964C0 34.3156 6.51299 43.1126 15.6522 46.5036V1.48926C6.51299 4.88028 0 13.6773 0 23.9964Z"
            fill="#008000"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_17336_20565">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ItalyFlagIcon;
