import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';

import API_ENDPOINT from 'constants/apiEndpoint';

import {
  TMerchantFeatureFlagContext,
  TMerchantFeatureFlagResponse,
  TMerchantFeatureFlagState,
} from './MerchantFeatureFlagProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './MerchantFeatureFlagProvider.constants';

const merchantFeatureFlagContext = createContext<TMerchantFeatureFlagContext>({
  merchantFeatureFlagState: INITIAL_STATE,
  merchantFeatureFlagDispatch: INITIAL_DISPATCH,
});

function MerchantFeatureFlagProvider({ children }: TChildrenOnly) {
  const { get: requestMerchantFeatureFlags, data } = useFetch<
    TCommonResponse<TMerchantFeatureFlagResponse>
  >(API_ENDPOINT.MERCHANT_FEATURE_FLAGS);
  const [state, setState] = useState<TMerchantFeatureFlagState>(INITIAL_STATE);

  useEffect(() => {
    if (data?.result) {
      setState(data.result);
    }
  }, [data]);

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = {
    merchantFeatureFlagState: state,
    merchantFeatureFlagDispatch: {
      getMerchantFeatureFlags: requestMerchantFeatureFlags,
      clear,
    },
  };

  return (
    <merchantFeatureFlagContext.Provider value={value}>
      {children}
    </merchantFeatureFlagContext.Provider>
  );
}

export default MerchantFeatureFlagProvider;

export const useMerchantFeatureFlagContext = (): TMerchantFeatureFlagContext =>
  useContext(merchantFeatureFlagContext);
