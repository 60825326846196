import { TMeResponse } from 'types/member';
import { TMerchant } from 'context/MerchantProvider/MerchantProvider.types';
import MerchantOnboardingStatus from 'enums/MerchantOnboardingStatus';

function updateUser(me: TMeResponse) {
  window?.Intercom?.('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: me?.id,
    email: me?.email,
    name: me?.firstName && `${me.firstName} ${me?.lastName}`,
    phone: me.phoneNumber,
  });
}

function updateCompany(merchant: TMerchant) {
  window?.Intercom?.('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    companies: [
      {
        id: merchant?.id,
        name: merchant?.registrationName,
      },
    ],
    customAttributes: {
      lead_status:
        merchant.onboardingStatus === MerchantOnboardingStatus.LIVE ? 'ONBOARDED' : 'LEAD',
      merchant_country_code: merchant.countryOfIncorporation.code ?? 'UNKNOWN',
      merchant_country_name: merchant.countryOfIncorporation.name ?? 'UNKNOWN',
      merchant_id: merchant?.id ?? 'UNKNOWN',
    },
  });
}

function clear() {
  window?.Intercom?.('shutdown');
  window?.Intercom?.('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });
}

function show() {
  window?.Intercom?.('show');
}

export default {
  updateUser,
  updateCompany,
  clear,
  show,
};
