import { TThemePrimitives } from 'theme/v2/theme.types';

import { TCardItemBaseStates } from './CardItemBase.types';

export function getCardItemBackgroundByState({
  pressed,
  hovered,
  selected,
}: TCardItemBaseStates): keyof TThemePrimitives {
  if (pressed) {
    return 'buttonGhostPrimaryHover';
  }
  if (hovered) {
    return 'backgroundSecondary';
  }
  if (selected) {
    return 'buttonGhostPrimary';
  }
  return 'transparent';
}
