import React from 'react';

import Text from 'components/core/Text';
import Spinner from 'components/core/Spinner';

import { StyledButton } from './Button.styles';
import { TButtonProps } from './Button.types';

function Button({
  id,
  textVariant = 'mediumTextSemiBold',
  icon: IconComponent,
  loading,
  disabled,
  block = true,
  type = 'button',
  variant = 'primary',
  rightComponent,
  textValues,
  ...rest
}: TButtonProps) {
  return (
    <StyledButton
      type={type}
      variant={variant}
      block={block}
      disabled={disabled || loading}
      {...rest}>
      <>
        {loading && <Spinner size={24} currentColor />}
        {!loading && IconComponent && <IconComponent iconColor="currentColor" />}
        {id && (
          <Text
            className="button-label"
            color="inherit"
            id={id}
            values={textValues}
            as="span"
            variant={textVariant}
          />
        )}
        {rightComponent && <div className="right-icon">{rightComponent}</div>}
      </>
    </StyledButton>
  );
}

export default Button;
