import styled from 'styled-components';

import media from 'helpers/media';
import { getColor } from 'helpers/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-template-rows: 3.5rem 1fr;
  height: 100vh;
  grid-template-areas:
    'header header'
    'main main'
    'navbar navbar';

  ${media.s} {
    grid-template-areas:
      'navbar header'
      'navbar main';
    grid-template-rows: 4.5rem 1fr;
  }

  ${media.l} {
    grid-template-columns: 14.75rem 1fr;
  }
`;

export const NavbarContainer = styled.nav`
  grid-area: navbar;
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  z-index: 10;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);

  ${media.s} {
    padding: 1.5rem 0;
    border-right: 1px solid ${(props) => props.theme.colors.borderDivider};
    box-shadow: none;
    bottom: unset;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 0;
    border-right: 1px solid ${getColor('borderDivider')};
  }
`;

export const HeaderContainer = styled.header`
  grid-area: header;
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDivider};
  width: 100%;
`;

export const MainContent = styled.main`
  grid-area: main;
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem 0;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  overflow: hidden;

  ${media.s} {
    padding: 1.5rem 2rem;
  }

  ${media.m} {
    padding: 1.5rem;
    grid-template-columns: 1fr 1.5fr;
  }
`;

export const LeftContent = styled.section`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  padding: 0 1.5rem;
  overflow-y: scroll;
  width: 100%;

  ${media.s} {
    padding: 0;
  }

  ${media.m} {
    max-width: 28rem;
    margin: 0 auto;
  }

  ${media.xl} {
    max-width: 36rem;
    margin: 0 auto;
  }
`;

export const RightContent = styled.section`
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  padding: 1rem;
  border-radius: 1rem;
  overflow-y: scroll;
  display: none;

  ${media.m} {
    display: block;
  }
`;
