import React, { createElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Text from 'components/v2/core/Text';

import { TNavItemProps } from './NavItem.types';
import { StyledBox } from './NavItem.styles';

function NavItem({ to, titleId, icon }: TNavItemProps) {
  const { pathname } = useLocation();

  return (
    <NavLink to={to}>
      <StyledBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="s"
        p="s"
        borderRadius={8}
        className={to === pathname ? 'selected' : ''}>
        {createElement(icon)}
        {titleId && (
          <Text
            className="nav-title"
            as="span"
            textId={titleId}
            variant="textBodySub"
            color="textSecondary"
          />
        )}
      </StyledBox>
    </NavLink>
  );
}

export default NavItem;
