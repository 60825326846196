import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import InfoItemShimmer from './views/InfoItemShimmer';
import { TInfoItemProps } from './InfoItem.types';
import { StyledText } from './InfoItem.styles';

function InfoItem({ titleId, value, valueColor, titleProps, ...rest }: TInfoItemProps) {
  return (
    <Box paddingY="m" {...rest}>
      <Text
        id={titleId}
        color="infoItemTitle"
        variant="smallTextSemiBold"
        mb="xs"
        {...titleProps}
      />
      <StyledText variant="largeTextSemiBold" color={valueColor}>
        {value}
      </StyledText>
    </Box>
  );
}

InfoItem.Shimmer = InfoItemShimmer;

export default InfoItem;
