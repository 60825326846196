import React from 'react';

import { TIconProps } from '../Icon.types';

const UsdIcon = (props: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FEFEFE"
        d="M24.986 48.003c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24Z"
      />
      <path
        fill="#C8102E"
        d="M23.943 24.004h25.043c0-2.166-.288-4.265-.826-6.26H23.943v6.26ZM23.943 11.483h21.52a24.125 24.125 0 0 0-5.537-6.261H23.943v6.26ZM24.986 48.004c5.649 0 10.84-1.952 14.94-5.217h-29.88a23.897 23.897 0 0 0 14.94 5.217ZM4.509 36.526h40.955a23.856 23.856 0 0 0 2.696-6.26H1.813a23.857 23.857 0 0 0 2.696 6.26Z"
      />
      <path
        fill="#1A2A6C"
        d="M12.104 3.75h2.187L12.256 5.23l.777 2.391L11 6.142 8.965 7.62l.671-2.066a24.13 24.13 0 0 0-4.655 5.188h.701l-1.295.94a23.97 23.97 0 0 0-.58 1.026l.618 1.904-1.154-.839a23.76 23.76 0 0 0-.785 1.864l.681 2.096h2.515l-2.034 1.478.777 2.392-2.035-1.478-1.218.885c-.122.98-.186 1.98-.186 2.993h24v-24c-4.74 0-9.16 1.375-12.882 3.748Zm.93 17.853-2.035-1.478-2.034 1.478.777-2.392-2.034-1.477h2.514L11 15.341l.777 2.391h2.515l-2.035 1.478.777 2.392Zm-.778-9.383.777 2.392L11 13.134l-2.034 1.478.777-2.392-2.034-1.478h2.514L11 8.351l.777 2.391h2.515l-2.035 1.478Zm9.386 9.383-2.034-1.478-2.035 1.478.778-2.392-2.035-1.477h2.515l.777-2.392.777 2.391h2.514l-2.034 1.478.777 2.392Zm-.777-9.383.777 2.392-2.034-1.478-2.035 1.478.778-2.392-2.035-1.478h2.515l.777-2.391.777 2.391h2.514l-2.034 1.478Zm0-6.991.777 2.391-2.034-1.478-2.035 1.478.778-2.391-2.035-1.478h2.515l.777-2.392.777 2.392h2.514l-2.034 1.478Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.986 0h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);

export default UsdIcon;
