import { TButtonSizes, TButtonVariants, TButtonShapes } from './Button.types';

export const BUTTON_VARIANTS: TButtonVariants = {
  primary: {
    backgroundColor: 'buttonPrimaryBackground',
    color: 'buttonPrimaryText',

    '&:hover': {
      backgroundColor: 'buttonPrimaryHoverBackground',
      color: 'buttonPrimaryTappedText',
    },

    '&:active': {
      borderColor: 'buttonPrimaryHoverBorder',
      backgroundColor: 'buttonPrimaryTappedBackground',
      color: 'buttonPrimaryTappedText',
    },

    ':disabled': {
      backgroundColor: 'buttonPrimaryDisabledBackground',
      color: 'buttonPrimaryDisabledText',
    },
  },
  secondary: {
    backgroundColor: 'buttonSecondaryBackground',
    color: 'buttonSecondaryText',
    borderColor: 'buttonSecondaryBorder',

    '&:hover': {
      color: 'buttonSecondaryTappedText',
      backgroundColor: 'buttonSecondaryHoverBackground',
      borderColor: 'buttonSecondaryHoverBorder',
      '.right-icon': {
        path: {
          stroke: 'buttonSecondaryTappedText',
        },
      },
    },

    '&:active': {
      color: 'buttonSecondaryTappedText',
      backgroundColor: 'buttonSecondaryTappedBackground',
      borderColor: 'buttonSecondaryTappedBorder',
      '.right-icon': {
        path: {
          stroke: 'buttonSecondaryTappedText',
        },
      },
    },

    ':disabled': {
      backgroundColor: 'buttonSecondaryDisabledBackground',
      color: 'buttonSecondaryDisabledText',
      borderColor: 'buttonSecondaryDisabledBorder',
    },
  },
  ghost: {
    backgroundColor: 'buttonGhostBackground',
    color: 'buttonGhostText',
    borderColor: 'buttonGhostBorder',
    '&:hover': {
      color: 'buttonGhostTappedText',
      backgroundColor: 'buttonGhostHoverBackground',
      borderColor: 'buttonGhostHoverBorder',
      '.right-icon': {
        path: {
          stroke: 'buttonGhostTappedText',
        },
      },
    },

    '&:active': {
      color: 'buttonGhostTappedText',
      backgroundColor: 'buttonGhostTappedBackground',
      borderColor: 'buttonGhostTappedBorder',
      '.right-icon': {
        path: {
          stroke: 'buttonGhostTappedText',
        },
      },
    },

    ':disabled': {
      backgroundColor: 'buttonGhostDisabledBackground',
      color: 'buttonGhostDisabledText',
      borderColor: 'buttonGhostDisabledBorder',
    },
  },
  link: {
    color: 'buttonLinkText',
    ':disabled': {
      color: 'buttonLinkDisabledText',
    },
  },
  danger: {
    backgroundColor: 'buttonDangerBackground',
    color: 'buttonDangerText',

    '&:hover': {
      color: 'buttonDangerHoverText',
      backgroundColor: 'buttonDangerHoverBackground',
      borderColor: 'buttonDangerTappedBorder',
    },

    '&:active': {
      color: 'buttonDangerTappedText',
      backgroundColor: 'buttonDangerTappedBackground',
      borderColor: 'buttonDangerTappedBorder',
    },
  },
  icon: {
    color: 'buttonTertiaryText',
    ':disabled': {
      color: 'buttonTertiaryDisabledText',
    },
  },
};

export const BUTTON_SIZES: TButtonSizes = {
  large: {
    height: '3.25rem',

    '> svg': {
      width: '32px',
      height: '32px',
    },
  },
  medium: {
    height: '2.5rem',

    '> svg': {
      width: '24px',
      height: '24px',
    },
  },
  small: {
    height: '2rem',
    borderRadius: '8px',

    '> svg': {
      width: '20px',
      height: '20px',
    },
  },
};

export const BUTTON_SHAPES: TButtonShapes = {
  circle: {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    padding: 0,
  },
  square: {
    width: '2.5rem',
    height: '2.5rem',
    padding: 0,
  },
};
