import React, { FC } from 'react';

import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TI18nId } from 'types/common';

export enum TAlertModalType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export type TAlertModalShowOptions = {
  title?: string;
  titleValues?: Record<string, string | number>;
  titleId?: TI18nId;
  text?: string;
  textValues?: Record<string, string | number>;
  textId?: TI18nId;
  type?: TAlertModalType;
  bottomContent?: React.FC<TAlertModalRef>;
  disableOnClose?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
  icon?: FC<TIconProps>;
};

export type TAlertModalRef = { show: (options: TAlertModalShowOptions) => void; hide: () => void };
