import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TInvoiceDetailDispatch, TInvoiceDetailState } from './InvoiceDetailProvider.types';

export const INITIAL_STATE: TInvoiceDetailState = {
  invoice: undefined,
  loading: true,
};

export const INITIAL_DISPATCH: TInvoiceDetailDispatch = {
  setInvoice: NOOP,
  requestGetInvoiceDetail: ASYNC_NOOP,
  refetchInvoiceDetail: ASYNC_NOOP,
  clear: NOOP,
};
