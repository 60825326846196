import React, { FC, useMemo } from 'react';

import Avatar from 'components/v2/core/Avatar';
import { ArrowsRightIcon } from 'components/Icons';

import { TIconProps } from 'components/v2/Icons/Icon.types';
import { getAvatarColorKeyByHash } from 'components/v2/BeneficiaryItem/BeneficiaryItem.helpers';
import getAvatarLetters from 'helpers/avatarLetters';

import { TScheduledTransactionItemAvatarProps } from '../ScheduledTransactionItem.types';

function ScheduledTransactionItemAvatar({
  title,
  avatarProps,
  isBulkTransfer,
}: TScheduledTransactionItemAvatarProps) {
  const avatarColor = useMemo(() => getAvatarColorKeyByHash(title), [title]);

  if (isBulkTransfer) {
    return (
      <Avatar
        backgroundColor="backgroundSecondary"
        size="small"
        icon={ArrowsRightIcon as FC<TIconProps>}
        iconColor="textSecondary"
      />
    );
  }

  return (
    <Avatar
      label={getAvatarLetters(title)}
      backgroundColor={avatarColor}
      size="small"
      {...avatarProps}
    />
  );
}

export default ScheduledTransactionItemAvatar;
