import React, { ReactElement } from 'react';

import { StyledHeader } from './StickyHeader.styles';
import { TStickyHeaderProps } from './StickyHeader.types';

function StickyHeader({ children, ...rest }: TStickyHeaderProps): ReactElement {
  return <StyledHeader {...rest}>{children}</StyledHeader>;
}

export default StickyHeader;
