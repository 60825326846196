enum TransactionDirectionType {
  INBOUND = 0,
  OUTBOUND = 1,
}

export function getSignByTransactionDirectionType(direction: TransactionDirectionType) {
  return direction === TransactionDirectionType.OUTBOUND ? '-' : '+';
}

export default TransactionDirectionType;
