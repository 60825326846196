import React from 'react';

import Text from 'components/core/Text';
import Spinner from 'components/core/Spinner';

import { TActionButtonProps } from '../CardDetail.types';
import ActionButtonContainer from '../CardDetail.styles';

function ActionButton({ titleId, onClick, loading, icon: Icon }: TActionButtonProps) {
  return (
    <ActionButtonContainer type="button" onClick={onClick} disabled={loading}>
      {loading ? (
        <Spinner color="tertiary" size={32} />
      ) : (
        <Icon iconColor="primary" width={32} height={32} />
      )}
      <Text
        id={titleId}
        variant="mediumTextBold"
        mt="xs"
        textAlign="center"
        color={loading ? 'cardActionLabelDisabled' : 'textPrimary'}
      />
    </ActionButtonContainer>
  );
}

export default ActionButton;
