import React from 'react';

import StickyScrollHeader from 'components/StickyScrollHeader';
import { CloseIcon } from 'components/Icons';

import { useSideLayoutContext } from 'context/SideLayoutProvider';
import { TChildrenOnly } from 'types/common';
import { StyledContainer } from '../SideLayoutPage.styles';

function SideLayoutPageShimmer({ children }: TChildrenOnly) {
  const { sideLayoutDispatch } = useSideLayoutContext();
  return (
    <StyledContainer>
      <StickyScrollHeader.Shimmer
        navButton={{
          icon: CloseIcon,
          onClick: () => sideLayoutDispatch.hideSideLayout(),
        }}
      />
      {children}
    </StyledContainer>
  );
}

export default SideLayoutPageShimmer;
