import NOOP from 'constants/noop';

import { TCardsDispatch, TCardsState } from './CardsProvider.types';

export const INITIAL_STATE: TCardsState = {
  cards: [],
  loading: false,
};

export const INITIAL_DISPATCH: TCardsDispatch = {
  getCards: NOOP as TCardsDispatch['getCards'],
  clear: NOOP,
};
