import React, { ReactElement, useRef } from 'react';
import { Controller } from 'react-hook-form';

import Text from 'components/core/Text';
import Box from 'components/core/Box';
import CountryImage from 'components/CountryImage';
import SelectCountryModal from 'modals/SelectCountryModal';
import { GlobalIcon } from 'components/Icons';

import { useCountriesContext } from 'context/CountriesProvider';
import { TControllerRenderProps } from 'types/form';
import { COUNTRY_DEFAULT_VALUE } from 'constants/form';
import { TSelectCountryModalRef } from 'modals/SelectCountryModal/SelectCountryModal.types';

import { StyledButton } from './FieldCountryCode.styles';
import { TFieldCountryCodeProps } from './FieldCountryCode.types';

// TODO:(melih) Field country code shouldn't dependent to default showCallingCode prop
function FieldCountryCode({
  headerTitleId,
  control,
  name,
  hasError,
  defaultValue = COUNTRY_DEFAULT_VALUE,
  disabled = false,
  ...rest
}: TFieldCountryCodeProps) {
  const modalRef = useRef<TSelectCountryModalRef>(null);
  const { countriesState } = useCountriesContext();

  function handleRender({ field }: TControllerRenderProps): ReactElement {
    const { onChange, value } = field;

    return (
      <StyledButton
        type="button"
        disabled={disabled}
        onClick={() =>
          modalRef.current?.show({
            onSelect: onChange,
            headerTitleId,
          })
        }
        hasError={hasError}>
        <Box alignment="row.verticalCenter">
          <Box mr="s">
            <Text
              id="label.country"
              variant="xSmallTextRegular"
              color={disabled ? 'textInputTopLabelDisabled' : 'textPrimary'}
            />
            <Text variant="mediumTextBold">{`+${value?.callingCode}`}</Text>
          </Box>
          {value?.code ? (
            <CountryImage code={value?.code} size={24} />
          ) : (
            <GlobalIcon width={24} height={24} iconColor="selectDefaultIcon" />
          )}
        </Box>
      </StyledButton>
    );
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={handleRender}
        defaultValue={defaultValue}
        {...rest}
      />
      <SelectCountryModal modalRef={modalRef} options={countriesState.countries} showCallingCode />
    </>
  );
}

export default FieldCountryCode;
