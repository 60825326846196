import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const CardIcon = ({ iconColor = 'primary', ...rest }: TIconProps): ReactElement => {
  const { colors } = useTheme();

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...rest}>
      <path
        d="M2.5 10L2.5 17C2.5 18.1046 3.39543 19 4.5 19L20.5 19C21.6046 19 22.5 18.1046 22.5 17L22.5 10M2.5 10L2.5 7C2.5 5.89543 3.39543 5 4.5 5L20.5 5C21.6046 5 22.5 5.89543 22.5 7V10M2.5 10L22.5 10M19.5 14L13.5 14"
        stroke={colors[iconColor]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
