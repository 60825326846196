import React from 'react';

import Form from 'components/Form';
import Text from 'components/core/Text';
import Box from 'components/core/Box';
import FieldDigitCode from 'components/FieldDigitCode';

import useForm from 'hooks/useForm';
import NOOP from 'constants/noop';
import { CARD_3DS_STATIC_PASSWORD_LENGTH } from 'constants/card';

import { FORM_VALIDATION_SCHEMA } from './CardStaticPasswordForm.constants';
import {
  TCardStaticPasswordFormData,
  TCardStaticPasswordFormProps,
} from './CardStaticPasswordForm.types';

function CardStaticPasswordForm({
  formButtonProps,
  pageHeaderProps,
  onSubmit,
}: TCardStaticPasswordFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TCardStaticPasswordFormData>({
    schema: FORM_VALIDATION_SCHEMA,
  });

  const staticPassword = watch('staticPassword');

  return (
    <>
      <Text id={pageHeaderProps.titleId} variant="largeTitleBold" mb="s" />
      <Text id={pageHeaderProps.descriptionId} variant="largeTextRegular" mb="l" />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        buttonProps={{
          disabled: staticPassword?.length !== CARD_3DS_STATIC_PASSWORD_LENGTH,
          ...formButtonProps,
        }}>
        <Box alignment="center">
          <FieldDigitCode
            name="staticPassword"
            control={control}
            errors={errors}
            onSubmit={NOOP}
            autoComplete="off"
          />
        </Box>
      </Form>
    </>
  );
}

export default CardStaticPasswordForm;
