import {
  TBusinessOwnersDispatch,
  TBusinessOwnersState,
} from 'context/BusinessOwnersProvider/BusinessOwnersProvider.types';
import NOOP, { ASYNC_NOOP } from 'constants/noop';

export const INITIAL_STATE: TBusinessOwnersState = {
  directors: [],
  directorsLoading: false,
  shareholders: [],
  shareholdersLoading: false,
};

export const INITIAL_DISPATCH: TBusinessOwnersDispatch = {
  getDirectors: ASYNC_NOOP,
  getShareholders: ASYNC_NOOP,
  clear: NOOP,
};
