import { FC } from 'react';

import { BankNote01Icon, Percent02Icon } from 'components/v2/Icons';

import { TI18nId } from 'types/common';
import { TOption } from 'components/v2/core/RadioGroup/RadioGroup.types';
import { TIconProps } from 'components/v2/Icons/Icon.types';

enum InvoiceItemDiscountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

const MAP: Record<
  InvoiceItemDiscountType,
  {
    labelId: TI18nId;
    icon: FC<TIconProps>;
  }
> = {
  [InvoiceItemDiscountType.PERCENTAGE]: {
    labelId: 'label.percentage',
    icon: Percent02Icon,
  },
  [InvoiceItemDiscountType.ABSOLUTE]: {
    labelId: 'label.amount',
    icon: BankNote01Icon,
  },
};

export function getLabelIdByInvoiceItemDiscountType(
  discountType: InvoiceItemDiscountType
): TI18nId {
  return MAP[discountType].labelId;
}

export function getIconByInvoiceItemDiscountType(discountType: InvoiceItemDiscountType) {
  return MAP[discountType].icon;
}

export function getUnitTypeRadioGroup(): TOption<InvoiceItemDiscountType>[] {
  return [
    {
      label: getLabelIdByInvoiceItemDiscountType(InvoiceItemDiscountType.PERCENTAGE),
      value: InvoiceItemDiscountType.PERCENTAGE,
    },
    {
      label: getLabelIdByInvoiceItemDiscountType(InvoiceItemDiscountType.ABSOLUTE),
      value: InvoiceItemDiscountType.ABSOLUTE,
    },
  ];
}

export default InvoiceItemDiscountType;
