import styled from 'styled-components';
import { motion } from 'framer-motion';

import Input from 'components/core/Input';

import spacing from 'theme/spacing';
import { getSpacing } from 'helpers/theme';

export const StyledInput = styled(Input)`
  height: 40px;

  .base-input {
    height: 40px;
    padding-bottom: ${getSpacing('m')};
    padding-right: 3rem;
  }
`;

export const CloseIconWrapper = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-right: ${spacing.m};
  padding-left: ${spacing.s};
  cursor: pointer;
  z-index: 2;
`;
