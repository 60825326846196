import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const LogoutIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M18.0741 21.334L12.8889 16.0007M12.8889 16.0007L18.0741 10.6673M12.8889 16.0007H25.3333M17.037 26.6673C14.2867 26.6673 11.6489 25.5435 9.70408 23.5431C7.75926 21.5427 6.66667 18.8296 6.66667 16.0007C6.66667 13.1717 7.75926 10.4586 9.70408 8.45818C11.6489 6.45779 14.2867 5.33398 17.037 5.33398"
        stroke={colors[iconColor]}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
