import React, { useImperativeHandle, forwardRef, Ref } from 'react';

import Form from 'components/Form';
import FieldPasscode from 'components/FieldPasscode';
import Text from 'components/core/Text';

import useForm from 'hooks/useForm';
import { TI18nId } from 'types/common';

import { FORM_VALIDATION_SCHEMA } from './PasswordForm.constants';
import { TPasswordFormData, TPasswordFormRef, TPasswordFormProps } from './PasswordForm.types';

function PasswordForm(
  { onSubmit, descriptionId, loading, children }: TPasswordFormProps,
  ref: Ref<TPasswordFormRef>
) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm<TPasswordFormData>({
    schema: FORM_VALIDATION_SCHEMA,
  });

  useImperativeHandle(ref, () => ({
    clear,
    setErrorMessage,
  }));

  function setErrorMessage(message: TI18nId) {
    setError('password', {
      message,
    });
  }

  function clear() {
    reset();
    setValue('password', '');
  }

  return (
    <Form isFullPage={false}>
      {descriptionId && <Text id={descriptionId} variant="largeTextRegular" mb="l" />}
      {children}
      <FieldPasscode
        name="password"
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
      />
    </Form>
  );
}

export default forwardRef(PasswordForm);
