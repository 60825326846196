import React from 'react';
import { useFetch } from 'use-http';
import { useNavigate } from 'react-router-dom';

import Card from 'components/core/Card';
import SectionItem from 'components/core/SectionItem';
import ScheduledTransactionHistoryItem from 'components/ScheduledTransactionHistoryItem';
import ListItemButton from 'components/core/ListItemButton';

import API_ENDPOINT from 'constants/apiEndpoint';
import { TScheduledTransactionOrderHistoryItem } from 'types/scheduledTransaction';
import normalizeUrl from 'helpers/normalizeUrl';
import routes from 'router/routes';
import toPath from 'helpers/toPath';
import useTransactionItemSelect from 'hooks/useTransactionItemSelect';

import { TScheduledTransactionOrderHistorySectionProps } from './ScheduledTransactionOrderHistorySection.types';

function ScheduledTransactionOrderHistorySection({
  scheduledId,
}: TScheduledTransactionOrderHistorySectionProps) {
  const navigate = useNavigate();
  const { selectedTransactionId, handleSelectItem: handleSelectTransactionItem } =
    useTransactionItemSelect();

  const { data } = useFetch(
    normalizeUrl(API_ENDPOINT.SCHEDULED_TRANSACTION_ORDERS_HISTORY, {
      pathVariables: {
        id: scheduledId,
      },
      queryParams: {
        page: 0,
        size: 2,
      },
    }),
    []
  );

  const scheduledTransactionOrdersHistory = data?.result?.content ?? [];

  function handleNavigateToDetail(
    scheduledTransactionOrderHistoryItem: TScheduledTransactionOrderHistoryItem
  ) {
    if (scheduledTransactionOrderHistoryItem.batchId) {
      return navigate(
        toPath(routes.bulkTransferDetail.path, {
          id: scheduledTransactionOrderHistoryItem.batchId,
        })
      );
    }
    if (scheduledTransactionOrderHistoryItem.transactionId) {
      return handleSelectTransactionItem(scheduledTransactionOrderHistoryItem.transactionId);
    }
    return undefined;
  }

  if (!scheduledTransactionOrdersHistory.length) {
    return <></>;
  }

  return (
    <Card marginX="m" mt="m">
      <SectionItem
        titleId="label.paymentHistory"
        rightButton={{
          id: 'button.seeAll',
          onClick: () =>
            navigate(
              toPath(routes.scheduledTransactionOrderHistory.path, {
                scheduledTransactionId: scheduledId,
              })
            ),
        }}
      />
      {scheduledTransactionOrdersHistory.map(
        (scheduledTransactionOrdersHistoryItem: TScheduledTransactionOrderHistoryItem) => (
          <ListItemButton
            key={
              scheduledTransactionOrdersHistoryItem.transactionId ||
              scheduledTransactionOrdersHistoryItem.batchId
            }
            aria-pressed={
              !!scheduledTransactionOrdersHistoryItem.transactionId &&
              selectedTransactionId === scheduledTransactionOrdersHistoryItem.transactionId
            }
            onClick={() => handleNavigateToDetail(scheduledTransactionOrdersHistoryItem)}>
            <ScheduledTransactionHistoryItem item={scheduledTransactionOrdersHistoryItem} />
          </ListItemButton>
        )
      )}
    </Card>
  );
}

export default ScheduledTransactionOrderHistorySection;
