import { useCallback, useEffect, useRef } from 'react';

import { TUsePolling } from './usePolling.types';

function usePolling({ pollInterval = 10000, pollingFunc }: TUsePolling) {
  const pollingFetchId = useRef<NodeJS.Timer | number>();

  useEffect(
    () => () => {
      stopPolling();
    },
    []
  );

  async function handleFetch() {
    try {
      const { errorCode } = await pollingFunc();

      if (errorCode) {
        stopPolling();
      }
    } catch (e) {
      stopPolling();
    }
  }

  const stopPolling = useCallback(() => {
    clearInterval(pollingFetchId?.current as NodeJS.Timeout);
  }, [pollingFetchId?.current]);

  const reFetch = useCallback(async () => {
    stopPolling();
    pollingFetchId.current = setInterval(handleFetch, pollInterval);
  }, [pollingFetchId?.current]);

  return {
    stopPolling,
    reFetch,
  };
}

export default usePolling;
