import { TPalette } from './theme.types';

const palette: TPalette = {
  black: '#051726',
  purple: '#B347FF',
  lightBlue: '#0FE3FF',

  blue900: '#002B99',
  blue800: '#003ACC',
  blue: '#0048FF',
  blue400: '#99B6FF',
  blue300: '#E6EDFF',
  blue200: '#9FB8CB',
  blue100: '#cfdbe5',

  teal900: '#19A0B3',
  teal800: '#1CB2C7',
  teal100: '#BBE8EE',

  orange: '#FF6F00',
  orange100: '#ff6f0014',

  wamoLight800: '#F7F2E9',

  gray900: '#212529',
  gray800: '#343A40',
  gray700: '#495057',
  gray600: '#868E96',
  gray500: '#ADB5BD',
  gray400: '#CED4DA',
  gray300: '#DEE2E6',
  gray200: '#E9ECEF',
  gray100: '#F1F3F5',
  gray90: '#E8E8E8',
  gray80: '#CCCCCC',
  gray70: '#B3B3B3',
  gray60: '#999999',
  gray50: '#F8F9FA',
  gray40: '#F5F5F5',
  gray: '#FDFDFE',

  newGray50: '#808080',
  newGray40: '#666666',
  newGray30: '#4D4D4D',

  white: '#FFFFFF',
  white100: '#FBF9F4',
  white200: '#FEFDFB',
  white300: '#9AA1A6',

  red900: '#993131',
  red800: '#CC4141',
  red700: '#FA5252',
  red500: '#FE5151',
  red400: '#FFCBCB',
  red300: '#FFEEEE',
  red100: '#FFEEEE',
  red50: '#FFF5F5',

  green900: '#2F9E44',
  green800: '#37B24D',
  green700: '#40C057',
  green600: '#008B4F',
  green500: '#00E883',
  green300: '#DBF4E9',
  green200: '#5E9C8F',
  green100: '#E3FDF2',
  green50: '#EBFBEE',

  yellow900: '#F59F00',
  yellow800: '#FAB005',
  yellow700: '#FCC419',
  yellow50: '#FFF9DB',

  transparent: 'transparent',
};

export default palette;
