import NOOP from 'constants/noop';

import {
  TInvoicePreviewPdfDispatch,
  TInvoicePreviewPdfState,
} from './InvoicePreviewPdfProvider.types';

export const INITIAL_STATE: TInvoicePreviewPdfState = {
  invoice: undefined,
};

export const INITIAL_DISPATCH: TInvoicePreviewPdfDispatch = {
  setInvoice: NOOP,
  clear: NOOP,
};
