import i18n from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import Locale from 'enums/Locale';
import { STORAGE_KEYS, STORAGE_PREFIX } from 'helpers/storage';

import { en, it } from 'translations';

const detectionOptions = {
  order: ['localStorage', 'navigator'],
  caches: [],
  lookupLocalStorage: `${STORAGE_PREFIX}${STORAGE_KEYS.PREFERRED_LANGUAGE}`,
  checkWhitelist: true,
  convertDetectedLanguage: (lng: string) => lng?.slice(0, 2) ?? 'en',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: detectionOptions,
    compatibilityJSON: 'v3',
    resources: {
      en: {
        translation: en,
      },
      it: {
        translation: it,
      },
    },
    keySeparator: false,
    nsSeparator: false,
    fallbackLng: Locale.EN,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
