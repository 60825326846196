import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useInvoiceCreateContext } from 'context/InvoiceCreateProvider';
import { useInvoiceCreatePreviewPdfContext } from 'context/InvoiceCreatePreviewPdfProvider';
import routes from 'router/routes';
import { TChildrenOnly } from 'types/common';
import InvoiceStatus from 'enums/InvoiceStatus';
import toPath from 'helpers/toPath';

import InvoicePreviewLayout from '../InvoicePreviewLayout';

function InvoiceCreateLayout({ children }: TChildrenOnly) {
  const navigate = useNavigate();
  const urlParams = useParams<{ invoiceId: string }>();
  const { invoiceCreateDispatch } = useInvoiceCreateContext();
  const {
    invoiceCreatePreviewPdfState: { invoice },
  } = useInvoiceCreatePreviewPdfContext();

  useEffect(() => {
    if (urlParams.invoiceId) {
      handleGetDraftInvoiceDetail();
    }
  }, []);

  async function handleGetDraftInvoiceDetail() {
    await invoiceCreateDispatch.getDraftInvoiceDetail({
      invoiceId: urlParams.invoiceId as string,
      onSuccess: (invoiceDetail) => {
        if (invoiceDetail.status === InvoiceStatus.DRAFT) {
          return invoiceCreateDispatch.setInvoice(invoiceDetail);
        }
        return navigate(
          toPath(routes.invoiceDetail.path, {
            invoiceId: invoiceDetail.id,
          }),
          {
            replace: true,
          }
        );
      },
      onError: () => navigate(routes.invoicesDashboard.path),
    });
  }

  return <InvoicePreviewLayout invoice={invoice}>{children}</InvoicePreviewLayout>;
}

export default InvoiceCreateLayout;
