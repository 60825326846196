import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

const SettingsIcon = ({ iconColor = 'primary', ...rest }: TIconProps) => {
  const { colors } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M8 21.3327H4M8 21.3327C8 23.5418 9.79086 25.3327 12 25.3327C14.2091 25.3327 16 23.5418 16 21.3327M8 21.3327C8 19.1235 9.79086 17.3327 12 17.3327C14.2091 17.3327 16 19.1235 16 21.3327M28 21.3327H16M16 10.666H4M16 10.666C16 12.8752 17.7909 14.666 20 14.666C22.2091 14.666 24 12.8752 24 10.666M16 10.666C16 8.45688 17.7909 6.66602 20 6.66602C22.2091 6.66602 24 8.45688 24 10.666M28 10.666H24"
        stroke={colors[iconColor]}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
