import React, { createContext, ReactElement, useContext, useState, useEffect } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TTeamMember } from 'types/teamMember';

import { INITIAL_DISPATCH, INITIAL_STATE } from './TeamMembersProvider.constants';
import { TTeamMemberState, TTeamMembersContext } from './TeamMembersProvider.types';

const teamMembersContext = createContext<TTeamMembersContext>({
  teamMembersState: INITIAL_STATE,
  teamMembersDispatch: INITIAL_DISPATCH,
});

function TeamMembersProvider({ children }: TChildrenOnly): ReactElement {
  const {
    get: requestTeamMembers,
    data,
    response,
  } = useFetch<TCommonResponse<TTeamMember[]>>(API_ENDPOINT.TEAM_MEMBERS);
  const [{ teamMembers, loading }, setState] = useState<TTeamMemberState>(INITIAL_STATE);

  useEffect(() => {
    if (data?.result && response.ok) {
      setState({ teamMembers: data.result, loading: false, hasError: false });
    }
  }, [data]);

  function clear() {
    setState(INITIAL_STATE);
  }

  async function getTeamMembers() {
    await requestTeamMembers();
  }

  const value: TTeamMembersContext = {
    teamMembersState: {
      teamMembers,
      loading,
      hasError: !response.ok,
    },
    teamMembersDispatch: { getTeamMembers, clear },
  };

  return <teamMembersContext.Provider value={value}>{children}</teamMembersContext.Provider>;
}

export default TeamMembersProvider;

export const useTeamMembersContext = (): TTeamMembersContext => useContext(teamMembersContext);
