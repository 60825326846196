import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function Apple01Icon({ width = 24, height = 24, iconColor = 'textPrimary' }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M15.9735 2.09619C16.097 3.28004 15.6289 4.43855 14.9391 5.30136C14.2244 6.13996 13.0919 6.80488 11.9584 6.70656C11.8106 5.57233 12.3775 4.36532 13.0181 3.62588C13.7315 2.76239 14.9637 2.1464 15.9735 2.09619ZM13.3116 7.51739C14.0479 7.22729 14.9579 6.86873 15.9592 6.92837C16.598 6.97779 18.4406 7.17445 19.6199 8.91864C19.614 8.92312 19.6009 8.93165 19.5816 8.94428C19.2788 9.14243 17.4348 10.3492 17.4581 12.7289C17.481 15.5643 19.7939 16.6274 20.1019 16.7689C20.1223 16.7783 20.1339 16.7836 20.1354 16.7852C20.1341 16.789 20.1319 16.7963 20.1286 16.8069C20.0685 16.9999 19.6687 18.2842 18.7602 19.6119C17.9249 20.8415 17.0648 22.0459 15.689 22.0705C15.0414 22.0825 14.6045 21.8953 14.1499 21.7005C13.6731 21.4962 13.1768 21.2836 12.3975 21.2836C11.5801 21.2836 11.0599 21.503 10.5597 21.714C10.1276 21.8963 9.7104 22.0723 9.12955 22.0951C7.80309 22.1437 6.7964 20.7911 5.96109 19.5627C4.24101 17.0797 2.93899 12.5574 4.7078 9.5087C5.56777 7.98472 7.14022 7.02653 8.83489 7.00164C9.57475 6.98808 10.2829 7.26795 10.9008 7.51215C11.3703 7.6977 11.7877 7.86266 12.1273 7.86266C12.4353 7.86266 12.8374 7.70423 13.3116 7.51739Z"
        fill={colors[iconColor as never]}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Apple01Icon;
