import i18next from 'i18next';

import { TCardHolderMember } from 'types/card';

export function getCardHolderName(holderMember: TCardHolderMember) {
  return holderMember?.firstName && holderMember?.lastName
    ? `${holderMember.firstName} ${holderMember.lastName}`
    : undefined;
}

function getCardHolderTitle(holderMember: TCardHolderMember, memberId?: string) {
  const shouldShowYouLabel = holderMember.id === memberId;
  const holderName = getCardHolderName(holderMember);

  if (holderName) {
    return `${holderName}${shouldShowYouLabel ? ` (${i18next.t('label.you')})` : ''}`;
  }

  return holderMember.email;
}

export default getCardHolderTitle;
